import React, { useContext } from 'react';

import {
  Row,
  Col
} from 'reactstrap';

import {
  DivShadowNonFlex
} from '~/styles/divs'

import {
  CompanyManager,
  InternalAgencyDash,
  ResumeDash,
  DivSelected,
  DivSelectedNone
} from './styles';

import MyAgencyContext from '../../context';

function Dashboard() {
  const {
    dash,
    handleChageDash
  } = useContext(MyAgencyContext);

  return (
    <Row>
      <Col sm={12} md={4} style={{margin: '8px 0',}}>
        <DivShadowNonFlex>
          <CompanyManager
            onClick={() => {
              if (dash === 1) {
                return false;
              }

              return handleChageDash(1);
            }}>
            <div>
              <h1>
                Gestão de Empresas
              </h1>

              <p>
                Gerencie empresas, seus respectivos usuários, centros de custo, dentre outros.
              </p>
            </div>

            {dash === 1 && (
              <DivSelected />
            )}

            {dash !== 1 && (
              <DivSelectedNone />
            )}
          </CompanyManager>
        </DivShadowNonFlex>
      </Col>

      <Col sm={12} md={4} style={{ margin: '8px 0', }}>
        <DivShadowNonFlex>
          <ResumeDash
            onClick={() => {
              if (dash === 2) {
                return false;
              }

              return handleChageDash(2);
            }}>
            <div>
              <h1>
                Gestão da Agência
              </h1>

              <p>
                Gerencie as informações da sua agencia.
              </p>
            </div>

            {dash === 2 && (
              <DivSelected />
            )}

            {dash !== 2 && (
              <DivSelectedNone />
            )}
          </ResumeDash>
        </DivShadowNonFlex>
      </Col>

      <Col sm={12} md={4} style={{margin: '8px 0',}}>
        <DivShadowNonFlex>
          <InternalAgencyDash
            onClick={() => {
              if (dash === 3) {
                return false;
              }

              return handleChageDash(3);
            }}>
            <div>
              <h1>
                Usuários da Agência
              </h1>

              <p>
                Gerencie o cadastro de usuários internos: suporte, financeiro, dentre outros.
              </p>
            </div>

            {dash === 3 && (
              <DivSelected />
            )}

            {dash !== 3 && (
              <DivSelectedNone />
            )}
          </InternalAgencyDash>
        </DivShadowNonFlex>
      </Col>
    </Row>
  );
}

export default Dashboard;
