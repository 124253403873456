import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ApiAxios } from '~/services/api.js';
import SliderToggle from '~/components/SliderToggle';

import {
  Card,
  CardBody,
  CardBodyItem,
} from '~/styles/card';

import {
  DivFlexRight,
  SpaceBetweenDiv
} from '~/styles/divs';

import {
  CreateAction
} from '~/components/ActionsOfTables';

import {
  EditAction
} from '~/components/ActionsOfTables';

import {
  Container,
} from '~/styles/container';

import {
  Row,
  Col
} from 'reactstrap';

import EmptyList from '~/components/EmptyList';

import {
  NewCompany,
  NewCompanyMobile,
} from './styles';

function ListCompanies({
  setType,
  setCompanyToDetail
}) {
  const axios = ApiAxios();
  const accessToken = useSelector(state => state.auth.accessToken);
  const [companies, setCompanies] = useState([]);
  const [originalCompanies, setOriginalCompanies] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [alterStatus, setAlterStatus] = useState(false);

  useEffect(() => {
    async function getCompanies() {
      try
      {
        axios.defaults.headers.Authorization = `Bearer ${accessToken}`;

        const companiesResponse = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client`
        );

        setOriginalCompanies(companiesResponse.data);

        return setCompanies(companiesResponse.data);
      } catch (error) {
        return toast.error('Não foi possível listar Empresas Cadastradas.');
      }
    }

    getCompanies();
  }, [accessToken]);

  async function handleStatusClient(companyCode, status) {
    try {
      setAlterStatus(true);

      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/status/${companyCode}`
      );

      setOriginalCompanies(response.data);

      setCompanies(response.data);

      toast.info(
        `Empresa ${status ? 'desativada' : 'ativada'} com sucesso.`
      );

      return setAlterStatus(false);
    } catch (error) {
      setAlterStatus(false);

      return console.log("Não foi possível atualizar a Empresa.");
    }
  }

  async function handleSearch (value) {
    setSearchFilter(value);

    if (value.length === 0) {
      return setCompanies(originalCompanies);
    }

    const newCompanies = originalCompanies.filter(
      x => x.name.toUpperCase().includes(value.toUpperCase()) || x.fantasy.toUpperCase().includes(value.toUpperCase())
    );

    setCompanies(newCompanies);

    return false;
  }

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <SpaceBetweenDiv>
            <Row style={{ width: '100%' }}>
              <NewCompanyMobile sm={12} style={{ textAlign: 'right' }}>
                <CreateAction
                  type='button'
                  style={{
                    backgroundColor: '#000000',
                  }}
                  onClick={() => setType('create')}>
                  Nova Empresa
                </CreateAction>
              </NewCompanyMobile>

              <Col sm={12} md={8} lg={5}>
                <input
                  name='search-companies'
                  value={searchFilter}
                  placeholder={'Pesquise por uma empresa cadastrada...'}
                  onChange={e => handleSearch(e.target.value)}
                  style={{
                    border: '#ccc 1px solid',
                    borderRadius: '4px',
                    padding: '4px',
                    height: '32px',
                    width: '100%',
                  }}
                />
              </Col>

              <NewCompany sm={12} md={4} lg={7} style={{ textAlign: 'right' }}>
                <CreateAction
                  type='button'
                  style={{
                    backgroundColor: '#000000',
                  }}
                  onClick={() => setType('create')}>
                  Nova Empresa
                </CreateAction>
              </NewCompany>
            </Row>

          </SpaceBetweenDiv>
        </Col>

        <Col sm={12}>
          <Card>
            <CardBody>
              {companies.length === 0 && (<EmptyList />)}

              {companies.map((e, i) => {
                return (
                  <CardBodyItem key={i}>
                    <Col sm={12} lg={7}>
                      <p>
                        <b>{ e.fantasy }</b>
                      </p>

                      <p>{ e.name }</p>
                    </Col>

                    <Col sm={12} lg={5}>
                      <DivFlexRight>
                        <p>
                          <EditAction
                            type='button'
                            onClick={() => {
                              setCompanyToDetail(e);

                              return setType('details');
                            }}>
                            Gerenciar
                          </EditAction>
                        </p>

                        <SliderToggle
                          label={'Status'}
                          checked={e.status}
                          click={() => handleStatusClient(e.code, e.status)}
                        />
                      </DivFlexRight>
                    </Col>
                  </CardBodyItem>
                );
              })}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ListCompanies;
