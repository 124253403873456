import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApiAxios } from '~/services/api.js';
import { MdInsertDriveFile } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';

import DownloadFile from '~/functions/DownloadFile';

import { Container, SpinnerWrapper } from './styles';

export default function ModalDocs({
  isOpenModal,
  setIsOpenModal,
  accessToken,
  FaturaHandle,
}) {
  const axios = ApiAxios();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    async function getDocs() {
      try {
        setLoading(true);

        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INVOICE}/invoice/docs`,
          {
            FaturaHandle,
          },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );

        setFiles(
          response.data.map(file => ({
            ...file,
            downloading: false,
          }))
        );

        setLoading(false);
      } catch (error) {
        toast.error('Não foi possível obter a lista de documentos.');
        setLoading(false);
      }
    }

    getDocs();

    return () => setFiles([]);
  }, []); // eslint-disable-line

  function handleToggleDownloading(id, status) {
    setFiles(
      files.map(file =>
        file.rloc === id ? { ...file, downloading: status } : file
      )
    );
  }

  async function handleDownload(id) {
    try {
      handleToggleDownloading(id, true);

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_INVOICE}/invoice/download/document/${id}`,
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      DownloadFile(
        `data:application/octet-stream;base64,${response.data}`,
        `${id}.pdf`
      );

      handleToggleDownloading(id, false);
    } catch (error) {
      handleToggleDownloading(id, false);

      toast.error(`Não foi possível baixar o documento solicitado: ${id}`);
    }
  }

  async function handleDownloadAll() {
    try {
      setDownloading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_INVOICE}/invoice/download/documents/${FaturaHandle}`,
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const link = document.createElement('a');
      link.href = `data:application/octet-stream;base64,${response.data}`;
      link.setAttribute('download', `${Date.now()}.zip`); // or any other extension
      document.body.appendChild(link);
      link.click();

      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      toast.error(`Não foi possível baixar o documentos!`);
    }
  }

  function handleToggleModal() {
    return setIsOpenModal(!isOpenModal);
  }

  return (
    <Modal isOpen={isOpenModal} toggle={handleToggleModal}>
      <ModalHeader toggle={handleToggleModal}>
        Documentos Fatura - {FaturaHandle}
      </ModalHeader>
      <ModalBody>
        <Container>
          {loading ? (
            <SpinnerWrapper>
              <Spinner size="lg" color="primary" />
            </SpinnerWrapper>
          ) : (
            <ul>
              {files.map(file => (
                <li key={file.rloc}>
                  <button
                    type="button"
                    className="fileInfo"
                    onClick={() => handleDownload(file.rloc)}
                  >
                    <MdInsertDriveFile size={24} color="#A5Cfff" />
                    <strong>{file.rloc}.pdf</strong>
                  </button>

                  {file.downloading && <Spinner size="sm" color="#ffff" />}
                </li>
              ))}
            </ul>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleDownloadAll}
          disabled={downloading}
        >
          {!downloading ? 'Baixar todos' : <Spinner size="sm" color="#ffff" />}
        </Button>
        <Button color="secondary" onClick={handleToggleModal}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ModalDocs.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  setIsOpenModal: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
  FaturaHandle: PropTypes.number.isRequired,
};
