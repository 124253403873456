import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Background = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginContent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;

  @media (min-width: 768px) {
    padding-left: 32px;
  }
`;


export const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TLink = styled(Link)`
  color: #fff;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;

export const Logo = styled.img`
  width: 100%;
  height: 80px;
  object-fit: contain;
`;

export const RightContent = styled.div`
  color: #ffff !important;
  opacity: .92;
  background-color: #D54C35 !important;
  width: 350px;
  padding: 15px;

  @media (max-width: 768px) {
    width: 100%;
  }

  .button-submit{
    background: #68ebde;
    font-weight: bold;
    color: #6118B7;
    width: 130px;
    height: 30px;
    border: none;
    margin: 10px 0;
    :focus{
      outline: none
    }
  }

  .g-recaptcha{
    transform:scale(.75);
    transform-origin: center;
  }
  
  /* i:hover {
  color: #00fcb6;
  cursor: pointer;
  } */

  /*.btn-disabled-login{
    background: #40c3d7;
  }*/
`;
