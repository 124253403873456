const CompanyPermission = (userProfileId) => {
  switch (userProfileId) {
    case 1:
      return ({ all: true });
    case 4:
      return ({ all: true });
    case 6:
      return ({ all: true });
    case 7:
      return ({ all: false });
    default:
      return ({ all: false });
  }
}

export default CompanyPermission;
