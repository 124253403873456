/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react';

import { Panel, PanelHeader } from '~/components/Panel';
import { Row, Col } from 'reactstrap';
import {
  CreateAction,
  CloseAction,
  CancelDeleteAction
} from '~/components/ActionsOfTables';

import {
  Container,
  ContainerCentered,
  ContainerPt8Pb4
} from '~/styles/container';

import {
  SpaceBetweenDiv
} from '~/styles/divs';

import {
  ButtonCard,
  ButtonCardSmall
} from '~/styles/buttons/card';

import SideBar from './components/SideBar';
import NewUser from './components/NewUser';
import List from './components/List';
import Edit from './components/Edit';
import Delete from './components/Delete';
import UserProfile from './components/UserProfile';

function InternalRegistration() {
  const [page, setPage] = useState('list');
  const [typeOfList, setTypeOfList] = useState(1);

  const [userToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);

  return (
    <Panel theme='inverse'>
      <PanelHeader noButton title={'Cadastros Internos'} />

      <Container>
        <Row>
          {page === 'list' && (
            <Col sm={12} style={{borderBottom: '1px solid #ccc'}}>
              <ContainerPt8Pb4>
                <Row>
                  <Col sm={12} lg={9}>
                    <h3>Gerencie Usuários Internos</h3>
                  </Col>

                  <Col sm={6} lg={3}>
                    <ButtonCardSmall
                      bg={'#000000'}
                      fg={'#fff'}
                      onClick={() =>
                      setPage('newuser')}>
                      Novo
                    </ButtonCardSmall>
                  </Col>
                </Row>
              </ContainerPt8Pb4>
            </Col>
          )}

          {page === 'userProfile' && (
            <Col sm={12} style={{borderBottom: '1px solid #ccc'}}>
              <ContainerPt8Pb4>
                <Row>
                  <Col sm={12} lg={9}>
                    <h3>Gerencie Tipos de Usuários</h3>
                  </Col>

                  <Col sm={6} lg={3}>

                  </Col>
                </Row>
              </ContainerPt8Pb4>
            </Col>
          )}

          <Col md={12} lg={4} xl={3}>
            <SideBar
              page={page}
              setPage={setPage}
              typeOfList={typeOfList}
              setTypeOfList={setTypeOfList} />
          </Col>

          <Col md={12} lg={8} xl={9}>
            <ContainerPt8Pb4>
              {page === 'newuser' && <NewUser setPage={setPage} />}

              {page === 'list' &&
                <List
                  setPage={setPage}
                  typeOfList={typeOfList}
                  setUserToEdit={setUserToEdit}
                  setUserToDelete={setUserToDelete} />
              }

              {page === 'edit' &&
                <Edit
                  setPage={setPage}
                  typeOfList={typeOfList}
                  userToEdit={userToEdit}
                  setUserToEdit={setUserToEdit} />
              }

              {page === 'delete' &&
                <Delete
                  setPage={setPage}
                  typeOfList={typeOfList}
                  userToDelete={userToDelete}
                  setUserToDelete={setUserToDelete} />
              }

              {page === 'userProfile' &&
                <UserProfile />
              }
            </ContainerPt8Pb4>
          </Col>
        </Row>
      </Container>
    </Panel>
  );
}

export default InternalRegistration;
