import React, {useState} from 'react';

import Tooltip from '~/components/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AiFillInfoCircle } from "react-icons/ai";
import { AiFillPushpin } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import { AiOutlinePushpin } from "react-icons/ai";

import {
  Container,
  Title,
  Row,
  CardBody,
  IconsCard
} from './styles'

const WhiteCard = ({
  children,
  title,
  modalTitle = '',
  modalDesc = '',
  PinChart = () => {},
  UnpinChart = () => {},
  chartId,
  pinned,
  setpinned,
  likes,
  setLikes
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function getLikeInfo () {
    if ((pinned ? likes + 1 : likes) === 0) {
      return `
        Compartilhe experiências!
        Marque esse indicador como importante, adicione-o ao Meu Painel.
      `;
    }

    if (pinned) {
      return `
        Você compartilha dessa experiência! Ao total, esse indicador está ajudando ${pinned ? likes + 1 : likes} usuário${(pinned ? likes + 1 : likes) === 1 ? '' : 's'} na tomada de decisões importantes.
      `;
    }

    return `
      Esse indicador está ajudando ${pinned ? likes + 1 : likes} usuário${(pinned ? likes + 1 : likes) === 1 ? '' : 's'} na tomada de decisões importantes, compartilhe dessa experiência! Adicione-o ao Meu Painel!
    `;
  }

  function handlePin () {
    PinChart(chartId, setpinned);
  }

  function handleUnpin () {
    UnpinChart(chartId, setpinned);
  }

  return (
    <>
      <Container>
        <Row>
          <Title>
            {title}
          </Title>

          <IconsCard>

            <>
              {pinned ? likes + 1 : likes}
              <AiFillStar
                id={`likes${chartId}`}
                size={18}
                style={{marginRight: '4px'}}
                color={'#ffcf3f'}
              />
              <Tooltip
                target={`likes${chartId}`}
                text={getLikeInfo()}
              />
            </>

            {pinned && (
              <AiFillPushpin
                size={18}
                style={{cursor: 'pointer', marginRight: '4px'}}
                color={'#6725a0'}
                onClick={() => handleUnpin()}
              />
            )}

            {!pinned && (
              <AiOutlinePushpin
                size={18}
                style={{cursor: 'pointer', marginRight: '4px'}}
                color={'#6725a0'}
                onClick={() => handlePin()}
              />
            )}

            <AiFillInfoCircle
              size={18}
              style={{cursor: 'pointer'}}
              color={'#000000'}
              onClick={() => setShow(chartId)}
            />
          </IconsCard>
        </Row>

        <CardBody>
          {children}
        </CardBody>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalDesc}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WhiteCard
