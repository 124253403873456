import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';
import { getDefaultChartColors } from '~/functions/GetColors.js';
// import { Container } from './styles';

export default function VolumeFee({
  data
}) {
  const defaultChartData = {
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    datasets: [{
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: getDefaultChartColors(12),
      borderWidth: 4,
      label: 'Valor total',

    }],
  };

  const [chartData1, setChartData1] = useState({});

  useEffect(() => {
    async function loadData() {

      if (data.months.length > 0)
      {
        return setChartData1({
          labels: data.months.map(e => `${e.monthLabelPtBr}`),
          datasets: [{
            data: data.months.map(e => e.sum),
            backgroundColor: getDefaultChartColors(data.months.length),
            borderWidth: 4,
            label: 'Valor total',

          }],
        })
      }

      return setChartData1(defaultChartData);
    }

    loadData();
  }, [data])

  return (
    <>
      <div style={styles.valuesPercents}>
        <h3>{FormatValueBRL(data.total)}</h3>

        <h3>{Math.round(data.variationAverage)}%*</h3>
      </div>
      <div style={{ width: '50%', margin: '1% auto', }}></div>
      <Bar data={chartData1} options={{
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (label, index, labels) {
                  return FormatValueBRL(label);
                },
                beginAtZero: true
              },
            }
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return `Valor total: ${FormatValueBRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`;
            }
          },
        },
      }} />
      <p>*Variação média mensal</p>
    </>
  );
}

const styles = {
  inlineChart: {
    display: "flex",
    justifyContent: "space-between"
  },
  valuesPercents: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%'
  }
}
