import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ApiAxios } from '~/services/api.js';
import { DefaultDate } from '~/functions/DefaultDate';
import ShowSelects from '~/functions/ShowSelects';
import CompanyPermission from '~/functions/CompanyPermission';
import TicketsContext from './context';

import Header from './components/Header';
import Filters from './components/Filters';
import Table from "./components/Table";

export default function Losses() {
  const axios = ApiAxios();
  const user = useSelector(state => state.user.profile);
  const userProfile = useSelector(state => state.user.userProfile);
  const accessToken = useSelector(state => state.auth.accessToken);
  const companies = useSelector(state => state.user.companies);
  const costCenters = useSelector(state => state.user.costCenters);

  const formRef = useRef(null);
  const [data, setData] = useState([]);
  const [costCentersSelected, setCostCentersSelected] = useState([]);
  const [companySelected, setCompanySelected] = useState(null);
  const [pax, setPax] = useState('');
  const [dateState, setDateState] = useState(DefaultDate);
  const [loading, setLoading] = useState(false);
  const [lastObtUpdate, setLastObtUpdate] = useState(null);

  const [companiesOptions, setCompaniesOptions] = useState(
    companies.map(e => ({
      label: e.nomeFantasia,
      value: e.codCliente
    }))
  );

  const [costCentersOptions, setCostCentersOptions] = useState(
    costCenters.map(e => ({
      label: e.costCenter,
      value: e.costCenter,
    }))
  );

  useEffect(() => {
    async function loadTable() {
      try {
        setData([]);

        setLoading(true);

        if (companiesOptions.length === 1) {
          const {
            selectedDate: { startDate, endDate },
          } = dateState;

          const StartDate = Number(startDate.split('/').reverse().join(''));

          const EndDate = Number(endDate.split('/').reverse().join(''));

          const Company = companiesOptions[0].value;

          const CostCenters = costCentersOptions.map(e => (e.value));

          const response = await axios.post(
            `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/losses`,
            {
              StartDate,
              EndDate,
              Company,
              CostCenters,
              pax,
            }
          );

          if (response.data)
            setData(response.data);
          else
            setData([]);
        }


        return setLoading(false);
      } catch (error) {
        toast.info('Nada foi encontrado, tente novamente');

        return setLoading(false);
      }
    }

    loadTable();
  }, []);

  useEffect(() => {
    async function getCostCenters() {
      if (companySelected !== null && ShowSelects(userProfile.userProfileId).companies) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/${companySelected.value}/costCenters`,
          );

          return setCostCentersOptions(data.map(e => {
            return {
              label: e.name,
              value: e.name
            };
          }));
        } catch (error) {
          return setCostCentersOptions([]);
        }
      }

      return setCostCentersOptions(costCenters.map(e => {
        return {
          label: e.costCenter,
          value: e.costCenter
        }
      }));
    }

    getCostCenters();
  }, [companySelected]);

  function handleResetForm() {
    formRef.current.reset();

    setPax('');

    setCostCentersSelected([]);

    setCompanySelected(null);

    return setDateState(DefaultDate);
  }

  function resetDateState() {
    return setDateState(DefaultDate);
  }

  async function handleSubmit() {
    try {
      setData([]);

      setLoading(true);

      if (companiesOptions.length > 1 && companySelected === null) {
        toast.info('Escolha uma empresa.');

        return setLoading(false);
      }

      const {
        selectedDate: { startDate, endDate },
      } = dateState;

      const StartDate = Number(startDate.split('/').reverse().join(''));

      const EndDate = Number(endDate.split('/').reverse().join(''));

      const Company = companySelected !== null
        ? companySelected.value
        : companiesOptions[0].value;

      const CostCenters = costCentersSelected.length > 0
        ? costCentersSelected.map(e => (e.value))
        : costCentersOptions.map(e => (e.value))

      const filter = {
        StartDate,
        EndDate,
        Company,
        CostCenters,
        pax,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/losses`,
        filter,
      );

      if (response.data)
        setData(response.data);
      else
        setData([]);

      return setLoading(false);
    } catch (error) {
      toast.info('Nada foi encontrado, tente novamente');

      return setLoading(false);
    }
  }

  useEffect(() => {
    async function loadLastObtUpdate() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/losses/getlastobtupdate/1`,
        );
        setLastObtUpdate(response.data);
      } catch (error) {
        return setLastObtUpdate(null);
      }
    }

    loadLastObtUpdate();
  }, [data]);

  return (
    <TicketsContext.Provider value={{
      axios,
      user,
      accessToken,
      companies,
      costCenters,
      formRef,
      handleResetForm,
      handleSubmit,
      resetDateState,
      data,
      setData,
      dateState,
      userProfile,
      setDateState,
      loading,
      setLoading,
      companiesOptions,
      setCompaniesOptions,
      costCentersOptions,
      setCostCentersOptions,
      costCentersSelected,
      setCostCentersSelected,
      companySelected,
      setCompanySelected,
      pax,
      setPax,
      lastObtUpdate
    }}>
      <Header />

      <Filters />

      <Table />
    </TicketsContext.Provider>
  );
}
