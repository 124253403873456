import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import NationalTicketsRemission from '../../../components/Widgets/NationalTicketsRemission';
import WhiteCard from '../../../components/WhiteCard'
import ShowSelects from '~/functions/ShowSelects';
import CompanyPermission from '~/functions/CompanyPermission';

import DashboardContext from '../../../context';

export default function NationalTicketsRemission8 () {
  const chartId = 8;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);
  const userProfile = useSelector(state => state.user.userProfile);

  const {
    accessToken,
    companiesOptions,
    setCompaniesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCentersOptions,
    setCostCentersOptions,
    costCentersSelected,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [Datad, setDatad] = useState({
    quant_Tickets: 0
  });

  const [NationalTicketsRemissionData, setNationalTicketsRemissionData] = useState({
    jan: 0,
    fev: 0,
    mar: 0,
    abr: 0,
    mai: 0,
    jun: 0,
    jul: 0,
    ago: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dez: 0
  });

  useEffect(() => {
    async function loadData () {
      try {
        setNationalTicketsRemissionData({
          jan: 0,
          fev: 0,
          mar: 0,
          abr: 0,
          mai: 0,
          jun: 0,
          jul: 0,
          ago: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dez: 0,
          jancount: 0,
          fevcount: 0,
          marcount: 0,
          abrcount: 0,
          maicount: 0,
          juncount: 0,
          julcount: 0,
          agocount: 0,
          sepcount: 0,
          octcount: 0,
          novcount: 0,
          dezcount: 0
        });

        setLoading(true);

        setLikes(user.preferences.dashboard.chart8);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        const Companies = companiesSelected.length === 0
        ? companiesOptions.map(x => (x.value))
        : companiesSelected.map(x => (x.value));

        const CostCenters = !CompanyPermission(userProfile.userProfileId).all
          ? (
            costCentersSelected.length === 0
              ? costCentersOptions.map(e => (e.value))
              : costCentersSelected.map(e => (e.value))
          )
          : costCentersSelected.map(e => (e.value));

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const mes = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/tickets/ticketsRee/d`,
          {
            handle: Companies,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: CostCenters
          },
          options
        );

        const datad = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/tickets/ticketsD`,
          {
            handle: Companies,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: CostCenters
          },
          options
        );

        if(mes.data){
          setNationalTicketsRemissionData({
            jan: mes.data[0].jan,
            fev: mes.data[0].fev,
            mar: mes.data[0].mar,
            abr: mes.data[0].abr,
            mai: mes.data[0].mai,
            jun: mes.data[0].jun,
            jul: mes.data[0].jul,
            ago: mes.data[0].ago,
            sep: mes.data[0].sep,
            oct: mes.data[0].oct,
            nov: mes.data[0].nov,
            dez: mes.data[0].dez,
            jancount: mes.data[0].jancount,
            fevcount: mes.data[0].fevcount,
            marcount: mes.data[0].marcount,
            abrcount: mes.data[0].abrcount,
            maicount: mes.data[0].maicount,
            juncount: mes.data[0].juncount,
            julcount: mes.data[0].julcount,
            agocount: mes.data[0].agocount,
            sepcount: mes.data[0].sepcount,
            octcount: mes.data[0].octcount,
            novcount: mes.data[0].novcount,
            dezcount: mes.data[0].dezcount,

          })
        }

        if(datad.data){
            setDatad({
              quant_Tickets: datad.data[0].quant_Tickets
            })
          }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected]);

  return (
    <WhiteCard
      title={'Reemissão de bilhetes - Nacional'}
      modalTitle='Observação'
      modalDesc='Valor gasto com remarcações de passagens nacionais.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <NationalTicketsRemission data={NationalTicketsRemissionData} datad={Datad}/>
    </WhiteCard>
  );
}
