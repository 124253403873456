import React, { useContext } from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import { Form } from '@unform/web';

import Input from '~/components/Input';
import CompanySelectSingle from '~/components/CompanySelectSingle';
import DatePicker from '~/components/DatePickerIndicators';
import CostCenterSelect from '~/components/CostCenterSelect';
import { Panel, PanelHeader, PanelBody } from '~/components/Panel';

import ShowSelects from '~/functions/ShowSelects';

import TicketsContext from '../../context';

import { FilterButtons } from '../../styles';

function Filters() {
  const {
    formRef,
    handleResetForm,
    handleSubmit,
    resetDateState,
    dateState,
    setDateState,
    loading,
    companiesOptions,
    setCompanySelected,
    costCentersOptions,
    setCostCentersSelected,
    pax,
    setPax,
    userProfile,
  } = useContext(TicketsContext);

  return (
    <Panel theme="inverse">
      <PanelHeader noButton title="Filtros" />

      <PanelBody>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row>
            <Col  sm={12} md={6} lg={4}>
              <DatePicker
                name="period"
                label="Período"
                setState={setDateState}
                state={dateState}
                reset={resetDateState}
              />
            </Col>

            {ShowSelects(userProfile.userProfileId).companies && companiesOptions.length > 1 && (
              <Col sm={12} md={6} lg={5} xl={4}>
                <CompanySelectSingle
                  name="companies"
                  label="Empresas"
                  placeholder="Selecione..."
                  options={companiesOptions}
                  setCompanySelected={setCompanySelected}
                  setCostCentersSelected={setCostCentersSelected}
                  disable={loading}
                />
              </Col>
            )}

            {/* {ShowSelects(userProfile.userProfileId).costCenters && costCentersOptions.length > 0 && (
              <Col sm={12} md={6} lg={5} xl={4}>
                <CostCenterSelect
                  name="costCenters"
                  label="Centros de Custo"
                  placeholder="Selecione..."
                  options={costCentersOptions}
                  setCostCentersSelected={setCostCentersSelected}
                  disable={loading}
                />
              </Col>
            )} */}

            <Col  sm={12} md={6} lg={4}>
              <Input
                name="passenger"
                label="Viajante"
                placeholder="Viajante"
                value={pax}
                onChange={e => setPax(e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <FilterButtons>

                <button
                  className="btn btn-warning"
                  type="button"
                  onClick={handleResetForm}
                  disabled={loading}>
                  {loading && <Spinner color="#fff" size="sm" />}

                  {!loading && 'Limpar'}
                </button>

                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}>
                  {loading && <Spinner color="#fff" size="sm" />}

                  {!loading && 'Aplicar'}
                </button>
              </FilterButtons>
            </Col>
          </Row>
        </Form>
      </PanelBody>
    </Panel>
  );
}

export default Filters;
