import React from 'react';
import { Panel, PanelHeader } from "~/components/Panel";
import { useRefund } from 'context';
import ReactTable from '~/components/Table';
import Details from './components/Details';
import {
  columns
} from './constants';

function Table() {
  const {
    data,
    loading,
    openModal,
    user
  } = useRefund();

  const showButton = user.solicitacaovisualreemb === true ? 1 : 2;

  return (
    <Panel theme="inverse">
      <PanelHeader noButton title="Resultado da Pesquisa" />

      <ReactTable
        columns={columns({
          userProfile: 1,
          openModal,
          view: showButton,
        })}
        data={data}
        tableProps={{
          defaultPageSize: 10,
          hasFooter: false,
          hasPagination: true,
          loading: loading,
          resizable: true
        }}
        tableStyle={{
          fontSize: '13px',
          cellPadding: '6px 4px'
        }}
        tableLabels={{
            loadingText: 'Carregando...',
            noDataText: 'Sem registros',
            nextButtonText: 'Próximo',
            previousButtonText: 'Anterior',
            pageOfText: 'de',
            pageSizeSelectText: 'registros',
            pageText: 'Página'
        }}
        subComponent={({ row }) => {
          return (
            <Details data={ row.original } view={showButton}/>
          );
        }}
      />
    </Panel>
  );
}

export default Table;
