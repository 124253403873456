import React from 'react';
import { FormatValueBRL } from '~/functions/FormatValue';

export const columns = [

  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        <i className={`
          fas fa-caret-${row.isExpanded ? 'up' : 'down'}
        `} />
      </span>
    ),
    width: 40
  },

  {
    Header: 'Agrupamento',
    accessor: 'grouping',
    width: 100,
    show: true,
    style: {
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    Footer: () => {
      return <div style={{
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold'
      }}>
        Resumo
      </div>;
    }
  },
  // {
  //   Header: 'Produto',
  //   accessor: 'product',
  //   width: 100,
  //   show: false,
  //   style: {
  //     whiteSpace: 'pre-wrap',
  //     padding: '8px 4px',
  //     textAlign: 'center'
  //   }
  // },
  {
    Header: 'Transações',
    accessor: 'count',
    width: 120,
    show: true,
    style: {
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
      textAlign: 'center'
    },
    Footer: info => {
      const total = React.useMemo(
        () => info.data.reduce((sum, row) => row.count + sum, 0), [info.data]
      );

      return <div style={{
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold'
      }}>
        {total}
      </div>;
    }
  },
  {
    Header: 'Não Faturado',
    accessor: 'sumNotBilled',
    width: 120,
    style: {
      textAlign : 'right'
    },
    Cell: props => FormatValueBRL(props.value),
    Footer: info => {
      const total = React.useMemo(
        () => info.data.reduce((sum, row) => row.sumNotBilled + sum, 0), [info.data]
      );

      return <div style={{
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold'
      }}>
        {FormatValueBRL(total)}
      </div>;
    }
  },
  {
    Header: 'Faturado',
    accessor: 'sumNotPaid',
    width: 120,
    style: {
      textAlign : 'right'
    },
    Cell: props => FormatValueBRL(props.value),
    Footer: info => {
      const total = React.useMemo(
        () => info.data.reduce((sum, row) => row.sumNotPaid + sum, 0),
        [info.data]
      );

      return <div style={{
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold'
      }}>
        {FormatValueBRL(total)}
      </div>;
    }
  },
  {
    Header: 'Liquidado',
    accessor: 'sumPaid',
    width: 120,
    style: {
      textAlign : 'right'
    },
    Cell: props => FormatValueBRL(props.value),
    Footer: info => {
      const total = React.useMemo(
        () => info.data.reduce((sum, row) => row.sumPaid + sum, 0),
        [info.data]
      );

      return <div style={{
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold'
      }}>
        {FormatValueBRL(total)}
      </div>;
    }
  },
  {
    Header: 'Valor Total',
    accessor: 'total',
    width: 120,
    show: true,
    style: {
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
      textAlign : 'right'
    },
    Cell: props => FormatValueBRL(props.value),
    Footer: info => {
      const total = React.useMemo(
        () => info.data.reduce((sum, row) => row.total + sum, 0),
        [info.data]
      );

      return <div style={{
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold'
      }}>
        {FormatValueBRL(total)}
      </div>;
    }
  },
];
