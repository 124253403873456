function FormatValueBRL(value) {
  if (value) {
    return value.toLocaleString(
      'pt-BR',
      {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }
    );
  }

  return '0,00'.toLocaleString(
    'pt-BR',
    {
      style: 'currency',
      currency: 'BRL',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }
  );
}

function GetCurrencyValue (value) {
  if (value)
    return parseInt(value.replace(/\D/g, '')) / 100;

  return 0;
}

function FormatInputValue ({
  value = '',
  maxValue = 18,
  onChangeCallback = () => {}
}) {
  if (value && value.length < maxValue) {
    var newValue = GetCurrencyValue(value);

    if (newValue > 0)
      return onChangeCallback(FormatValueBRL(newValue));
    else
      return onChangeCallback(FormatValueBRL(0));
  }

  if (!value)
    return onChangeCallback(FormatValueBRL(0));
}

export { FormatValueBRL, GetCurrencyValue, FormatInputValue };
