import styled from 'styled-components';

import {
  Row,
  Col
} from 'reactstrap';

export const CompanyManager = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #a6d59a;
  border-radius: 8px;
  padding: 8px 8px 4px;

  &:hover {
    cursor: pointer;
    background-color: #96c57a;
  }

  h1 {
    color: #333;
    font-weight: bold;
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }

  p {
    color: #333;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
  }
`;

export const InternalAgencyDash = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fbba37;
  border-radius: 8px;
  padding: 8px 8px 4px;

  &:hover {
    cursor: pointer;
    background-color: #db9a17;
  }

  h1 {
    color: #333;
    font-weight: bold;
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }

  p {
    color: #333;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
  }
`;

export const ResumeDash = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #db575b;
  border-radius: 8px;
  padding: 8px 8px 4px;

  &:hover {
    cursor: pointer;
    background-color: #bb373b;
  }

  h1 {
    color: #333;
    font-weight: bold;
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }

  p {
    color: #333;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
  }

`;

export const DivSelected = styled.div`
  margin: 4px auto;
  width: 40%;
  height: 8px;
  border-radius: 4px;
  background: linear-gradient(to top, white, transparent);
`;

export const DivSelectedNone = styled.div`
  margin: 4px auto;
  width: 40%;
  height: 8px;
  border-radius: 3px;
  background: transparent;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
`;
