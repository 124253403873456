import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Row, Col, Spinner } from 'reactstrap';
import ShowSelects from '~/functions/ShowSelects';

import InvoiceContext from '../../context';
import { Panel, PanelHeader, PanelBody } from '~/components/Panel';
import Input from '~/components/Input';
import Select from '~/components/Select';
import DatePicker from '~/components/DatePickerIndicators';

import { FilterButtons } from './styles';
import CostCenterSelect from '~/components/CostCenterSelect';
import CompanySelect from '~/components/CompanySelect';
import ExecutiveSelect from '~/components/ExecutiveSelect';

import { DefaultDateInvoice } from "../../constants";

export default function Filters ({
  pageFilters,
  setPageFilters,
  pageOptions,
}) {
  const userProfile = useSelector(state => state.user.userProfile);

  const {
    companiesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCentersOptions,
    costCentersSelected,
    setCostCentersSelected,
    executivesOptions,
    executivesSelected,
    setExecutivesSelected,
    formRef,
    loading,
    dateState,
    setDateState,
  } = useContext(InvoiceContext);

  async function handleSubmit(values) {
    const Companies = companiesSelected.length === 0
      ? companiesOptions
      : companiesSelected;

    const CostCenters = costCentersSelected.length === 0
      ? costCentersOptions
      : costCentersSelected;

    return setPageFilters({
      ...pageFilters,
      dateState: dateState,
      companies: Companies,
      costs: CostCenters,
      status: values.status ?? 'Todos',
      product: values.product,
      invoiceNumber: Number(values.invoice),
      reference: Number(values.sell),
      traveler: values.passenger,
      os: values.numOS,
    });
  }

  async function handleResetForm() {
    formRef.current.reset();

    setCostCentersSelected([]);

    setCompaniesSelected([]);

    setExecutivesSelected([]);

    setDateState(DefaultDateInvoice);

    return await handleSubmit({
      status: '',
      product: '',
      invoice: 0,
      sell: 0,
      passenger: '',
      numOS: '',
    });
  }

  async function resetDate() {
    return setDateState(DefaultDateInvoice);
  }

  return (
    <Panel theme="inverse">
      <PanelHeader noButton title="Filtros" />
      <PanelBody>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row>
            <Col sm={12} md={6} lg={4} xl={3}>
              <DatePicker
                label="Período de Vencimento"
                setState={setDateState}
                state={dateState}
                reset={resetDate}
                disable={loading}
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Select
                name="status"
                label="Status da Fatura"
                placeholder="Selecione..."
                isClearable
                options={[
                  { label: 'Em Aberto', value: 'Aberto' },
                  { label: 'Vencidas', value: 'Vencida' },
                  { label: 'Liquidadas', value: 'Liquidada' },
                ]}
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Select
                name="product"
                label="Produto"
                placeholder="Selecione..."
                isClearable
                options={pageOptions.products}
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                name="invoice"
                label="N° Fatura"
                placeholder="N° Fatura"
                type="number"
              />
            </Col>

            {executivesOptions.length > 0 && (
              <Col sm={12} md={6} lg={4} xl={3}>
                <ExecutiveSelect
                  value={executivesSelected}
                  name="executives"
                  label="Executivo de Conta"
                  placeholder="Selecione..."
                  options={executivesOptions}
                  setExecutivesSelected={setExecutivesSelected}
                  setCompaniesSelected={setCompaniesSelected}
                  setCostCentersSelected={setCostCentersSelected}
                  disable={loading}
                  isMulti={false}
                  isClearable
                />
              </Col>
            )}

            {ShowSelects(userProfile.userProfileId).companies && companiesOptions.length > 0 && (
              <Col sm={12} md={6} lg={4} xl={3}>
                <CompanySelect
                  value={companiesSelected}
                  name="companies"
                  label="Empresas"
                  placeholder="Selecione..."
                  options={companiesOptions}
                  setCompaniesSelected={setCompaniesSelected}
                  setCostCentersSelected={setCostCentersSelected}
                  disable={loading}
                />
              </Col>
            )}

            {costCentersOptions.length > 0 && (
              <Col sm={12} md={6} lg={4} xl={3}>
                <CostCenterSelect
                  value={costCentersSelected}
                  name="costCenter"
                  label="Centros de Custo"
                  placeholder="Selecione..."
                  options={costCentersOptions}
                  setCostCentersSelected={setCostCentersSelected}
                  disable={loading}
                />
              </Col>
            )}

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                name="sell"
                label="Referência"
                placeholder="Referência"
                type="number"
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                name="passenger"
                label="Viajante"
                placeholder="Viajante"
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                name="numOS"
                label="OS"
                placeholder="OS"
              />
            </Col>
          </Row>

          <FilterButtons>
            <button
              className="btn btn-warning"
              type="button"
              onClick={() => handleResetForm()}>
              {loading && <Spinner color="#fff" size="sm" />}

              {!loading && <>Limpar</>}
            </button>

            <button
              className={`btn btn-primary ${loading && 'disabled'}`}
              type="submit"
              disabled={loading}>
              {loading && <Spinner color="#fff" size="sm" />}

              {!loading && <>Aplicar</>}
            </button>
          </FilterButtons>
        </Form>
      </PanelBody>
    </Panel>
  );
}
