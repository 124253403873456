import React, { useContext, useEffect, useState } from 'react';
// import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';

import RequestsContext from "../../context";

export default function ChartAnalytics() {
  const { budgetsAnalytics } = useContext(RequestsContext);

  const [chartData, setChartData] = useState({
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dev'],
    datasets: [],
  });


  useEffect(() => {
    async function handleChartData() {
      if (budgetsAnalytics !== null) {
        const newChartData = {
          ...chartData,
          datasets: [
            {
              data: budgetsAnalytics.spent_months.map(e => (e.spent)),
              backgroundColor: '#BDBFBF',
              borderWidth: 4,
              label: 'Gasto'
            },
            {
              data: budgetsAnalytics.budget_months.map(e => (e.spent)),
              backgroundColor: '#00EAE1',
              borderWidth: 4,
              label: 'Orçamento'
            }
          ]
        };

        return setChartData(newChartData);
      }

      return setChartData({
        ...chartData,
        datasets: [],
      });
    }

    handleChartData();
  }, [budgetsAnalytics]);

  // const chart = () => {
  //   setChartData({
  //     datasets: [
  //       {
  //         data: [],
  //         backgroundColor: '#BDBFBF',
  //         borderWidth: 4,
  //         label: 'Gasto'
  //       },
  //       {
  //         data: [],
  //         backgroundColor: '#00EAE1',
  //         borderWidth: 4,
  //         label: 'Orçamento'
  //       }
  //     ],
  //   });
  // }

  // useEffect(() => {
  //   async function loadData(){
  //     setGasto(budgetsAnalytics.map(x => x.totalGasto));
  //     setlabels(budgetsAnalytics.map(x => x.ccId));
  //     switch (actualMonth) {
  //       case 1: return setBudget(budgetsAnalytics.map(x => x.jan));
  //       case 2: return setBudget(budgetsAnalytics.map(x => x.fev));
  //       case 3: return setBudget(budgetsAnalytics.map(x => x.mar));
  //       case 4: return setBudget(budgetsAnalytics.map(x => x.abr));
  //       case 5: return setBudget(budgetsAnalytics.map(x => x.mai));
  //       case 6: return setBudget(budgetsAnalytics.map(x => x.jun));
  //       case 7: return setBudget(budgetsAnalytics.map(x => x.jul));
  //       case 8: return setBudget(budgetsAnalytics.map(x => x.ago));
  //       case 9: return setBudget(budgetsAnalytics.map(x => x.set));
  //       case 10: return setBudget(budgetsAnalytics.map(x => x.out));
  //       case 11: return setBudget(budgetsAnalytics.map(x => x.nov));
  //       case 12: return setBudget(budgetsAnalytics.map(x => x.dez));
  //       default: return setBudget(budgetsAnalytics.map(x => x.year));
  //     }
  //   }

  //   loadData();
  // }, [budgetsAnalytics])

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function(label, index, labels) {
              return FormatValueBRL(label);
            },
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, data) {
          return `${FormatValueBRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`;
        }
      },
    },
  }

  return(
    <Bar data={chartData} options={options} />
  );
}
