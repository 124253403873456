import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { FormatValueBRL } from '~/functions/FormatValue';
import azul from '~/assets/images/azul.png';
import latam from '~/assets/images/latam.png';
import gol from '~/assets/images/gol.png';
import glob from '~/assets/images/glob.png';

export default function TopCompanies({ data }) {
  const [chartData11, setChartData11] = useState({});
  useEffect(() => {
  async function loadData () {
    setChartData11({
      datasets: [{
        data: [data.g3, data.g3_tic, data.lan, data.lan_tic, data.ad_tic, data.somaTick, data.somaValor, data.outr, data.outr_tic],
      }]
    })
  }
  loadData();
          }, [data])


  return (
    <>
      <div style={{ width: '50%', margin: '0 auto' }}>
        {/* <Line data={chartData11} options={{ responsive: true }} /> */}
        <div className="d-flex align-items-center m-b-15">
          <div className="mr-2">
            <img src={gol} width={50} alt="gol" />
          </div>
          <div className="text-truncate">
            <div className="f-s-16">Gol</div>
            <div className="text-black-lighter f-s-16">
              {FormatValueBRL(data.g3)}
            </div>
          </div>
          <div className="ml-auto text-center">
            <div className="f-s-16">
              <span data-animation="number" data-value="0">
                {data.g3_tic}
              </span>
            </div>
            <div className="f-s-13">tickets</div>
          </div>
        </div>

        <div className="d-flex align-items-center m-b-15">
          <div className="mr-2">
            <img src={latam} width={50} alt="latam" />
          </div>
          <div className="text-truncate">
            <div className="f-s-16">Latam</div>
            <div className="text-black-lighter f-s-16">
              {FormatValueBRL(data.lan)}
            </div>
          </div>
          <div className="ml-auto text-center">
            <div className="f-s-16">
              <span data-animation="number" data-value="0">
                {data.lan_tic}
              </span>
            </div>
            <div className="f-s-13">tickets</div>
          </div>
        </div>

        <div className="d-flex align-items-center m-b-15">
          <div className="mr-2">
            <img src={azul} width={50} alt="azul" />
          </div>
          <div className="text-truncate">
            <div className="f-s-16">Azul</div>
            <div className="text-black-lighter f-s-16">
              {FormatValueBRL(data.ad)}
            </div>
          </div>
          <div className="ml-auto text-center">
            <div className="f-s-16">
              <span data-animation="number" data-value="0">
                {data.ad_tic}
              </span>
            </div>
            <div className="f-s-13">tickets</div>
          </div>
        </div>
        <div className="d-flex align-items-center m-b-15">
          <div className="mr-2">
            <img src={glob} width={50} alt="glob" />
          </div>
          <div className="text-truncate">
            <div className="f-s-16">Outras</div>
            <div className="text-black-lighter f-s-16">
              {FormatValueBRL(data.outr)}
            </div>
          </div>
          <div className="ml-auto text-center">
            <div className="f-s-16">
              <span data-animation="number" data-value="0">
                {data.outr_tic !== null ? data.outr_tic : 0}
              </span>
            </div>
            <div className="f-s-13">tickets</div>
          </div>
        </div>
      </div>
    </>
  );
}
