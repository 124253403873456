import React, { useRef, useState, useEffect, useContext } from 'react';
import Select from '~/components/Select';
import DatePicker from '~/components/DatePickerIndicators';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import { Col, Row } from 'reactstrap';

import ShowSelects from '~/functions/ShowSelects';
import CostCenterSelect from '~/components/CostCenterSelect';
import CompanySelect from '~/components/CompanySelect';
import ExecutiveSelect from '~/components/ExecutiveSelect';

import EmitterSelect from '~/components/EmitterSelect';

import DashboardAgencyContext from '../../context';
import { Form } from '@unform/web';
import {
  HeaderTitle,
  HeaderNav,
  HeaderNavButton,
  TitleFilters,
  FiltersContainer
} from './styles';

import { DefaultDate } from '../../constants';

export default function DashboardAgencyHeader() {
  const axios = ApiAxios();
  const formRef = useRef(null);
  const userProfile = useSelector(state => state.user.userProfile);

  const [changedFilters, setChangedFilters] = useState(0);
  // const [emitterSelected, setEmitterSelected] = useState([]);
  // const [emitterOptions, setEmitterOptions] = useState([]);

  const {
    companies,
    costCenters,
    companiesSelected,
    setCompaniesSelected,
    companiesOptions,
    setCompaniesOptions,
    costCentersOptions,
    setCostCentersOptions,
    emitterSelected,
    setEmitterSelected,
    emitterOptions,
    setEmitterOptions,
    costCentersSelected,
    setCostCentersSelected,
    executivesOptions,
    setExecutivesOptions,
    executivesSelected,
    setExecutivesSelected,
    user,
    dateState,
    setDateState,
    resetDateState,
    body,
    setBody,
    loading,
    setLoading,
    PinChart,
    UnpinChart
  } = useContext(DashboardAgencyContext);

  useEffect(() => {
    async function applyExecutivesOptions() {
      if (!ShowSelects(userProfile.userProfileId).executives)
        return false;

      try {
        const { data } = await axios.options(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/accountexecutive`
        );

        return setExecutivesOptions(data)
      } catch (error) {
        return console.log('Falha ao carregar Executivos de Contas.')
      }
    }

    applyExecutivesOptions();
  }, [user]);

  useEffect(() => {
    async function applyEmitterOptions() {

      if (!ShowSelects(userProfile.userProfileId).emitter)
        return false;

      try {
        setEmitterOptions([])
        const { data } = await axios.options(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/emitteroptions`
        );

        return setEmitterOptions(data.map(e => {
          return {
            label: e.label,
            value: e.value
          };
        }));
      } catch (error) {
        return console.log('Falha ao carregar Emissores.')
      }
    }

    applyEmitterOptions();
  }, [user]);

  useEffect(() => {
    async function getCompanies() {
      setCompaniesSelected([]);
      setCompaniesOptions([]);

      if (executivesSelected.length === 1 && ShowSelects(userProfile.userProfileId).companies) {
        try {
          const { data } = await axios.options(
            `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/accountexecutive/${executivesSelected[0].value}/companies`,
          );

          return setCompaniesOptions(data);
        } catch (error) {
          return setCompaniesOptions([]);
        }
      }

      return setCompaniesOptions(companies.map(e => {
        return {
          label: e.nomeFantasia,
          value: e.codCliente
        };
      }));
    }

    getCompanies();
  }, [executivesSelected]);

  useEffect(() => {
    async function getCostCenters() {
      if (companiesSelected.length === 1 && ShowSelects(userProfile.userProfileId).costCenters) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/${companiesSelected[0].value}/costCenters`,
          );

          return setCostCentersOptions(data.map(e => {
            return {
              label: e.name,
              value: e.name
            };
          }));
        } catch (error) {
          return setCostCentersOptions([]);
        }
      }

      return setCostCentersOptions([]);
    }

    getCostCenters();
  }, [companiesSelected]);

  useEffect(() => {
    setCompaniesSelected([]);
    setCompaniesOptions([]);

    function applyCompanyOptions() {
      setCompaniesOptions(companies.map(e => {
        return {
          label: e.nomeFantasia,
          value: e.codCliente
        };
      }))
    }

    applyCompanyOptions();
  }, [companies]);

  useEffect(() => {
    function applyCostCentersOptions() {
      setCostCentersOptions(costCenters.map(e => {
        return {
          label: e.costCenter,
          value: e.costCenter
        }
      }));
    }

    applyCostCentersOptions();
  }, [costCenters]);

  const [showFilter, setShowFilter] = useState(true);

  const hasChangedFilters = () => {
    return dateState !== DefaultDate
      || executivesSelected.length > 0
      || companiesSelected.length > 0
      || emitterSelected.length > 0
      || costCentersSelected.length > 0;
  }

  return (

    <Row>
      <Col sm={12} style={{
        padding: '0 15px'
      }}>
        <TitleFilters>
          <h1 className="page-header">
            Dashboard
          </h1>

          <span
            className={showFilter ? 'btn btn-primary' : hasChangedFilters() ? 'btn btn-primary' : 'btn'}
            style={{ background: showFilter ? '#000000' : hasChangedFilters() ? '#000000' : 'transparent' }}
            onClick={() => setShowFilter(!showFilter)}>
            <i className="fas fa-filter" />
            Filtros
          </span>
        </TitleFilters>
      </Col>

      <Col sm={12} lg={12} hidden={!showFilter} style={{ padding: '0 20px', margin: 0 }}>
        <FiltersContainer>
          <Form ref={formRef} onSubmit={() => { }}>
            <Row>
              <Col sm={12} md={6} lg={4} xl={3}>
                {dateState !== null && (
                  <DatePicker
                    setState={setDateState}
                    state={dateState}
                    reset={resetDateState}
                  />
                )}
              </Col>

              {/* {ShowSelects(userProfile.userProfileId).executives && executivesOptions.length > 0 && (
                <Col sm={12} md={6} lg={4} xl={3}>
                  <ExecutiveSelect
                    value={executivesSelected}
                    name="executives"
                    placeholder="Executivo de Conta"
                    options={executivesOptions}
                    setExecutivesSelected={setExecutivesSelected}
                    setCompaniesSelected={setCompaniesSelected}
                    setCostCentersSelected={setCostCentersSelected}
                    disable={loading}
                    isMulti={false}
                    isClearable
                  />
                </Col>
              )} */}

              {ShowSelects(userProfile.userProfileId).companies && companiesOptions.length > 1 && (
                <Col sm={12} md={6} lg={4} xl={3}>
                  <CompanySelect
                    value={companiesSelected}
                    name="companies"
                    placeholder="Empresa"
                    options={companiesOptions}
                    setCompaniesSelected={setCompaniesSelected}
                    setCostCentersSelected={setCostCentersSelected}
                    disable={loading}
                  />
                </Col>
              )}

              {ShowSelects(userProfile.userProfileId).emitter && emitterOptions.length > 0 && (
                <Col sm={12} md={6} lg={4} xl={3}>
                  <EmitterSelect
                    value={emitterSelected}
                    name="emitter"
                    placeholder="Emissores"
                    options={emitterOptions}
                    setEmitterSelected={setEmitterSelected}
                    disable={loading}
                  />
                </Col>
              )}


              {ShowSelects(userProfile.userProfileId).costCenters && costCentersOptions.length > 0 && (
                <Col sm={12} md={6} lg={4} xl={3}>
                  <CostCenterSelect
                    value={costCentersSelected}
                    name="costCenter"
                    placeholder="Centro de Custo"
                    options={costCentersOptions}
                    setCostCentersSelected={setCostCentersSelected}
                    disable={loading}
                  />
                </Col>
              )}
            </Row>
          </Form>
        </FiltersContainer>
      </Col>
    </Row>
  );
}
