import styled from 'styled-components';
import Input from '~/components/Input';

export const CreateCompanyForm = styled.div`
  padding: 16px;
`;

export const Container = styled.div``;

export const SubmitButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 0;
    border-radius: 4px;
    height: 35px;

    svg {
      margin-left: 5px;
    }
  }
`;

export const PanelSearchCompanies = styled.div`
  display: flex;
  /* padding: 0 5px; */
  margin-bottom: 15px;

  input {
    width: 100%;
    height: 3em;
    border-radius: 6px;
    border: 1px solid #cccccc;
    padding: 0 6px;
  }

  button {
    width: 20px;
    padding: 8px;
  }
`;

export const PanelFilterCompanyGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  /* background: rgba(0, 0, 0, 0.2); */

  b {
    margin-right: 35%;
    padding: 5px 0;
    font-size: 13px;
  }

  button {
    height: 30%;
    padding: 5px 10px;
  }
`;

export const PanelSearchResult = styled.div`
  padding: 16px;
  background: #fafafa;
  border-radius: 8px;
  min-height: 150px;
  max-height: 400px;
  overflow: auto;

  ul {
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const PanelSearchResultNonOverFlow = styled.div`
  padding: 16px;
  background: #fafafa;
  border-radius: 8px;
  min-height: 150px;

  ul {
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const SpanPasswordAlert = styled.span`
  color: #f33;
`;

export const LiSearch = styled.li`
  background: #efefef;
  margin-bottom: 4px;
  border-radius: 4px;
`;

export const OrderCCItens = styled.div`
  background: #efefef;
  margin-bottom: 4px;
  border-radius: 4px;
  padding: 4px;
`;

export const SpanLi = styled.span`
  width: 100%;
  text-align: left;
  padding-left: 5px;
  font-size: 13px;
`;

export const SpanLiGrupo = styled.span`
  width: 100%;
  font-size: 9px;
  border: 1px solid #ccc;
  background: #A6D59A;//rgba(125, 186, 124, 1); //#5faf5f;
  color: #fff;
  border-radius: 4px;
  padding: 4px;
`;

export const DownloadModels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  margin: 0 0 4px;

  button {
    margin: 0 0 4px;
    border-radius: 4px;
    border: none;
    color: #fff;
    background-color: #7e2dc2;
  }
`;

export const CompanyDetailsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  margin: 0 0 4px;

  button {
    margin: 0 0 4px;
    border-radius: 4px;
    border: none;
    color: #333;
    background-color: transparent;
  }
`;
