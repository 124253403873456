import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ClearCache } from './extensions';
import './config/ReactotronConfig';
import { store, persistor } from './store';
import './locales/i18n';
import Routes from './routes';
import GlobalStyles from './styles/global';

const App = () => {
  ClearCache();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <Routes />

          <GlobalStyles />

          <ToastContainer autoClose={4000} />
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
