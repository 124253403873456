import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
} from 'reactstrap';


import { DropItem, DropMenu } from "./styles";
import langs from './constants';

import LanguageActions from '~/store/modules/ducks/language';

export default function DropdownLanguage() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const language = useSelector(state => state.language);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  function toggle() {
    setDropdownOpen(!dropdownOpen);
  }

  function handleChangeLanguage(key, text, badge) {
    dispatch(LanguageActions.changeLanguage(key, text, badge));
    i18n.changeLanguage(key);
  }

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="dropdown navbar-languager"
      tag="li"
    >
      {language && (
        <>
          <DropdownToggle className="dropdown-toggle" tag="a">
            <span
              className={`flag-icon flag-icon-${language.badge} m-r-5`}
              title={language.badge}
            />
            <span className="name d-none d-sm-inline">{language.text}</span>
          </DropdownToggle>
          <DropMenu className="dropdown-menu dropdown-menu-right" tag="ul">
            {langs.map(({ className, key, text, title }) => (
              <DropItem
                key={key}
                onClick={() => handleChangeLanguage(key, text, title)}
              >
                <span className={className} title={title} /> {text}
              </DropItem>
            ))}
          </DropMenu>
        </>
      )}
    </Dropdown>
  );
}
