import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "@unform/web";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Recaptcha } from "react-recaptcha";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";

import Input from "~/components/Input";

import {
  Content,
  Background,
  Loading,
  LoginContent,
  Options,
  TLink,
  Logo,
  RightContent,
} from "./styles";

import AuthActions from "~/store/modules/ducks/auth";

import BackgroundImage from "~/assets/images/telagestao.jpg";
import logo from "~/assets/images/weegestaowhite.png";
import { toast } from "react-toastify";
import { getDate } from "~/functions/Date";
import moment from "moment";

//const eye = <FontAwesomeIcon icon={faEye} />;

var isVerified = false;

var callback = function () {
  isVerified = false;
};

var verifyCallback = function (response) {
  if (response) {
    isVerified = true;
  }
};

export default function SignIn() {
  const dispatch = useDispatch();
  const { t } = useTranslation("SignIn");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(true);
  const signed = useSelector((state) => state.auth.signed);
  const loadingAuth = useSelector((state) => state.auth.loading);
  const [hidePass, setHidePass] = useState(true);
  var Recaptcha = require("react-recaptcha");
  const now = moment();

  useEffect(() => {
    async function loadSigned() {
      if (!signed) {
        const chatBotScript = document.getElementById("chatBotScriptId");

        if (chatBotScript) {
          document.body.removeChild(chatBotScript);

          const chatBotContent = document.body.firstChild;

          if (chatBotContent) {
            document.body.removeChild(chatBotContent);
          }
        }

        dispatch(AuthActions.signFailure());
      }
    }

    loadSigned();
  }, [signed]);

  function handleSubmit({ email, password }) {
    return dispatch(AuthActions.signInRequest(email, password))
  }

  return (
    <Content>
      <Background
        src={BackgroundImage}
        alt={"Wee Gestão - Plano de Fundo"}
        onLoad={() => setLoading(false)}
      />

      {!loading && (
        <LoginContent>
          <RightContent className="right-content">
            <div className="login-header">
              <div className="brand">
                <Logo src={logo} />
              </div>

              <div className="icon">
                <i className="fa fa-sign-in" />
              </div>
            </div>

            <div className="login-content">
              <Form
                className="margin-bottom-0"
                onSubmit={handleSubmit}
                initialData={{
                  email: email,
                  password: pass,
                }}
              >
                <label style={{ fontSize: 15, fontWeight: "400" }}>LOGIN</label>

                <div>
                  <Input
                    name="email"
                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    title="Informe um endereço de e-mail válido."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("Form.Inputs.Email")}
                    style={{ borderRadius: 0 }}
                    required
                    disabled={loadingAuth}
                  />
                </div>

                <label style={{ fontSize: 15, fontWeight: "400" }}>SENHA</label>

                <div>
                  <Input
                    name="password"
                    type={hidePass ? "password" : "text"}
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    placeholder={t("Form.Inputs.Password")}
                    style={{ borderRadius: 0 }}
                    isPasswordForLogin={true}
                    hidePass={hidePass}
                    callbackHidePassword={() => setHidePass(!hidePass)}
                    required
                    disabled={loadingAuth}
                  />
                </div>

                <div
                  className="login-buttons"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <button
                    type="submit"
                    className="button-submit"
                    style={{ fontSize: 15 }}
                    disabled={loadingAuth}
                  >
                    {!loadingAuth && t("ButtonSubmit")}

                    {loadingAuth && (
                      <i className="fas fa-circle-notch fa-spin" />
                    )}
                  </button>

                  <Options className="m-b-15">
                    <TLink to="/forgot-password">ESQUECEU SUA SENHA?</TLink>
                  </Options>
                  <p style={{ fontSize: 13 }}>
                    Todos direitos reservados {now.format("yyyy")} &copy;
                    WeeTech
                  </p>
                </div>
              </Form>
            </div>
          </RightContent>
        </LoginContent>
      )}

      {loading && (
        <Loading>
          <Spinner color={"#eee"} size={"lg"} />
        </Loading>
      )}
    </Content>
  );
}
