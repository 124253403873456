import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';
export default function RankingRoutes({
  data: LocalData
}) {
    const [chartData11, setChartData11] = useState({});

    useEffect(() => {
      async function loadData () {
      setChartData11({
        labels: LocalData.map(route => route.cidade),
        datasets: [{
                data: LocalData.map(route => route.soma),
                backgroundColor: ['#00EAE1', '#BDBFBF', '#95F496', '#f4dbab', '#7e2ec2', '#FBBA37'],
                borderColor: 'transparent',
                borderWidth: 2,
                label: 'Viagens'
              }]
            })
          }
            loadData();
    }, [LocalData])


    function getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    }


    return (
        <>
            <Bar data={chartData11} options={{
              legend: {
                display: false,
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    min: 0
                  }
                }]
              },
              tooltips: {
                enabled: true,
                callbacks: {
                  label: function(tooltipItem, data) {
                    const label = data.labels[tooltipItem.index];
                    const [finded] = LocalData.filter(rank => rank.cidade === label)
                    return ` ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]} viagens / ${FormatValueBRL(finded.valor)}`;
                  }
                }
              },
              responsive: true }} />
            {/* <Line data={chartData11} options={{
              legend: {
                display: false,
              },
              responsive: true,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    min: 0
                  }
                }]
              }}
              } /> */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='description'>
                    {/* <p style={styles.titleLegend}>Gasto em Reemissão</p> */}
                    {/* <p className='textBoldRed'>19.059,59</p> */}
                </div>
            </div>
        </>
    );
}

