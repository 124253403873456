import styled from 'styled-components';
import {
  Row,
  Col
} from 'reactstrap';

export const CompanyDetailsContainer = styled.div`
  padding: 16px;
`;

export const DetailsContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 4px;
`;

export const DetailsContainer = styled.div`
  background: #fafafa;
  border-radius: 4px;
  padding: 8px;
  margin: 8px 8px 8px;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.4);
  opacity: 0;
  visibility: hidden;
  transform: .5s all;

  ${props => props.visible && `
    opacity: 1;
    visibility: visible;
  `}
`;

export const ModalBox = styled.div`
  width: 350px;
  height: 280px;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  padding: 25px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: auto;

  .modal-close {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    right: 10px;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 20px;
      background: #333;
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  ${props => props.insertWhatsapp && `
   height: 200px;
  `}
`;

export const ModalTextarea = styled.textarea`
  display: block;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  resize: none;

  border: 1px solid #ddd;
  padding: 5px;
  font-size: 14px;
  border-radius: 8px;
  margin: 10px 0;
  min-height: 100px;

  ${props => props.insertWhatsapp && `
    display: flex;
    padding-top: 9px;
    justify-content: center;
    min-height: 40px;
    max-height: 40px;
    width: auto;
    margin: 15px 0;
  `}


`;

export const ModalTitle = styled.h2`
  font-size: 20px;
  letter-spacing: -1px;
`;

export const ModalButton = styled.button`
  appearance: none;
  -webkit-appearance: none;
  display: inline-block;
  color: #fff;
  border: 0 none;
  background: #128C7E;
  padding: 8px 20px;
  border-radius: 8px;
`;

export const ModalContent = styled.div``;

export const DetailsItemsContainer = styled.div`
  padding: 0;
  margin: 0;
  height: 220px;
  overflow: auto;
`;

export const DetailsContainerItemHeader = styled(Row)`
  padding: 4px 16px;
  margin: 0px 0px 4px;
  font-weight: bold;
`;

export const DetailsContainerItem = styled(Row)`
  background: #ededed;
  border-radius: 4px;
  padding: 4px 16px;
  margin: 0px 0px 4px;
`;

export const Container = styled.div``;

export const SubmitButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 0;
    border-radius: 4px;
    height: 35px;

    svg {
      margin-left: 5px;
    }
  }
`;

export const PanelSearchCompanies = styled.div`
  display: flex;
  /* padding: 0 5px; */
  margin-bottom: 15px;

  input {
    width: 100%;
    height: 3em;
    border-radius: 6px;
    border: 1px solid #cccccc;
    padding: 0 6px;
  }

  button {
    width: 20px;
    padding: 8px;
  }
`;

export const PanelFilterCompanyGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  /* background: rgba(0, 0, 0, 0.2); */

  b {
    margin-right: 20%;
    padding: 5px 0;
    font-size: 13px;
  }

  button {
    height: 30%;
    padding: 5px 10px;
  }
`;

export const PanelSearchResult = styled.div`
  padding: 5px;
  background: #fafafa;
  border-radius: 8px;
  min-height: 150px;
  max-height: 400px;
  overflow: auto;

  ul {
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const SpanPasswordAlert = styled.span`
  color: #f33;
`;

export const LiSearch = styled.li`
  background: #efefef;
  margin-bottom: 4px;
  border-radius: 4px;
`;

export const SpanLi = styled.span`
  width: 100%;
  text-align: left;
  padding-left: 5px;
  font-size: 13px;
`;

export const SpanLiGrupo = styled.span`
  width: 100%;
  font-size: 9px;
  border: 1px solid #ccc;
  background: rgba(125, 186, 124, 1); //#5faf5f;
  color: #fff;
  border-radius: 4px;
  padding: 4px;
`;
