import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import SidebarNavSubItem from '../NavSubItem';

import {
  NavUlSubItems,
  NavLiItem,
  NavLiItemLink,
  NavUlSubItemsPopover,
  SubmenuPopover,
  SubmenuPopoverBody,
} from '../styles';

function SidebarNavItem({
  data,
  location,
  pageSettings,
  keyItem
}) {
  const [itemState, setItemState] = useState({
    hasSub: false,
    active: false,
    clicked: false,
    popover: false,
  });
  const [expanded, setExpanded] = useState(false);
  const user = useSelector(state => state.user);
  var hasCesar = user.companies.some(x => x.codCliente === 'CESARRECIF');

  useEffect(() => {
    async function loadState () {
      return setItemState({
        ...itemState,
        hasSub: data.menuSubMenu && data.menuSubMenu.length > 0,
        active: location && location.pathname === data.path,
      });
    }

    loadState();
  }, [data, location]);

  const togglePopover = () => setItemState({
    ...itemState,
    popover: !itemState.popover,
  });

  return (
    <li
      className={`
        ${itemState.active && 'active '}
        ${itemState.hasSub && 'hasSub '}
      `}>
      {data.exact && (
        <NavLiItemLink
          minify={pageSettings.pageSidebarMinify ? true : undefined}
          to={data.path}>
          <i style={{ fontSize: 18 }} className={data.icon} />

          {!pageSettings.pageSidebarMinify && <span>{data.title}</span>}
        </NavLiItemLink>
      )}

      {!data.exact && (
        <NavLiItem
          id={`sidebarItem${keyItem}`}
          minify={pageSettings.pageSidebarMinify ? true : undefined}
          onClick={() => setExpanded(!expanded)}>
          <div>
            <i style={{ fontSize: 18 }} className={data.icon} />

            {!pageSettings.pageSidebarMinify && <span>{data.title}</span>}
          </div>

          {itemState.hasSub && !pageSettings.pageSidebarMinify &&  (
            !expanded && <i style={{width: '8px'}} className="fas fa-caret-down" />
            || expanded && <i style={{width: '8px'}} className="fas fa-caret-up" />
          )}

          {itemState.hasSub && pageSettings.pageSidebarMinify &&  (
            <i
              className="fas fa-caret-right"
              style={{
                position: 'absolute',
                fontSize: '12px',
                right: '0'
              }}
            />
          )}

          {itemState.hasSub && pageSettings.pageSidebarMinify &&  (
            <SubmenuPopover
              placement="right"
              isOpen={itemState.popover}
              target={`sidebarItem${keyItem}`}
              toggle={togglePopover}>
              <SubmenuPopoverBody>
                <NavUlSubItemsPopover>
                  {data.menuSubMenu.map(({ subMenu }, i) => {
                    if(!hasCesar && subMenu.path === '/losses')
                      return <></>;

                    return (
                      <SidebarNavSubItem
                        data={subMenu}
                        location={location}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        pageSettings={pageSettings}
                        keyItem={i}
                      />
                    );
                  })}
                </NavUlSubItemsPopover>
              </SubmenuPopoverBody>
            </SubmenuPopover>
          )}
        </NavLiItem>
      )}


      {itemState.hasSub && !pageSettings.pageSidebarMinify && (
        <NavUlSubItems
          className={`
            ${expanded ? 'd-block ' : 'd-none '}
          `}>
            {data.menuSubMenu.map(({ subMenu }, i) => {
              if(!hasCesar && subMenu.path === '/losses')
                return <></>;

              return (
                <SidebarNavSubItem
                  data={subMenu}
                  location={location}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  pageSettings={pageSettings}
                  key={i}
                />
              );
            })}
        </NavUlSubItems>
      )}
    </li>
  );
}

SidebarNavItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    childrens: PropTypes.array
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
};

export default SidebarNavItem;
