import React from "react";
import { FormatValueBRL } from '~/functions/FormatValue';
import Tooltip from '~/components/Tooltip';

export const columns = ({ openAlerts, openEdit }) =>
[
  {
    Header: () => null,
    id: 'edit',
    width: 60,
    Cell: ({ row, index }) =>  <div>
      <Tooltip target={`editbottonbudget-${index}`} text="Editar Orçamento" />

      <button
        className="btn btn-link"
        id={`editbottonbudget-${index}`}
        onClick={() => openEdit(row.original)}>
        <i class="far fa-edit"></i>
      </button>
    </div>,
  },
  {
    Header: () => null,
    id: 'edit_alert',
    width: 60,
    Cell: ({ row, index }) => <div>
      <Tooltip target={`editalertbottonbudget-${index}`} text="Alertas" />

      <button
        className="btn btn-link"
        id={`editalertbottonbudget-${index}`}
        onClick={() => openAlerts(row.original)}>
        <i class="far fa-bell"></i>
      </button>
    </div>,
  },
  {
    accessor: "cost_name",
    Header: "Centro de Custo",
    filterable: false,
    width: 100
  },
  {
    accessor: "total",
    Header: "Anual",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
  {
    accessor: "jan",
    Header: "Janeiro",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
  {
    accessor: "feb",
    Header: "Fevereiro",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
  {
    accessor: "mar",
    Header: "Março",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value),
    width: 100
  },
  {
    accessor: "apr",
    Header: "Abril",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
  {
    accessor: "may",
    Header: "Maio",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
  {
    accessor: "jun",
    Header: "Junho",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
  {
    accessor: "jul",
    Header: "Julho",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
  {
    accessor: "aug",
    Header: "Agosto",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
  {
    accessor: "sep",
    Header: "Setembro",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
  {
    accessor: "oct",
    Header: "Outubro",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
  {
    accessor: "nov",
    Header: "Novembro",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
  {
    accessor: "dec",
    Header: "Dezembro",
    filterable: false,
    Cell: ({ value }) => FormatValueBRL(value)
  },
]
