import styled from 'styled-components';

export const SelectItemGroup  = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    text-align: center;
    padding: 2px 2px;
    margin-left: 16px;
    border-radius: 4px;
    font-weight: 500;
    background: #7DBA7C;
    color: #FFF;
  }
`;

export const FilterButtons = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 0;
    border-radius: 4px;
    height: 35px;
    font-size: 13px;

    svg {
      margin-left: 5px;
    }

    & {
      margin-left: 5px;
    }
  }
`;

export const CellLogo = styled.img`
  border-radius: 5px;
`;

export const ColumnsTableMain = styled.div`
  .row {
    display: flex;
    align-items: center;
    margin-left: 4px;
  }

  input {
    width: 18px;
    height: 18px;
  }

  span {

    margin: 2px 4px;
  }
`;
export const InputLocalizador = styled.div`
  width: 120px;
`;

export const InputDatePicker = styled.div`
  min-width: 230px;
  margin-left: -5px;
`;

export const ColumnsTitleMain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
