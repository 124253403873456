import styled from 'styled-components';
import { FormGroup } from 'reactstrap';

export const Group = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;
  margin: 0 0 !important;

  label {
    margin: 2px 4px;
    font-weight: 600;
  }
`;

export const Group2 = styled(FormGroup)`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
`;

export const Span = styled.span`
  padding: 0 6px;
`;

export const SpanDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 36px;
  border-left: 1px solid #ccc;
  color: #ccc;

  &:hover {
    cursor: pointer;
  }

  position: absolute;
  right: 0;
`;
