import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';
import { Form } from '@unform/web';

import Input from '~/components/Input';
import TextArea from '~/components/TextArea';

import { FormatPhoneBR } from '~/functions/FormatPhone';
// import { Container } from './styles';

function CompanyData({
  data
}) {
  return (
    <>
      <h4>
        Dados da Empresa
      </h4>

      <Row>
        <Col sm={12} md={6} lg={6}>
          <Input
            disabled={true}
            name={'companyName'}
            label={'Empresa'}
            value={
              (data.companies[0].empresa ?? '')
            } />

          <Input
            disabled={true}
            name={'companyFantasyName'}
            label={'Nome Fantasia'}
            value={
              (data.companies[0].nomeFantasia ?? '')
            } />

          <Input
            disabled={true}
            name={'companyGroup'}
            label={'Grupo Empresarial'}
            value={
              data.companies[0].grupoEmpresarial ?? ''
            } />
        </Col>


        {data.costCenters.length > 0 && (
          <Col sm={12} md={6} lg={6}>
            <p style={{ fontWeight: '600'}}>
              Meus Centros de Custo
            </p>

            <ul type='none'>
              {data.costCenters.map(e => (
                <li>
                  {e.costCenter}
                </li>
              ))}
            </ul>
          </Col>
        )}
      </Row>
    </>
  );
}

CompanyData.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    email2: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    phone2: PropTypes.string.isRequired,
    profile: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      desc: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
    costCenters: PropTypes.array,
    companies: PropTypes.array,
    menus: PropTypes.array,
  })
};

export default CompanyData;
