import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Form } from "@unform/web";
import { api } from "~/services/api";
import { Row, Col, Spinner } from 'reactstrap';
import Select from "~/components/Select";
import Input from "~/components/Input";
import Table from './Table';
import Card from './Card';
import {
  Section,
  Header,
  Title,
  Grid,
  GridItem,
  ContainerDestinyFilter,
  ButtonMenu,
  FilterButtons,
} from "./styles";

const Traveler = () => {
  const formRef = useRef(null);
  const companies = useSelector((state) => state.user.companies);
  const costCenters = useSelector(state => state.user.costCenters);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [costCentersOptions, setCostCentersOptions] = useState([]);

  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [originsValues, setOriginsValues] = useState([]);
  const [destinationsValues, setDestinationsValues] = useState([]);
  const [locator, setLocator] = useState('');
  const [locatorFilter, setLocatorFilter] = useState('');
  const [pax, setPax] = useState('');
  const [paxFilter, setPaxFilter] = useState('');
  // const [billet, setBillet] = useState('');
  // const [billetFilter, setBilletFilter] = useState('');

  // const [pageProps, setPageProps] = useState({
  //   title: '',
  //   type: 'card',
  //   // inputFilterType: ''
  // });

  useEffect(() => {
    async function loadCompanies() {
      const list = companies.map(company => company.handleEmpresa);

      return setCompaniesOptions(list);
    }

    loadCompanies();
  }, [companies]);

  useEffect(() => {
    async function loadCostCenter() {
      const list = costCenters.map(({ costCenter }) => costCenter);

      return setCostCentersOptions(list);
    }

    loadCostCenter();
  }, [costCenters]);

  const [typeBox, setTypeBox] = useState("box"); // 'box' OR 'list'

  const [travelers, setTravelers] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timing = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timing);
  });

  const toggleItens = () => {
    if (typeBox === "box") {
      setTypeBox("list");
    }

    if (typeBox === "list") {
      setTypeBox("box");
    }
  };

  async function travelsCrawler(travels) {
    const originsCrawled = travels.map(({ origin }) => ({
      label: `${origin.name} (${origin.airportCode.trim()})`,
      value: origin.name,
    }));

    const destinationsCrawled = travels.map(({ destiny }) => ({
      label: `${destiny.name} (${destiny.airportCode.trim()})`,
      value: destiny.name,
    }));

    const origins = originsCrawled.reduce((acc, current) => {
      const x = acc.find((item) => item.label === current.label);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const destinations = destinationsCrawled.reduce((acc, current) => {
      const x = acc.find((item) => item.label === current.label);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    return [origins, destinations];
  }

  function handleSetOriginsFilter(origin) {
    if (origin !== null) {
      return setOriginsValues(origin);
    }
    setOriginsValues([]);
  }

  function handleSetDestinationsFilter(destination) {
    if (destination !== null) {
      return setDestinationsValues(destination);
    }
    setDestinationsValues([]);
  }

  function filteredTravelers() {
    if (originsValues.length === 0 && destinationsValues.length === 0) {
      return travelers;
    }

    const filterTravelers = travelers.filter(({ origin, destiny }) => {
      let filteredByOrigin = originsValues.some(
        (values) =>
          values.label === `${origin.name} (${origin.airportCode.trim()})`
      );
      let filteredByDestiny = destinationsValues.some(
        (values) =>
          values.label === `${destiny.name} (${destiny.airportCode.trim()})`
      );

      return filteredByOrigin || filteredByDestiny;
    });

    return filterTravelers;
  }

  useEffect(() => {
    async function getTravelers() {
      try {
        setLoading(true);

        setTravelers([]);

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { limit: "20", offset: "0" },
        };

        const { data } = await api.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/flights/todays-flights`,
          {
            CompanyHandle: companiesOptions,
            CoustCenterNames: costCentersOptions,
            Locator: locatorFilter,
            passenger: paxFilter,

          },
          options
        );

        const [origins, destinations] = await travelsCrawler(data);

        setOrigins(origins);
        setDestinations(destinations);

        setLoading(false);

        return setTravelers(data);
      } catch (error) {
        setLoading(false);

        return console.log(error);
      }
    }

    getTravelers();
  }, [companiesOptions, costCentersOptions, locatorFilter, paxFilter]);

  function searchTravelers() {
    setPaxFilter(pax);
    setLocatorFilter(locator);
  }

  return (
    <>
      <Header>
        <Title new={true}>Controle do Viajante</Title>
        <hr></hr>
      </Header>

      <Section>
        <Grid period={true}>
          <li>
            <GridItem to="/traveler/list/next-flights">
              <div class="traveler-content">
                <span>Próximas viagens</span>
              </div>
            </GridItem>
          </li>
          <li>
            <GridItem to="/traveler/list/last-month">
              <div class="traveler-content">
                <span>Último mês</span>
              </div>
            </GridItem>
          </li>
          <li>
            <GridItem to="/traveler/list/last-semester">
              <div class="traveler-content">
                <span>Último semestre</span>
              </div>
            </GridItem>
          </li>
          <li>
            <GridItem to="/traveler/list/custom-period">
              <div class="traveler-content">
                <span>Personalizado</span>
              </div>
            </GridItem>
          </li>
        </Grid>
      </Section>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Title>Viagens do Dia</Title>

        <ButtonMenu onClick={() => toggleItens()}>
          Mudar visualização
        </ButtonMenu>
      </div>

      <Form ref={formRef} onSubmit={() => { }}>
        <ContainerDestinyFilter>
          <Row>
            <Col sm={12} md={6} lg={3} xl={3}>
              <Select
                value={originsValues}
                label="Origem"
                name="origins"
                placeholder=""
                options={origins}
                isClearable
                isMulti={true}
                onChange={handleSetOriginsFilter}
                disable={loading}
              />
            </Col>

            <Col sm={12} md={6} lg={3} xl={3}>
              <Select
                value={destinationsValues}
                label="Destino"
                placeholder=""
                name="destinations"
                isClearable
                options={destinations}
                isMulti={true}
                onChange={handleSetDestinationsFilter}
                disable={loading}
              />
            </Col>


            <Col sm={12} md={5} lg={2} xl={2}>
              <Input
                label="Localizador/Bilhete"
                name="locator"
                placeholder=""
                onChange={e => setLocator(e.target.value)}
                value={locator}
                // searchButtonLabel={true}
                // searchButtonText={'Buscar'}
                // callbackSearch={() => setLocatorFilter(locator)}
                disable={loading}
                loading={loading}

              />
            </Col>

            <Col sm={12} md={5} lg={3} xl={3}>
              <Input
                label="Viajante"
                name="pax"
                placeholder=""
                onChange={e => setPax(e.target.value)}
                value={pax}
                // searchButtonLabel={true}
                // searchButtonText={'Buscar'}
                // callbackSearch={() => setPaxFilter(pax)}
                disable={loading}
                loading={loading}

              />
            </Col>

            <Col sm={12} md={2} lg={1} xl={1}>
              <FilterButtons>
                <div>
                  <div></div>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => searchTravelers()}
                    disabled={loading}
                  >
                    {loading && <Spinner color="#fff" size="sm" />}
                    {!loading && <>Aplicar</>}
                  </button>
                </div>
              </FilterButtons>
            </Col>
          </Row>
        </ContainerDestinyFilter>
      </Form>

      {typeBox === 'box' && !loading && (
        <Card travelers={filteredTravelers()} />
      )}

      {typeBox === "list" && !loading && (
        <Table
          travelers={filteredTravelers()}
          title={'Viagens do Dia'}
          loading={loading} />
      )}
    </>
  );
};

export default Traveler;
