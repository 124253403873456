import styled from 'styled-components';
import {
  Row,
  Col
} from 'reactstrap';

export const CompanyDetailsContainer = styled.div`
  padding: 16px;
`;

export const DetailsContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 4px;
`;

export const DetailsContainer = styled.div`
  background: #fafafa;
  border-radius: 4px;
  padding: 8px;
  margin: 8px 8px 8px;
`;

export const DetailsItemsContainer = styled.div`
  padding: 0;
  margin: 0;
  height: 220px;
  overflow: auto;
`;

export const DetailsContainerItemHeader = styled(Row)`
  padding: 4px 16px;
  margin: 0px 0px 4px;
  font-weight: bold;
`;

export const DetailsContainerItem = styled(Row)`
  background: #ededed;
  border-radius: 4px;
  padding: 4px 16px;
  margin: 0px 0px 4px;
`;

export const Container = styled.div``;

export const EditAction = styled.button`
  font-size: 11px;
  background-color: #0f9e3e;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  margin: 0 8px;
`;

export const SubmitButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 0;
    border-radius: 4px;
    height: 35px;

    svg {
      margin-left: 5px;
    }
  }
`;

export const PanelSearchCompanies = styled.div`
  display: flex;
  /* padding: 0 5px; */
  margin-bottom: 15px;

  input {
    width: 100%;
    height: 3em;
    border-radius: 6px;
    border: 1px solid #cccccc;
    padding: 0 6px;
  }

  button {
    width: 20px;
    padding: 8px;
  }
`;

export const PanelFilterCompanyGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  /* background: rgba(0, 0, 0, 0.2); */

  b {
    margin-right: 20%;
    padding: 5px 0;
    font-size: 13px;
  }

  button {
    height: 30%;
    padding: 5px 10px;
  }
`;

export const PanelSearchResult = styled.div`
  padding: 5px;
  background: #fafafa;
  border-radius: 8px;
  min-height: 150px;
  max-height: 400px;
  overflow: auto;

  ul {
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const SpanPasswordAlert = styled.span`
  color: #f33;
`;

export const LiSearch = styled.li`
  background: #efefef;
  margin-bottom: 4px;
  border-radius: 4px;
`;

export const SpanLi = styled.span`
  width: 100%;
  text-align: left;
  padding-left: 5px;
  font-size: 13px;
`;

export const SpanLiGrupo = styled.span`
  width: 100%;
  font-size: 9px;
  border: 1px solid #ccc;
  background: rgba(125, 186, 124, 1); //#5faf5f;
  color: #fff;
  border-radius: 4px;
  padding: 4px;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.4);
  opacity: 0;
  visibility: hidden;
  transform: .5s all;

  ${props => props.visible && `
    opacity: 1;
    visibility: visible;
  `}
`
export const ModalBox = styled.div`
  width: 350px;
  height: 280px;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  padding: 25px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: auto;

  .modal-close {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    right: 10px;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 20px;
      background: #333;
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  ${props => props.insertWhatsapp && `
   height: 200px;
  `}
`
export const ModalClose = styled.div``

export const ModalContent = styled.div``

export const ModalTextarea = styled.textarea`
  display: block;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  resize: none;

  border: 1px solid #ddd;
  padding: 5px;
  font-size: 14px;
  border-radius: 8px;
  margin: 10px 0;
  min-height: 100px;

  ${props => props.insertWhatsapp && `
    display: flex;
    padding-top: 9px;
    justify-content: center;
    min-height: 40px;
    max-height: 40px;
    width: auto;
    margin: 15px 0;
  `}


`

export const ModalButton = styled.button`
  appearance: none;
  -webkit-appearance: none;
  display: inline-block;
  color: #fff;
  border: 0 none;
  background: #128C7E;
  padding: 8px 20px;
  border-radius: 8px;
`

export const ModalTitle = styled.h2`
  font-size: 20px;
  letter-spacing: -1px;
`
export const DetailsItem = styled.li`
  font-size: 16px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  transition: .5s all;
  box-shadow: 0px 38px 56px rgba(154, 154, 167, 0.07), 0px 11.4559px 16.8824px rgba(154, 154, 167, 0.0456112), 0px 4.75819px 7.01207px rgba(154, 154, 167, 0.035), 0px 1.72094px 2.53613px rgba(154, 154, 167, 0.0243888);

  &:hover {
    background: #fafafa;
  }

  ${props => props.general && `
    background: #ccc;
  `}

  ${props => props.whatsapp && `
    background: #128C7E;
    color: #fff;
    cursor: pointer;

    &:hover {
      background: #075E54;
    }
  `}
  ${props => props.peddingWhatsapp && `
    background: #ccc;
    color: #000;
    cursor: pointer;

    &:hover {
      background: #075E54;
      color: #fff;
    }
  `}
`;
export const Body = styled.div`
  width: 100%;
  min-height: 60vh;
  padding: 16px;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fefefe;

  label {
    font-size: 13px;
  }

  input {
    padding: 4px;
    font-size: 13px;
    border-radius: 4px;
  }
  button {
    float: right;
  }
`;

export const Pesquisa = styled.div`
  .container {
    width: 100%;
    position: relative;
    height: 50px;
  }

  label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  input {
    height: 50px;
    outline: none;
    padding-left: 50px;
    width: 0;
    left: 0;
    border: none;
    background: none;
    z-index: 3;
    transition: width 1s ease-out;
    cursor: pointer;

    }
  input:focus {
    border: 1px solid #ccc;
    border-radius: 25px;
    width: 50%;
    z-index: 1;
    transition: width 1s ease-in;
  }
  button {
    height: 50px;
    width: 50px;
    background:  url('lupa.png')  center no-repeat;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }
  button:focus {
    outline: none;
  }
  input {padding-left: 60px;} /* alterado aqui */
`
export const Tabela = styled.div`
  table {
    //border-collapse:collapse;
    /* border:1px solid #CCC; */

  }

  tr {
    //border:1px solid #CCC;
  }

  th {
    //padding: 42px;
  }

  td {
    //width:40px;
    //height:40px;
    //padding:42px;
    //border:1px solid #CCC;
  }

`

export const PaginationFooter = styled.div`
  display: flex;
  justify-content: last baseline;
  margin-left: 260px;

  width: 100%;

  button {
    border: 0;
    background-color: transparent;
    margin: 0 10px;
  }
`;
export const Button = styled.div`
  margin: 0px 4px;
`;
