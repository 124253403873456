import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  border-radius: 4px;
  background-color: #e3e3e3;
  padding: 4px 8px;
  margin: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ButtonAction = styled.div`
  padding: 2px 4px;
  font-weight: bold;
  margin-left: 8px;
`;

export const LoadingDiv = styled.div`
  width: 100%;
  padding: 16px;
  font-weight: bold;
  text-align: center;
`;

export const EmptyDiv = styled.div`
  width: 100%;
  padding: 16px;
  font-weight: bold;
  text-align: center;
`;
