import styled from 'styled-components';

export const FilterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 0;
    border-radius: 4px;
    height: 35px;

    svg {
      margin-left: 5px;
    }

    & {
      margin-left: 5px;
    }
  }
`;
