import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import RequestManagementContext from "./context";
import Filters from "./components/Filters";
import Indicators from "./components/Indicators";
import Table from "./components/Table";
import ModalInvoiceReport from "./components/ModalInvoiceReport";

import { DefaultDate } from "./constants";

import ShowSelects from "~/functions/ShowSelects";
import { ApiAxios } from "~/services/api";

export default function RequestManagement() {
  const axios = ApiAxios();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const user = useSelector((state) => state.user.profile);
  const userProfile = useSelector((state) => state.user.userProfile);
  const companies = useSelector((state) => state.user.companies);
  const costCenters = useSelector((state) => state.user.costCenters);

  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [costCentersOptions, setCostCentersOptions] = useState([]);
  const [costCentersSelected, setCostCentersSelected] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [projectsSelected, setProjectsSelected] = useState([]);
  const [executivesOptions, setExecutivesOptions] = useState([]);
  const [executivesSelected, setExecutivesSelected] = useState([]);

  const formRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateState, setDateState] = useState(DefaultDate);
  const [pax, setPax] = useState("");
  const [products, setProducts] = useState([]);

  const companiesArray = companies.map((x) => x.codCliente);

  const [filters, setFilters] = useState({
    companyHandle: companies.map((x) => x.codCliente),
  });

  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    async function applyExecutivesOptions() {
      if (!ShowSelects(userProfile.userProfileId).executives) return false;

      try {
        const { data } = await axios.options(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/accountexecutive`
        );

        return setExecutivesOptions(data);
      } catch (error) {
        return console.log("Falha ao carregar Executivos de Contas.");
      }
    }

    applyExecutivesOptions();
  }, [user]);

  useEffect(() => {
    function applyCompanyOptions() {
      setCompaniesOptions(
        companies.map((e) => {
          return {
            label: e.nomeFantasia,
            value: e.codCliente,
          };
        })
      );
    }

    applyCompanyOptions();
  }, [companies]);

  useEffect(() => {
    function applyCostCentersOptions() {
      setCostCentersOptions(
        costCenters.map((e) => {
          return {
            label: e.costCenter,
            value: e.costCenter,
          };
        })
      );
    }

    applyCostCentersOptions();
  }, [costCenters]);

  // orders
  const [passenger, setPassenger] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [status, setStatus] = useState("");
  const [period, setPeriod] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [indicatorsCount, setIndicatorsCount] = useState({
    approved: 0,
    canceled: 0,
    finished: 0,
  });

  const [pagination, setPagination] = useState({ page: 1, offset: 10000 });

  const filterStates = {
    passenger: [passenger, setPassenger],
    idNumber: [idNumber, setIdNumber],
    status: [status, setStatus],
    period: [period, setPeriod],
  };

  const getOrders = async (page, offset, filters) => {
    try {
      setLoadingOrders(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/serviceorder`,
        {
          ...filters,
          companies: companiesArray
        },
        {
          params: {
            page,
            offset,
          },
        }
      );

      if (data) {

        setData(data.orders);

        setIndicatorsCount({
          approved: data.ordersStatusCount.approved?.count,
          finished: data.ordersStatusCount.canceled?.count,
          canceled: data.ordersStatusCount.finished?.count,
        });

        return setLoadingOrders(false);
      }

      setData([]);

      setIndicatorsCount({
        approved: 0,
        finished: 0,
        canceled: 0,
      });

      return setLoadingOrders(false);
    } catch (error) {
      return setLoadingOrders(false);
    }
  };

  useEffect(() => {
    getOrders(pagination.page, pagination.offset, {
      openingDate: dateState.selectedDate.startDate
        .split("/")
        .reverse()
        .join("-"),
      closingDate: dateState.selectedDate.endDate
        .split("/")
        .reverse()
        .join("-"),
      status: filterStates.status[0],
      idNumber: filterStates.idNumber[0],
      passenger: filterStates.passenger[0],
      companies: companiesArray,
    });
  }, []);

  return (
    <RequestManagementContext.Provider
      value={{
        accessToken,
        user,
        companies,
        costCenters,
        companiesOptions,
        setCompaniesOptions,
        companiesSelected,
        setCompaniesSelected,
        costCentersOptions,
        setCostCentersOptions,
        costCentersSelected,
        setCostCentersSelected,
        projectOptions,
        setProjectOptions,
        projectsSelected,
        setProjectsSelected,
        executivesOptions,
        setExecutivesOptions,
        executivesSelected,
        setExecutivesSelected,
        formRef,
        data,
        setData,
        loading,
        setLoading,
        dateState,
        setDateState,
        pax,
        setPax,
        products,
        setProducts,
        filters,
        setFilters,
        pageNumber,
        setPageNumber,
        // newstate
        getOrders,
        loadingOrders,
        filterStates,
        pagination,
        setPagination,
        indicatorsCount,
      }}
    >
      {/* {showModal && invoiceToReport !== null && (
        <ModalInvoiceReport
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          invoiceToReport={invoiceToReport}
        />
      )} */}

      <h1 className="page-header mb-3">Solicitações de Viagens</h1>
      <Indicators />

      <Filters />

      <Table />
    </RequestManagementContext.Provider>
  );
}
