import React from 'react';

import Select from '~/components/Select';

const EmitterSelect = ({
  setEmitterSelected,
  placeholder,
  name,
  label,
  options,
  ...rest
}) => {
  return (
    <Select
      {...rest}
      name={name}
      label={label}
      options={options}
      placeholder={placeholder}
      isMulti={true}
      onChange={(e) => {
        if (e !== null)
          return setEmitterSelected(e);

        return setEmitterSelected([]);
      }}
    />
  );
}

export default EmitterSelect;
