import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AgencyActions from '~/store/modules/ducks/agency';
import { ApiAxios } from '~/services/api';
import { toast } from 'react-toastify';
import {
  Row,
  Col,
  Spinner,
  FormText
} from 'reactstrap';
import {
  FormatPhoneOnlyNumbersBR,
  FormatPhoneBR
} from '~/functions/FormatPhone';

import { Form } from '@unform/web';
import Input from '~/components/Input';

import {
  DivFlexRight,
  SpaceBetweenDiv
} from '~/styles/divs';

import {
  CloseAction
} from '~/components/ActionsOfTables';

function CreateAgency({
  setPage,
  editing = false
}) {
  const axios = ApiAxios();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const { agencySetting } = useSelector(state => state.agency);

  const [agency, setAgency] = useState({
    displayName: '',
    fullName: '',
    suffix: 'Gestão',
    email1: '',
    email2: '',
    phone1: '',
    phone2: '',
    color1: '',
    color2: ''
  });

  useEffect(() => {
    async function setEditing () {
      if (editing === true) {
        return setAgency(agencySetting);
      }
    }

    setEditing();
  }, [editing, agencySetting]);

  function handlePhone (phone1, phone2) {
    let p1 = FormatPhoneOnlyNumbersBR(phone1);
    let p2 = FormatPhoneOnlyNumbersBR(phone2);

    setAgency({
      ...agency,
      phone1: p1,
      phone2: p2
    })

    return true;
  }

  async function handleSubmit () {
    try {
      setLoading(true);

      if (agency.fullName.length < 7)
      {
        return toast.info(
          "O nome da Agência é muito pequeno. O nome deve ter mais que 7(sete) caracteres!"
        );
      }

      if (editing === true) {
        const resEditing = await axios.put(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency`,
          agency
        );

        dispatch(AgencyActions.insertAgencySetting(resEditing.data));
      } else {
        const res = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency`,
          agency
        );

        dispatch(AgencyActions.insertAgencySetting(res.data));
      }


      setLoading(false);

      toast.success('Alterações salvas com sucesso.');

      return setPage('list');
    } catch (error) {
      setLoading(false);

      return toast.error(
        error.data
      );
    }
  }

  return (
    <>
      <SpaceBetweenDiv>
        <h3>
          Alterar Dados da Agência
        </h3>

        <CloseAction
          className="btn"
          type="button"
          onClick={() => setPage('list')}>
          <i className="fa fa-times" aria-hidden="true" />
        </CloseAction>
      </SpaceBetweenDiv>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Col sm={12}>
            <Row>
              <Col sm={12} lg={6}>
                <Input
                  required={true}
                  name='fullName'
                  label='Nome da Agência'
                  value={agency.fullName}
                  onChange={e => setAgency({
                    ...agency,
                    fullName: e.target.value
                  })} />
              </Col>
            </Row>
          </Col>

          <Col sm={12} lg={6}>
            <Row>
              <Col sm={8}>
                <Input
                  required={true}
                  name='Display'
                  label='Título da Agência'
                  value={agency.displayName}
                  onChange={e => setAgency({
                    ...agency,
                    displayName: e.target.value
                  })} />
              </Col>

              <Col sm={4}>
                <Input
                  required={true}
                  label='Sufixo'
                  name='suffix'
                  disabledButton={true}
                  value={agency.suffix}
                  onChange={e => setAgency({
                    ...agency,
                    suffix: e.target.value
                  })} />
              </Col>

              <Col sm={12}>
                <FormText color='muted'>
                  O título aparecerá como <b><i><u>Título da Agência</u> Gestão</i></b>
                </FormText>
              </Col>
            </Row>
          </Col>

          <Col sm={12} lg={6}>
            <Row>
              <Col sm={12} lg={6}>
                <Input
                  required={true}
                  name='phone1'
                  label='Telefone Principal'
                  value={agency.phone1}
                  isPhone={true}
                  onChange={e => handlePhone(e.target.value, agency.phone2)} />
              </Col>

              <Col sm={12} lg={6}>
                <Input
                  name='phone2'
                  label='Telefone 2'
                  isPhone={true}
                  value={agency.phone2}
                  onChange={e => handlePhone(agency.phone1, e.target.value)} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col sm={12} lg={6}>
            <Row>
              <Col sm={12} lg={6}>
                <Input
                  required={true}
                  name='email1'
                  label='Email Principal'
                  value={agency.email1}
                  onChange={e => setAgency({
                    ...agency,
                    email1: e.target.value
                  })} />
              </Col>

              <Col sm={12} lg={6}>
                <Input
                  name='email2'
                  label='Email 2'
                  value={agency.email2}
                  onChange={e => setAgency({
                    ...agency,
                    email2: e.target.value
                  })} />
              </Col>
            </Row>
          </Col>
        </Row>

        <DivFlexRight>
          <button
            className="btn btn-success"
            type="submit"
            disabled={loading}
            style={{
              background: '#000000',
              color: '#fff',
              fontWeight: 700,
              border: 'none'
            }}>
            {loading && <Spinner color="#fff" size="sm" />}

            {!loading && <>Salvar</>}
          </button>
        </DivFlexRight>
      </Form>
    </>
  );
}

export default CreateAgency;
