import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Spinner } from 'reactstrap';
import { Form } from '@unform/web';
import {
  FormatPhoneOnlyNumbersBR,
  FormatPhoneBR
} from '~/functions/FormatPhone';
import { ApiAxios } from '~/services/api.js';
import SliderToggle from '~/components/SliderToggle';

import {
  EditAction,
  DeleteAction,
  CreateAction
} from '~/components/ActionsOfTables';

import { Panel } from '~/components/Panel';
import Input from '~/components/Input';
import Select from '~/components/Select';

import {
  SubmitButton,
  PanelFilterCompanyGroup,
  PanelSearchResult,
  SpanPasswordAlert,
  SpanLi,
  LiSearch,
} from './styles';

function Edit({
  company,
  setManagerPage,
  managerToEdit
}) {
  const axios = ApiAxios();
  const accessToken = useSelector(state => state.auth.accessToken);
  const formRef = useRef(null);

  const [isGroupAdmin, setIsGroupAdmin] = useState(false);
  const [nameOfUser, setNameOfUser] = useState('');
  const [oldEmailOfUser, setOldEmailOfUser] = useState('');
  const [emailOfUser, setEmailOfUser] = useState('');
  const [phoneOfUser, setPhoneOfUser] = useState('');
  const [phoneOfUserFormated, setPhoneOfUserFormated] = useState('');
  const [phoneOfUserChecked, setPhoneOfUserChecked] = useState(false);
  const [messagePhone, setMessagePhone] = useState('');
  const [messageEmailUsed, setMessageEmailUsed] = useState('');
  const [typeOfNewUser, setTypeOfNewUser] = useState('0');
  const [clientsUserProfiles, setClientsUserProfiles] = useState([]);
  const [clientsUserProfileSelected, setClientsUserProfileSelected] = useState(null);

  const [alterPhone, setAlterPhone] = useState(false);
  const [alterName, setAlterName] = useState(false);

  const [loading, setLoading] = useState(false);
  const [editChange, setEditChange] = useState(true);

  const [originalListCC, setOriginalListCC] = useState([]);
  const [listCC, setListCC] = useState([]);
  const [listCCVinculadas, setListCCVinculadas] = useState([]);

  useEffect(() => {
    function loadManagerToEdit() {
      setNameOfUser(managerToEdit.manager.name);
      setEmailOfUser(managerToEdit.manager.email);
      setOldEmailOfUser(managerToEdit.manager.email);
      setIsGroupAdmin(managerToEdit.manager.isGroupAdmin);
    }

    loadManagerToEdit();
  }, [managerToEdit]);

  useEffect(() => {
    async function loadClientCostCenter () {
      try {
        const { data: ccsOfCostManager } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/manager/getcostcenters/${managerToEdit.manager.email}`
        );

        const { data: ccsOfClient } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/manager/getcostcenters/notof/${managerToEdit.manager.email}`
        );

        setListCC(ccsOfClient);

        setListCCVinculadas(ccsOfCostManager);

        setOriginalListCC(ccsOfClient.concat(ccsOfCostManager));

        return true;
      } catch (error) {
        return console.log(error);
      }
    }

    loadClientCostCenter();
  }, []);

  useEffect(() => {
    async function loadClientsUserProfile () {
      setClientsUserProfiles([]);

      let response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/user-type/ofclients`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        },
      );

      return setClientsUserProfiles(response.data);
    }

    loadClientsUserProfile();
  }, [accessToken]);

  useEffect(() => {
    async function loadManagerPhone () {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/manager/getphone/${managerToEdit.manager.email}`
      );

      return handlePhone(response.data.toString())
    }

    loadManagerPhone();
  }, []);

  useEffect(() => {
    if (phoneOfUser.length > 0 && phoneOfUser.length < 11) {
      setMessagePhone('Telefone inválido.');
    }

    if (phoneOfUser.length === 11 || phoneOfUser.length === 0) {
      setMessagePhone('');
    }
  }, [phoneOfUser]);

  function handlePhone (value) {
    let phone = FormatPhoneOnlyNumbersBR(value);
    let phoneFormated = FormatPhoneBR(phone);

    setPhoneOfUser(phone);
    setPhoneOfUserFormated(phoneFormated);

    setPhoneOfUserChecked(phone.length === 1);

    return true;
  }

  async function handleSubmit(body) {
    try {
      setLoading(true);

      if (emailOfUser !== managerToEdit.manager.email) {
        const res = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/agency-user/checkemail/${emailOfUser}`
        );

        if (res.data === true) {
          setLoading(false);

          return setMessageEmailUsed('O email já está sendo utilizado.')
        }
      }

      setMessageEmailUsed('')

      const filter = {
        oldEmail: oldEmailOfUser,
        email: emailOfUser,
        phone: phoneOfUser,
        name: nameOfUser,
        clientCode: company.code,
        costCenters: listCCVinculadas.map(e => (e.nameCC)),
        isGroupAdmin: isGroupAdmin
      };

      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/manager/updateclientmanager`,
        filter
      );

      toast.success('Usuário atualizado com sucesso!');

      setTimeout(() => setManagerPage('list'), 1000);

      return setLoading(false);
    } catch (error) {
      setLoading(false);

      return toast.error('Não foi possível atualizar o usuário.');
    }
  }

  function targetCostCenter(cc) {
    setEditChange(false);

    const newListCC = [];

    listCC.map(list => {
      if (list.nameCC !== cc.nameCC) {
        newListCC.push(list);
      }
      return false;
    });

    if (listCCVinculadas.length > 0) {
      const res = listCCVinculadas.filter(
        lv => lv.nameCC === cc.nameCC
      );

      if (res.length <= 0) {
        setListCC(newListCC);

        return setListCCVinculadas(listCCVinculadas.concat([cc]));
      }

      return toast.info(
        `A Empresa: ${cc.empresa}, já está vinculada ao usuário`
      );
    }

    setListCC(newListCC);

    return setListCCVinculadas(listCCVinculadas.concat([cc]));
  }

  function untargetCostCenter(cc) {
    setEditChange(false);

    const newListCCVinculadas = [];

    listCCVinculadas.map(list => {
      if (list.nameCC !== cc.nameCC) {
        newListCCVinculadas.push(list);
      }
      return false;
    });

    setListCCVinculadas(newListCCVinculadas);

    if (listCC.length > 0) {
      const res = listCC.filter(
        lc => lc.nameCC === cc.nameCC
      );

      if (res.length <= 0)
        return setListCC(listCC.concat([cc]));

      return false;
    }

    return setListCC(listCC.concat([cc]));
  }

  function targetAllCostCenters () {
    setEditChange(false);

    setListCCVinculadas(originalListCC);

    setListCC([]);

    return true;
  }

  function untargetAllCostCenters () {
    setEditChange(false);

    setListCC(originalListCC);

    setListCCVinculadas([]);

    return true;
  }

  async function handleIsGroupAdmin () {

    return setIsGroupAdmin(!isGroupAdmin);
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Row>
        <Col lg="6">
          <Input
            name="name"
            placeholder="Nome Completo"
            label="Nome"
            value={nameOfUser}
            onChange={e => {
              setNameOfUser(e.target.value)

              return setEditChange(false);
            }}
            required
            disabledButton={true}
            />
        </Col>

        <Col lg="6">
          <Input
            name="email"
            type="email"
            placeholder="E-mail"
            label="E-mail"
            value={emailOfUser}
            onChange={e => {
              setEmailOfUser(e.target.value);

              return setEditChange(false);
            }}
            required
            disabledButton={true}
            errorMessage={messageEmailUsed}
          />
        </Col>

        <Col lg='6'>
          <Input
            name="telefone"
            type="text"
            placeholder="Telefone"
            label="Telefone"
            value={phoneOfUserFormated}
            onChange={e => {
              handlePhone(e.target.value)

              return setEditChange(false);
            }}
            disabledButton={true}
            errorMessage={messagePhone}
            required
          />
        </Col>

        {/* <Col lg='6'>
          <label>
            <b>Ações</b>
          </label>

          <br />

          <CreateAction
            style={{margin: '0 3px 0 0'}}
            type='button'
            onClick={() => setAlterName(!alterName)}>
            {!alterName ? `Alterar Nome` : `Nome Ok!`}
          </CreateAction>

          <DeleteAction
            style={{margin: '0 3px 0 0'}}
            type='button'
            onClick={() => setAlterPhone(!alterPhone)}>
            {!alterPhone ? `Alterar Telefone` : `Telefone Ok!`}
          </DeleteAction>
        </Col> */}
      </Row>

      <Row>
        <Col lg="6">
          <Input
            name="userType"
            label="Tipo de Usuário"
            type="text"
            value={
              managerToEdit.type === 1
                ? `Gestor de Centros de Custo`
                : managerToEdit.type === 2
                  ? `Gestor de Viagens`
                  : `Gestor Geral da Empresa`
            }
            required
            disabled={true}
          />
        </Col>

        {!loading && managerToEdit.type === 2 && (
          <Col lg={6}>
            <SliderToggle
              label={'Grupo'}
              fontSize={'13px'}
              checked={isGroupAdmin}
              click={() => {
                handleIsGroupAdmin();

                return setEditChange();
              }}
              textAlign={'left'}
            />
          </Col>
        )}
      </Row>

      {loading && (
        <div style={{width: '100%', textAlign: 'center'}}>
          <Spinner color="#fff" size="lg" />
        </div>
      )}

      {!loading && managerToEdit.type === 1 && (
        <Row>
          <Col lg="6">
            <Panel>
              <PanelFilterCompanyGroup>
                <b>Escolha os Centros de Custo</b>

                <EditAction
                  className='btn'
                  disabled={listCC.length === 0}
                  type='button'
                  onClick={() => targetAllCostCenters()}>
                  Adicionar Todos
                </EditAction>
              </PanelFilterCompanyGroup>

              <PanelSearchResult>
                <ul type="none">
                  {
                    listCC.map((row, i) => (
                      <LiSearch key={i.toString()}>
                        <SpanLi>{row.nameCC}</SpanLi>

                        <button
                          className="btn"
                          type="button"
                          onClick={() => targetCostCenter(row)}>
                          <i className="fa fa-plus" aria-hidden="true" />
                        </button>
                      </LiSearch>
                    ))
                  }
                </ul>
              </PanelSearchResult>
            </Panel>
          </Col>

          <Col lg="6">
            <Panel>
              <PanelFilterCompanyGroup>
                <b>Centros de Custo Vinculados</b>

                <DeleteAction
                  disabled={listCCVinculadas.length === 0}
                  className='btn'
                  type='button'
                  onClick={() => untargetAllCostCenters()}>
                  Remover Todos
                </DeleteAction>
              </PanelFilterCompanyGroup>

              <PanelSearchResult>
                <ul type="none">
                  {
                    listCCVinculadas.map((row, i) => (
                      <LiSearch key={i.toString()}>
                        <SpanLi>{row.nameCC}</SpanLi>

                        <button
                          className="btn"
                          type="button"
                          onClick={() => untargetCostCenter(row)}
                        >
                          <i className="fa fa-minus" aria-hidden="true" />
                        </button>
                      </LiSearch>
                    ))
                  }
                </ul>
              </PanelSearchResult>
            </Panel>
          </Col>
        </Row>
      )}

      <Row>
        <SubmitButton>
          <button
            className="btn btn-success"
            type="submit"
            disabled={
              editChange
              || (phoneOfUser.length > 0 && phoneOfUser.length < 11
              || (managerToEdit.type === 1 && listCCVinculadas.length === 0)
            )}
            style={{
              background: '#000000',
              color: '#fff',
              fontWeight: 700,
              marginLeft: 10
            }}
          >
            {loading && <Spinner color="#fff" size="sm" />}
            {!loading && <>Salvar</>}
          </button>
        </SubmitButton>
      </Row>
    </Form>
  );
}

export default Edit;
