import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enUS from './en-US/index.json';
import ptBR from './pt-BR/index.json';

const persistor = JSON.parse(localStorage.getItem('persist:corpsys'));

const language = persistor && JSON.parse(persistor.language);

const resources = {
  'pt-BR': ptBR,
  'en-US': enUS,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: { escapeValue: false },
    lng: (persistor && language.key) || 'pt-BR',
    resources,
    returnObjects: true,
  });

export default i18n;
