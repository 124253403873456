import { useClearCache } from 'react-clear-cache';

const ClearCache = () => {
  const {
    isLatestVersion,
    emptyCacheStorage
  } = useClearCache();


  if (!isLatestVersion) {
    console.log('NO LATEST, CLEANING');

    return emptyCacheStorage();
  }

  return true;
};

export default ClearCache;
