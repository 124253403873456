import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import VolumePurchase from '../../Widgets/VolumePurchase.js';
import WhiteCard from '../../WhiteCard';
import ShowSelects from '~/functions/ShowSelects';
import CompanyPermission from '~/functions/CompanyPermission';
import { FormatValueBRL } from '~/functions/FormatValue.js';

import DashboardContext from '../../../context';

export default function VolumePurchase1 () {
  const chartId = 1;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);
  const userProfile = useSelector(state => state.user.userProfile);

  const {
    accessToken,
    companiesOptions,
    setCompaniesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCentersOptions,
    setCostCentersOptions,
    costCentersSelected,
    setCostCentersSelected,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [volumePurchaseData, setVolumePurchaseData] = useState({
    international: 0,
    nacional: 0
  });

  async function loadData () {
    if(companiesOptions.length === 0) return
    try {
      setVolumePurchaseData({
        international: 0,
        nacional: 0
      });

      setLoading(true);

      setLikes(user.preferences.dashboard.chart1);

      user.myCharts.map(e => {
        if (e === chartId) {
          return setPinned(true)
        }

        return false;
      });

      const {
        selectedDate: { startDate, endDate },
      } = dateState;

      const VencimentoInicial = Number(
        startDate
          .split('/')
          .reverse()
          .join('')
      );

      const VencimentoFinal = Number(
        endDate
          .split('/')
          .reverse()
          .join('')
      );

      const Companies = companiesSelected.length === 0
      ? companiesOptions.map(x => (x.value))
      : companiesSelected.map(x => (x.value));

      const CostCenters = !CompanyPermission(userProfile.userProfileId).all
        ? (
          costCentersSelected.length === 0
            ? costCentersOptions.map(e => (e.value))
            : costCentersSelected.map(e => (e.value))
        )
        : costCentersSelected.map(e => (e.value));

      const options = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };


      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/gastosDI`,
        {
          handle: Companies,
          datai: VencimentoInicial,
          dataf: VencimentoFinal,
          custos: CostCenters
        },
        options
      );

      if (response.data) {

        setVolumePurchaseData({
          international: response.data[0].porcentI,
          nacional: response.data[0].porcentD,
          valueInternational: FormatValueBRL(response.data[0].i),
          valueNacional: FormatValueBRL(response.data[0].d),
        });
      }


      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, [dateState, companiesSelected, costCentersSelected, setCompaniesSelected, companiesOptions]);

  return (
    <WhiteCard title={'Gasto por tipo de viagem'}
      modalTitle='Observação'
      modalDesc='Gastos separados por compras nacionais e internacionais.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <VolumePurchase data={volumePurchaseData}

      />
    </WhiteCard>
  );
}
