import styled from 'styled-components';

export const Widget = styled.div`
  cursor: pointer;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  &:hover {
    filter: brightness(80%);
  }
`;
