import React, { useState, useEffect, useContext } from 'react'
import BudgetManagement from "../../context";
import { Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from '@unform/web';
import { FilterButtons } from './styles';
import Input from "~/components/Input";
import { ApiAxios } from '~/services/api';
import { toast } from 'react-toastify';
import { Spinner } from "reactstrap";
import { FormatValueBRL, GetCurrencyValue, FormatInputValue } from '~/functions/FormatValue';

export function ModalEditBudget({ isOpen, handleClose, formRef, budget }) {
  const axios = ApiAxios();
  const {
    getBudget,
    loading,
    setLoading
  } = useContext(BudgetManagement);

  const [budgetToUpdate, setBudgetToUpdate] = useState({
    budget_id: 0,
    cost_id: 0,
    company_id: 0,
    company_name: '',
    cost_name: '',
    cost_type: '',
    created_at: '',
    updated_at: '',
    year: 0,
    jan: FormatValueBRL(0),
    feb: FormatValueBRL(0),
    mar: FormatValueBRL(0),
    may: FormatValueBRL(0),
    apr: FormatValueBRL(0),
    jun: FormatValueBRL(0),
    jul: FormatValueBRL(0),
    aug: FormatValueBRL(0),
    sep: FormatValueBRL(0),
    oct: FormatValueBRL(0),
    nov: FormatValueBRL(0),
    dec: FormatValueBRL(0),
    total: FormatValueBRL(0),
  });

  useEffect(() => {
    formRef?.current?.setData(budget);
  }, [budget]);

  useEffect(() => {
    function updateBudget () {
      if (budget === null) {
        return setBudgetToUpdate({
          budget_id: 0,
          cost_id: 0,
          company_id: 0,
          company_name: '',
          cost_name: '',
          cost_type: '',
          created_at: '',
          updated_at: '',
          year: 0,
          jan: FormatValueBRL(0),
          feb: FormatValueBRL(0),
          mar: FormatValueBRL(0),
          may: FormatValueBRL(0),
          apr: FormatValueBRL(0),
          jun: FormatValueBRL(0),
          jul: FormatValueBRL(0),
          aug: FormatValueBRL(0),
          sep: FormatValueBRL(0),
          oct: FormatValueBRL(0),
          nov: FormatValueBRL(0),
          dec: FormatValueBRL(0),
          total: FormatValueBRL(0),
        });
      }

      return setBudgetToUpdate({
        budget_id: budget.budget_id,
        cost_id: budget.cost_id,
        company_id: budget.company_id,
        company_name: budget.company_name,
        cost_name: budget.cost_name,
        cost_type: budget.cost_type,
        created_at: budget.created_at,
        updated_at: budget.updated_at,
        year: budget.year,
        jan: FormatValueBRL(budget.jan ?? 0),
        feb: FormatValueBRL(budget.feb ?? 0),
        mar: FormatValueBRL(budget.mar ?? 0),
        may: FormatValueBRL(budget.may ?? 0),
        apr: FormatValueBRL(budget.apr ?? 0),
        jun: FormatValueBRL(budget.jun ?? 0),
        jul: FormatValueBRL(budget.jul ?? 0),
        aug: FormatValueBRL(budget.aug ?? 0),
        sep: FormatValueBRL(budget.sep ?? 0),
        oct: FormatValueBRL(budget.oct ?? 0),
        nov: FormatValueBRL(budget.nov ?? 0),
        dec: FormatValueBRL(budget.dec ?? 0),
        total: FormatValueBRL(budget.total ?? 0),
      });
    }

    updateBudget();
  }, [budget]);

  async function onSubmit(){
    try {
      setLoading(true);

      const filter = {
        ...budgetToUpdate,
        jan: GetCurrencyValue(budgetToUpdate.jan),
        feb: GetCurrencyValue(budgetToUpdate.feb),
        mar: GetCurrencyValue(budgetToUpdate.mar),
        may: GetCurrencyValue(budgetToUpdate.may),
        apr: GetCurrencyValue(budgetToUpdate.apr),
        jun: GetCurrencyValue(budgetToUpdate.jun),
        jul: GetCurrencyValue(budgetToUpdate.jul),
        aug: GetCurrencyValue(budgetToUpdate.aug),
        sep: GetCurrencyValue(budgetToUpdate.sep),
        oct: GetCurrencyValue(budgetToUpdate.oct),
        nov: GetCurrencyValue(budgetToUpdate.nov),
        dec: GetCurrencyValue(budgetToUpdate.dec),
        total: GetCurrencyValue(budgetToUpdate.total)
      };

      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/budget/save`,
        filter,
      );

      toast.success("Orçamento atualizado com sucesso.")

      handleClose();

      getBudget();

      return setLoading(false);
    } catch (error) {
      
      toast.error("Erro ao tentar atualizar orçamento.")

      handleClose();

      getBudget();

      return setLoading(false);
    }
  }

  function handleDivideBudget () {
    var dividedTotal = GetCurrencyValue(budgetToUpdate.total) / 12;

    return setBudgetToUpdate({
      ...budgetToUpdate,
      jan: FormatValueBRL(dividedTotal ?? 0),
      feb: FormatValueBRL(dividedTotal ?? 0),
      mar: FormatValueBRL(dividedTotal ?? 0),
      may: FormatValueBRL(dividedTotal ?? 0),
      apr: FormatValueBRL(dividedTotal ?? 0),
      jun: FormatValueBRL(dividedTotal ?? 0),
      jul: FormatValueBRL(dividedTotal ?? 0),
      aug: FormatValueBRL(dividedTotal ?? 0),
      sep: FormatValueBRL(dividedTotal ?? 0),
      oct: FormatValueBRL(dividedTotal ?? 0),
      nov: FormatValueBRL(dividedTotal ?? 0),
      dec: FormatValueBRL(dividedTotal ?? 0),
    });
  }

  return (
    <Modal show={isOpen} onHide={handleClose} centered size="md">
      <Form ref={formRef} onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Editar Orçamento ({budget?.cost_name})
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
          <Row>
            <Col sm={12} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
              <Input
                name="year"
                label="Orçamento Anual"
                value={budgetToUpdate.total}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    total: value,
                  }),
                })}
              />

              <button
                type={'button'}
                onClick={handleDivideBudget}
                disabled={GetCurrencyValue(budgetToUpdate.total) <= 0}
                style={{
                  margin: '0 0 2px 16px',
                  height: '38px',
                  border: '1px solid #cdcdcd',
                  borderRadius: '5px'
                }}>
                Dividir Valores
              </button>
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="jan"
                label="Janeiro"
                value={budgetToUpdate.jan}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    jan: value,
                  }),
                })} />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="feb"
                label="Fevereiro"
                value={budgetToUpdate.feb}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    feb: value,
                  }),
                })} />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="mar"
                label="Março"
                value={budgetToUpdate.mar}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    mar: value,
                  }),
                })} />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="apr"
                label="Abril"
                value={budgetToUpdate.apr}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    apr: value,
                  }),
                })} />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="may"
                label="Maio"
                value={budgetToUpdate.may}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    may: value,
                  }),
                })} />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="jun"
                label="Junho"
                value={budgetToUpdate.jun}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    jun: value,
                  }),
                })} />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="jul"
                label="Julho"
                value={budgetToUpdate.jul}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    jul: value,
                  }),
                })} />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="aug"
                label="Agosto"
                value={budgetToUpdate.aug}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    aug: value,
                  }),
                })} />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="sep"
                label="Setembro"
                value={budgetToUpdate.sep}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    sep: value,
                  }),
                })} />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="oct"
                label="Outubro"
                value={budgetToUpdate.oct}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    oct: value,
                  }),
                })} />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="nov"
                label="Novembro"
                value={budgetToUpdate.nov}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    nov: value,
                  }),
                })} />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Input
                name="dec"
                label="Dezembro"
                value={budgetToUpdate.dec}
                onChange={e => FormatInputValue({
                  value: e.target.value,
                  maxValue: 18,
                  onChangeCallback: value => setBudgetToUpdate({
                    ...budgetToUpdate,
                    dec: value,
                  }),
                })} />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {/* {GetCurrencyValue(budgetToUpdate.total) < (
            GetCurrencyValue(budgetToUpdate.jan) +
            GetCurrencyValue(budgetToUpdate.feb) +
            GetCurrencyValue(budgetToUpdate.mar) +
            GetCurrencyValue(budgetToUpdate.may) +
            GetCurrencyValue(budgetToUpdate.apr) +
            GetCurrencyValue(budgetToUpdate.jun) +
            GetCurrencyValue(budgetToUpdate.jul) +
            GetCurrencyValue(budgetToUpdate.aug) +
            GetCurrencyValue(budgetToUpdate.sep) +
            GetCurrencyValue(budgetToUpdate.oct) +
            GetCurrencyValue(budgetToUpdate.nov) +
            GetCurrencyValue(budgetToUpdate.dec)
          ) && (
            <p style={{color: '#400'}}>
              <b>A soma dos meses ultrapassou o Orçamento Anual.</b>
            </p>
          )} */}

          <FilterButtons>
            <Button
              variant="btn btn-warning"
              type="button"
              onClick={handleClose}
              disabled={loading}>
              Cancelar
            </Button>

            <Button
              className={`btn btn-primary`}
              className={`btn btn-primary ${loading && 'disabled'}`}
              type="submit"
              disabled={loading}>
              {loading && <Spinner color="#fff" size="sm" />}

              {!loading && 'Salvar'}
            </Button>
          </FilterButtons>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
