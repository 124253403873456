import styled from "styled-components";

export const Title = styled.h1`
  font-weight: 600;
  color: #333;
  font-size: 28px;
  letter-spacing: -1px;
  ${(props) =>
    props.new &&
    `
  `}

  ${(props) =>
    props.small &&
    `
    font-size: 24px;
  `}
    svg {
    margin-right: 10px;
    :hover {
      cursor: pointer;
      transition-property: opacity;
      opacity: 0.6;
      transition-duration: 200ms;
    }
  }
`;

export const Header = styled.header`
  hr{margin: 0px 0px 10px;}
`;

export const ButtonMenu = styled.button`
  font-size: 15px;
  background: rgba(0, 0, 0, 0.05);
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s background;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
export const DatePickerContainer = styled.div`
  margin-top: 10px;
  width: 100%;

  @media (min-width: 1024px) {
    width: 300px;
  }

  div.form-group {
    display: block !important;
  }
`;

export const ContainerDestinyFilter = styled.div`
  .filterSelectors {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    span {
      margin: 2px 4px;
      font-size: 13px;
      font-weight: 600;
    }

    div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      button {
        height: 38px;
        border: none;
        border-radius: 4px;
        background-color: #0000000D;
      }
    }
  }
`;
export const FilterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  div {
    div{
      width: 100%;
      height: 5px;
    }
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;

      border: 0;
      border-radius: 4px;
      height: 35px;

      svg {
        margin-left: 5px;
      }

      & {
        margin-left: 5px;
      }
    }
  }

`;
