import styled from "styled-components";

export const ColumnsTableMain = styled.div`
padding: 8px 0 0 0;

div {
  display: flex;
  align-items: center;
  padding: 2px;
}

input {
  width: 18px;
  height: 18px;
}

span {
  margin: 0px 8px;
}
`;

export const ColumnsTitleMain = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
div {
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
    margin: 0 8px;
  }
}
`;

export const ButtonCheckColumns = styled.button`
//background: rgba(230, 230, 230, 1);
margin-left: 8px;
padding: 0px 8px;
border-radius: 4px;
border: none;
font-weight: 700;
font-size: 11px;
border: 1px solid #fff;
background-color: #000000;
color: white;
`;
