import React from "react";

import Select from "~/components/Select";

const CompanySelect = ({
  setCompaniesSelected,
  placeholder,
  name,
  label,
  options,
  isMulti = true,
  ...rest
}) => {
  return (
    <Select
      {...rest}
      name={name}
      label={label}
      options={options}
      placeholder={placeholder}
      isMulti={true}
      onChange={(e) => {
        if (e !== null) {
          if (!isMulti) {
            if (e.length > 1) {
              return false;
            }

            return setCompaniesSelected(e);
          }

          return setCompaniesSelected(e);
        }

        return setCompaniesSelected([]);
      }}
    />
  );
};

export default CompanySelect;
