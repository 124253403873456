import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useTranslation } from 'react-i18next';
// import { Row, Col } from 'reactstrap';
import { Form } from '@unform/web';
import Input from '~/components/Input';

import {
  Container,
  Pane,
  UserSection,
  // ProfileSection,
  // UserAvatar,
  // LeftCol,
  // RightCol,
} from './styles';
import GetPhoto from '~/functions/GetPhoto';

// import { Container } from './styles';

export default function UserProfileModal({ show, handleClose }) {
  const profile = useSelector(state => state.user.profile.profile);
  const companies = useSelector(state => state.user.companies);
  const [groupName, setGroupName] = useState();
  const [avatarUser, setAvatarUser] = useState();
  const [edit, setEdit] = useState(true);
  useMemo(() => {
    const { logotipo, grupoEmpresarial } = GetPhoto(profile, companies);

    setGroupName(grupoEmpresarial);
    setAvatarUser(logotipo);
  }, [companies, profile]);
  const { t } = useTranslation('UserProfile');
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>{t('Title')}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Pane>
              <UserSection>
                {groupName && <h5>{groupName}</h5>}
                <img
                  src={
                    avatarUser === 'null'
                      ? require('~/assets/images/logos/logo-casablanca-navbar.jpg')
                      : `data:image/png;base64,${avatarUser}`
                  }
                  alt={t('Panel.SideInformations.AvatarAlt')}
                />
                <div id="nameEmail">
                  <h5>Telma Montone</h5>
                  <h6>telma.montone@newigestor.com.br</h6>
                  <h6>Perfil de Teste para Telma</h6>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => setEdit(!edit)}
                  >
                    <i className="fa fa-edit fa-2x" />
                  </button>
                </div>
                {/* <div id="nickname">
                  <h6>{t('Panel.SideInformations.NicknameSection')}: Telma</h6>
                  <h6>
                    {t('Panel.SideInformations.UsernameSection')}: telma.montone
                  </h6>
                </div> */}
                {/* <div id="profile-title">
                  <h6>
                    <b>{t('Panel.SideInformations.ProfileTitle')}: </b>
                  </h6>{' '}
                </div>
                <div id="profile-desc">
                  <h6>
                    <b>{t('Panel.SideInformations.ProfileDesc')}: </b>
                  </h6>{' '}
                  <span>
                    Esse perfil foi pra Telma Testar a interface de usuario da
                    nova Experiência e resaltar os detalhes que podem se
                    ajustados
                  </span>
                </div> */}
              </UserSection>
              <Form>
                {/* <Col> */}
                <Input
                  name="name"
                  placeholder="Nome Completo"
                  disabled={edit}
                />
                {/* </Col> */}
                {/* <Col> */}
                <Input name="nickname" placeholder="Apelido" disabled={edit} />
                {/* </Col> */}

                {/* <Col> */}
                <Input name="username" placeholder="Usuário" disabled={edit} />
                {/* </Col> */}
                {/* <Col> */}
                {/* <Input name="email" placeholder="Email" /> */}
                {/* </Col> */}
              </Form>
            </Pane>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" disabled={edit}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

UserProfileModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
