import { apiDefault } from '../api';
import PropTypes from 'prop-types';

async function apiInvoiceDefault ({
  method,
  route,
  body = {},
  accessToken,
  setLoading,
  options = {},
}) {
  apiDefault.defaults.headers = {
    Authorization: `Bearer ${accessToken}`
  };

  try {
    setLoading(true);

    const res = await apiDefault[method](
      `${process.env.REACT_APP_ENDPOINT_INVOICE}/invoice${route}`,
      body,
      options
    );

    setLoading(false);

    return res;
  } catch (error) {
    setLoading(false);

    return null;
  }
}

async function getInvoiceIndicators (accessToken, body, setLoading) {
  return await apiInvoiceDefault({
    method: 'post',
    route: '/statussums',
    body,
    accessToken,
    setLoading,
  });
}

async function getInvoiceTable (accessToken, body, setLoading) {
  return await apiInvoiceDefault({
    method: 'post',
    route: '/all',
    body,
    accessToken,
    setLoading,
  });
}

async function getInvoiceProducts (accessToken, setLoading) {
  return await apiInvoiceDefault({
    method: 'options',
    route: '/list/produtos',
    accessToken,
    setLoading,
  });
}

apiInvoiceDefault.propTypes = {
  method: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  body: PropTypes.object,
  accessToken: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  options: PropTypes.object,
};

export {
  getInvoiceIndicators,
  getInvoiceTable,
  getInvoiceProducts
};
