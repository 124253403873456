import React from "react";
import moment from "moment";

import { FormatValueBRL } from '~/functions/FormatValue.js';

const zeroPad = (num, places = 2) => String(num).padStart(places, '0')

export const parseFormatDate = date => {
  let hourAux;
  if(!date){
    return ""
  }
  const [splitedDate, hours, tur] = date.split(" ");

  const [hour, min, sec] = hours.split(":");

  if(tur === "PM") {
    hourAux = Number(hour) + 12;
  } else if (tur === "AM"){
    hourAux = Number(hour);
  }

  const [mouth, day, year] = splitedDate.split("/");


  return `${zeroPad(day)}/${zeroPad(mouth)}/${year} ${zeroPad(hourAux)}:${zeroPad(min)}`
}

export const parseExternalFields = (externalFields) => {
  if (externalFields != null){
    const parsedFields = JSON.parse(externalFields)
    const [obj_filial_intercia] = parsedFields.filter(item => item["Name"]  === "Filiais Intercia");
    const [obj_atividades] = parsedFields.filter(item => item["Name"]  === "Atividades");
    const [obj_diaria] = parsedFields.filter(item => item["Name"]  === "Diária");
    const [obj_cargo] = parsedFields.filter(item => item["Name"]  === "Cargo");
    const [obj_regiao] = parsedFields.filter(item => item["Name"]  === "Região");
    const [obj_project] = parsedFields.filter(item => item["Name"]  === "Project")
  }
  return {
    filial_intercia: "",
    atividades: "",
    regiao: "",
    projeto: "",
    cargo: "",
    diaria: "",
  }
}

function showExternalFields () {
  var externalFieldsColumns = [];

  externalFieldsColumns = externalFieldsColumns.concat([
    {
      accessor: "cost_center",
      Header: "Centro de Custo",
      filterable: false,
    },
    {
      accessor: "filial_intercia",
      Header: "Filial Intercia",
      filterable: false,
      Cell: ({ row }) => <>{parseExternalFields(row.original.externalfields).filial_intercia ?? ''}</>
    },
    {
      accessor: "projeto",
      Header: "Projeto",
      filterable: false,
      Cell: ({ row }) => <>{parseExternalFields(row.original.externalfields).projeto ?? ''}</>
    },
    {
      accessor: "atividade",
      Header: "Atividade",
      filterable: false,
      Cell: ({ row }) => <>{parseExternalFields(row.original.externalfields).atividades ?? ''}</>
    },
    {
      accessor: "regiao",
      Header: "Região",
      filterable: false,
      Cell: ({ row }) => <>{parseExternalFields(row.original.externalfields).regiao ?? ''}</>
    },
    {
      accessor: "cargo",
      Header: "Cargo",
      filterable: false,
      Cell: ({ row }) => <>{parseExternalFields(row.original.externalfields).cargo ?? ''}</>
    },
    {
      accessor: "diaria",
      Header: "Diária ",
      filterable: false,
      Cell: ({ row }) => <>{parseExternalFields(row.original.externalfields).diaria ?? ''}</>
    },
  ]);

  return externalFieldsColumns;
}

export const columns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        <i className={`
          fas fa-caret-${row.isExpanded ? 'up' : 'down'}
        `} />
      </span>
    ),
    width: 40
  },
  {
    accessor: "id",
    Header: "Pedido/OS",
    width: 100,
    className: "d-flex justify-content-center",
  },
  {
    accessor: "status",
    Header: "Status",
    width: 200,
  },
  {
    accessor: "createdAt",
    Header: "Data de criação",
    width: 160,
    filterable: false,
    Cell: ({ value }) => <>{moment(value).format("DD/MM/YYYY") ?? ''}</>,
  },
  {
    accessor: "updatedAt",
    Header: "Última alteração",
    width: 160,
    filterable: false,
    Cell: ({ value }) => <>{moment(value).format("DD/MM/YYYY") ?? ''}</>,
  },
  // {
  //   accessor: "passenger",
  //   Header: "Viajante",
  //   filterable: false,
  //   width: 180,
  //   Cell: ({ value } ) => <>{value ?? ''}</>,
  // },
  {
    accessor: "reason",
    Header: "Motivo",
    filterable: false,
    width: 180,
    Cell: ({ value }) => <>{value ?? ''}</>,
  },
  {
    accessor: "requester",
    Header: "Solicitante",
    filterable: false,
    width: 180,
  },
  // ...showExternalFields(),
  // {
  //   accessor: "first_approver",
  //   Header: "Aprovador 1",
  //   filterable: false,
  //   Cell: ({ row }) => <button onClick={() => console.log(parseExternalFields(row.original.externalfields))}>
  //     {/* {parseExternalFields(row.original.externalfields)} */}123
  //     </button>,
  // },
  // {
  //   accessor: "first_approver",
  //   Header: "Aprovador 1",
  //   filterable: false,
  //   Cell: ({ row }) => <>{row.original.first_approver_name} {row.original.first_approver_middle_name} {row.original.first_approver_last_name ?? ''}</>,
  // },
  // {
  //   accessor: "first_approver_date",
  //   Header: "Data Aprov. 1",
  //   filterable: false,
  //   Cell: ({ row }) => {
  //     return <>{row.original.first_have_approved ? parseFormatDate(row.original.first_approve_date) : "Aguardando aprovação"}</>
  //   },
  // },
  // {
  //   accessor: "second_approver",
  //   Header: "Aprovador 2",
  //   filterable: false,
  //   Cell: ({ row }) => <>{row.original.second_approver_name} {row.original.second_approver_middle_name} {row.original.second_approver_last_name}</>,
  // },
  // {
  //   accessor: "second_approver_date",
  //   Header: "Data Aprov. 2",
  //   filterable: false,
  //   Cell: ({ row }) => <>{row.original.second_have_approved ? parseFormatDate(row.original.second_approve_date) : "Aguardando aprovação"}</>,
  // },
  // {
  //   accessor: "third_approver",
  //   Header: "Aprovador 3",
  //   filterable: false,
  //   Cell: ({ row }) => <>{row.original.third_approver_name} {row.original.third_approver_middle_name} {row.original.third_approver_last_name}</>,
  // },
  // {
  //   accessor: "third_approver_date",
  //   Header: "Data Aprov. 3",
  //   filterable: false,
  //   Cell: ({ row }) => <>{row.original.third_have_approved ? parseFormatDate(row.original.third_approve_date) : "Aguardando aprovação"}</>,
  // },
  // {
  //   accessor: "policy_reason",
  //   Header: "Justificativa",
  //   filterable: false,
  //   Cell: ({ row }) => row.original.policy_reason && <p>{row.original.policy_reason.slice(0, 40) ?? ''}...</p>,
  // },
  // {
  //   accessor: "valor_total",
  //   Header: "Valor Total",
  //   filterable: false,
  //   Cell: ({ value }) => <>{FormatValueBRL(Number(value)) ?? ''}</>
  // },
];

export const DefaultDate = {
  currentWeek: `${moment().subtract(30, "days").format("DD/MM/YYYY")} -
    ${moment().format("DD/MM/YYYY")}`,
  selectedDate: {
    startDate: `${moment().subtract(30, "days").format("DD/MM/YYYY")}`,
    endDate: `${moment().format("DD/MM/YYYY")}`,
  },
  prevWeek: `${moment().subtract(15, "days").format("DD/MM/YYYY")} -
    ${moment().subtract(8, "days").format("DD/MM/YYYY")}`,
  startDate: moment().subtract(30, "days"),
  endDate: moment(),
};
