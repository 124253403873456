import styled from 'styled-components';

import {
  Col,
} from 'reactstrap';

export const NewCompany = styled(Col)`
  display: none;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const NewCompanyMobile = styled(Col)`
  display: none;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    display: block;
  }
`;
