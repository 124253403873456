import styled from 'styled-components';
import { Col } from 'reactstrap';
import { Panel, PanelBody } from '~/components/Panel';

export const Container = styled(Panel)``;

export const Pane = styled(PanelBody)`
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
  padding: 16px 16px;
  box-sizing: border-box;
  min-height: 50vh;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`;

export const LeftCol = styled(Col)`
  position: relative;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
  background: #ebebeb;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;
export const RightCol = styled(Col)`
  position: relative;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  /* background: #ebebeb; */
  /* border-radius: 8px; */
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
`;

export const UserSection = styled.div`
  padding: 8px;
  box-sizing: border-box;
  text-align: center;

  img {
    border-radius: 8px;
    max-width: 70%;
    min-width: 50%;
    max-width: 200px;
    margin-bottom: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  #nameEmail {
    margin-bottom: 16px;
  }

  #nickname {
    border-top: 1px solid #aaa;
    padding-top: 8px;
    margin-bottom: 18px;
    text-align: left;
  }
`;

export const ProfileSection = styled.div`
  padding: 8px;
  box-sizing: border-box;
  background: #fbfbfb;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;
