import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AgencyActions from '~/store/modules/ducks/agency';
import { ApiAxios } from '~/services/api';
import SliderToggle from '~/components/SliderToggle';
import { toast } from 'react-toastify';
import {
  Row,
  Col,
  Spinner,
  FormText
} from 'reactstrap';
import {
  FormatPhoneOnlyNumbersBR,
  FormatPhoneBR
} from '~/functions/FormatPhone';

import { Form } from '@unform/web';
import Input from '~/components/Input';

import {
  DivFlexRight,
  SpaceBetweenDiv
} from '~/styles/divs';

import {
  EditAction,
  CloseAction
} from '~/components/ActionsOfTables';

function Edit({
  page,
  setPage,
  toEdit,
  setToEdit
}) {
  const axios = ApiAxios();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [userProfile, setUserProfile] = useState({
    id: 0,
    text: '',
    description: '',
    deletado: false,
    profileMenu: []
  });

  useEffect(() => {
    function applyToEdit () {
      setUserProfile({
        id: toEdit.id,
        text: toEdit.text,
        description: toEdit.description,
        deletado: toEdit.deletado,
        profileMenu: toEdit.profileMenu.map(x => (x))
      });
    }

    applyToEdit();
  }, [toEdit]);

  async function handleSubmit () {
    try {
      setLoading(true);

      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/profile/updateProfile/menuStatus`,
        userProfile
      );

      setLoading(false);

      toast.success("Tipo de usuário atualizado.");

      return setPage('list');
    } catch (error) {
      setLoading(false);

      return setPage('list');
    }
  }

  function handleMenuStatus (i) {
    var arr = userProfile.profileMenu;

    arr[i].deletado = !arr[i].deletado;

    return setUserProfile({
      ...userProfile,
      profileMenu: arr
    });
  }

  return (
    <>
      <SpaceBetweenDiv>
        <h5>
          Editar Tipo de Usuário
        </h5>

        <CloseAction
          className="btn"
          type="button"
          onClick={() => setPage('list')}>
          <i className="fa fa-times" aria-hidden="true" />
        </CloseAction>
      </SpaceBetweenDiv>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Col sm={12}>
            <Input
              required={true}
              name='text'
              label='Título'
              value={userProfile.text}
              onChange={e => setUserProfile({
                ...userProfile,
                text: e.target.value
              })} />
          </Col>

          <Col sm={12}>
            <Input
              required={true}
              name='description'
              label='Descrição'
              value={userProfile.description}
              onChange={e => setUserProfile({
                ...userProfile,
                description: e.target.value
              })} />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <table className='table table-hover table-responsive'>
              <thead>
                <tr>
                  <th>Título</th>
                  <th>Ícone</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                {userProfile.profileMenu.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{e.menu.title}</td>

                      <td><i className={e.menu.icon} /></td>

                      <td>
                        <SliderToggle
                          checked={!e.deletado}
                          click={() => handleMenuStatus(i)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row>

        <DivFlexRight>
          <button
            className="btn btn-success"
            type="submit"
            disabled={loading}
            style={{
              background: '#000000',
              color: '#fff',
              fontWeight: 700,
              border: 'none'
            }}>
            {loading && <Spinner color="#fff" size="sm" />}

            {!loading && <>Salvar</>}
          </button>
        </DivFlexRight>
      </Form>
    </>
  );
}

export default Edit;
