import styled from 'styled-components';

export const Title = styled.h1`
  font-weight: 600;
  color: #333;
  font-size: 28px;
  letter-spacing: -1px;
  padding-bottom: 6px;
  ${props => props.new && `
  `}

  ${props => props.small && `
    font-size: 20px;
  `}

  svg {
    margin-right: 10px;
    :hover {
      cursor: pointer;
      transition-property: opacity;
      opacity: 0.6;
      transition-duration: 200ms;
    }
  }
`

export const Description = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

export const IframeHolder = styled.div`
  width: 100%;
  min-height: 400px;

  display: grid;
  grid-template-columns: 1fr 250px;
  grid-gap: 20px;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 350px;
  border-radius: 5px;
  border: 0;
`;

export const DetailsHolder = styled.div`
  margin-top: 16px;

  ${props => props.row && `
    margin: 0;
  `}
`;
export const DetailsTitle = styled.h2``;

export const DetailsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;

  ${props => props.row && `
    // display: block;
    grid-template-columns: 1fr;
    max-width: 100%;
  `}
`;

export const DetailsItem = styled.li`
  font-size: 16px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  transition: .5s all;
  box-shadow: 0px 38px 56px rgba(154, 154, 167, 0.07), 0px 11.4559px 16.8824px rgba(154, 154, 167, 0.0456112), 0px 4.75819px 7.01207px rgba(154, 154, 167, 0.035), 0px 1.72094px 2.53613px rgba(154, 154, 167, 0.0243888);

  &:hover {
    background: #fafafa;
  }

  ${props => props.general && `
    background: #ccc;
  `}

  ${props => props.whatsapp && `
    background: #128C7E;
    color: #fff;
    cursor: pointer;

    &:hover {
      background: #075E54;
    }
  `}
  ${props => props.peddingWhatsapp && `
    background: #ccc;
    color: #000;
    cursor: pointer;

    &:hover {
      background: #075E54;
      color: #fff;
    }
  `}
`;

export const GridItem = styled.li`
  background-position: left center;
  background-attachment: fixed;
  padding: 26px;
  position: relative;
  overflow: hidden;
  min-height: 200px;
  border-radius: 30px;

  & span {
    z-index: 2;
    position: relative;
    color: #fff;
    font-size: 35px;
    font-weight: 600;
    line-height: 1;
    pointer-events: none;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.2);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    transition: .5s all;
  }

  &:hover {
    cursor: pointer;

    &::after {
      background: rgba(0,0,0,.5);
    }
  }
`

export const Grid = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  ${GridItem} {

    ${props => props.period && `
    &:nth-child(1) {
      background-image: url('https://images.unsplash.com/photo-1552733407-5d5c46c3bb3b?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8N3x8dHJhdmVsfGVufDB8fDB8&auto=format&fit=crop&w=800&q=60');
    }

    &:nth-child(2) {
      background-image: url(https://images.unsplash.com/photo-1533105079780-92b9be482077?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=668&q=80);
    }
    &:nth-child(3) {
      background-image: url(https://images.unsplash.com/photo-1512100356356-de1b84283e18?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=818&q=80);
    }
    &:nth-child(4) {
      background-image: url(https://images.unsplash.com/photo-1494783367193-149034c05e8f?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1650&q=80);
    }
  `}
  ${props => props.company && `
    &:nth-child(1) {
      background: #1b0088;
    }
    &:nth-child(2) {
      background: #FF5E00;
    }
    &:nth-child(3) {
      background: #026cb6;
    }
    &:nth-child(4) {
      background: #daca0c;
    }
  `}

  }
`

export const Header = styled.header`
  hr{margin: 0px 0px 10px;}

`

export const Section = styled.div`
  padding: 14px 0;

  ${props => props.general && `
    background: #fff;
    padding: 14px;
    border-radius: 8px;
  `}
`


export const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.4);
  opacity: 0;
  visibility: hidden;
  transform: .5s all;

  ${props => props.visible && `
    opacity: 1;
    visibility: visible;
  `}
`
export const ModalBox = styled.div`
  width: 350px;
  height: 280px;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  padding: 25px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: auto;

  .modal-close {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    right: 10px;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 20px;
      background: #333;
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  ${props => props.insertWhatsapp && `
   height: 200px;
  `}
`
export const ModalClose = styled.div``

export const ModalContent = styled.div``

export const ModalTextarea = styled.textarea`
  display: block;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  resize: none;

  border: 1px solid #ddd;
  padding: 5px;
  font-size: 14px;
  border-radius: 8px;
  margin: 10px 0;
  min-height: 100px;

  ${props => props.insertWhatsapp && `
    display: flex;
    padding-top: 9px;
    justify-content: center;
    min-height: 40px;
    max-height: 40px;
    width: auto;
    margin: 15px 0;
  `}


`

export const ModalButton = styled.button`
  appearance: none;
  -webkit-appearance: none;
  display: inline-block;
  color: #fff;
  border: 0 none;
  background: #128C7E;
  padding: 8px 20px;
  border-radius: 8px;
`

export const ModalTitle = styled.h2`
  font-size: 20px;
  letter-spacing: -1px;
`

export const HeaderPaxList = styled.h2`
  margin-top: 20px;
  font-size: 28px;
`
