import React, { createContext, useContext } from 'react';

const Context = createContext({});

const useRefund = () => useContext(Context);

const RefundProvider = ({ children, value }) => {
  return (
    <Context.Provider value={value}>
      { children }
    </Context.Provider>
  );
}

export {
  useRefund,
  RefundProvider,
};
