import React from 'react';
import styled from "styled-components";
import { FormatValueBRL } from '~/functions/FormatValue.js';

export const CellLogo = styled.img`
  border-radius: 5px;
`;

export const TotalPaidItem = styled.div`
  font-size: 10px;
  font-weight: 700;
  border-radius: 4px;
  padding: 2px 2px;
  background: #7e2dc2;
  border: 1px solid #fff;
  color: #fff;
`;

export const TotalNotPaidItem = styled.div`
  font-size: 10px;
  font-weight: 700;
  border-radius: 4px;
  padding: 2px 2px;
  background: transparent;
  border: 1px solid #ccc;
  color: #000;
`;

export const columnsAir = [
  {
    Header: 'Fatura',
    accessor: 'invoice',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    Cell: ({ row }) => {
      if (row.original.invoice > 0) {
        if (row.original.paid === row.original.totalPaid) {
          return (
            <TotalPaidItem>
              Liquidado
            </TotalPaidItem>
          );
        }

        return (
          <TotalPaidItem>
            FAT-{row.original.invoice}
          </TotalPaidItem>
        );
      }

      return <TotalNotPaidItem>(Não Faturado)</TotalNotPaidItem>;
    }
  },
  {
    default: true,
    Header: 'N° NF',
    accessor: 'nf',
    width: 75,
    show: true,
    Cell: ({row}) => (
      <span>
        {row.original.nf > 0 ? row.original.nf.toString() : ""}
      </span>
    ),
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Referência',
    accessor: 'sell',
    width: 75,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Emissão',
    accessor: 'emission',
    width: 80,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Fornecedor',
    accessor: 'cia',
    show: true,
  },
  {
    default: true,
    Header: 'Localizador',
    accessor: 'locator',
    width: 80,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Trecho',
    accessor: 'stretch',
    show: false,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Viajante',
    accessor: 'passenger',
    show: true,
    Cell: ({ row }) => {
      let nome = row.original.passenger.toUpperCase().split('/').join(' / ');
      return (<span>{nome}</span>);
    },
    style: { textAlign: 'center',
    paddingLeft: '20px',
    whiteSpace: 'pre-wrap' },
  },
  {
    default: true,
    Header: 'Centro de Custo',
    accessor: 'costCenter',
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Valor',
    accessor: 'total',
    width: 100,
    show: true,
    Cell: ({ row }) => {
      return <span>{FormatValueBRL(row.original.total)}</span>;
    },
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
];

export const columnsHotel = [
  {
    Header: 'Fatura',
    accessor: 'invoice',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    Cell: ({ row }) => {
      if (row.original.invoice > 0) {
        if (row.original.paid === row.original.totalPaid) {
          return (
            <TotalPaidItem>
              Liquidado
            </TotalPaidItem>
          );
        }

        return (
          <TotalPaidItem>
            FAT-{row.original.invoice}
          </TotalPaidItem>
        );
      }

      return <TotalNotPaidItem>(Não Faturado)</TotalNotPaidItem>;
    }
  },
  {
    default: true,
    Header: 'N° NF',
    accessor: 'nf',
    width: 75,
    show: true,
    Cell: ({row}) => (
      <span>
        {row.original.nf > 0 ? row.original.nf.toString() : ""}
      </span>
    ),
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Referência',
    accessor: 'sell',
    width: 70,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Emissão',
    accessor: 'emission',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Fornecedor',
    accessor: 'cia',
  },
  {
    default: true,
    Header: 'Localizador',
    accessor: 'locator',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Cidade',
    accessor: 'city',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Viajante',
    accessor: 'passenger',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Centro de Custo',
    accessor: 'costCenter',
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Valor',
    accessor: 'total',
    width: 100,
    show: true,
    Cell: ({ row }) => {
      return <span>{FormatValueBRL(row.original.total)}</span>;
    },
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
];

export const columnsCar = [
  {
    Header: 'Fatura',
    accessor: 'invoice',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    Cell: ({ row }) => {
      if (row.original.invoice > 0) {
        if (row.original.paid === row.original.totalPaid) {
          return (
            <TotalPaidItem>
              Liquidado
            </TotalPaidItem>
          );
        }

        return (
          <TotalPaidItem>
            FAT-{row.original.invoice}
          </TotalPaidItem>
        );
      }

      return <TotalNotPaidItem>(Não Faturado)</TotalNotPaidItem>;
    }
  },
  {
    default: true,
    Header: 'N° NF',
    accessor: 'nf',
    width: 75,
    show: true,
    Cell: ({row}) => (
      <span>
        {row.original.nf > 0 ? row.original.nf.toString() : ""}
      </span>
    ),
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Referência',
    accessor: 'sell',
    width: 70,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Emissão',
    accessor: 'emission',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Fornecedor',
    accessor: 'cia',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Localizador',
    accessor: 'locator',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Cidade',
    accessor: 'city',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Viajante',
    accessor: 'passenger',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Centro de Custo',
    accessor: 'costCenter',
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Valor',
    accessor: 'total',
    width: 100,
    show: true,
    Cell: ({ row }) => {
      return <span>{FormatValueBRL(row.original.total)}</span>;
    },
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
];

export const columnsRoad = [
  {
    Header: 'Fatura',
    accessor: 'invoice',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    Cell: ({ row }) => {
      if (row.original.invoice > 0) {
        if (row.original.paid === row.original.totalPaid) {
          return (
            <TotalPaidItem>
              Liquidado
            </TotalPaidItem>
          );
        }

        return (
          <TotalPaidItem>
            FAT-{row.original.invoice}
          </TotalPaidItem>
        );
      }

      return <TotalNotPaidItem>(Não Faturado)</TotalNotPaidItem>;
    }
  },
  {
    default: true,
    Header: 'N° NF',
    accessor: 'nf',
    width: 75,
    show: true,
    Cell: ({row}) => (
      <span>
        {row.original.nf > 0 ? row.original.nf.toString() : ""}
      </span>
    ),
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Referência',
    accessor: 'sell',
    width: 70,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Emissão',
    accessor: 'emission',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Fornecedor',
    accessor: 'cia',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  // {
  //   default: true,
  //   Header: 'Localizador',
  //   accessor: 'locator',
  //   width: 100,
  //   show: true,
  //   style: {
  //     textAlign: 'center',
  //     whiteSpace: 'pre-wrap',
  //     padding: '8px 4px',
  //   }
  // },
  {
    default: true,
    Header: 'Cidade',
    accessor: 'city',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Viajante',
    accessor: 'passenger',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Centro de Custo',
    accessor: 'costCenter',
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Valor',
    accessor: 'total',
    width: 100,
    show: true,
    Cell: ({ row }) => {
      return <span>{FormatValueBRL(row.original.total)}</span>;
    },
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
];

export const columnsOther = [
  {
    Header: 'Fatura',
    accessor: 'invoice',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    Cell: ({ row }) => {
      if (row.original.invoice > 0) {
        if (row.original.paid === row.original.totalPaid) {
          return (
            <TotalPaidItem>
              Liquidado
            </TotalPaidItem>
          );
        }

        return (
          <TotalPaidItem>
            FAT-{row.original.invoice}
          </TotalPaidItem>
        );
      }

      return <TotalNotPaidItem>(Não Faturado)</TotalNotPaidItem>;
    }
  },
  {
    default: true,
    Header: 'N° NF',
    accessor: 'nf',
    width: 75,
    show: true,
    Cell: ({row}) => (
      <span>
        {row.original.nf > 0 ? row.original.nf.toString() : ""}
      </span>
    ),
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Referência',
    accessor: 'sell',
    width: 70,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Emissão',
    accessor: 'emission',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Fornecedor',
    accessor: 'cia',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Localizador',
    accessor: 'locator',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Serviços',
    accessor: 'productDesc',
    width: 120,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  // {
  //   default: true,
  //   Header: 'Data Utilização',
  //   accessor: 'checkin',
  //   width: 100,
  //   show: true,
  //   style: {
  //     textAlign: 'center',
  //     whiteSpace: 'pre-wrap',
  //     padding: '8px 4px',
  //   }
  // },
  {
    default: true,
    Header: 'Cidade',
    accessor: 'city',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Viajante',
    accessor: 'passenger',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Centro de Custo',
    accessor: 'costCenter',
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Valor',
    accessor: 'total',
    width: 100,
    show: true,
    Cell: ({ row }) => {
      return <span>{FormatValueBRL(row.original.total)}</span>;
    },
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
];
