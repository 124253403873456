import React from 'react';

import { Col, Row } from 'reactstrap';

import AdvancedPurchase6 from './Charts/6AdvancedPurchase';
import LowestFare7 from './Charts/7LowestFare';
import InternationalAdoption from './Charts/12InternationalAdoption';
import InternationalH from './Charts/13InternationalAdoption';

export default function Policy () {
  return (
    <>
      <Row
        style={{
          width: '100%',
          padding: '0',
          margin: '0'
        }}>

        <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <AdvancedPurchase6 />
        </Col>

        <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <LowestFare7 />
        </Col>

        {/* <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <InternationalAdoption />
        </Col>
        <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <InternationalH />
        </Col> */}

      </Row>
    </>
  );
}
