import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import InternationalTicketsRemission from '../../Widgets/InternationalTicketsRemission.js';
import WhiteCard from '../../WhiteCard';
import ShowSelects from '~/functions/ShowSelects';
import CompanyPermission from '~/functions/CompanyPermission';


import DashboardContext from '../../../context';

export default function InternationalTicketsRemission9 () {
  const chartId = 9;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);
  const userProfile = useSelector(state => state.user.userProfile);

  const {
    accessToken,
    companiesOptions,
    setCompaniesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCentersOptions,
    setCostCentersOptions,
    costCentersSelected,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [Datai, setDatai] = useState({
    quant_Tickets: 0
  });

  const [InternationalTicketsRemissionData, setInternationalTicketsRemissionData] = useState({
    jan: 0,
    fev: 0,
    mar: 0,
    abr: 0,
    mai: 0,
    jun: 0,
    jul: 0,
    ago: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dez: 0
  });

  useEffect(() => {
    async function loadData () {
      try {
        setInternationalTicketsRemissionData({
          jan: 0,
          fev: 0,
          mar: 0,
          abr: 0,
          mai: 0,
          jun: 0,
          jul: 0,
          ago: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dez: 0,
          jancount: 0,
          fevcount: 0,
          marcount: 0,
          abrcount: 0,
          maicount: 0,
          juncount: 0,
          julcount: 0,
          agocount: 0,
          sepcount: 0,
          octcount: 0,
          novcount: 0,
          dezcount: 0
        });

        setLoading(true);

        setLikes(user.preferences.dashboard.chart9);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        const Companies = companiesSelected.length === 0
        ? companiesOptions.map(x => (x.value))
        : companiesSelected.map(x => (x.value));

        const CostCenters = !CompanyPermission(userProfile.userProfileId).all
          ? (
            costCentersSelected.length === 0
              ? costCentersOptions.map(e => (e.value))
              : costCentersSelected.map(e => (e.value))
          )
          : costCentersSelected.map(e => (e.value));

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const mesI = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/tickets/ticketsRee/i`,
          {
            handle: Companies,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: CostCenters
          },
          options
        );

        if(mesI.data){
          setInternationalTicketsRemissionData({
            jan: mesI.data[0].jan,
            fev: mesI.data[0].fev,
            mar: mesI.data[0].mar,
            abr: mesI.data[0].abr,
            mai: mesI.data[0].mai,
            jun: mesI.data[0].jun,
            jul: mesI.data[0].jul,
            ago: mesI.data[0].ago,
            sep: mesI.data[0].sep,
            oct: mesI.data[0].oct,
            nov: mesI.data[0].nov,
            dez: mesI.data[0].dez,
            jancount: mesI.data[0].jancount,
            fevcount: mesI.data[0].fevcount,
            marcount: mesI.data[0].marcount,
            abrcount: mesI.data[0].abrcount,
            maicount: mesI.data[0].maicount,
            juncount: mesI.data[0].juncount,
            julcount: mesI.data[0].julcount,
            agocount: mesI.data[0].agocount,
            sepcount: mesI.data[0].sepcount,
            octcount: mesI.data[0].octcount,
            novcount: mesI.data[0].novcount,
            dezcount: mesI.data[0].dezcount,
          })
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected]);

  return (
    <WhiteCard
      title={'Reemissão de bilhetes - Internacional'}
      modalTitle='Observação'
      modalDesc='Valor gasto com remarcações de passagens internacionais.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <InternationalTicketsRemission data={InternationalTicketsRemissionData} datai={Datai}/>
    </WhiteCard>
  );
}
