const ShowRefundMenu = (email) => {
  switch (email) {
    case 'manuele.santiago@mdiasbranco.com.br':
      return true;
    case 'fernanda.melo@mdiasbranco.com.br':
      return true;
    case 'nayara.carneiro@mdiasbranco.com.br':
      return true;
    case 'mariajornada@email.com':
      return true;
    case 'gv@weetravel.com':
      return true;
    case 'testecesar@weetravel.com':
      return true;
    case 'admin@weetravel.com':
      return true;
    default:
      return false;
  }
}

export { ShowRefundMenu };
