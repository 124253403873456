import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

export default function AdvancedPurchase({
  data
}) {

  const [chartData4, setChartData4] = useState({});


  useEffect(() => {
    async function loadData() {
      setChartData4({
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        datasets: [{
          data: [data.jan, data.fev, data.mar, data.abr, data.mai, data.jun, data.jul, data.ago, data.set, data.out, data.nov, data.dez],
          backgroundColor: ['#00EAE1', '#BDBFBF', '#95F496', '#f4dbab', '#7e2ec2', '#FBBA37', '#DB575B', '#A6D59A', '#D54A26', '#333333', '#9e5ef2', '#2fa9a0'],
          borderWidth: 4,
          label: 'Compras Nacionais',
        }]
      })
    }

    loadData();
  }, [data])

  return (
    <>
      <div style={{ width: '50%', margin: '2% auto', }}></div>
      <Bar data={chartData4} options={{
        legend: {
          display: false,
        },
        responsive: true,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return ` ${data.labels[tooltipItem.index]}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}%`;
            }
          }
        }
      }} />
    </>
  );
}
const styles = {
  titleLegend: {
    marginTop: "10px",
    fontSize: "11px",
    fontWeight: 700
  },
  redLegend: {
    marginTop: "10px",
    fontSize: "11px",
    fontWeight: 700,
    color: 'red'
  }
}
