import styled from 'styled-components';

export const Container = styled.div`
  padding: 2px 8px;

  div.contentRecoveryPassword {
    border-radius: 8px;
    background-color: #e9ecef;
    border: 1px solid #eee;
    padding: 7px 12px;

    div.headerRecoveryPassword {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      h6 {
        margin: 0;
        padding: 0;
      }

      button {
        padding: 0 4px;
        margin: 0;
        border: none;
        cursor: pointer;

        i {
          width: 12px;
          height: 12px;
        }
      }
    }

    div.bodyRecoveryPassword {
      padding: 0;

      span {
        font-size: 11px;
        font-weight: 600;
        color: #c00;
      }
    }
  }
`;
