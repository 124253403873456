import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import BudgetManagementContext from "./context";
import Filters from "./components/Filters";
import Table from "./components/Table";
import SectionAnalytics from "./components/SectionAnalytics";
import { ApiAxios } from "~/services/api";
import { toast } from 'react-toastify';
import {
  getCompanyCostCenters
} from '~/services/auth';

export default function BudgedManagement() {
  const axios = ApiAxios();
  const accessToken = useSelector(state => state.auth.accessToken);
  const [loading, setLoading] = useState(false);

  const [loadingBudgets, setLoadingBudgets] = useState(false)
  const [budgets, setBudgets] = useState([]);
  const [budgetsAnalytics, setBudgetsAnalytics] = useState(null);

  const companies = useSelector((state) => state.user.companies);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState(
    companies.map(company => ({
      label: company.nomeFantasia,
      value: company.codCliente,
    }))
  );

  const costCenters = useSelector((state) => state.user.costCenters);
  const [costCentersSelected, setCostCentersSelected] = useState([]);
  const [costCentersOptions, setCostCentersOptions] = useState(
    costCenters.map(costCenter => ({
      label: costCenter.name,
      value: costCenter.costId,
      type: costCenter.type,
    }))
  );

  const [analytics, setAnalytics] = useState(false);
  const [actualMonth, setActualMonth] = useState(1);
  const [panelSection, setPanelSection] = useState("alerts")
  const formRef = useRef(null);

  async function getBudget(){
    try {
      setLoadingBudgets(true);

      const filter = {
        Companies: companiesSelected.length == 1
          ? [companiesSelected[0].value]
          : [companiesOptions[0].value],
        CostCenters: costCentersSelected.length > 0
          ? costCentersSelected.map(e => e.value)
          : costCentersOptions.map(e => e.value),
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/budget`,
        filter,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        },
      );

      setBudgets(data);

      setLoadingBudgets(false);
    } catch (error) {
      setLoadingBudgets(false);
    }
  }

  async function getBudgetAnalytics(){
    try {
      if (costCentersSelected.length !== 1)
        return toast.info("Selecione um Centro de Custo.");

      setLoadingBudgets(true);

      const filter = {
        Companies: companiesSelected.length == 1
          ? [companiesSelected[0].value]
          : [companiesOptions[0].value],
        CostCenters: costCentersSelected.length > 0
          ? costCentersSelected.map(e => e.value)
          : costCentersOptions.map(e => e.value),
      };

      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/budget/graphic`,
        filter,
      );

      setBudgetsAnalytics(res.data);

      return setLoadingBudgets(false);
    } catch (error) {
      setBudgetsAnalytics(null);

      return setLoadingBudgets(false);
    }
  }

  useEffect(() => {
    getBudget();
  }, []);

  useEffect(() => {
    setCompaniesSelected([]);

    setCostCentersSelected([]);

    if (!analytics)
      setBudgetsAnalytics(null);
  }, [analytics]);

  useEffect(() => {
    async function loadCompanyCostCenters() {
      setBudgets([]);

      if (companiesSelected.length === 1) {
        const res = await getCompanyCostCenters(
          accessToken,
          () => {},
          companiesSelected[0].value
        );

        if (res === null) {
          return toast.info(
            `Não foi possível obter os centros de custo de clientes.`
          );
        }

        if (res.data.length > 0)
          return setCostCentersOptions(res.data.map(costCenter => ({
            label: costCenter.name,
            value: costCenter.costId,
            type: costCenter.type,
          })));

        toast.info(
          `${companiesSelected[0].label} - sem centros de custo.`
        );
      }

      return setCostCentersOptions(costCenters.map(costCenter => ({
        label: costCenter.name,
        value: costCenter.costId,
        type: costCenter.type,
      })));
    }

    loadCompanyCostCenters();
  }, [companiesSelected]);


  function handleResetForm() {
    formRef.current.reset();

    setBudgetsAnalytics(null);

    setCompaniesSelected([]);

    return setCostCentersSelected([]);
  }


  return (
    <BudgetManagementContext.Provider
      value={{
        companies,
        costCenters,
        companiesOptions,
        setCompaniesOptions,
        companiesSelected,
        setCompaniesSelected,
        costCentersOptions,
        setCostCentersOptions,
        costCentersSelected,
        setCostCentersSelected,
        formRef,
        handleResetForm,
        panelSection,
        setPanelSection,
        loadingBudgets,
        budgets,
        setBudgets,
        getBudget,
        analytics,
        setAnalytics,
        setActualMonth,
        getBudgetAnalytics,
        budgetsAnalytics,
        actualMonth,
        loading,
        setLoading,
      }}
    >
      <h1 className="page-header mb-3">
        {`Controle de Orçamento ${analytics ? "- Analisar" : ""}`}
      </h1>

        <>
          <Filters />

          {analytics && <SectionAnalytics />}

          {!analytics && <Table />}
        </>

    </BudgetManagementContext.Provider>
  );
}
