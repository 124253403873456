function GetRandomColors () {
  let result = '#';
  const chars = '0123456789abcdef';
  const charsLength = chars.length;

  for (let i = 0; i < 6; i++) {
    result += chars.charAt(
      Math.floor(Math.random() * charsLength)
    );
  }

  return result;
}

function Get12Colors (color) {
  let result = [];

  for (let i = 0; i < 12; i++) {
    result.push(color);
  }

  return result;
}

function getDefaultChartColors (length) {
  const defaultColors = [
    '#00EAE1',
    '#BDBFBF',
    '#95F496',
    '#f4dbab',
    '#7e2ec2',
    '#FBBA37',
    '#DB575B',
    '#A6D59A',
    '#D54A26',
    '#333333',
    '#9e5ef2',
    '#2fa9a0'
  ];

  var colorsArray = [];

  const countGroups = (length / defaultColors.length);

  for (let i = 0; i < countGroups; i++) {
    colorsArray = colorsArray.concat(defaultColors);
  }

  return colorsArray;
}

export { GetRandomColors, Get12Colors, getDefaultChartColors };
