import moment from 'moment';

export const DefaultDate = {
  currentWeek:
    `${moment().subtract(30, 'days').format('DD/MM/YYYY')} -
    ${moment().format('DD/MM/YYYY')}`,
  selectedDate: {
    startDate: `${moment().subtract(30, 'days').format('DD/MM/YYYY')}`,
    endDate: `${moment().format('DD/MM/YYYY')}`,
  },
  prevWeek:
    `${moment().subtract(15, 'days').format('DD/MM/YYYY')} -
    ${moment().subtract(8, 'days').format('DD/MM/YYYY')}`,
  startDate: moment().subtract(30, 'days'),
  endDate: moment(),
};
