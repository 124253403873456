import { Container } from './styles';
import React, { useRef, useState, useEffect } from 'react';
import { ApiAxios } from '~/services/api.js';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from '@unform/web';
import { FilterButtons } from './styles';
import TextArea from '~/components/TextArea';
import { Spinner } from 'reactstrap';
import  Table  from './Table';

function ModalIntegration({
  showModal,
  closeModal,
}) {
  const [integration, setIntegration] = useState([]);
  const accessToken = useSelector(state => state.auth.accessToken);
  const axios = ApiAxios();
  const formRef = useRef(null);
  const companies = useSelector(state => state.user.companies);
  const user = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);
  const [bodyMessage, setBodyMessage] = useState('');
  const [messageOk, setMessageOk] = useState('Sua observação será analisada e logo retornaremos por email.');

  const [disableSend, setDisableSend] = useState(false);
  const [colorMessageOk, setColorMessageOk] = useState('#333');
  const [disableClose, setDisableClose] = useState(false);
  const [disableBodyMessage, setDisableBodyMessage] = useState(false);

  const [bodyMessageCount, setBodyMessageCount] = useState(false);
  const [bodyMessageCountColor, setBodyMessageCountColor] = useState('#b00');

  const options = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  useEffect(() => {
    async function integrations() {
      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/integration`,
        {
          login: "ws.cesar",
        }
      );
      if(res.data.length > 0){
        setIntegration(res.data)
      } 
    }
    integrations();
  },[]);



  return (
    <Modal show={showModal} onHide={closeModal}>
      <Form ref={formRef}>
        <Modal.Header closeButton>
          <Modal.Title>
            Integrações - Faturamento
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
        {integration.length > 0 &&(
          <Table data={integration}/>
        )}
        {integration.length === 0 &&(
          <h5>Você não tem integrações este mês.</h5>
        )}
        </Modal.Body>
        

        <Modal.Footer>
          {/* <p style={{color: colorMessageOk}}>
          <b>
            {messageOk}
          </b>
        </p>  */}

          <FilterButtons>
            <Button
              variant="btn btn-warning"
              type="button"
              onClick={closeModal}
              disabled={loading || messageOk === '' || disableClose}>
              Fechar
            </Button>

             {/* <button
              className={`btn btn-primary ${loading && 'disabled'}`}
              type="submit"
              disabled={loading || disableSend}>
              {loading && <Spinner color="#fff" size="sm" />}

              {!loading && <>Enviar</>}
            </button> */}
          </FilterButtons>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ModalIntegration;