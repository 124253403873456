import styled from "styled-components";

export const Widget = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  min-height: 151px;

  &:hover {
    cursor: pointer;
    filter: brightness(80%);
  }
`;
