import styled from 'styled-components';

export const Submit = styled.button`
  color: #fff;
  background-color: #000000;
  border: 1px solid #fff;
  font-size: 11px;
  font-weight: 700;
  margin-left: 8px;
  padding: 0px 8px;
  border-radius: 4px;
`;
