import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api';
import { toast } from 'react-toastify';
import {
  UlAgencys
} from '~/styles/ul';

import HasNoAgency from './components/HasNoAgency';
import CreateAgency from './components/CreateAgency';
import HasAgency from './components/HasAgency';

function Agencys() {
  const axios = ApiAxios();
  const [agencys, setAgencys] = useState([]);
  const [page, setPage] = useState('list');
  const [editing, setEditing] = useState(false);

  const { agencySetting } = useSelector(state => state.agency);

  useEffect(() => {
    async function loadAgencys () {
      try {
        if (agencySetting !== null) {
          setAgencys([agencySetting]);
        } else {
          const res = await axios.get(
            `${process.env.REACT_APP_ENDPOINT_AUTH}/agency`
          );

          setAgencys(res.data);
        }

        return true;
      } catch (error) {
        return toast.info(
          error.data
        );
      }
    }

    loadAgencys();
  }, [axios, agencySetting]);


  return (
    <UlAgencys type='none'>
      {page === 'list' && agencys.length === 0 && (
        <HasNoAgency setPage={setPage} />
      )}

      {page === 'create' && (
        <CreateAgency setPage={setPage} editing={editing} />
      )}

      {page === 'list' && agencys.length > 0 && (
        <HasAgency agencys={agencys} setPage={setPage} setEditing={setEditing} />
      )}
    </UlAgencys>
  );
}

export default Agencys;

