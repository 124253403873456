import React from "react";
import { useSelector } from "react-redux";
import SidebarNavItem from "./NavItem";
import { useLocation } from "react-router-dom";
import { ShowRefundMenu } from "~/functions/ShowMenus";

import {
  NavUlItems
} from './styles';

export default function SidebarNav({
  pageSettings
}) {
  const menusWeb = useSelector(state => state.user.profile.profile.profileMenu);
  const user = useSelector(state => state.user);

  let location = useLocation();

  return (
    <NavUlItems minify={pageSettings.pageSidebarMinify}>
      {menusWeb.map(({ menu }, i) => {
        var hasCompany = user.companies.some(x => x.codCliente === '01FABRICAF');

        if (hasCompany) {
          if (menu.path === '/refund-management' && !ShowRefundMenu(user.profile.email)) {
            return <></>;
          }

          return (
            <SidebarNavItem
              data={menu}
              location={location}
              pageSettings={pageSettings}
              keyItem={i}
              key={i}
            />
          );
        }

        return (
          <SidebarNavItem
            data={menu}
            location={location}
            pageSettings={pageSettings}
            keyItem={i}
            key={i}
          />
        );
      })}
    </NavUlItems>
  );
}
