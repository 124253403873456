import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  max-height: 300px;

  ul {
    list-style: none;
    margin: 0;
    padding: 5px;
  }

  ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      border: 0;
      background: transparent;
      outline-color: transparent;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      strong {
        margin-right: 5px;
      }
    }
  }

  ul li + li {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #eee;
  }

  ul li .fileInfo {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  ul li .fileInfo strong {
    font-weight: normal;
    font-size: 14px;
    margin-left: 10px;
    color: #333;
  }

  ul li span {
    color: #999;
    font-size: 13px;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 30px;
`;
