/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react';

import { Panel, PanelHeader } from '~/components/Panel';

import {
  CreateAction,
  CloseAction,
  CancelDeleteAction
} from '~/components/ActionsOfTables';

import {
  Container,
} from '~/styles/container';

import {
  SpaceBetweenDiv
} from '~/styles/divs';

import ListCompanies from './ListCompanies';
import CreateCompany from './CreateCompany';
import CompanyDetails from './CompanyDetails';

import CompanyManagerContext from './context';

function Companies({
  setCompanyManagerPage
}) {
  const [type, setType] = useState('list');
  const [companyToDetail, setCompanyToDetail] = useState(null);

  return (
    <CompanyManagerContext.Provider value={{
      setType,
      companyToDetail,
      setCompanyToDetail
    }}>
      <>
        {type === 'list' && (
          <ListCompanies
            setType={setType}
            setCompanyToDetail={setCompanyToDetail} />
        )}

        {type === 'create' && (
          <CreateCompany />
        )}

        {type === 'details' && (
          <CompanyDetails setType={setType} />
        )}
      </>
    </CompanyManagerContext.Provider>
  );
}

export default Companies;
