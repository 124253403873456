import React, { useState, useEffect, useContext } from 'react';
import { ApiAxios } from '~/services/api.js';
import InternationalTicketsRemission from '../../Widgets/InternationalTicketsRemission.js';
import WhiteCard from '../../WhiteCard'

import DashboardContext from '../../../context';

export default function InternationalTicketsRemission9 () {
  const chartId = 9;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);

  const {
    companiesSelected,
    accessToken,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
    costCentersSelected,
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [InternationalTicketsRemissionData, setInternationalTicketsRemissionData] = useState({
    jan: 0,
    fev: 0,
    mar: 0,
    abr: 0,
    mai: 0,
    jun: 0,
    jul: 0,
    ago: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dez: 0
  });

  useEffect(() => {
    async function loadData () {
      try {
        setLoading(true);

        setLikes(user.preferences.dashboard.chart9);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        let handles = companiesSelected.map(x => x.value);

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const mesI = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/tickets/ticketsRee/i`,
          {
            handle: handles,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: costCentersSelected.map(e => (e.value))
          },
          options
        );

        if(mesI.data){
          setInternationalTicketsRemissionData({
            jan: mesI.data[0].jan,
            fev: mesI.data[0].fev,
            mar: mesI.data[0].mar,
            abr: mesI.data[0].abr,
            mai: mesI.data[0].mai,
            jun: mesI.data[0].jun,
            jul: mesI.data[0].jul,
            ago: mesI.data[0].ago,
            sep: mesI.data[0].sep,
            oct: mesI.data[0].oct,
            nov: mesI.data[0].nov,
            dez: mesI.data[0].dez
          })
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected]);

  return (
    <WhiteCard
      title={'Reemissão de bilhetes - Internacional'}
      modalTitle='Observação'
      modalDesc='Valor gasto com remarcações de passagens internacionais.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <InternationalTicketsRemission data={InternationalTicketsRemissionData}/>
    </WhiteCard>
  );
}
