import React, { useState, useContext, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';

import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { Panel } from '~/components/Panel';
import Select from '~/components/Select';
import { Row, Col, Spinner, FormGroup, Label, Input, FormText} from 'reactstrap';

import {
  Card,
  CardBody,
} from '~/styles/card';

import {
  ButtonCard
} from '~/styles/buttons/card';

import {
  SpaceBetweenDiv
} from '~/styles/divs';

import {
  CreateAction,
  CloseAction,
  CancelDeleteAction
} from '~/components/ActionsOfTables';

import EmptyList from '~/components/EmptyList';

import CompaniesContext from '../context';

import {
  CreateCompanyForm,
  PanelSearchCompanies,
  PanelFilterCompanyGroup,
  PanelSearchResult,
  PanelSearchResultNonOverFlow,
  SpanLi,
  SpanLiGrupo,
  LiSearch,
  DownloadModels,
  CompanyDetailsTitle,
  OrderCCItens
} from './styles';

import {
  EditAction
} from '~/components/ActionsOfTables';

import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function CreateCompany() {
  const axios = ApiAxios();
  const formRef = useRef(null);
  const accessToken = useSelector(state => state.auth.accessToken);
  const [loading, setLoading] = useState(false);
  const { setType = () => {} } = useContext(CompaniesContext);

  const [activeFilter, setActiveFilter] = useState('companyName');
  const [groupSearchLoading, setGroupSearchLoading] = useState(false);
  const [companySearchLoading, setCompanySearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [obtOptions, setObtOptions] = useState([]);
  const [obtSelected, setObtSelected] = useState(0);

  const [listCompanies, setListCompanies] = useState([]);
  const [listVinculadas, setListVinculadas] = useState([]);
  const [hasOnlyOneGroup, setHasOnlyOneGroup] = useState(false);

  const [ccImportType, setCcImportType] = useState('');
  const [ccFile, setCcFile] = useState();
  const [orderFile, setOrderFile] = useState();

  useEffect(() => {
    async function getObtOptions () {
      try {
        const response = await axios.options(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/obt`
        );

        setObtOptions(response.data);
      } catch (error) {
        return toast.info(
          "Não foi possível obter a lista de OBT's."
        );
      }
    }

    getObtOptions();
  }, [accessToken]);

  const handleInsertCcFile = (e) => {
    return setCcFile(e.target.files[0]);
  };

  async function handleSubmit() {
    setLoading(true);

    if (ccImportType === '') {
      setLoading(false);

      return toast.info(
        "Selecione o OBT da Empresa."
      );
    }

    try {
      axios.defaults.headers.Authorization = `Bearer ${accessToken}`;

      if (listVinculadas.length === 1)
      {
        const companyBody = {
          CompanyCode: listVinculadas[0].codEmpresa,
          Company: listVinculadas[0].empresa,
          FantasyName: listVinculadas[0].nomeFantasia,
          CompanyGroup: listVinculadas[0].grupoEmpresarial,
          CpfCnpj: listVinculadas[0].cpfCnpj,
          City: listVinculadas[0].cidade,
          State: listVinculadas[0].estado,
          Handle: listVinculadas[0].handleEmpresa,
          ObtId: obtSelected
        };

        const companyResponse = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/registration`,
          companyBody
        );

        // if (ccImportType === 'default') {
        //   const filter = {
        //     CompanyCode: listVinculadas[0].codEmpresa
        //   };

        //   const response = await axios.post(
        //     `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/create-client/cost-default`,
        //     filter
        //   );
        // }

        // if (ccImportType === 'excel') {
        //   const data = new FormData();

        //   data.append('cc', ccFile);

        //   data.append('order', orderFile);

        //   data.append('clientCode', listVinculadas[0].codEmpresa);

        //   const response = await axios.post(
        //     `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/create-client/cost/order`,
        //     data
        //   );
        // }

        const filter = {
          CompanyCode: listVinculadas[0].codEmpresa
        };

        await axios.post(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/create-client/cost-obt`,
          filter
        );

        toast.success(
          'Empresa salva com sucesso!'
        );

        setLoading(false);

        return setType('list');
      }

      return setLoading(false);
    } catch (error) {
      if (error && error.response.status === 400) {
        toast.info(error.response.data);
      }

      return setLoading(false);
    }
  }

  async function handleSearch(companyName) {
    setCcImportType('');

    if (!companyName.match(/[a-z]/gi) && companyName.match(/\s/g))
      return setSearchValue('');

    await setSearchValue(companyName);

    if (companyName.length < 4)
      return setListCompanies([]);

    try {
      if (activeFilter === 'groupName')
        setGroupSearchLoading(true);
      else
        setCompanySearchLoading(true);

      const options = {
        params: {
          [activeFilter]: companyName
        },
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/company/get/tocreate`,
        options
      );

      const { payload } = response.data;

      setGroupSearchLoading(false);

      setCompanySearchLoading(false);

      if (payload?.length > 1) {
        let tempGroupName = payload[0].grupoEmpresarial;
        let tempHasOnlyGroup = true;

        payload.map(company => {
          if (tempGroupName !== company.grupoEmpresarial) {
            tempHasOnlyGroup = false;
          }
        });

        setHasOnlyOneGroup(tempHasOnlyGroup);
      }

      return await setListCompanies(payload);
    } catch (error) {
      setGroupSearchLoading(false);

      setCompanySearchLoading(false);

      return toast.error('Não foi possível realizar a busca.');
    }
  }

  function targetCompany(company) {
    setCcImportType('');

    const newListCompanies = [];

    if (listVinculadas.length > 0)
      return toast.info(
        'Uma empresa já está selecionada.'
      );

    listCompanies.map(list => {
      if (list.handleEmpresa !== company.handleEmpresa) {
        newListCompanies.push(list);
      }
      return false;
    });

    if (listVinculadas.length > 0) {
      const res = listVinculadas.filter(
        lv => lv.handleEmpresa === company.handleEmpresa
      );

      if (res.length <= 0) {
        setListCompanies(newListCompanies);

        return setListVinculadas(listVinculadas.concat([company]));
      }

      return toast.info(
        `A Empresa: ${company.empresa}, já está vinculada ao usuário`
      );
    }

    setListCompanies(newListCompanies);

    return setListVinculadas(listVinculadas.concat([company]));
  }

  function untargetCompany(company) {
    setCcImportType('');

    const newListVinculadas = [];

    listVinculadas.map(list => {
      if (list.handleEmpresa !== company.handleEmpresa) {
        newListVinculadas.push(list);
      }
      return false;
    });

    setListVinculadas(newListVinculadas);

    if (listCompanies.length > 0) {
      const res = listCompanies.filter(
        lc => lc.handleEmpresa === company.handleEmpresa
      );

      if (res.length <= 0)
        return setListCompanies(listCompanies.concat([company]));

      return false;
    }

    return setListCompanies(listCompanies.concat([company]));
  }

  return (
    <CreateCompanyForm>
      <Row style={{
        justifyContent: 'center'
      }}>
        <Col sm={12} md={12} lg={8} xl={6}>
          <SpaceBetweenDiv>
            <h4>
              Nova Empresa
            </h4>

            <CancelDeleteAction
              type='button'
              onClick={() => setType('list')}>
              Cancelar
            </CancelDeleteAction>
          </SpaceBetweenDiv>

          <br />
          {listVinculadas.length === 0 && (
            <Panel>
              <>
                <PanelFilterCompanyGroup>
                  <b>Pesquise uma Empresa</b>

                  <button
                    type="button"
                    className={
                      activeFilter === 'groupName' ? 'btn btn-primary' : 'btn'
                    }
                    onClick={() => {
                      setActiveFilter('groupName');

                      return setListCompanies([]);
                    }}>
                    {/* {groupSearchLoading && (
                      <Spinner color="#fff" size="sm" />
                    )} */}

                    {/* {!groupSearchLoading && <>Grupo</>} */}

                    {'Grupo'}
                  </button>

                  <button
                    type="button"
                    className={
                      activeFilter === 'companyName'
                        ? 'btn btn-primary'
                        : 'btn'
                    }
                    onClick={() => {
                      setActiveFilter('companyName');

                      return setListCompanies([]);
                    }}>
                    {/* {companySearchLoading && (
                      <Spinner color="#fff" size="sm" />
                    )} */}

                    {/* {!companySearchLoading && <>Empresa</>} */}

                    {'Empresa'}
                  </button>
                </PanelFilterCompanyGroup>

                <PanelSearchCompanies>
                  <input
                    name="search"
                    value={searchValue}
                    placeholder={
                      activeFilter === 'companyName'
                        ? "Pesquise por uma Empresa "
                        : "Pesquise em um Grupo Empresarial"
                    }
                    onChange={e => {
                      setListCompanies([]);

                      // if (groupSearchLoading || companySearchLoading)
                      //   return true;

                      return handleSearch(e.target.value)
                    }}
                  />
                </PanelSearchCompanies>
              </>

              <PanelSearchResult>
                <ul type="none">
                  <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '4px'
                  }}>
                    <b>Resultado da Busca</b>
                  </div>
                  {!groupSearchLoading && !companySearchLoading && listCompanies.length === 0 && (<EmptyList />)}

                  {(groupSearchLoading || companySearchLoading) && (
                    <div style={{textAlign: 'center', minHeight: '200px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Spinner color="#fff" size="lg" />
                    </div>
                  )}

                  {listCompanies.map((row, i) => (
                    <LiSearch key={i}>
                      <SpanLi>{row.empresa}</SpanLi>

                      {row.grupoEmpresarial
                        ? <SpanLiGrupo> Grupo: {row.grupoEmpresarial} </SpanLiGrupo>
                        : <></>
                      }

                      <button
                        className="btn"
                        type="button"
                        onClick={() => {
                          if (listVinculadas.length > 0) {
                            return false;
                          }

                          return targetCompany(row);
                        }}>
                        <i className="fa fa-plus" aria-hidden="true" />
                      </button>
                    </LiSearch>
                  ))}
                </ul>
              </PanelSearchResult>
            </Panel>
          )}

          <Panel>
            {listVinculadas.map(e => {
              return (
                <PanelSearchResultNonOverFlow>
                  <CompanyDetailsTitle>
                    <h3>Detalhes da Empresa</h3>

                    <button
                      className="btn"
                      type="button"
                      onClick={() => untargetCompany(e)}>
                      <i class="fas fa-times" aria-hidden="true"></i>
                    </button>
                  </CompanyDetailsTitle>

                  <p><b>Nome da Empresa:</b> {e.empresa}</p>
                  <p><b>Nome Fantasia:</b> {e.nomeFantasia}</p>
                  <p><b>Grupo Empresarial:</b> {e.grupoEmpresarial}</p>
                  <p><b>Código BKO:</b> {e.codEmpresa}</p>
                  <p><b>CNPJ:</b> {e.cpfCnpj}</p>
                  <p><b>Cidade:</b> {e.cidade}/{e.estado}</p>

                <div>
                      <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        enctype="multipart/form-data">
                        <Select
                          name='obt'
                          label='OBT'
                          options={obtOptions}
                          onChange={(e) => {
                            setCcImportType('obt');

                            return setObtSelected(e.value);
                          }}
                          placeholder='Selecione um OBT...' />
                      </Form>

                      {/* <ButtonCard
                        bg={'#7e2dc2'}
                        fg={'#eee'}
                        type='button'
                        onClick={() => setCcImportType('default')}>
                        VENDAS ANTERIORES
                      </ButtonCard>

                      <FormText color="muted">
                        Baixe uma Planilha Modelo e preencha com os Centros de Custos da Empresa.
                      </FormText>

                      <ButtonCard
                        bg={'#0f9e3e;'}
                        fg={'#eee'}
                        type='button'
                        onClick={() => setCcImportType('excel')}>
                        PLANILHA MODELO
                      </ButtonCard>

                      <FormText color="muted">
                        Se os Centros de Custo estão em um OBT que a Empresa usa.
                      </FormText>

                      <ButtonCard
                        bg={'#13f;'}
                        fg={'#eee'}
                        type='button'
                        onClick={() => setCcImportType('obt')}>
                        OBT
                      </ButtonCard> */}
                      </div>


                  {/* {ccImportType === 'default' && (
                    <OrderCCItens>
                      <CompanyDetailsTitle>
                        <h5>Vendas Anteriores</h5>

                        <button
                          className="btn"
                          type="button"
                          onClick={() => setCcImportType('')}>
                          <i class="fas fa-times" aria-hidden="true"></i>
                        </button>
                      </CompanyDetailsTitle>

                      <p>Os Centros de Custo da empresa </p>
                      <p><b>{e.empresa}</b></p>
                      <p>serão importados de compras já efetuas anteriormente pela empresa.</p>
                    </OrderCCItens>
                  )}

                  {ccImportType === 'excel' && (
                    <OrderCCItens>
                      <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        enctype="multipart/form-data">
                        <CompanyDetailsTitle>
                          <h5>Planilha Modelo</h5>

                          <button
                            className="btn"
                            type="button"
                            onClick={() => setCcImportType('')}>
                            <i class="fas fa-times" aria-hidden="true"></i>
                          </button>
                        </CompanyDetailsTitle>

                        <p>
                          Faça o upload da planilha contendo todos os Centros de Custo da <b>{`${e.nomeFantasia}`}</b>.
                        </p>

                        <p>
                          Baixe o modelo e preencha os com os dados.
                        </p>

                        <OrderCCItens>
                          <FormGroup>
                            <DownloadModels>
                              <ExcelFile
                                element={
                                  <EditAction
                                    type='button'
                                    onClick={() => {}}>
                                    {`Baixar Modelo .xlsx`}
                                  </EditAction>
                                }
                                filename={`Modelo-CentrosDeCustos`}>
                                <ExcelSheet data={[]} name="Centros De Custo">
                                  <ExcelColumn label="Código" value="codcc"/>
                                  <ExcelColumn label="Centro de Custo" value="nomecc"/>
                                  <ExcelColumn label="Descrição" value="desccc"/>
                                </ExcelSheet>
                              </ExcelFile>
                            </DownloadModels>

                            <Label for="centrosCustoFile"><b>Centros de Custo</b></Label>

                            <Input
                              type="file"
                              name="centrosCustoFile"
                              id="centrosCustoFile"
                              accept=".xlsx"
                              onChange={handleInsertCcFile}
                              />
                          </FormGroup>
                        </OrderCCItens>
                      </Form>
                    </OrderCCItens>
                  )}

                  {ccImportType === 'obt' && (
                    <OrderCCItens>
                      <CompanyDetailsTitle>
                        <h5>OBT</h5>

                        <button
                          className="btn"
                          type="button"
                          onClick={() => setCcImportType('')}>
                          <i class="fas fa-times" aria-hidden="true"></i>
                        </button>
                      </CompanyDetailsTitle>

                      <p>Os Centros de Custo da empresa </p>
                      <p><b>{e.empresa}</b></p>
                      <p>serão importados de um OBT.</p>
                      <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        enctype="multipart/form-data">
                        <Select
                          name='obt'
                          label='OBT'
                          options={obtOptions}
                          onChange={(e) => setObtSelected(e.value)}
                          placeholder='Selecione um OBT...' />
                      </Form>
                    </OrderCCItens>
                  )} */}

                  <div style={{ width: '100%', textAlign: 'right' }}>
                    <button
                      class='btn btn-primary'
                      onClick={() => handleSubmit()}
                      disabled={loading || obtSelected === 0}>
                      {loading && <Spinner color="#fff" size="sm" />}

                      {!loading && <>Salvar</>}
                    </button>
                  </div>
                </PanelSearchResultNonOverFlow>
              );
            })}
          </Panel>
        </Col>
      </Row>
    </CreateCompanyForm>
  );
}

export default CreateCompany;
