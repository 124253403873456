import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ReactTable from '~/components/Table';
import { ApiAxios } from '~/services/api.js';
import { Panel } from '~/components/Panel';

import Header from './Header';
import { Container } from './styles';
import { columnsOrders } from './constants';

export default function TableOrders({ original, accessToken }) {
  const axios = ApiAxios();
  const [ordered, setOrdered] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadingOrders() {
      try {
        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INVOICE}/invoice/ordered`,
          { FaturaHandle: original.handle },
          options
        );

        setLoading(false);

        return setOrdered(response.data);
      } catch (error) {
        toast.error('Não foi possível carregar os pedidos da fatura');

        return setLoading(false);
      }
    }

    loadingOrders();

    return () => setOrdered([]);
  }, []); // eslint-disable-line

  return (
    <Panel theme="inverse">
      <Header data={ordered} original={original}/>

      <ReactTable
        data={ordered}
        columns={columnsOrders}
        tableProps={{
          defaultPageSize: 10,
          hasPagination: true,
          loading: loading,
          resizable: true
        }}
        tableStyle={{
          fontSize: '13px',
          cellPadding: '6px 4px'
        }}
        tableLabels={{
          loadingText: 'Carregando...',
          noDataText: 'Sem registros',
          nextButtonText: 'Próximo',
          previousButtonText: 'Anterior',
          pageOfText: 'de',
          pageSizeSelectText: 'registros',
          pageText: 'Página'
        }}
      />
    </Panel>
  );
}
