import styled from 'styled-components';
import { Col } from 'reactstrap';
import { PanelBody } from '~/components/Panel';

export const Container = styled.div``;

export const Header = styled.div`
  width: 100%;
  min-height: 20vh;
  box-sizing: border-box;
  offset: outline;

  display: flex;
  justify-content: space-between;
  align-items: center;

  #user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      margin: 8px;
    }
  }

  #indicator {
    text-align: center;
    span {
      font-size: 26px;
      color: #111;
    }
  }

  img {
    border: 2px solid #eaeaea;
    margin: 16px;
    border-radius: 50%;
    max-width: 240px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
`;

export const NavMenu = styled.div`
  width: 100%;
  box-sizing: border-box;
  offset: outline;
  text-align: left;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin: 0;
    padding: 0;
  }
  div {
    button {
      margin-left: 8px;
      background: #fff;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  padding: 16px;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fefefe;

  label {
    font-size: 13px;
  }

  input {
    padding: 4px;
    font-size: 13px;
    border-radius: 4px;
  }
  button {
    float: right;
  }
`;

export const SpanAlert = styled.span`
  font-size: 12px;
  color: #700;
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;
`;

export const Pane = styled(PanelBody)`
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
  padding: 16px 16px;
  box-sizing: border-box;
  min-height: 50vh;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`;

export const LeftCol = styled(Col)`
  position: relative;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
  background: #ebebeb;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

export const RightCol = styled(Col)`
  position: relative;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  /* background: #ebebeb; */
  /* border-radius: 8px; */
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
`;

export const UserSection = styled.div`
  padding: 8px;
  box-sizing: border-box;
  text-align: center;

  img {
    border-radius: 8px;
    max-width: 70%;
    min-width: 50%;
    max-width: 200px;
    margin-bottom: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  #nameEmail {
    margin-bottom: 16px;
  }

  #nickname {
    border-top: 1px solid #aaa;
    padding-top: 8px;
    margin-bottom: 18px;
    text-align: left;
  }
`;

export const ProfileSection = styled.div`
  padding: 8px;
  box-sizing: border-box;
  background: #fbfbfb;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

export const LabelItemJustified = styled.div`
  display: flex;
  justify-content: space-between;
`;
