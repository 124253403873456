import styled from 'styled-components';

export const ButtonCard = styled.button`
  width: 100%;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${props => props.bg};
  color: ${props => props.fg};
  font-size: 13px;
  font-weight: bold;
  box-shadow: 0px 0px 8px #999;
  border: none;
  margin-bottom: 4px;
`;

export const ButtonCardSmall = styled.button`
  width: 30%;
  background-color:white;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${props => props.bg};
  color: ${props => props.fg};
  font-size: 13px;
  font-weight: bold;
  box-shadow: 0px 0px 8px #999;
  border: none;
  margin-bottom: 4px;
  padding: 4px 8px;
`;

export const ButtonCardColumn = styled.button`
  width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${props => props.bg};
  color: ${props => props.fg};
  font-size: 13px;
  font-weight: bold;
  /* box-shadow: 0px 0px 10px #ccc; */
  border: none;
  margin-bottom: 4px;
  border: 1px solid #ccc;
  :hover {
    background: white;
  }

`;
