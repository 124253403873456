import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import VolumeFee from '../../Widgets/VolumeFee.js';
import WhiteCard from '../../WhiteCard';
import CompanyPermission from '~/functions/CompanyPermission';


import DashboardContext from '../../../context';

export default function VolumeFee5 () {
  const chartId = 5;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);
  const userProfile = useSelector(state => state.user.userProfile);

  const {
    accessToken,
    companiesOptions,
    setCompaniesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCentersOptions,
    setCostCentersOptions,
    costCentersSelected,
    setCostCentersSelected,
    user,
    dateState,
    setLoading,
    setLoading3,
    PinChart,
    UnpinChart,
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [volumeFeeData, setVolumeFeeData] = useState({
    months: [],
    variationAverage: 0,
    total: 0,
  });

  useEffect(() => {
    async function loadData () {
      try {
        setVolumeFeeData({
          months: [],
          variationAverage: 0,
          total: 0,
        });

        setLoading(true);
        setLoading3(true);

        setLikes(user.preferences.dashboard.chart5);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        const Companies = companiesSelected.length === 0
        ? companiesOptions.map(x => (x.value))
        : companiesSelected.map(x => (x.value));

        const CostCenters = !CompanyPermission(userProfile.userProfileId).all
          ? (
            costCentersSelected.length === 0
              ? costCentersOptions.map(e => (e.value))
              : costCentersSelected.map(e => (e.value))
          )
          : costCentersSelected.map(e => (e.value));

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const res = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/gastosMes`,
          {
            handle: Companies,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: CostCenters
          },
          options
        );

        if(res.data){
          setVolumeFeeData({
            months: res.data.months,
            variationAverage: res.data.variationAverage,
            total: res.data.total,
          })
        }

        setLoading(false);
        setLoading3(false);
      } catch (error) {
        setLoading3(false);
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected]);

  return (
    <WhiteCard title={'Gasto total com viagens'}
      modalTitle='Observação'
      modalDesc='Valor total gasto com viagens dentro do período informado.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <VolumeFee data={volumeFeeData}/>
    </WhiteCard>
  );
}
