import React, { useState } from 'react';

import Dashboard from './components/Dashboard';
import CompanyManager from './components/CompanyManager';
import AgencyManager from './components/AgencyManager';
import InternalRegistration from './components/InternalRegistration';

import {
  Spinner
} from 'reactstrap';

import {
  SpinnerContainer
} from './styles';

import MyAgencyContext from './context';

import {
  AgencyStyle
} from '~/styles/agency';

function UserType() {
  const [dash, setDash] = useState(1);
  const [loading, setLoading] = useState(false);

  function handleChageDash (dashValue) {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);

      return setDash(dashValue);
    }, 400);
  }

  return (
    <MyAgencyContext.Provider value={{
      dash,
      handleChageDash,
      loading,
      setLoading
    }}>
      <h1 className='page-header mb-0'>
        Minha Agência
      </h1>

      <Dashboard />

      {loading && (
        <SpinnerContainer>
          <Spinner color="#fff" size="lg" />
        </SpinnerContainer>
      )}

      <AgencyStyle>
        {!loading && dash === 1 && (
          <CompanyManager />
        )}

        {!loading && dash === 2 && (
          <AgencyManager />
        )}

        {!loading && dash === 3 && (
          <InternalRegistration />
        )}
      </AgencyStyle>
    </MyAgencyContext.Provider>
  );
}

export default UserType;
