import React from 'react';
import TopMenuNav from './Nav';
import PageSettings from '~/config/PageSettings';

function TopMenu() {
  return (
    <PageSettings.Consumer>
      {({ pageMobileTopMenu }) => (
        <div
          id="top-menu"
          className={`top-menu ${pageMobileTopMenu && 'd-block '}`}
        >
          <TopMenuNav />
        </div>
      )}
    </PageSettings.Consumer>
  );
}

export default TopMenu;
