import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

import InvoiceContext from "./context";
import Filters from "./components/Filters";
import Indicators from "./components/Indicators";
import Table from "./components/Table";
import ModalInvoiceReport from "./components/ModalInvoiceReport";

import { DefaultDateInvoice } from "./constants";
import ShowSelects from "~/functions/ShowSelects";

import {
  getInvoiceIndicators,
  getInvoiceTable,
} from '~/services/invoice';

import {
  getDefaultProducts
} from '~/services/filters';

import {
  getAccountExecutives,
  getAccountExecutivesCompanies
} from '~/services/agency';

import {
  getCompanyCostCenters
} from '~/services/auth';

import { getPeriod } from '~/functions/Date';

export default function Invoice() {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const user = useSelector((state) => state.user.profile);
  const userProfile = useSelector((state) => state.user.userProfile);
  const companies = useSelector((state) => state.user.companies);
  const costCenters = useSelector((state) => state.user.costCenters);

  const [indicators, setIndicators] = useState({
    open: {
      status: '',
      quantidade: 0,
      valorFatura: 0,
    },
    late: {
      status: '',
      quantidade: 0,
      valorFatura: 0,
    },
    toPay: {
      status: '',
      quantidade: 0,
      valorFatura: 0,
    },
  });

  const [pageFilters, setPageFilters] = useState({
    dateState: DefaultDateInvoice,
    companies: companies.map(x => ({
      label: x.nomeFantasia,
      value: x.codCliente,
    })),
    costs: costCenters.map(x => ({
      label: x.costCenter,
      value: x.costCenter,
    })),
    status: '',
    product: '',
    invoiceNumber: 0,
    reference: 0,
    traveler: '',
    os: '',
  });

  const [pageOptions, setPageOptions] = useState({
    products: [],
    executives: [],
  });

  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [costCentersOptions, setCostCentersOptions] = useState([]);
  const [costCentersSelected, setCostCentersSelected] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [projectsSelected, setProjectsSelected] = useState([]);
  const [executivesOptions, setExecutivesOptions] = useState([]);
  const [executivesSelected, setExecutivesSelected] = useState([]);

  const formRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateState, setDateState] = useState(DefaultDateInvoice);
  const [pax, setPax] = useState("");
  const [products, setProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [invoiceToReport, setInvoiceToReport] = useState(null);

  const [filters, setFilters] = useState({
    companyHandle: companies.map((x) => x.codCliente),
  });

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    async function loadIndicators () {
      const {
        dateI,
        dateF
      } = getPeriod(dateState);

      const res = await getInvoiceIndicators(
        accessToken,
        {
          CompanyHandle: pageFilters.companies.map(e => e.value),
          CentrosCusto: pageFilters.costs.map(e => e.value),
          VencimentoInicial: dateI,
          VencimentoFinal: dateF,
          FaturaHandle: pageFilters.invoiceNumber,
          Passageiro: pageFilters.traveler,
          Produto: pageFilters.product,
          Sell: pageFilters.reference,
          Status: pageFilters.status,
          NumOS: pageFilters.os,
        },
        setLoading,
      );

      const toPays = res.data.filter(x => x.status === 'APagar');
      const opens = res.data.filter(x => x.status === 'Aberto');
      const lates = res.data.filter(x => x.status === 'Vencida');

      if (res !== null) {
        return setIndicators({
          toPay: toPays.length > 0
            ? toPays[0]
            : {status: 'APagar',quantidade: 0, valorFatura: 0},
          open: opens.length > 0
            ? opens[0]
            : {status: 'Aberto',quantidade: 0, valorFatura: 0},
          late: lates.length > 0
            ? lates[0]
            : {status: 'Vencida',quantidade: 0, valorFatura: 0},
        })
      }

      // return toast.info(
      //   'Não foi possível carregar os indicadores das suas últimas faturas.'
      // );
    }

    loadIndicators();
  }, [pageFilters]);

  useEffect(() => {
    async function loadTable () {
      const {
        dateI,
        dateF
      } = getPeriod(dateState);

      const res = await getInvoiceTable(
        accessToken,
        {
          CompanyHandle: pageFilters.companies.map(e => e.value),
          CentrosCusto: pageFilters.costs.map(e => e.value),
          VencimentoInicial: dateI,
          VencimentoFinal: dateF,
          FaturaHandle: pageFilters.invoiceNumber,
          Passageiro: pageFilters.traveler,
          Produto: pageFilters.product,
          Sell: pageFilters.reference,
          Status: pageFilters.status,
          NumOS: pageFilters.os,
        },
        setLoading,
      );

      if (res !== null) {
        return setData(res.data);
      }

      // return toast.info(
      //   'Não foi possível carregar a tabela com suas últimas faturas.'
      // );
    }

    loadTable();
  }, [pageFilters]);

  useEffect(() => {
    async function loadProducts() {
      const res = await getDefaultProducts(
        accessToken,
        setLoading,
      );

      if (res !== null) {
        return setPageOptions({
          ...pageOptions,
          products: res.data
        });
      }

      // return toast.info(
      //   'Não foi possível carregar as opções de produtos.'
      // );
    }

    loadProducts();
  }, []);

  useEffect(() => {
    async function loadAccountExecutives() {
      if (!ShowSelects(userProfile.userProfileId).executives)
        return false;

      const res = await getAccountExecutives(
        accessToken,
        setLoading,
      );

      if (res !== null)
        return setExecutivesOptions(res.data);

      // return toast.info(
      //   'Não foi possível carregar as opções de produtos.'
      // );
    }

    loadAccountExecutives();
  }, []);

  useEffect(() => {
    function applyCompanyOptions() {
      setCompaniesOptions(
        companies.map((e) => {
          return {
            label: e.nomeFantasia,
            value: e.codCliente,
          };
        })
      );
    }

    applyCompanyOptions();
  }, []);

  useEffect(() => {
    function applyCostCentersOptions() {
      setCostCentersOptions(
        costCenters.map((e) => {
          return {
            label: e.costCenter,
            value: e.costCenter,
          };
        })
      );
    }

    applyCostCentersOptions();
  }, []);

  useEffect(() => {
    async function loadExecutivesCompanies() {
      if (executivesSelected.length === 1) {
        const res = await getAccountExecutivesCompanies(
          accessToken,
          () => {},
          executivesSelected[0].value
        );

        if (res === null) {
          setExecutivesSelected([]);

          return toast.info('Não foi possível obter a carteira de clientes.');
        }

        if (res.data.length > 0)
          return setCompaniesOptions(res.data);

        setExecutivesSelected([]);

        return toast.info(
          `${executivesSelected[0].label} - carteira de clientes vazia.`
        );
      }

      return setCompaniesOptions(companies.map(x => ({
        label: x.nomeFantasia,
        value: x.codCliente,
      })))
    }

    loadExecutivesCompanies();
  }, [executivesSelected]);

  useEffect(() => {
    async function loadCompanyCostCenters() {
      if (companiesSelected.length === 1 && ShowSelects(userProfile.userProfileId).costCenters) {
        const res = await getCompanyCostCenters(
          accessToken,
          () => {},
          companiesSelected[0].value
        );

        if (res === null) {
          return toast.info(
            `Não foi possível obter os centros de custo de clientes.`
          );
        }

        if (res.data.length > 0)
          return setCostCentersOptions(res.data.map(e => {
            return {
              label: e.name,
              value: e.name
            };
          }));

        toast.info(
          `${companiesSelected[0].label} - sem centros de custo.`
        );
      }

      return setCostCentersOptions(costCenters.map(x => ({
        label: x.costCenter,
        value: x.costCenter,
      })));
    }

    loadCompanyCostCenters();
  }, [companiesSelected]);

  function salvarProto(row) {
    setInvoiceToReport(row);

    setShowModal(true);

    return true;
  }

  return (
    <InvoiceContext.Provider
      value={{
        accessToken,
        user,
        companies,
        costCenters,
        companiesOptions,
        setCompaniesOptions,
        companiesSelected,
        setCompaniesSelected,
        costCentersOptions,
        setCostCentersOptions,
        costCentersSelected,
        setCostCentersSelected,
        projectOptions,
        setProjectOptions,
        projectsSelected,
        setProjectsSelected,
        executivesOptions,
        setExecutivesOptions,
        executivesSelected,
        setExecutivesSelected,
        formRef,
        data,
        setData,
        loading,
        setLoading,
        dateState,
        setDateState,
        pax,
        setPax,
        products,
        setProducts,
        filters,
        setFilters,
        pageNumber,
        setPageNumber,
        salvarProto,
      }}>
      {showModal && invoiceToReport !== null && (
        <ModalInvoiceReport
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          invoiceToReport={invoiceToReport}
        />
      )}

      <h1 className="page-header mb-3">Faturas e Boletos</h1>

      <Indicators
        data={indicators}
        pageFilters={pageFilters}
        setPageFilters={setPageFilters}
        loading={loading} />

      <Filters
        pageFilters={pageFilters}
        setPageFilters={setPageFilters}
        pageOptions={pageOptions} />

      <Table />
    </InvoiceContext.Provider>
  );
}
