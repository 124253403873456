import React from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { MdArrowDownward } from 'react-icons/md';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import { FormatValueBRL } from '~/functions/FormatValue';

import { DeleteAction } from '~/components/ActionsOfTables';

const Button = styled.button`
  border: 0;
  background: transparent;
  outline-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const DownloadButton = styled.button`
  border: 0;
  border-radius: 2px;
  background: rgb(0, 120, 255);
  outline-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #ffff;
  font-size: 12px;

  padding: 5px;

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        background: ${lighten(0.1, 'rgb(0, 120, 255)')};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

const GenerateBadgeColor = value => {
  const values = {
    Liquidada: 'primary',
    Vencida: 'danger',
    Aberta: 'success',
  };

  return values[value];
};

export const columns = ({
  handleSelectInvoice,
  handleDownload,
  reportError,
  setIsOpenModalInvoice,
  setIsOpenModalBillet,
  setIsOpenModalNF,
  setIsOpenModalDocs,
  invoice,
  handleSelectRow,
  handleSelectAllRows,
  selectAllInvoices,
  handleDownloadRow,
  downloadingAllFiles,
  salvarProto = () => {}
}) => [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        <i className={`
          fas fa-caret-${row.isExpanded ? 'up' : 'down'}
        `} />
      </span>
    ),
    width: 40
  },
  {
    Header: () => (
      <div className="d-flex justify-content-center">
        {invoice.length > 0 && (
          <input
            type="checkbox"
            checked={selectAllInvoices}
            onChange={handleSelectAllRows}
          />
        )}
      </div>
    ),
    accessor: 'checked',
    canSort: false,
    Cell: ({row}) => {
      return (
        <div className="d-flex justify-content-center">
          <input
            type="checkbox"
            checked={row.original.checked}
            onChange={() => handleSelectRow(row.original.handle)}
          />
        </div>
      );
    },
    width: 50,
    Footer: () => (
      <div className="d-flex justify-content-center">
        <DownloadButton
          type="button"
          disabled={
            invoice.length <= 0
            || invoice.filter(x => x.checked).length <= 0
            || (
              invoice.filter(x => x.wasDownloaded).length <= 0
              && invoice.filter(x => x.wasDownloadedBoleto).length <= 0
            )
          }
          onClick={handleDownloadRow}
        >
          {!downloadingAllFiles ? (
            <MdArrowDownward color="#ffff" size={12} />
          ) : (
            <Spinner size="sm" color="#ffff" />
          )}
        </DownloadButton>
      </div>
    ),
  },
  {
    accessor: 'status',
    Header: 'Status',
    width: 75,
    Cell: ({row}) => (
      <div className="d-flex justify-content-center">
        <span className={`badge badge-${GenerateBadgeColor(row.original.status)}`}>
          {row.original.status}
        </span>
      </div>
    ),
  },
  {
    accessor: 'handle',
    Header: 'N° Fatura',
    width: 83,
    className: 'd-flex justify-content-center',
  },
  {
    accessor: 'nf',
    Header: 'Nota Fiscal',
    width: 83,
    Cell: ({row}) => (
      <span>
        {row.original.nf > 0 ? row.original.nf.toString() : ""}
      </span>
    ),
    className: 'd-flex justify-content-center',
  },
  {
    accessor: 'dataVencimentoFormatada',
    Header: 'Vencimento',
    width: 108,
    className: 'd-flex justify-content-center',
  },
  {
    accessor: 'arquivoFatura',
    Header: 'Fatura',
    width: 76,
    filterable: false,
    Cell: ({row}) => (
      <div className="d-flex justify-content-center">
        <Button
          type="button"
          disabled={!row.original.wasDownloaded}
          onClick={() => handleDownload(row.original.handle, 0, `Faturas`)}
        >
          <i
            className="fas fa-file-pdf"
            style={{ color: !row.original.wasDownloaded ? '#c3c3c3' : '#0078ff' }}
          />
        </Button>
      </div>
    ),
    Footer: () => (
      <div className="d-flex justify-content-center">
        <DownloadButton
          type="button"
          onClick={setIsOpenModalInvoice}
          disabled={
            invoice.length <= 0 || invoice.filter(x => x.wasDownloaded).length <= 0
          }
        >
          <MdArrowDownward color="#ffff" size={12} />
        </DownloadButton>
      </div>
    ),
  },
  {
    accessor: 'arquivoBoleto',
    Header: 'Boleto',
    filterable: false,
    width: 72,
    Cell: ({row}) => (
      <div className="d-flex justify-content-center">
        <Button
          type="button"
          className={`${!row.value && 'disable'}`}
          disabled={!row.original.wasDownloadedBoleto}
          onClick={() => {
            handleDownload(row.original.handle, 0, `Boletos`)
          }
            }
        >
          <i
            className="fas fa-file-pdf"
            style={{ color: !row.original.wasDownloadedBoleto ? '#c3c3c3' : '#0078ff' }}
          />
        </Button>
      </div>
    ),
    Footer: row => (
      <div className="d-flex justify-content-center">
        <DownloadButton
          type="button"
          onClick={setIsOpenModalBillet}
          disabled={
            invoice.length <= 0 || invoice.filter(x => x.wasDownloadedBoleto).length <= 0
          }>
          <MdArrowDownward color="#ffff" size={12} />
        </DownloadButton>
      </div>
    ),
  },
  {
    accessor: 'arquivoNF',
    Header: 'NF',
    width: 76,
    filterable: false,
    Cell: ({row}) => (
      <div className="d-flex justify-content-center">
        <Button
          type="button"
          disabled={!row.original.wasDownloadedNF}
          onClick={() => handleDownload(row.original.handle, row.original.nf, `NFs`)}
        >
          <i
            className="fas fa-file-pdf"
            style={{ color: !row.original.wasDownloadedNF ? '#c3c3c3' : '#0078ff' }}
          />
        </Button>
      </div>
    ),
    Footer: () => (
      <div className="d-flex justify-content-center">
        <DownloadButton
          type="button"
          onClick={setIsOpenModalNF}
          disabled={
            invoice.length <= 0 || invoice.filter(x => x.wasDownloadedNF).length <= 0
          }
        >
          <MdArrowDownward color="#ffff" size={12} />
        </DownloadButton>
      </div>
    ),
  },
  {
    accessor: 'valorFatura',
    Header: 'Valor Fatura',
    width: 111,
    Cell: ({row}) => `${FormatValueBRL(row.values.valorFatura)}`,
  },
  {
    accessor: 'valorPago',
    Header: 'Valor Pago',
    width: 98,
    Cell: ({row}) => `${FormatValueBRL(row.values.valorPago)}`,
  },
  {
    accessor: 'actions',
    Header: '',
    width: 138,
    className: 'd-flex justify-content-end',
      Cell: ({row}) => {
        return (
          <div>
            <DeleteAction
              type="button"
              onClick={() => salvarProto(row)} >
              Contestar fatura
            </DeleteAction>
          </div>
        );
      }
  },
];

export const DefaultDate = {
  currentWeek:
    `${moment().subtract(30, 'days').format('DD/MM/YYYY')} -
    ${moment().format('DD/MM/YYYY')}`,
  selectedDate: {
    startDate: `${moment().subtract(30, 'days').format('DD/MM/YYYY')}`,
    endDate: `${moment().format('DD/MM/YYYY')}`,
  },
  prevWeek:
    `${moment().subtract(15, 'days').format('DD/MM/YYYY')} -
    ${moment().subtract(8, 'days').format('DD/MM/YYYY')}`,
  startDate: moment().subtract(30, 'days'),
  endDate: moment(),
};

export const DefaultDateInvoice = {
  currentWeek:
    `${moment().subtract(1, 'month').format('DD/MM/YYYY')} -
    ${moment().add(2, 'months' ).format('DD/MM/YYYY')}`,
  selectedDate: {
    startDate: `${moment().subtract(1, 'month').format('DD/MM/YYYY')}`,
    endDate: `${moment().add(2, 'months' ).format('DD/MM/YYYY')}`,
  },
  prevWeek:
    `${moment().subtract(15, 'days').format('DD/MM/YYYY')} -
    ${moment().subtract(8, 'days').format('DD/MM/YYYY')}`,
  startDate: moment().subtract(1, 'month'),
  endDate: moment().add(2, 'months' ),
};
