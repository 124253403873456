import styled from "styled-components";
import { Link } from "react-router-dom";

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  /* @media (min-width: 1366px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
  } */
`;

export const IframeHolder = styled.div`
  height: 150px;
  border-radius: 8px;

  overflow: hidden;
  padding-bottom: 8px;
`;

export const ListItem = styled.li`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  transition: 0.5s all;
  box-shadow: 0px 38px 56px rgba(154, 154, 167, 0.07),
    0px 11.4559px 16.8824px rgba(154, 154, 167, 0.0456112),
    0px 4.75819px 7.01207px rgba(154, 154, 167, 0.035),
    0px 1.72094px 2.53613px rgba(154, 154, 167, 0.0243888);

  &:hover {
    background: #fafafa;
  }
`;

export const Name = styled.strong`
  display: block;
  font-size: 18px;
`;

export const Infos = styled.div`
  position: relative;
  padding: 8px;
  margin-top: 10px;
  background: #eee;
  border-radius: 8px;

  div {
    margin: 0 0 4px;
  }
`;
export const About = styled.span`
  display: block;
`;
export const Date = styled.span`
  display: block;
`;

export const InfosUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MapButton = styled(Link)`
  background: #eee;
  font-weight: 700;
  padding: 8px;
  border-radius: 10px;
  text-decoration: none;
  color: #333;

  &:hover {
    text-decoration: none;
    color: #333;
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box !important;
  padding: 8px 0 !important;
  margin: 0 0 60px 0 !important;
  width: 100%;

  button {
    font-size: 11px;
    border: none;
    background: #eee;
    font-weight: 600;
    padding: 8px;
    border-radius: 10px;
    color: #333;
    background: rgba(0,0,0,.05);

    &:hover:not(:disabled) {
      transition: 1s;
      background: rgba(0,0,0,.1);
    }

    &:disabled {
      color: #777;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 11px;
      color: #777;
      font-weight: 600;
    }

    span:last-child {
      font-size: 11px;
      color: #999;
      font-weight: 500;
    }
  }
`;

export const NoContent = styled.div`
  width: 100%;
  height: 300px;
  text-align: center;
  margin: 0 0 60px 0 !important;
`;
