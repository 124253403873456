const language = [
  // {
  //   key: 'en-US',
  //   title: 'us',
  //   text: 'Inglês',
  //   className: 'flag-icon flag-icon-us m-r-5',
  // },
  {
    key: 'pt-BR',
    title: 'br',
    text: 'Português',
    className: 'flag-icon flag-icon-br m-r-5',
  },
];

export default language;
