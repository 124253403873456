import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

export const SpanLink = styled(Link)`
  text-decoration: none;
  border: none;

  &&:hover {
    text-decoration: none;
  }
`;

export const DropItem = styled(DropdownItem)`
  color: #eee;
  text-decoration: none;
  background-color: rgba(0,0,0,0);
  font-size: 13px;
  &&:active {
    color: #eee;
    background-color: rgba(10,30,50,1);
    border: 0px solid rgba(0,0,0,0.2);
  }
`;
export const DropMenu = styled(DropdownMenu)`
  background: #0E1A24;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`;
