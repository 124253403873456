import moment from 'moment';

function formatDate (date) {
  const year = date.slice(0, 4);

  const month = date.slice(5, 7);

  const day = date.slice(8, 10);

  return `${day}/${month}/${year}`;
};

const getDate = (day, month, year) => {
  return moment().day(day).month(month).year(year);
};

const getPeriod = (dateState) => {
  const {
    selectedDate: { startDate, endDate },
  } = dateState;

  const dateI = Number(startDate.split('/').reverse().join(''));

  const dateF = Number(endDate.split('/').reverse().join(''));

  return { dateI, dateF };
};

const getPeriodFormated = (dateState) => {
  const {
    selectedDate: { startDate, endDate }
  } = dateState;

  const dateI = startDate.split('/').reverse().join('-');

  const dateF = endDate.split('/').reverse().join('-');

  return { dateI, dateF };
};

export {
  formatDate,
  getDate,
  getPeriod,
  getPeriodFormated,
};
