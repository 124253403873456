import React from "react";
import { splitPaxName } from '~/functions/String';
import { formatDate } from '~/functions/Date';

export const columns = () => [
  {
    Header: "Status",
    accessor: "status",
    Cell: ({row}) => {
      return (
        <span>
          {splitPaxName(row.original.status)}
        </span>
      );
    },
  },
  {
    Header: "Viajante",
    accessor: "paxName",
    Cell: ({row}) => {
      return (
        <span>
          {splitPaxName(row.original.paxName)}
        </span>
      );
    },
  },
  {
    Header: "Empresa",
    accessor: "client",
  },
  {
    Header: "Setor",
    accessor: "section",
    Cell: ({row}) => {
      return (
        <span>
          {row.original.section ? row.original.section : "Sem setor"}
        </span>
      );
    },
  },
  {
    Header: "Partida",
    accessor: "from",
    Cell: ({row}) => {
      return (
        <span>{`${
          row.original.origin.name
        } (${row.original.origin.airportCode.trim()})`}</span>
      );
    },
  },
  {
    Header: "Data da Partida",
    accessor: "originDate",
    Cell: ({row}) => {
      return (
        <span>
          {formatDate(row.original.origin.date)}
        </span>
      );
    },
  },
  {
    Header: "Destino",
    accessor: "to",
    Cell: ({row}) => {
      return (
        <span>
          {` ${row.original.destiny.name} (${row.original.destiny.airportCode.trim()}) `}
        </span>
      );
    },
  },
  {
    Header: "Data da Chegada",
    accessor: "arrivalDate",
    Cell: ({row}) => {
      return (
        <span>
          {formatDate(row.original.destiny.date)}
        </span>
      );
    },
  },
];
