import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import FeePerProduct from '../../Widgets/FeePerProduct.js';
import WhiteCard from '../../WhiteCard';
import ShowSelects from '~/functions/ShowSelects';
import CompanyPermission from '~/functions/CompanyPermission';


import DashboardContext from '../../../context';

export default function FeePerProduct2 () {
  const chartId = 2;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);
  const userProfile = useSelector(state => state.user.userProfile);

  const {
    accessToken,
    companiesOptions,
    setCompaniesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCentersOptions,
    setCostCentersOptions,
    costCentersSelected,
    setCostCentersSelected,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [feePerProductData, setFeePerProductData] = useState({
    aereo: 0,
    aereoPercent: 0,
    hotel: 0,
    hotelPercent: 0,
    seguro: 0,
    seguroPercent: 0,
    outros: 0,
    outrosPercent: 0,
    visto: 0,
    vistoPercent: 0
  });

  useEffect(() => {
    async function loadData () {
      try {
        setFeePerProductData({
          aereo: 0,
          aereoPercent: 0,
          hotel: 0,
          hotelPercent: 0,
          //seguro: 0,
          //seguroPercent: 0,
          carro: 0,
          carroPercent: 0,
          outros: 0,
          outrosPercent: 0,
          //visto: 0,
          //vistoPercent: 0
          rodoviario: 0,
          rodoviarioPercent: 0
        });

        setLoading(true);

        setLikes(user.preferences.dashboard.chart2);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        const Companies = companiesSelected.length === 0
        ? companiesOptions.map(x => (x.value))
        : companiesSelected.map(x => (x.value));

        const CostCenters = !CompanyPermission(userProfile.userProfileId).all
          ? (
            costCentersSelected.length === 0
              ? costCentersOptions.map(e => (e.value))
              : costCentersSelected.map(e => (e.value))
          )
          : costCentersSelected.map(e => (e.value));

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const tipo = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/gastosTipo`,
          {
            handle: Companies,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: CostCenters
          },
          options
        );

        if(tipo.data){
          setFeePerProductData({
            aereo: tipo.data[0].valorAereo,
            hotel: tipo.data[0].valorHotel,
            carro: tipo.data[0].valorCarro,
            outros: tipo.data[0].valorOutros,
            rodoviario: tipo.data[0].valorRodoviario,
            aereoPercent: tipo.data[0].porcentAereo,
            hotelPercent: tipo.data[0].porcentHotel,
            rodoviarioPercent: tipo.data[0].porcentRodoviario,
            outrosPercent: tipo.data[0].porcentOutros,
            carroPercent: tipo.data[0].porcentCarro
          })
        }


        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected]);

  return (
    <WhiteCard title={'Gasto por produto'}
      modalTitle='Observação'
      modalDesc='Gastos separados por cada tipo de produto adquirido.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <FeePerProduct data={feePerProductData}/>
    </WhiteCard>
  );
}
