import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Spinner } from 'reactstrap';
import { Form } from '@unform/web';
import {
  FormatPhoneOnlyNumbersBR,
  FormatPhoneBR
} from '~/functions/FormatPhone';
import { ApiAxios } from '~/services/api.js';

import {
  EditAction,
  CancelDeleteAction
} from '~/components/ActionsOfTables';

import { Panel } from '~/components/Panel';
import Input from '~/components/Input';
import Select from '~/components/Select';
import SliderToggle from '~/components/SliderToggle';

import {
  SubmitButton,
  PanelFilterCompanyGroup,
  PanelSearchResult,
  SpanPasswordAlert,
  SpanLi,
  LiSearch,
} from './styles';

function Delete({
  setPage,
  userToDelete,
  setUserToDelete,
  typeOfList,
}) {
  const axios = ApiAxios();
  const formRef = useRef(null);
  const accessToken = useSelector(state => state.auth.accessToken);
  const [loading, setLoading] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true);

      const response = await axios.delete(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/agency-user/${userToDelete.email}`
      );

      setPage('list');

      setUserToDelete(null);

      return setLoading(false);
    } catch (error) {
      return setLoading(false);
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <h5>
        {`Detalhes`}
      </h5>

      <p>
        <b>Nome:</b> {userToDelete.name}
      </p>

      <p>
        <b>Email:</b> {userToDelete.email}
      </p>

      <p>
        <b>Telefone:</b> {userToDelete.contacts.length > 0 ? `${userToDelete.contacts[0].phone}` : ''}
      </p>

      <p>
        <b>Status:</b> <b>{userToDelete.status ? 'Ativado' : 'Desativado'}</b>
      </p>

      <button
        className="btn btn-danger"
        type="submit"
        style={{
          background: '#',
          color: '#fff',
          fontWeight: 700,
          marginLeft: 10
        }}>
        {loading && <Spinner color="#fff" size="sm" />}

        {!loading && <>Remover</>}
      </button>
    </Form>
  );
}

export default Delete;
