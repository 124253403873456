import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Filters from './components/Filters';
import Table from "./components/Table";
import Modal from "./components/Modal";
import ShowSelects from '~/functions/ShowSelects';
import { ApiAxios } from '~/services/api';
import {
  pageFiltersInitialState,
  DefaultDate,
} from './constants.js';
import { RefundProvider } from 'context';
import {
  getPeriodFormated,
} from 'functions/Date';
import { toast } from 'react-toastify';

export default function Refund() {
  const axios = ApiAxios();
  const accessToken = useSelector(state => state.auth.accessToken);
  const user = useSelector(state => state.user.profile);
  const userProfileId = useSelector(state => state.user.userProfile.userProfileId);
  const companies = useSelector(state => state.user.companies);
  const costCenters = useSelector(state => state.user.costCenters);
  const formRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageFilters, setPageFilters] = useState(pageFiltersInitialState({
    date: DefaultDate,
    companies,
    costs: costCenters,
  }));
  const [itemToSave, setItemToSave] = useState(null);

  async function handleSumbit () {
    if (pageFilters.companies.length > 1 && pageFilters.companiesSelected == 0) {
      return toast.info("Selecione ao menos uma empresa.");
    }

    try {
      setLoading(true);

      const {
        dateI,
        dateF,
      } = getPeriodFormated(pageFilters.date);

      const filters = {
        startEmission: dateI,
        endEmission: dateF,
        companies: pageFilters.companiesSelected.length > 0
          ? pageFilters.companiesSelected.map(e => e.value)
          : pageFilters.companies.map(e => e.value),
        costs: [],
        locator: pageFilters.locator,
        ticket: pageFilters.ticket,
        request: pageFilters.request,
        pax: pageFilters.pax,
        status: pageFilters.status,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/refund/getall`,
        filters,
      );

      if (res.data !== null) {
        setData(res.data);
      }

      return setLoading(false);
    } catch ({ response }) {
      if (response) {
        toast.info(response.data);
      } else {
        toast.error("Não foi possivel realizar a requisição!");
      }

      return setLoading(false);
    }
  }

  async function openModal (row) {
    setItemToSave(row);
  }

  async function closeModal () {
    setItemToSave(null);

    handleSumbit();
  }

  function resetPageFilters () {
    return setPageFilters(pageFiltersInitialState({
      date: DefaultDate,
      companies,
      costs: costCenters,
    }));
  }

  return (
    <RefundProvider value={{
      companies,
      accessToken,
      user,
      handleSumbit,
      data,
      setData,
      formRef,
      loading,
      setLoading,
      pageFilters,
      setPageFilters,
      resetPageFilters,
      openModal,
      closeModal,
      itemToSave,
      setItemToSave,
    }}>
      <>
        <h1 className="page-header mb-3">Gestão de Reembolso</h1>

        <Filters />

        <Table pageFilters={pageFilters} />

        {itemToSave !== null && (
          <Modal
            itemToSave={itemToSave}
            isAdmin={ShowSelects(userProfileId).isAgency} />
        )}
      </>
    </RefundProvider>
  );
}
