import styled from 'styled-components';

export const SolicitationButton = styled.button`
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  background-color: #222;
  color: #fff;
`;

export const RequestedButton = styled.button`
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  background-color: #777;
  color: #fff;
`;

export const PendingButton = styled.button`
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  background-color: orange;
  color: #fff;
`;

export const RefundedButton = styled.button`
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  background-color: #bfb;
  color: #333;
`;

export const RejectedButton = styled.button`
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  background-color: #fbb;
  color: #333;
`;

export const CancelededButton = styled.button`
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  background-color: none;
  color: #333;
`;
