import React, { useEffect, useState } from 'react';

import {
  CreateAction,
  CancelDeleteAction,
  CloseAction
} from '~/components/ActionsOfTables';

import {
  Container
} from '~/styles/container';

import {
  SpaceBetweenDiv
} from '~/styles/divs';

import List from './List';
import Create from './Create';
import Delete from './Delete';
import Details from './Details';
import Edit from './Edit';

function CostCenters({
  company,
  setCompanyPage
}) {
  const [page, setPage] = useState('list');
  const [toDelete, setToDelete] = useState(null);
  const [toDetails, setToDetails] = useState(null);
  const [toEdit, setToEdit] = useState(null);

  const handleDelete = () => setPage('delete');
  const handleDetails = () => setPage('details');
  const handleEdit = () => setPage('edit');

  useEffect(() => {
    if (toDelete !== null) handleDelete();
  }, [toDelete]);

  useEffect(() => {
    if (toDetails !== null) handleDetails();
  }, [toDetails]);

  useEffect(() => {
    if (toEdit !== null) handleEdit();
  }, [toEdit]);

  return (
    <>
      <SpaceBetweenDiv>
        <span>{' '}</span>

        <div>
          {page === 'list' &&
            <CloseAction
              type='button'
              onClick={() => setCompanyPage('dashboard')}>
              <i className='fas fa-times' />
            </CloseAction>
          }
        </div>
      </SpaceBetweenDiv>

      {page === 'list' && (
        <List
          setToDelete={setToDelete}
          setToDetails={setToDetails}
          setToEdit={setToEdit} />
      )}
    </>
  );
}

export default CostCenters;
