import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';

import { Bar } from 'react-chartjs-2';

export default function LowestFare({
  data
}) {
  const axios = ApiAxios();
  const accessToken = useSelector(state => state.auth.accessToken);
  const companies = useSelector(state => state.user.companies);
  const [chartData6, setChartData6] = useState({});

  useEffect(() => {
    async function loadData() {
      setChartData6({
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        datasets: [{
          data: [data.jan, data.fev, data.mar, data.abr, data.mai, data.jun, data.jul, data.ago, data.set, data.out, data.nov, data.dez],
          backgroundColor: ['#00EAE1', '#BDBFBF', '#95F496', '#f4dbab', '#7e2ec2', '#FBBA37', '#DB575B', '#A6D59A', '#D54A26', '#333333', '#9e5ef2', '#2fa9a0'],
          borderWidth: 4,
          label: 'Compras Internacionais',
        }]
      })
    }

    loadData();
  }, [data])

  return (
    <>
      <div style={{ width: '50%', margin: '2% auto', }}></div>
      <Bar data={chartData6} options={{
        legend: {
          display: false,
        },
        responsive: true,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return ` ${data.labels[tooltipItem.index]}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}%`;
            }
          }
        }
      }} />
      {/* <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div className='description'>
          <p style={styles.titleLegend}>Economia Realizada</p>
          <p className='textBoldGreen'>355.075,03</p>
        </div>

        <div className='description'>
          <p style={styles.titleLegend}>Oportunidade de Economia</p>
          <p className='textBoldRed'>575.399,40</p>
        </div>

        <div className='description'>
          <p style={styles.titleLegend}>Meta</p>
          <p className='textBold'>80%</p>
        </div>

        <div className='description'>

          <p style={styles.redLegend}>Abaixo da meta</p>
          <p className='textBackGroundRed'>-0,517164</p>
        </div>
        </div> */}
    </>
  );
}
const styles = {
  titleLegend: {
    marginTop: "10px",
    fontSize: "11px",
    fontWeight: 700
  },
  redLegend: {
    marginTop: "10px",
    fontSize: "11px",
    fontWeight: 700,
    color: 'red'
  }
}
