import React from 'react';
import { useSelector } from 'react-redux';

import { Row, Col } from 'reactstrap';
import { Form } from '@unform/web';

import Input from '~/components/Input';
import TextArea from '~/components/TextArea';

import { FormatPhoneBR } from '~/functions/FormatPhone';
// import { Container } from './styles';

function AgencyData() {
  const agency = useSelector(state => state.agency);

  return (
    <>
      <h4>
        Dados da Agência
      </h4>

      <Row>
        <Col sm={12} md={6} lg={4} xl={3}>
          <Input
            disabled={true}
            name={'agencyDisplayName'}
            label={'Título'}
            value={
              (agency.agencySetting.displayName ?? '')
              + ' '
              + (agency.agencySetting.suffix ?? '')
            } />
        </Col>

        {agency.agencySetting.fullName !== '' && (
          <Col sm={12} md={6} lg={4} xl={3}>
            <Input
              disabled={true}
              name={'agencySurname'}
              label={'Nome Completo da Agência'}
              value={
                agency.agencySetting.fullName ?? ''
              } />
          </Col>
        )}

        <Col sm={12} md={6} lg={4} xl={3}>
          <Input
            disabled={true}
            name={'agencyEmail'}
            label={'Email Principal'}
            value={
              agency.agencySetting.email1 ?? ''
            } />
        </Col>

        <Col sm={12} md={6} lg={4} xl={3}>
          <Input
            disabled={true}
            name={'agencyPhone'}
            label={'Telefone Principal'}
            value={
              FormatPhoneBR(agency.agencySetting.phone1 ?? '')
            } />
        </Col>

        <Col sm={12} md={6} lg={4} xl={3}>
          <Input
            disabled={true}
            name={'emailSupport'}
            label={'Email de Suporte'}
            value={'suporte@weetech.io'} />
        </Col>
      </Row>
    </>
  );
}

export default AgencyData;
