import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import { toast } from 'react-toastify';

import DashboardHeader from './components/DashboardHeader';
import MyPanel from './components/DashboardBody/MyPanel';
import Outlays from './components/DashboardBody/Outlays';
import Policy from './components/DashboardBody/Policy';
import Tickets from './components/DashboardBody/Tickets';

import UserActions from '~/store/modules/ducks/user';
import DashboardContext from './context';
import { DefaultDate } from './constants';
import { Row, Container } from './styles';

export default function Dashboard () {
  const axios = ApiAxios();
  const dispatch = useDispatch();

  const accessToken = useSelector(state => state.auth.accessToken);
  const user = useSelector(state => state.user.profile);
  const userProfile = useSelector(state => state.user.userProfile);
  const companies = useSelector(state => state.user.companies);
  const costCenters = useSelector(state => state.user.costCenters);

  const [companiesOptions, setCompaniesOptions] = useState(
    companies.map(e => {
      return {
        label: e.nomeFantasia,
        value: e.codCliente
      };
    })
  );
  const [costCentersOptions, setCostCentersOptions] = useState(
    costCenters.map(e => {
      return {
        label: e.costCenter,
        value: e.costCenter
      }
    })
  );
  const [costCentersSelected, setCostCentersSelected] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);

  const [dateState, setDateState] = useState(DefaultDate);
  const [loading3, setLoading3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState(1);

  function handleBody (body) {
    setBody(body);
  }

  useEffect(() => {
    async function setPage(){
      if(user.myCharts.length <= 0){
        handleBody(2);
      }
    }
    setPage();
  }, [user]);

  const resetDateState = () => setDateState(DefaultDate);

  async function PinChart (id, setpinned) {
    try {
      const options = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      if (user.myCharts.indexOf(id) !== -1) {
        return toast.info(
          'O Indicador selecionado já está adicionado ao Meu Painel!'
        );
      }

      let charts = user.myCharts;

      charts.push(id);

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/${user.email}/preferences/mypanel/charts`,
        charts,
        options
      );

      if (response.data && response.data.statusCode === 200) {
        dispatch(UserActions.insertProfile(
          {
            ...user,
            myCharts: charts
          }
        ));
        setpinned(true);
      }

      toast.dismiss();

      setBody(1);

      return toast.success(
        'Indicador adicionado ao Meu Painel.'
      );
    } catch (error) {
      return toast.info(
        'Não foi possível adicionar esse indicador ao Meu Painel!'
      );
    }
  }

  async function UnpinChart (id, setpinned) {
    try {
      const options = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      if (user.myCharts.indexOf(id) === -1) {
        return toast.warning(
          'O Indicador selecionado não está adicionado ao Meu Painel!'
        );
      }

      let charts = user.myCharts.filter(c => c !== id);

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/${user.email}/preferences/mypanel/charts`,
        charts,
        options
      );

      if (response.data && response.data.statusCode === 200) {
        dispatch(UserActions.insertProfile(
          {
            ...user,
            myCharts: charts
          }
        ));
        setpinned(false);
      }

      toast.dismiss();

      return toast.info(
        'Indicador removido do Meu Painel.'
      );
    } catch (error) {
      return toast.info(
        'Não foi possível remover esse indicador do Meu Painel!'
      );
    }
  }

  return (
    <DashboardContext.Provider value={{
      accessToken,
      user,
      userProfile,
      setDateState,
      resetDateState,
      body,
      setBody,
      loading,
      setLoading,
      loading3,
      setLoading3,
      companiesOptions,
      setCompaniesOptions,
      companiesSelected,
      setCompaniesSelected,
      costCentersOptions,
      setCostCentersOptions,
      costCentersSelected,
      setCostCentersSelected,
      dateState,
      PinChart,
      UnpinChart
    }}>
      <Container>
        <DashboardHeader />

        <Row>
          { body === 1 && (
            <MyPanel />
          )}

          { body === 2 && (
            <Outlays />
          )}

          { body === 3 && (
            <Policy />
          )}

          { body === 4 && (
            <Tickets />
          )}
        </Row>
      </Container>
    </DashboardContext.Provider>
  );
}
