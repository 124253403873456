import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Panel, PanelHeader } from '~/components/Panel';
import { Row, Col } from 'reactstrap';
import {
  CreateAction,
  CloseAction,
  CancelDeleteAction
} from '~/components/ActionsOfTables';

import {
  Container,
  ContainerCentered,
  ContainerPt8Pb4
} from '~/styles/container';

import {
  SpaceBetweenDiv
} from '~/styles/divs';

import {
  ButtonCard,
  ButtonCardSmall
} from '~/styles/buttons/card';
function SideBar({
  page,
  setPage,
  typeOfList,
  setTypeOfList,
}) {
  const user = useSelector(state => state.user.profile);
  const userProfile = useSelector(state => state.user.userProfile);

  return (
    <>
      {page === 'list' && (
        <ContainerPt8Pb4>
          <h5>Tipos de Usuários</h5>

          <ButtonCardSmall
            bg={typeOfList === 1 ? '#D54A26' : '#ddd'}
            fg={typeOfList === 1 ? '#fff' : '#333'}
            type='button'
            style={{width: '100%'}}
            onClick={() => {
              setPage('list');

              return setTypeOfList(1);
            }}>
            Administrador
          </ButtonCardSmall>

          <ButtonCardSmall
            bg={typeOfList === 8 ? '#D54A26' : '#ddd'}
            fg={typeOfList === 8 ? '#fff' : '#333'}
            type='button'
            style={{width: '100%'}}
            onClick={() => {
              setPage('list');

              return setTypeOfList(8);
            }}>
            Gerente Corporativo
          </ButtonCardSmall>

          <ButtonCardSmall
            bg={typeOfList === 9 ? '#D54A26' : '#ddd'}
            fg={typeOfList === 9 ? '#fff' : '#333'}
            type='button'
            style={{width: '100%'}}
            onClick={() => {
              setPage('list');

              return setTypeOfList(9);
            }}>
            Executivo de Contas
          </ButtonCardSmall>

          {/* <ButtonCardSmall
            bg={typeOfList === 2 ? '#D54A26' : '#ddd'}
            fg={typeOfList === 2 ? '#fff' : '#333'}
            type='button'
            style={{width: '100%'}}
            onClick={() => {
              setPage('list');

              return setTypeOfList(2);
            }}>
            Suporte OBT
          </ButtonCardSmall>

          <ButtonCardSmall
            bg={typeOfList === 3 ? '#D54A26' : '#ddd'}
            fg={typeOfList === 3 ? '#fff' : '#333'}
            type='button'
            style={{width: '100%'}}
            onClick={() => {
              setPage('list');

              return setTypeOfList(3);
            }}>
            Agente Financeiro
          </ButtonCardSmall> */}

          {userProfile.userProfileId === 4 && (
            <ButtonCardSmall
              bg={typeOfList === 4 ? '#D54A26' : '#ddd'}
              fg={typeOfList === 4 ? '#fff' : '#333'}
              type='button'
              style={{width: '100%'}}
              onClick={() => {
                setPage('list');

                return setTypeOfList(4);
              }}>
                Desenvolvedor
            </ButtonCardSmall>
          )}

          <ButtonCardSmall
            bg={typeOfList === 10 ? '#D54A26' : '#ddd'}
            fg={typeOfList === 10 ? '#fff' : '#333'}
            type='button'
            style={{width: '100%'}}
            onClick={() => {
              setPage('list');

              return setTypeOfList(10);
            }}>
            Emissor
          </ButtonCardSmall>

          <hr />

          <ButtonCardSmall
            bg={typeOfList === 11 ? '#fff' : '#000000'}
            fg={typeOfList === 11 ? '#000000' : '#fff'}
            type='button'
            style={{width: '100%'}}
            onClick={() => {
              setPage('userProfile');

              return setTypeOfList(11);
            }}>
            Gerenciar Tipos de Usuário
          </ButtonCardSmall>
        </ContainerPt8Pb4>
      )}

      {page === 'newuser' && (
        <ContainerCentered>
          <h5>Novo Usuário da Agência</h5>

          <button
            className='btn btn-danger'
            type='button'
            onClick={() => setPage('list')}>
            Cancelar
          </button>
        </ContainerCentered>
      )}

      {page === 'edit' && (
        <ContainerCentered>
          <h5>Editar Usuário</h5>

          <button
            className='btn btn-danger'
            type='button'
            onClick={() => setPage('list')}>
            Cancelar
          </button>
        </ContainerCentered>
      )}

      {page === 'delete' && (
        <ContainerCentered>
          <h5>Remover Usuário</h5>

          <button
            className='btn btn-danger'
            type='button'
            onClick={() => setPage('list')}>
            Cancelar
          </button>
        </ContainerCentered>
      )}

      {page === 'userProfile' && (
        <ContainerCentered>
          <button
            className='btn btn-secondary'
            type='button'
            onClick={() => {
              setPage('list');

              return setTypeOfList(1);
            }}>
            Voltar para Usuários Internos
          </button>
        </ContainerCentered>
      )}
    </>
  );
}

export default SideBar;
