import styled from 'styled-components';

export const Container = styled.div`
  * {
    box-sizing: border-box;
  }

  [class ^= 'col'], .row {
    padding: 0;
    margin: 0;
  }

  [class ^= 'col'] {
    padding: 2px 8px;

    .row {
      [class ^= 'col'] {
        padding: 0;
      }
    }
  }

  p {
    margin-bottom: 4px;
  }

  ul {
    max-height: 200px;
    overflow: auto;
    padding: 0;
    margin: 0;

    li {
      padding: 0px 2px;
      margin: 0 0 3px 0;
      border: 1px solid #eee;
      background-color: #e9ecef;
      border-radius: 4px;
      font-weight: 500;

      i {
        width: 20px;
      }
    }
  }
`;
