import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Panel, PanelHeader } from "~/components/Panel";
import ReactTable from '~/components/Table';
import ReactExport from "react-export-excel";
import InvoiceReportContext from "../../context";
import { FormatValueBRL } from '~/functions/FormatValue';
import { columns } from './constants';
import { ColumnsTableMain, ColumnsTitleMain, ButtonCheckColumns } from './styles';

function Table() {
  const { data, loadingTable } = useContext(InvoiceReportContext);
  const [tableColumns, setTableColumns] = useState(columns);
  const [hiddenColumns, setHiddenColumns] = useState(true);
  const [todos, setTodos] = useState(true);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [columnsToExcel, setColumnsToExcel] = useState([]);
  const [dataToExcel, setDataToExcel] = useState([]);

  useEffect(() => {
    async function loadDataToExcel() {
      var newData = [];

      data.map(e => {
        newData.push({
          ...e,
          totalTarifa: (e.totalTarifa),
          taxas: (e.taxas),
          totalFee: (e.totalFee),
          mef: (e.mef),
          maf: (e.maf),
          total: (e.total),
        });
      });

      return setDataToExcel(newData);
    }

    loadDataToExcel();
  }, [data]);

  useEffect(() => {
    async function loadColumnsToExcel() {
      let newColumns = [];

       tableColumns.map(e => {
         if (e.show) {
           newColumns.push(e);
         }
       });

      return setColumnsToExcel(newColumns);
    }

    loadColumnsToExcel();
  }, [tableColumns]);

  function changeColumn2(e) {
    if (e === 'all') {
      const columnsMapped = tableColumns.map(c => {
        var column = c;

        column.show = !todos;

        return column;
      });

      setTodos(!todos);

      return setTableColumns(columnsMapped);
    }

    const columnsMapped = tableColumns.map(c => {
      var column = c;

      if (column.accessor === e) {
        column.show = !column.show;
      }

      return column;
    });

    setTodos(false);

    return setTableColumns(columnsMapped);
  }

  return (
    <Panel theme="inverse">
      <PanelHeader noButton>
        <ColumnsTitleMain>
          <div>
            <span>
              {!hiddenColumns ? 'Selecione as colunas desejadas:' : 'Detalhes da Pesquisa'}
            </span>
            {(!hiddenColumns) && (
              <>
                <input
                  type="checkbox"
                  defaultChecked={todos}
                  checked={todos}
                  value={'all'}
                  onChange={({ target }) => changeColumn2(target.value)}
                />
                <span>Todos</span>
              </>
            )}
          </div>

          <div>
            <ButtonCheckColumns
              type='button'
              onClick={() => {
                return setHiddenColumns(!hiddenColumns);
              }}>
              {hiddenColumns ? 'Escolher Colunas' : (<i className="fa fa-caret-up fa-1x"></i>)}
            </ButtonCheckColumns>

            <ExcelFile
              element={
                <ButtonCheckColumns
                  type="button"
                  onClick={() => { }}
                  disabled={data.length === 0 || tableColumns.length === 0}>
                  Exportar
                </ButtonCheckColumns>
              }
              filename={`Relatório Analítico de Emissão`}>
              <ExcelSheet data={dataToExcel} name="Emissão">
                {columnsToExcel.map((e, i) => (
                  <ExcelColumn label={e.Header} value={e.accessor} key={i} />
                ))}
              </ExcelSheet>
            </ExcelFile>
          </div>
        </ColumnsTitleMain>

        <ColumnsTableMain hidden={hiddenColumns}>
          <Row>{
            tableColumns.map((c, i) =>
            (<Col sm={3} md={3} lg={4} xl={3} key={i}>
              <input
                type="checkbox"
                defaultChecked={c.show}
                checked={c.show}
                value={c.accessor}
                onChange={({ target }) => changeColumn2(target.value)}
              />

              <span>{i === 0 ? 'Emissão' : c.Header}</span>
            </Col>)
            )}</Row>
        </ColumnsTableMain>
      </PanelHeader>

      <ReactTable
        columns={tableColumns.filter(e => e.show === true)}
        data={data}
        tableProps={{
          defaultPageSize: 10,
          hasFooter: false,
          hasPagination: true,
          loading: loadingTable,
          resizable: true
        }}
        tableStyle={{
          fontSize: '13px',
          cellPadding: '6px 4px'
        }}
        tableLabels={{
          loadingText: 'Carregando...',
          noDataText: 'Sem registros',
          nextButtonText: 'Próximo',
          previousButtonText: 'Anterior',
          pageOfText: 'de',
          pageSizeSelectText: 'registros',
          pageText: 'Página'
        }} />
    </Panel>
  );
}

export default Table;
