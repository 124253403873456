import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ApiAxios } from '~/services/api.js';

import {
  Card,
  CardTitle,
  CardBody,
  CardBodyItem,
} from '~/styles/card';

import {
  CancelDeleteAction,
  DeleteAction,
  EditAction
} from '~/components/ActionsOfTables';
import { Row, Col } from 'reactstrap';

import CompanyRegistrationContext from '../../../context';

function Details({
  managerToDetails
}) {
  const axios = ApiAxios();

  const [ccs, setCcs] = useState([]);

  useEffect(() => {
    async function getCcs() {
      try
      {
        const gccResponse = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/manager/getcostcenters/${managerToDetails.manager.email}`
        );

        return setCcs(gccResponse.data);
      } catch (error) {
        return toast.error('Não foi possível carregar Centros de Custo do Gestor.');
      }
    }

    getCcs();
  }, []);

  return <Row>
    <Col md={12} lg={6} xl={6}>
      <Card>
        <CardTitle>
          <h5>Gestor</h5>
        </CardTitle>

        <CardBody>
          <div>
            <p><b>Nome:</b> {managerToDetails.manager.name}</p>

            <p><b>Email:</b> {managerToDetails.manager.email}</p>

            <p><b>Tipo:</b>
              {managerToDetails.type === 1 && (` Gestor de Centros de Custo`)}
              {managerToDetails.type === 2 && (` Gestor de Viagens`)}
            </p>
          </div>
        </CardBody>
      </Card>
    </Col>

    <Col md={12} lg={6} xl={6}>
      <Card>
        <CardTitle>
          <h5>Centros de Custo</h5>
        </CardTitle>

        <CardBody>
          {ccs.map((cc, i) => {
            return (
              <CardBodyItem key={i}>
                <div>
                  <p><b>{cc.nameCC}</b>: {cc.descCC}</p>
                </div>
              </CardBodyItem>
            );
          })}
        </CardBody>
      </Card>
    </Col>
  </Row>;
}

export default Details;
