import React, { useState } from 'react';
import { Link, Route } from 'react-router-dom';

function TopMenuNavList({ data, expand, active }) {
  const [state, setState] = useState({ active: -1 });

  function handleExpand(e, i, match) {
    e.preventDefault();

    setState({
      ...state,
      active: state.active === i ? -1 : i,
    });
  }

  const icon = data.icon && <i className={data.icon} />;
  const img = data.img && (
    <div className="icon-img">
      <img src={data.img} alt="" />
    </div>
  );
  const caret = data.children && !data.badge && <b className="caret" />;
  const label = data.label && (
    <span className="label label-theme m-l-5">{data.label}</span>
  );
  const badge = data.badge && (
    <span className="badge pull-right">{data.badge}</span>
  );
  const title = data.title && (
    <span>
      {data.title} {label}
    </span>
  );

  return (
    <Route
      path={data.path}
      exact={data.exact}
      children={({ match }) => (
        <li className={(match && 'active ') + (data.children && 'has-sub ')}>
          {data.children ? (
            <Link to={data.path} onClick={expand}>
              {img} {icon} {title} {caret} {badge}
            </Link>
          ) : (
            <Link to={data.path}>
              {img} {icon} {title} {caret} {badge}
            </Link>
          )}
          {data.children && (
            <ul className={`sub-menu ${active && 'd-block'}`}>
              {data.children &&
                data.children.map((submenu, i) => (
                  <TopMenuNavList
                    data={submenu}
                    key={i}
                    expand={e => handleExpand(e, i, match)}
                    active={i === state.active}
                    clicked={state.clicked}
                  />
                ))}
            </ul>
          )}
        </li>
      )}
    />
  );
}

export default TopMenuNavList;
