/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import ListNotify from './ListNotify';
import CreateNotify from './CreateNotify';

function UserNotify() {
  const [page, setPage] = useState('list');

  return (
    <>
      {page === 'list' && (
        <ListNotify setPage={setPage} />
      )}

      {page === 'create' && (
        <CreateNotify setPage={setPage} />
      )}
    </>
  );
}


export default UserNotify;

