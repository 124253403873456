import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0px;
  margin: 0px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;;
  padding: 0;
  margin: 0;

  @media(max-width: 800px) {
    flex-direction: column;
  }
`;

export const RowDashNav = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    @media(max-width: 800px) {
    flex-direction: column;
    position: relative;
  }
`;


export const DashboardTitle = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 4px;
  }
`;

