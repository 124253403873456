import styled from 'styled-components';

export const HeaderTitle = styled.div`
  width: 100%;
`;

export const HeaderTitleTotal = styled.div`
  font-size: 13px;
  font-weight: 700;
  border-radius: 4px;
  padding: 2px 2px;
  background: #7e2dc2;
  border: 1px solid #fff;
  color: #fff;
`;

export const HeaderTitleFilters = styled.div`
  font-size: 13px;
  font-weight: 700;
  border-radius: 4px;
  padding: 2px 2px;
  background: #0f9e3e;
  border: 1px solid #fff;
  color: #fff;
`;

export const HeaderSpanSpace = styled.span`
  padding: 0 4px;
`;
