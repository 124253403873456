import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonCard = ({ height, width }) => {
  return(
    <SkeletonTheme color='#ccc' highlightColor="#fff">
      <Skeleton style={{marginBottom: 10}} duration={1} height={height ? height : 200} width={width ? width : 300} />
      <Skeleton duration={1} height={30} width={300} />
    </SkeletonTheme>

  )
}

export default SkeletonCard;
