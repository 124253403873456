import React from 'react';

function Footer() {
  const date = new Date();

  return (
    <div id="footer" className="footer text-center" style={{border: 'none'}}>
      <h6>
        <a href="https://saarquivos.blob.core.windows.net/lgpd/terms.pdf">Privacidade</a>

        &nbsp;&#124;&nbsp;&copy;&nbsp;Wee&nbsp;Tech&nbsp;&middot;&nbsp;Todos&nbsp;os&nbsp;direitos&nbsp;reservados&nbsp;&middot;&nbsp;{date.getFullYear()}
      </h6>
    </div>
  );
}

export default Footer;
