import React from 'react';
import styled from "styled-components";

export const CellLogo = styled.img`
  border-radius: 5px;
`;

export const columns = [
  // {
  //   default: true,
  //   Header: "Faturas",
  //   accessor: 'nInvoices',
  //   Cell: ({row}) => {
  //     if (row.nInvoices > 0) {
  //       return <>
  //         <i id="infoInvoice" className="fas fa-file-alt" />
  //         <Tooltip target="infoInvoice" text={"Quantidade de Faturas vinculadas à Bilhetes"}/>
  //       </>;
  //     }
  //   return <span>{' '}</span>
  //   },
  //   style: {
  //     textAlign: 'center',
  //     whiteSpace: 'pre-wrap',
  //     fontSize: '14px',
  //     fontWeight: '500'
  //   },
  //   width: 60,
  //   show: true,
  //   resizable: false
  // },
  {
    default: true,
    Header: 'Emissão',
    accessor: 'dataEmissao',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Empresa',
    accessor: 'cliente',
     width: 140,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Status',
    accessor: 'status',
     width: 100,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'OS',
    accessor: 'numOS',
     width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Venda',
    accessor: 'id',
    width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Fornecedor',
    accessor: 'fornecedor',
     width: 100,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  // {
  //   default: true,
  //   Header: 'Pedido',
  //   accessor: 'pedido',
  //   width: 100,
  //   show: true,
  //   style: {
  //     textAlign: 'center'
  //   },
  // },
  {
    default: true,
    Header: 'Centro de Custo',
    accessor: 'centro',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {

    default: true,
    Header: 'Viajante',
    accessor: 'passageiro',
     width: 120,
    show: true,
    Cell: ({ row }) => {
      let nome = row.original.passageiro.toUpperCase().split('/').join(' / ');

      return (<span>{nome}</span>);
    },
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap'
    },
  },
  {
    default: true,
    Header: 'Trechos',
    accessor: 'trecho',
     width: 100,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Solicitante',
    accessor: 'solicitante',
     width: 100,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Data Viagem',
    accessor: 'dataViagem',
     width: 80,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Motivo',
    accessor: 'motivo',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Nº Fatura',
    accessor: 'fatura',
     width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Aprovador',
    accessor: 'aprovador',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap'
    },
  },
  {
    default: true,
    Header: 'Localizador',
    accessor: 'localizador',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap'
    },
  },
  // {
  // default: false,
  //   Header: 'Finalidade',
  //   accessor: 'finalidade',
  //   width: 100,
  //   show: true,
  //   style: {
  //     textAlign: 'center',
  //     paddingLeft: '20px',
  //     whiteSpace: 'pre-wrap',
  //   },
  // },
  // {
  // default: false,
  //   Header: 'Divisao',
  //   accessor: 'divisao',
  //   width: 100,
  //   show: true,
  //   style: {
  //     textAlign: 'center',
  //     paddingLeft: '20px',
  //     whiteSpace: 'pre-wrap',
  //   },
  // },
];

export const columnsClient = [
  // {
  //   default: true,
  //   Header: "Faturas",
  //   accessor: 'nInvoices',
  //   Cell: ({row}) => {
  //     if (row.nInvoices > 0) {
  //       return <>
  //         <i id="infoInvoice" className="fas fa-file-alt" />
  //         <Tooltip target="infoInvoice" text={"Quantidade de Faturas vinculadas à Bilhetes"}/>
  //       </>;
  //     }
  //   return <span>{' '}</span>
  //   },
  //   style: {
  //     textAlign: 'center',
  //     whiteSpace: 'pre-wrap',
  //     fontSize: '14px',
  //     fontWeight: '500'
  //   },
  //   width: 60,
  //   show: true,
  // },
  {
    default: true,
    Header: 'Emissão',
    accessor: 'dataEmissao',
     width: 130,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Empresa',
    accessor: 'cliente',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Status',
    accessor: 'status',
     width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Fornecedor',
    accessor: 'fornecedor',
     width: 100,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Centro de Custo',
    accessor: 'centro',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {

    default: true,
    Header: 'Viajante',
    accessor: 'passageiro',
     width: 120,
    show: true,
    Cell: ({ row }) => {
      let nome = row.orignal.passageiro.toUpperCase().split('/').join(' / ');

      return (<span>{nome}</span>);
    },
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap'
    },
  },
  {
    default: true,
    Header: 'Trechos',
    accessor: 'trecho',
     width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Solicitante',
    accessor: 'solicitante',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Origem',
    accessor: 'cidadeOri',
     width: 80,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Destino',
    accessor: 'cidadeDest',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap'
    },
  },
  {
    default: true,
    Header: 'Data Viagem',
    accessor: 'dataViagem',
     width: 80,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Motivo',
    accessor: 'motivo',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Nº Fatura',
    accessor: 'fatura',
     width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Aprovador',
    accessor: 'aprovador',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap'
    },
  },
  {
    default: true,
    Header: 'Localizador',
    accessor: 'localizador',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap'
    },
  },
];
