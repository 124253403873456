import React from 'react';

import Select from '~/components/Select';

const ExecutiveSelect = ({
  setExecutivesSelected,
  setCompaniesSelected,
  setCostCentersSelected,
  placeholder,
  name,
  label,
  options,
  isMulti = true,
  ...rest
}) => {
  return (
    <Select
      {...rest}
      name={name}
      label={label}
      options={options}
      placeholder={placeholder}
      isMulti={isMulti}
      onChange={(e) => {
        setCompaniesSelected([]);
        setCostCentersSelected([]);

        if (e !== null)
          return setExecutivesSelected([e]);

        return setExecutivesSelected([]);
      }}
    />
  );
}

export default ExecutiveSelect;
