import styled from 'styled-components';

export const FilterButtons = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 0;
    border-radius: 4px;
    height: 35px;
    font-size: 11px;

    svg {
      margin-left: 5px;
    }

    & {
      margin-left: 5px;
    }
  }
`;


export const ColumnsTitleMain = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
div {
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
    margin: 0 8px;
  }
}
`;
