import styled from "styled-components";
import { Link } from "react-router-dom";

export const Title = styled.h1`
  font-weight: 600;
  color: #333;
  font-size: 28px;
  letter-spacing: -1px;
  ${(props) =>
    props.new &&
    `
  `}

  ${(props) =>
    props.small &&
    `
    font-size: 24px;
  `}
`;

export const GridItem = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const Grid = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  align-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }

  /* @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  } */

  @media (min-width: 1025px) {
    grid-template-columns: repeat(4, 1fr);
  }

  & .traveler-content {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: 30% 70%;
    padding: 24px;
    overflow: hidden;
    min-height: 160px;
    border-radius: 24px;
    /* background-position: left;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover; */

    & span {
      z-index: 2;
      position: relative;
      color: #fff;
      font-size: 22px;
      font-weight: 600;
      line-height: 1;
      pointer-events: none;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.5s all;
    }

    &:hover {
      cursor: pointer;

      &::after {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  & li {
    ${(props) =>
      props.period &&
      `
    &:nth-child(1) {
      .traveler-content {
        background-image: url('https://images.unsplash.com/photo-1552733407-5d5c46c3bb3b?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8N3x8dHJhdmVsfGVufDB8fDB8&auto=format&fit=crop&w=800&q=60');
      }
    }

    &:nth-child(2) {
      .traveler-content {
        background-image: url(https://images.unsplash.com/photo-1533105079780-92b9be482077?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=60);
      }
    }
    &:nth-child(3) {
      .traveler-content {
        background-image: url(https://images.unsplash.com/photo-1512100356356-de1b84283e18?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=60);
      }
    }
    &:nth-child(4) {
      .traveler-content {
        background-image: url(https://images.unsplash.com/photo-1494783367193-149034c05e8f?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=60);
      }
    }
  `}
    ${(props) =>
      props.company &&
      `
    &:nth-child(1) {
      background: #1b0088;
    }
    &:nth-child(2) {
      background: #FF5E00;
    }
    &:nth-child(3) {
      background: #026cb6;
    }
    &:nth-child(4) {
      background: #daca0c;
    }
  `}

  ${(props) =>
      props.sector &&
      `
    &:nth-child(1) {
      background: #34495e;
    }
    &:nth-child(2) {
      background: #3498db;
    }
    &:nth-child(3) {
      background: #2ecc71;
    }
    &:nth-child(4) {
      background: #1abc9c;
    }
  `}
  }
`;

export const Header = styled.header`
  hr{margin: 0px 0px 10px;}
`;

export const Section = styled.div`
  padding: 4px 0;
`;

export const ButtonMenu = styled.button`
  font-size: 15px;
  background: rgba(0, 0, 0, 0.05);
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s background;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const ContainerDestinyFilter = styled.div`
  .filterSelectors {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    span {
      margin: 2px 4px;
      font-size: 13px;
      font-weight: 600;
    }

    div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      button {
        height: 38px;
        border: none;
        border-radius: 4px;
        background-color: #0000000D;
      }
    }
  }
`;
export const FilterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  div {
    div{
      width: 100%;
      height: 5px;
    }
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;

      border: 0;
      border-radius: 4px;
      height: 35px;

      svg {
        margin-left: 5px;
      }

      & {
        margin-left: 5px;
      }
    }
  }
`;
