function removeSpace (value){
  if (value === null) {
    return '';
  }
  return value.replace(' ', '');
}

function splitPaxName (completeName) {
  if (completeName) {
    const normalizedName = completeName.split("/").reverse().join(" ");

    const arrayNames = normalizedName.split(" ");

    return `${arrayNames[0]} ${arrayNames[1] ?? ""} ${arrayNames[2] ?? ""}`;
  } else return completeName;
};

function verifyDefaultPass (value) {
  return value.length > 7
    && value.length < 13
    && value.match(/\d/g)
    && value.match(/[a-z]/gi);
}

export {
  removeSpace,
  splitPaxName,
  verifyDefaultPass
};
