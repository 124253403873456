import React, { useEffect, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';

export default function RankingPass({
  data
}) {
  const [chartData19, setChartData19] = useState({});
  const [labels, setlabels] = useState([]);
  const [values, setvalues] = useState([]);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState([]);

  const chart19 = () => {
    setChartData19({
      labels: labels,
      datasets: [{
        data: values,
        backgroundColor: ['#00EAE1', '#BDBFBF', '#95F496', '#f4dbab', '#9e5ef2', '#FBBA37', '#DB575B', '#A6D59A', '#D54A26', '#000000',],
        borderWidth: 4,
        label: 'Quantidade'
      }]
    })
  };

  useEffect(() => {
    async function loadData() {
      setlabels(data.map(x => x.passageiro));
      setvalues(data.map(x => x.soma));
      setProducts(data.map(x => x.prod));
      setTotal(data.map(x => x.valor));
    }

    loadData();
  }, [data])

  useEffect(() => {
    chart19()
  }, [labels, values])

  return (
    <>
      <div style={{ width: '50%', margin: '4% auto', }}></div>
      <HorizontalBar data={chartData19} options={{
        legend: {
          display: false,
        },
        responsive: true,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, data) {
              const quantityOfTravels = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              const value = FormatValueBRL(total[tooltipItem.index]);

              const legendOfTravels = quantityOfTravels > 1 ? "hospedagens" : "hospedagem"
              // return ` ${quantityOfTravels} ${legendOfTravels}`;
              return ` ${quantityOfTravels} ${legendOfTravels} / ${value} `;
            }
          }
        },
        scales:{
          yAxes: [{
            ticks: {
              callback: function(value) {
                  if(value.includes("/")){
                    const [, name] = value.split("/")
                    return name
                  }
                  return value
              },
              fontSize: 10,
              beginAtZero: true,
          }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0
            }
          }]
      }
        }} />
    </>
  );
}

// const styles = {
//     titleLegend: {
//         marginTop: "10px",
//         fontSize: "11px",
//         fontWeight: 700
//     },
//     redLegend: {
//         marginTop: "10px",
//         fontSize: "11px",
//         fontWeight: 700,
//         color: 'red'
//     }
// }
