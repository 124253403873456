import React from 'react';

import {
  Container,
  Logo,
  Main,
  Footer,
} from './styles';

import logo from '~/assets/images/weegestao2.png';

function About() {
  const year = new Date().getFullYear();

  return (
    <Container>
      <Logo>
        <img
          src={logo}
          alt="Wee Gestor" />
      </Logo>

      <h6>v{process.env.REACT_APP_APP_VERSION}</h6>

      <Main>
        <h2>
          Plataforma de Gestão Corporativa de Viagens
        </h2>

        <h6>
          Gerencie suas viagens corporativas e <br />
          aumente a produtividade dos colaboradores
        </h6>

        <h6>
          Para mais informações enviar e-mail para <b>suporte@weetech.io</b>
        </h6>
      </Main>
    </Container>
  );
}

export default About;
