import React, { useState, useEffect, useContext } from 'react'
import Switch from 'react-bootstrap/Switch';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from '@unform/web';
import { FilterButtons } from './styles';
import Input from "~/components/Input";
import { ApiAxios } from '~/services/api';
import BudgetManagement from "../../context";
import { toast } from 'react-toastify';
import { Spinner } from "reactstrap";

export function ModalAlerts({ isOpen, handleClose, formRef, budget }) {
  const axios = ApiAxios();
  const {
    getBudget,
    loading,
    setLoading
  } = useContext(BudgetManagement);
  const [showInputs, setShowInputs] = useState({
    exceed_alert: false,
    exceed_limit: false,
  });
  const [budgetToUpdate, setBudgetToUpdate] = useState({
    budget_id: 0,
    cost_id: 0,
    company_id: 0,
    company_name: '',
    cost_name: '',
    cost_type: '',
    created_at: '',
    updated_at: '',
    send_specific_email: false,
    specific_email: '',
    send_exceed_alert_costmanager: false,
    send_exceed_alert_travelmanager: false,
    send_exceed_limit_costmanager: false,
    send_exceed_limit_travelmanager: false,
    percent_limit: 0,
  });

  function updateBudget () {
    if (budget === null) {
      return setBudgetToUpdate({
        budget_id: 0,
        cost_id: 0,
        company_id: 0,
        company_name: '',
        cost_name: '',
        cost_type: '',
        created_at: '',
        updated_at: '',
        send_specific_email: false,
        specific_email: '',
        send_exceed_alert_costmanager: false,
        send_exceed_alert_travelmanager: false,
        send_exceed_limit_costmanager: false,
        send_exceed_limit_travelmanager: false,
        percent_limit: 0,
      });
    }

    setShowInputs({
      ...showInputs,
      exceed_alert: budget.send_exceed_alert_costmanager || budget.send_exceed_alert_travelmanager,
      exceed_limit: budget.send_exceed_limit_costmanager || budget.send_exceed_limit_travelmanager,
    });

    return setBudgetToUpdate(budget);
  }

  function updateSendsAlerts () {
    if (!showInputs.exceed_alert) {
      setBudgetToUpdate({
        ...budgetToUpdate,
        send_exceed_alert_costmanager: false,
        send_exceed_alert_travelmanager: false,
      });
    }

    if (!showInputs.exceed_limit) {
      setBudgetToUpdate({
        ...budgetToUpdate,
        send_exceed_limit_costmanager: false,
        send_exceed_limit_travelmanager: false,
      });
    }
  }

  useEffect(() => {
    updateBudget();
  }, [budget]);

  useEffect(() => {
    updateSendsAlerts();
  }, [showInputs]);

  async function handleSubmit(){
    try {
      setLoading(true);

      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/budget/alerts`,
        {
          ...budgetToUpdate,
          send_exceed_alert_costmanager: showInputs.exceed_alert,
          send_exceed_alert_travelmanager: showInputs.exceed_alert,
          send_exceed_limit_costmanager: showInputs.exceed_limit,
          send_exceed_limit_travelmanager: showInputs.exceed_limit,
        }
      );

      getBudget();

      toast.success("Alertas registrados com sucesso.");

      handleClose();

      setLoading(false);
    } catch (error) {
      toast.error("Falha ao registrar alertas.");

      setLoading(false);
    }
  }

  return (
    <Modal show={isOpen} onHide={handleClose} centered size="lg">
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Disparos alerta de orçamento ({budget?.cost_name})
          </Modal.Title>

          <br />
        </Modal.Header>

        <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
          <Switch
            id="switch-custom-email"
            name="switch-custom-email"
            type="switch"
            style={{ fontWeight: 'bold' }}
            label="Adicionar um email específico para enviar alertas quando despesas excederem o orçamento."
            checked={budgetToUpdate.send_specific_email}
            onChange={() => setBudgetToUpdate({
              ...budgetToUpdate,
              send_specific_email: !budgetToUpdate.send_specific_email,
            })}
          />

          {budgetToUpdate.send_specific_email && (
            <div style={{ display: 'flex', flexDirection: 'column', padding: '8px 24px 0px' }}>
              <Input
                name="spacific-email"
                placeholder="email@example.com;personal@email.com"
                value={budgetToUpdate.specific_email}
                required={budgetToUpdate.send_specific_email}
                onChange={e => setBudgetToUpdate({
                  ...budgetToUpdate,
                  specific_email: e.target.value,
                })}
              />

              <p style={{color: '#040'}}>
                <b>Adicione vários emails separando-os por ';' (ponto e vírgula).</b>
              </p>
            </div>
          )}


          <br />

          <Switch
            id="switch-exceed-alert"
            name="switch-exceed-alert"
            type="switch"
            style={{ fontWeight: 'bold' }}
            label="Enviar alertas para Gestores quando despesas excederem o orçamento."
            checked={showInputs.exceed_alert}
            onChange={() => setShowInputs({
              ...showInputs,
              exceed_alert: !showInputs.exceed_alert,
            })}
          />

          {/* {showInputs.exceed_alert && (
            <div style={{ display: 'flex', flexDirection: 'column', padding: '8px 24px 0px' }}>
              <Switch
                id="switch-exceed-alert-cost-manager"
                name="switch-exceed-alert-cost-manager"
                type="switch"
                label="Gestores de Centros de Custo"
                checked={budgetToUpdate.send_exceed_alert_costmanager}
                onChange={e => setBudgetToUpdate({
                  ...budgetToUpdate,
                  send_exceed_alert_costmanager: !budgetToUpdate.send_exceed_alert_costmanager,
                })}
              />

              <Switch
                id="switch-exceed-alert-travel-manager"
                name="switch-exceed-alert-travel-manager"
                type="switch"
                label="Gestores de Viagens"
                checked={budgetToUpdate.send_exceed_alert_travelmanager}
                onChange={e => setBudgetToUpdate({
                  ...budgetToUpdate,
                  send_exceed_alert_travelmanager: !budgetToUpdate.send_exceed_alert_travelmanager,
                })}
              />
            </div>
          )} */}

          <br />

          <Switch
            id="switch-exceed-limit"
            name="switch-exceed-limit"
            type="switch"
            style={{ fontWeight: 'bold' }}
            label="Definir uma porcentagem, para ser avisado quando despesas ultrapassarem esse limite."
            checked={showInputs.exceed_limit}
            onChange={() => setShowInputs({
              ...showInputs,
              exceed_limit: !showInputs.exceed_limit,
            })}
          />

          {showInputs.exceed_limit && (
            <div style={{ display: 'flex', flexDirection: 'row', padding: '8px 24px 0px' }}>
              <div>
                <div>
                  <span> Porcentagem: </span>

                  <input
                    style={{
                      margin: '0px 4px'
                    }}
                    name="percent-limit"
                    default={10}
                    type="number"
                    max={100}
                    min={10}
                    value={budgetToUpdate.percent_limit}
                    onChange={e => setBudgetToUpdate({
                      ...budgetToUpdate,
                      percent_limit: e.target.value,
                    })}
                  />

                  <b> % </b>
                </div>

                {/* <Switch
                  id="switch-exceed-limit-cost-manager"
                  name="switch-exceed-limit-cost-manager"
                  type="switch"
                  label="Gestores de Centros de Custo"
                  checked={budgetToUpdate.send_exceed_limit_costmanager}
                  onChange={e => setBudgetToUpdate({
                    ...budgetToUpdate,
                    send_exceed_limit_costmanager: !budgetToUpdate.send_exceed_limit_costmanager,
                  })}
                />

                <Switch
                  id="switch-exceed-limit-travel-manager"
                  name="switch-exceed-limit-travel-manager"
                  type="switch"
                  label="Gestores de Viagens"
                  checked={budgetToUpdate.send_exceed_limit_travelmanager}
                  onChange={e => setBudgetToUpdate({
                    ...budgetToUpdate,
                    send_exceed_limit_travelmanager: !budgetToUpdate.send_exceed_limit_travelmanager,
                  })}
                /> */}
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <FilterButtons>
            <Button
              variant="btn btn-warning"
              type="button"
              onClick={() => {
                updateBudget();
                handleClose();
              }}
              disabled={loading}>
              Cancelar
            </Button>

            <Button
              className={`btn btn-primary`}
              className={`btn btn-primary ${loading && 'disabled'}`}
              type="submit"
              disabled={loading}>
              {loading && <Spinner color="#fff" size="sm" />}

              {!loading && 'Salvar'}
            </Button>
          </FilterButtons>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
