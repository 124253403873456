import { createGlobalStyle, keyframes } from "styled-components";

import "react-toastify/dist/ReactToastify.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// import 'react-table/react-table.css';
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "simple-line-icons/css/simple-line-icons.css";
import "flag-icon-css/css/flag-icon.min.css";
import "bootstrap-social/bootstrap-social.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "primereact/resources/themes/saga-orange/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import "~/scss/styles.scss";
import "~/scss/plugins/plugins.css";

const loading = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export default createGlobalStyle`
  .loading {
    animation: ${loading} .6s linear infinite;
  }

  .disabled {
    cursor: not-allowed;
  }

  /* a:link{text-decoration: none} */
`;
