import React from "react";
import { useSelector } from "react-redux";
import { Switch, Redirect } from "react-router-dom";
import ShowSelects from "~/functions/ShowSelects";
import Route from "./Route";

import SignIn from "~/pages/SignIn";
import Error from "~/pages/Error";
import MyAgency from "~/pages/Agency/MyAgency";
import Dashboard from "~/pages/Dashboard";
import Invoice from "~/pages/Invoice";
import RequestRecoveryPassword from "~/pages/RequestRecoveryPassword";
import LinkPage from "~/pages/LinkPage";
import RecoveryPassword from "~/pages/RecoveryPassword";
import RequestsManagement from "~/pages/RequestsManagement";
import BudgetManagement from "~/pages/BudgetManagement";
import OrderAndInvoice from "~/pages/OrderAndInvoice";
import Tickets from "~/pages/Reports/Tickets";
import Reemission from "~/pages/Reports/Reemission";
import Substitution from "~/pages/Reports/Substitution";
import Emission from "~/pages/Reports/Emission";
import Traveler from "~/pages/Traveler";
import TravelerList from "~/pages/TravelerList";
import TravelerUser from "~/pages/TravelerUser";
import About from "~/pages/About";
import Plan from "~/pages/Plan";
import Profile from "~/pages/Profile";
import UserProfile from "~/pages/UserProfile";
import UserNotify from "~/pages/Notify";
import { ShowRefundMenu } from "~/functions/ShowMenus";
import Losses from "~/pages/Reports/Losses";
import Refund from "~/pages/Refund";

export default function Routes() {
  const routes = useSelector((state) => state.user.routes);
  const { userProfile } = useSelector(({ user }) => user);
  const user = useSelector((state) => state.user);
  var hasCompany = user.companies.some((x) => x.codCliente === "01FABRICAF");
  var hasCesar = user.companies.some((x) => x.codCliente === "CESARRECIF");

  var hasPlanPremium =
    user &&
    user.userProfile &&
    user.companies.some((x) => x.planId === 4) &&
    user.userProfile.code === "company";
  var hasPlanMaster =
    user &&
    user.userProfile &&
    user.companies.some((x) => x.planId === 3) &&
    user.userProfile.code === "company";
  var hasPlanEconomic =
    user &&
    user.userProfile &&
    user.companies.some((x) => x.planId === 2) &&
    user.userProfile.code === "company";
  var hasPlanFree =
    user &&
    user.userProfile &&
    user.companies.some((x) => x.planId === 1) &&
    user.userProfile.code === "company";
  var isAgency = user && user.userProfile && user.userProfile.code === "agency";
  return (
    <Switch>
      {routes.map((x, i) => {
        switch (x) {
          case "/dashboard":
            return (
              <Route
                path="/dashboard"
                isPrivate
                exact
                key={i}
                component={Dashboard}
              />
            );

          case "/invoice":
            return (
              <Route
                path="/invoice"
                isPrivate
                exact
                key={i}
                component={Invoice}
              />
            );

          case "/order-management":
            return hasPlanPremium || hasPlanMaster || isAgency ? (
              <Route
                path="/order-management"
                isPrivate
                exact
                key={i}
                component={OrderAndInvoice}
              />
            ) : (
              <Route path="/plan" isPrivate exact key={i} component={Plan} />
            );

          case "/traveler":
            return hasPlanPremium ||
              hasPlanMaster ||
              hasPlanEconomic ||
              isAgency ? (
              <Route
                path="/traveler"
                exact
                isPrivate
                key={i}
                component={Traveler}
              />
            ) : (
              <Route path="/plan" exact isPrivate key={i} component={Plan} />
            );

          case "/tickets":
            return (
              <Route
                path="/tickets"
                isPrivate
                exact
                key={i}
                component={Tickets}
              />
            );

          case "/emission":
            return (
              <Route
                path="/emission"
                isPrivate
                exact
                key={i}
                component={Emission}
              />
            );

          case "/reemission":
            return (
              <Route
                path="/reemission"
                isPrivate
                exact
                key={i}
                component={Reemission}
              />
            );

          case "/substitution":
            return (
              <Route
                path="/substitution"
                isPrivate
                exact
                key={i}
                component={Substitution}
              />
            );

          case "/losses":
            return hasCesar ? (
              <Route
                path="/losses"
                isPrivate
                exact
                key={i}
                component={Losses}
              />
            ) : (
              <Route path="/#" isPrivate exact key={i} component={<></>} />
            );

          default:
            return (
              <Route path="/#" isPrivate exact key={i} component={<></>} />
            );
        }
      })}

      {userProfile && ShowSelects(userProfile.userProfileId).isAgency && (
        <Route path="/agency" exact isPrivate component={MyAgency} />
      )}

      {userProfile && ShowSelects(userProfile.userProfileId).showNotify && (
        <Route path="/notification" exact isPrivate component={UserNotify} />
      )}

      {/* <Route path="/substitution" exact isPrivate component={Substitution} /> */}

      <Route
        path="/traveler/list/:id"
        exact
        isPrivate
        component={TravelerList}
      />

      <Route
        path="/traveler/list/:filter"
        exact
        isPrivate
        component={TravelerList}
      />

      <Route
        path="/traveler/person/:user"
        exact
        isPrivate
        component={TravelerUser}
      />

      <Route
        path="/traveler/person/:flightIDSale/:flightNumber/:flightDateOrigin"
        exact
        isPrivate
        component={TravelerUser}
      />

      <Route
        path="/request-management"
        exact
        isPrivate
        component={RequestsManagement}
      />

      <Route path="/refund-management" exact isPrivate component={Refund} />

      {hasPlanPremium || hasPlanMaster || hasPlanEconomic || isAgency ? (
        <Route
          path="/budget-management"
          exact
          isPrivate
          component={BudgetManagement}
        />
      ) : (
        <Route path="/plan" isPrivate exact component={Plan} />
      )}

      <Route path="/profile" exact isPrivate component={Profile} />

      <Route path="/user-profile" exact isPrivate component={UserProfile} />

      <Route path="/about" exact isPrivate component={About} />

      <Route
        path="/forgot-password"
        exact
        component={RequestRecoveryPassword}
      />

      <Route
        path="/LinkPage"
        exact
        component={LinkPage}
      />

      <Route path="/forgot-password/:token" component={RecoveryPassword} />

      <Route path="/signin/:pageid" exact component={SignIn} />

      <Route path="/" exact component={SignIn} />

      <Route path="/error" component={Error} />

      {hasPlanEconomic || hasPlanFree ? (
        <Redirect from="*" to="/plan" />
      ) : (
        <Redirect from="*" to="/" />
      )}
    </Switch>
  );
}
