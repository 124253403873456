import React, { useState, useRef, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { FormGroup } from "reactstrap";
import { Row, Col } from "reactstrap";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import Input from "~/components/Input";
import { Panel, PanelHeader, PanelBody } from "~/components/Panel";
import TextArea from "~/components/TextArea";
import { ApiAxios } from "~/services/api.js";
import moment from "moment";
import ShowSelects from "~/functions/ShowSelects";
import CompanySelect from "~/components/CompanySelect";

import {LabelItemJustified, PaginationFooter, Button } from "./styles";

import { EditAction, CancelDeleteAction } from "~/components/ActionsOfTables";

function LoadingNotify({ setPage }) {
  const axios = ApiAxios();
  const userProfile = useSelector((state) => state.user.userProfile);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const formRefEdit = useRef(null);

  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);

  const [notifyList, setNotifyList] = useState([]);
  const [notifyListPaginated, setNotifyListPaginated] = useState([]);
  const [descricao, setDescricao] = useState([]);
  const [userList, setUserList] = useState([]);
  const [changingStatus, setChangingStatus] = useState(false);
  const [originalNotifyList, setOriginalNotifyList] = useState([]);

  const [notifyFilter, setNotifyFilter] = useState('');

  const [showledPages, setShowledPages] = useState(0);
  const [actualPage, setActualPage] = useState(0);

  const [titleLenght, setTitleLenght] = useState(0);
  const [descriptionLenght, setDescriptionLenght] = useState(0);

  const [notifyToEdit, setNotifyToEdit] = useState({
    notificacao_id: 0,
    titulo: "",
    descricao: "",
    cliente_id_fk: [],
  });

  const [notifyToDelete, setNotifyToDelete] = useState({
    notificacao_id: 0,
    titulo: "",
    descricao: "",
    cliente_id_fk: [],
  });

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => {
    const clients = companiesOptions.filter((c) => {
      const find = notifyToEdit.cliente_id_fk.filter((e) => e === c.id);

      if (find) return true;
      else return true;
    });

    setCompaniesSelected(clients);

    setShowEdit(true);
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  useEffect(() => {
    async function applyCompanyOptions() {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client`
      );

      setCompaniesOptions(
        response.data.map((e) => {
          return {
            label: e.name,
            value: e.id,
          };
        })
      );
    }

    applyCompanyOptions();
  }, []);

  // async function filtro_email(values) {
  //   try {
  //     const filter = {
  //       Email: values.Email,
  //     };

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/notify/all/${values.Email}`
  //     );

  //     setUserList(response.data);
  //   } catch (error) {
  //     toast.error("Não foi possível carregar as notificações");
  //   }
  // }

  // async function handleLido(values) {
  //   try {
  //     setChangingStatus(true);

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/notify/lida/${values}`
  //     );

  //     setChangingStatus(false);

  //     return;
  //   } catch (error) {
  //     setChangingStatus(false);

  //     return;
  //   }
  // }

  async function handleSubmitEdit(values) {
    try {
      const filter = {
        notificacao_id: notifyToEdit.notificacao_id,
        titulo: notifyToEdit.titulo,
        descricao: notifyToEdit.descricao,
        cliente_id_fk: values.cliente_id_fk,
      };

      await axios.put(
        `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/notify/${notifyToEdit.notificacao_id}`,
        filter
      );

      handleCloseEdit();

      setNotifyToEdit({
        notificacao_id: 0,
        titulo: "",
        descricao: "",
        cliente_id_fk: [],
      });

      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/notify`
      );

      setNotifyList(response.data);

      toast.success(`Notificação editada com sucesso.`);

      return;
    } catch (error) {
      return toast.error(
        "Não foi possível editar a notificação, por favor tente mais tarde"
      );
    }
  }

  async function handleSubmitDelete(notificacao_id) {
    try {
      await axios.delete(
        `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/notify/${notificacao_id}`
      );

      handleCloseDelete();

      setNotifyToDelete({
        notificacao_id: 0,
        titulo: "",
        descricao: "",
        cliente_id_fk: [],
      });

      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/notify`
      );

      setNotifyList(response.data);

      return toast.success(`Notificação excluida com sucesso.`);
    } catch (error) {
      return toast.error(
        "Não foi possível excluir a notificação, por favor tente mais tarde"
      );
    }
  }

  function sliceNotificationToPagination(notificationArray, pageSize) {
    let index = 0;
    let arrayLength = notificationArray.length;
    let auxArray = [];

    for (index = 0; index < arrayLength; index += pageSize) {
      let chunk = notificationArray.slice(index, index + pageSize);
      auxArray.push(chunk);
    }

    return auxArray;
  }

  useEffect(() => {
    async function loadingNotify() {
      try {
        setLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/notify`
        );

        setNotifyList(response.data);

        setLoading(false);

        return setOriginalNotifyList(response.data);
      } catch (error) {
        return setLoading(false);
      }
    }

    loadingNotify();
  }, []);

  useEffect(() => {
    setNotifyListPaginated(sliceNotificationToPagination(notifyList, 10));
  }, [notifyList]);


  const handleSearch = (pax) => {
    setLoading(true);

    var newListNotify = [];

    notifyList.map(e => {
      const pos = e.titulo.toLowerCase().indexOf(notifyFilter.toLowerCase());
      // const pos = e.pax.toLowerCase().indexOf(pax.toLowerCase());


      if (pos !== -1) {
        newListNotify.push(e);
      }
    });

    if (newListNotify.length === 0) {
      toast.dismiss();

      toast.info("Nenhuma notificação encontrada!");

      setLoading(false);

      return setTimeout(() => {
        setNotifyList(originalNotifyList);
      }, 1000);
    }

    setNotifyList(newListNotify);

    return setLoading(false);
  }

  function handleDescriptionLength({ target }) {
    let descriptionLength = target.value.length;


    if (descriptionLength <= 200) {
      setDescriptionLenght(descriptionLength);
    }
  }

  function handleTitleLength({ target }) {
    let titleLength = target.value.length;

    if (titleLength <= 100) {
      setTitleLenght(titleLength);
    }
  }


  return (
    <>
      <Panel theme="inverse">
        <PanelHeader noButton>
          <Row
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              paddingLeft: 25,
              justifyContent: "space-between",
            }}
          >
            <h4>
              {loading ? (
                <i className="fas fa-circle-notch fa-spin" />
              ) : (
                "Histórico de Notificação"
              )}
            </h4>
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => setPage("create")}
            >
              Nova Notificação
            </button>
          </Row>
        </PanelHeader>
        <>
          <PanelBody>
            <Row>
              <Col sm={12} lg={9}>
                <div style={{ maxHeight: "430px", overflow: "auto",}}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Título</th>

                        <th>Descrição</th>

                        <th>Data</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {notifyListPaginated[actualPage]?.map(
                        (notify, i) =>
                          !loading && (
                            <tr key={i}>
                              <td
                                style={{
                                  wordBreak: "break-all",
                                  width: 200,
                                  maxWidth: "12ch",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >

                                <span>{notify.titulo}</span>

                              </td>

                              <td
                                style={{
                                  wordBreak: "break-all",
                                  width: 400,
                                  maxWidth: "22ch",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {notify.descricao}
                              </td>

                              <td>

                                <span>
                                  {moment(notify.created_on).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>

                              </td>

                              <td>
                                <EditAction
                                  type="button"
                                  style={{
                                    backgroundColor: "#000",
                                    color: "#fff",
                                  }}
                                  onClick={() => {
                                    setNotifyToEdit(notify);

                                    return handleShowEdit(notify);
                                  }}
                                >
                                  <strong>Editar</strong>
                                  <br />
                                </EditAction>
                              </td>

                              <td>
                                <CancelDeleteAction
                                  type="button"
                                  onClick={() => {
                                    setNotifyToDelete(notify);

                                    return handleShowDelete(notify);
                                  }}
                                >
                                  <strong>Deletar</strong>
                                </CancelDeleteAction>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </Col>
              <Col sm={12} lg={3}>
                <Form ref={formRef} onSubmit={handleSearch}>
                  <Row>
                    <Col sm={12}  >
                      <FormGroup>
                        <Input
                          name="notificação"
                          type="search"
                          value={notifyFilter}
                          onChange={e => {
                            if (e.target.value === '') {
                              setNotifyList(originalNotifyList);
                            }

                            setNotifyFilter(e.target.value);

                            // return handleSearch(e.target.value);
                          }}
                          placeholder="Pesquisar"
                          //disable={loading}
                          searchButton={true}
                          loading={loading}
                          callbackSearch={handleSearch} />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>

            <br></br>

            <Row>
              <PaginationFooter>
                <Button
                  type="button"
                  className={actualPage === 0 ? "on-limit" : ""}
                  onClick={() => {
                    if (actualPage !== 0) {
                      setActualPage(actualPage - 1);
                    }
                  }}>
                  {'<'}
                </Button>

                {`${actualPage === 0 ? actualPage + 1 : actualPage * 10 + 1} -  ${
                  (actualPage === 0 ? actualPage : actualPage * 10) +
                  notifyListPaginated[actualPage]?.length
                }
                de  ${notifyList.length}`}

                <Button
                  type="button"
                  className={
                    actualPage + 1 === notifyListPaginated.length
                      ? "on-limit"
                      : ""
                  }
                  onClick={() => {
                    if (actualPage + 1 !== notifyListPaginated.length) {
                      setActualPage(actualPage + 1);
                    }
                  }}>
                  {">"}
                </Button>
              </PaginationFooter>
            </Row>
          </PanelBody>
        </>
      </Panel>

      {showEdit && (
        <Modal show={showEdit} onHide={handleCloseEdit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Editar - Notificação {notifyToEdit.notificacao_id}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form ref={formRefEdit} onSubmit={handleSubmitEdit}>
              <FormGroup>
                <Input
                  label="Título da Notificação"
                  name="titulo"
                  value={notifyToEdit.titulo}
                  type="text"
                  maxLength={70}
                  required
                  onChange={(e) =>
                    setNotifyToEdit({
                      ...notifyToEdit,
                      titulo: e.target.value,
                    })
                  }
                />
              </FormGroup>

              <FormGroup>
                <TextArea
                  label="Descrição"
                  name="descricao"
                  value={notifyToEdit.descricao}
                  type="text"
                  maxLength={200}
                  required
                  onChange={(e) =>
                    setNotifyToEdit({
                      ...notifyToEdit,
                      descricao: e.target.value,
                    })
                  }
                ></TextArea>
              </FormGroup>

              {/* {ShowSelects(userProfile.userProfileId).companies && companiesOptions.length > 1 && (
                <FormGroup>
                  <CompanySelect
                    name="cliente_id_fk"
                    label="Empresas"
                    placeholder="Selecione..."
                    options={companiesOptions}
                    setCompaniesSelected={setCompaniesSelected}
                    disable={loading}
                    required
                  />
                </FormGroup>
              )} */}

              <button type="submit" className="btn btn-primary float-right">
                Salvar
              </button>
            </Form>
          </Modal.Body>
        </Modal>
      )}

      {showDelete && (
        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>
              Remover - Notificação {notifyToDelete.notificacao_id}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body >
            <p
              style={{
                wordBreak: "break-all",
              }}
            >
              <b>Título da Notificação:</b> <br />  {notifyToDelete.titulo}
            </p>

            <p
              style={{
                wordBreak: "break-all",
              }}
            >
              <b>Descrição da Notificação:</b> <br /> {notifyToDelete.descricao}
            </p>

            <button
              type="submit"
              className="btn btn-danger"
              onClick={() => handleSubmitDelete(notifyToDelete.notificacao_id)}
            >
              Remover Notificação
            </button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default LoadingNotify;
