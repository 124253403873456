import React from 'react';
import { MdInfo } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import AnimatedNumber from 'animated-number-react';

import Shimmer from '~/components/Shimmer';
import Tooltip from '~/components/Tooltip';
import { Widget } from './styles';
import { FormatValueBRL } from '~/functions/FormatValue';

export default function Indicators({
  data,
  pageFilters,
  setPageFilters,
  loading,
}) {
  const { t } = useTranslation('Invoice');

  return (
    <div className="row">
      <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <Widget
          className="widget widget-stats bg-success"
          onClick={() => setPageFilters({
            ...pageFilters,
            status: data.open.status
          })}>
          <div className="stats-icon stats-icon-lg">
            <i className="fas fa-dollar-sign" />
          </div>

          <div
            className="stats-content "
            style={{color: '#333'}}>
            <div
              className="stats-title "
              style={{color: '#333'}}>
              {t('Indicators.OpenInvoices.Title')}

              &nbsp;&nbsp;&nbsp;

              <MdInfo id="openInvoices" />

              <Tooltip
                target="openInvoices"
                text="Clique no card para filtrar faturas em aberto" />
            </div>

            {loading && (
              <Shimmer
                color="rgba(255,255,255,0.1)"
                highlightColor="rgba(255,255,255,0.3)" />
            )}

            {!loading && (
              <>
                <div className="stats-number " style={{color: '#333'}}>
                  <AnimatedNumber
                    value={data.open.valorFatura}
                    formatValue={FormatValueBRL}
                  />
                </div>

                <div className="stats-desc " style={{color: '#333'}}>
                  {data.open.quantidade}&nbsp;
                  {t('Indicators.OpenInvoices.Subtitle')}
                </div>
              </>
            )}
          </div>
        </Widget>
      </div>

      <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <Widget
          className="widget widget-stats bg-danger"
          onClick={() => setPageFilters({
            ...pageFilters,
            status: data.late.status
          })}>
          <div className="stats-icon stats-icon-lg">
            <i className="fas fa-exclamation-triangle" />
          </div>

          <div className="stats-content">
            <div
              className="stats-title "
              style={{color: '#333'}}>
              {t('Indicators.OverdueInvoices.Title')}

              &nbsp;&nbsp;&nbsp;

              <MdInfo id="overdueInvoices" />

              <Tooltip
                target="overdueInvoices"
                text="Clique no card para filtrar faturas vencidas" />
            </div>

            {loading && (
              <Shimmer
                color="rgba(255,255,255,0.1)"
                highlightColor="rgba(255,255,255,0.3)" />
            )}

            {!loading && (
              <>
                <div className="stats-number " style={{color: '#333'}}>
                  <AnimatedNumber
                    value={data.late.valorFatura}
                    formatValue={FormatValueBRL}
                  />
                </div>

                <div className="stats-desc " style={{color: '#333'}}>
                  {data.late.quantidade}&nbsp;
                  {t('Indicators.OverdueInvoices.Title')}
                </div>
              </>
            )}
          </div>
        </Widget>
      </div>

      <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <Widget
          className="widget widget-stats bg-warning"
          onClick={() => setPageFilters({
            ...pageFilters,
            status: data.toPay.status
          })}>
          <div className="stats-icon stats-icon-lg">
            <i className="fas fa-thumbtack" />
          </div>

          <div className="stats-content">
            <div
              className="stats-title "
              style={{color: '#333'}}>
              {t('Indicators.InvoicesToPay.Title')}

              &nbsp;&nbsp;&nbsp;

              <MdInfo id="invoicesToPay" />

              <Tooltip
                target="invoicesToPay"
                text="Clique no card para filtrar faturas a pagar" />
            </div>

            {loading && (
              <Shimmer
                color="rgba(255,255,255,0.1)"
                highlightColor="rgba(255,255,255,0.3)" />
            )}

            {!loading && (
              <>
                <div className="stats-number " style={{color: '#333'}}>
                  <AnimatedNumber
                    value={data.toPay.valorFatura}
                    formatValue={FormatValueBRL}
                  />
                </div>

                <div className="stats-desc " style={{color: '#333'}}>
                  {data.toPay.quantidade}&nbsp;
                  {t('Indicators.InvoicesToPay.Title')}
                </div>
              </>
            )}
          </div>
        </Widget>
      </div>
    </div>
  );
}
