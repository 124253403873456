import React, { useState } from 'react';

import List from './components/List';
import Edit from './components/Edit';

function UserProfile() {
  const [page, setPage] = useState('list');
  const [toEdit, setToEdit] = useState(null);

  return (
    <>
      {page === 'list' && (
        <List
          page={page}
          setPage={setPage}
          setToEdit={setToEdit} />
      )}

      {page === 'edit' && (
        <Edit
          page={page}
          setPage={setPage}
          toEdit={toEdit}
          setToEdit={setToEdit} />
      )}
    </>
  );
}

export default UserProfile;
