import { createReducer, createActions } from 'reduxsauce';

import { AuthTypes } from './auth';

const { Types, Creators } = createActions({
  insertProfile: ['profile'],
  insertCompanies: ['companies'],
  insertRoutes: ['routes'],
  insertCharts: ['charts'],
  insertCostCenters: ['costCenters'],
  insertGlobalFilters: ['globalFilters'],
  insertUserProfile: ['userProfile']
});

export const UserTypes = Types;
export default Creators;

const INITIAL_STATE = {
  profile: {},
  companies: [],
  routes: [],
  charts: [],
  costCenters: [],
  globalFilters: null,
  userProfile: null
};

export const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.SIGN_IN_SUCCESS]: (state, { user }) => ({
    ...state,
    profile: user
  }),

  [Types.INSERT_PROFILE]: (state, { profile }) => ({
    ...state,
    profile: profile
  }),

  [Types.INSERT_COMPANIES]: (state, { companies }) => ({
    ...state,
    companies: companies
  }),

  [Types.INSERT_ROUTES]: (state, { routes }) => ({
    ...state,
    routes: routes
  }),

  [Types.INSERT_CHARTS]: (state, { charts }) => ({
    ...state,
    charts: charts
  }),

  [Types.INSERT_COST_CENTERS]: (state, { costCenters }) => ({
    ...state,
    costCenters: costCenters
  }),

  [Types.INSERT_GLOBAL_FILTERS]: (state, { globalFilters }) => ({
    ...state,
    globalFilters: globalFilters
  }),

  [Types.INSERT_USER_PROFILE]: (state, { userProfile }) => ({
    ...state,
    userProfile: userProfile
  }),

  [AuthTypes.SIGN_FAILURE]: state => ({
    ...state,
    profile: {},
    companies: [],
    routes: [],
    charts: [],
    costCenters: [],
    globalFilters: null,
    userProfile: null,
    loading: false,
    accessToken: null,
    signed: false,
  }),

  [AuthTypes.SIGN_OUT]: () => ({
    profile: {},
    companies: [],
    routes: [],
    charts: [],
    costCenters: [],
    globalFilters: null,
    userProfile: null,
    loading: false,
    accessToken: null,
    signed: false,
  })
});
