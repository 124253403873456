/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect, useRef } from "react";
import { ApiAxios } from '~/services/api';
import { Row, Col, Spinner } from "reactstrap";
import Input from "~/components/Input";
import Select from "~/components/Select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Form } from "@unform/web";
import { toast } from 'react-toastify';
import TextArea from "~/components/TextArea";
import { useRefund } from 'context';
import { mask } from "remask";
import DownloadFile from '~/functions/DownloadFile';
import {
  CaretButton,
} from './styles';
import {
  refundInitialState,
  refundStatus,
  disableInputs,
} from './constants';
import FileList from '~/components/FileList';

export default function ModalRefund({
  itemToSave,
  isAdmin,
}) {
  const axios = ApiAxios();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [refundToSave, setRefundToSave] = useState(refundInitialState);
  const [refundFiles, setRefundFiles] = useState([]);
  const [newStatus, setNewStatus] = useState({
    label: "Solicitado",
    value: "requested",
  });
  const {
    closeModal,
  } = useRefund();

  useEffect(() => {
    async function loadRefund () {
      if (itemToSave !== null) {
        const actualStatus = refundStatus.filter(e => e.value === itemToSave.status);

        if (actualStatus.length > 0) {
          setNewStatus(actualStatus);
        }

        if (itemToSave.status !== 'available') {
          setAcceptTerms(true);
        }

        if (itemToSave.status === 'available') {
          setShowBankDetails(true);
        }

        setRefundToSave(itemToSave);

        try {
          setLoadingFiles(true);

          var res = await axios.get(
            `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/refund/get-files/${itemToSave.refundId}`,
          );

          setRefundFiles(res.data);

          setLoadingFiles(false);
        } catch ({ response }) {
          setLoadingFiles(false);
        }
      }
    }

    loadRefund();
  }, [itemToSave]);

  async function handleSubmit () {
    try {
      setLoading(true);

      const filters = {
        refundId: refundToSave.refundId,
        requestId: refundToSave.id,
        code: refundToSave.code,
        name: refundToSave.name,
        email: refundToSave.email,
        reason: refundToSave.reason,
        bankName: refundToSave.bankName,
        bankAgency: refundToSave.bankAgency,
        bankAccount: refundToSave.bankAccount,
        cpfCnpj: refundToSave.cpfCnpj,
        status: refundToSave.status,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/refund/save`,
        filters,
      );

      if (res.data) {
        closeModal();
      }

      toast.success("Solicitação salva com sucesso.");

      return setLoading(false);
    } catch ({ response }) {
      if (response) {
        toast.info(response.data);
      } else {
        toast.error("Não foi possível realizar a requisição");
      }

      closeModal();

      return setLoading(false);
    }
  }

  async function handleAddFiles (e) {
    try {
      if (e.target.files[0].size > 5242880) {
        return toast.info("O arquivo deve ter no máximo 5MB de tamnho.");
      }

      setLoadingFiles(true);

      const data = new FormData();

      data.append("arquivo", e.target.files[0]);

      var res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/refund/save-file/${refundToSave.refundId}`,
        data,
      );

      if (res.data) {
        setRefundFiles(res.data);

        toast.success("Arquivo adicionado com sucesso.");
      }

      return setLoadingFiles(false);
    } catch ({ response }) {
      setLoadingFiles(false);

      if (response) {
        toast.info(response.data);
      } else {
        toast.error("Não foi possível realizar a requisição. Tente novamente mais tarde.")
      }
    }
  }

  async function handleRemoveFiles (e) {
    try {
      setLoadingFiles(true);

      var res = await axios.delete(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/refund/remove-file/${e.id}`,
      );

      if (res.data) {
        setRefundFiles(res.data);

        toast.success("Arquivo removido com sucesso.");
      }

      return setLoadingFiles(false);
    } catch ({ response }) {
      setLoadingFiles(false);

      if (response) {
        toast.info(response.data);
      } else {
        toast.error("Não foi possível realizar a requisição. Tente novamente mais tarde.")
      }
    }
  }

  async function handleDownloadFile (e) {
    DownloadFile(
      `data:application/octet-stream;base64,${e.data}`,
      e.filename
    );

    return toast.info(`Baixando ${e.filename}, aguarde...`);
  }

  return (
    <Modal show={true} onHide={closeModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          {refundToSave.code.length === 0
            ? `Solicitar Reembolso`
            : `Solicitação de Reembolso (${refundToSave.code})`
          }
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form ref={formRef} onSubmit={handleSubmit}>
          {refundToSave.status !== 'available' && (
            <>
              <h4>Alterar Status</h4>

              <Row>
                <Col sm={12}>
                  <Select
                    name="status"
                    label=""
                    value={newStatus}
                    onChange={e => {
                      setNewStatus(e);

                      return setRefundToSave({
                        ...refundToSave,
                        status: e.value,
                      })
                    }}
                    options={refundStatus}
                    isDisabled={!isAdmin}
                  />
                </Col>
              </Row>
            </>
          )}

          {refundToSave.status !== 'available' && (
            <>
              <h4>Anexos ({refundFiles.length})</h4>

              {isAdmin && (
                <span>
                  * Limite máximo de 5 arquivos com 5MB de tamanho cada.
                </span>
              )}

              <Row>
                <Col sm={12}>
                  <FileList
                    files={refundFiles}
                    accept="image/jpeg,image/png,text/plain,application/pdf,application/msword"
                    downloadCallback={handleDownloadFile}
                    removeCallback={handleRemoveFiles}
                    addCallback={handleAddFiles}
                    loading={loadingFiles}
                    stopAdd={refundFiles.length == 5}
                    allowRemove={isAdmin}
                    allowAdd={isAdmin}
                  />
                </Col>
              </Row>

              <br />
            </>
          )}

          <h4>
            <span>
              Detalhes do Pedido
            </span>

            <CaretButton type="button" onClick={() => setShowDetails(!showDetails)}>
              <i className={`fas fa-caret-${showDetails ? 'up' : 'down'} fa-lg`} />
            </CaretButton>
          </h4>

          {showDetails && (
            <Row>
              <Col sm={12} md={6} lg={3}>
                <Input
                  name="company"
                  label="Empresa"
                  placeholder="Empresa"
                  disabled
                  value={refundToSave.company}
                />
              </Col>

              <Col sm={12} md={6} lg={3}>
                <Input
                  name="emission"
                  label="Data Emissão"
                  placeholder="Data Emissão"
                  disabled
                  value={refundToSave.emission}
                />
              </Col>

              <Col sm={12} md={6} lg={3}>
                <Input
                  name="travelDate"
                  label="Data da Viagem"
                  placeholder="Empresa"
                  disabled
                  value={refundToSave.travelDate}
                />
              </Col>

              <Col sm={12} md={6} lg={3}>
                <Input
                  name="locator"
                  label="Localizador"
                  placeholder="Localizador"
                  disabled
                  value={refundToSave.locator}
                />
              </Col>

              <Col sm={12} md={6} lg={3}>
                <Input
                  name="ticket"
                  label="Bilhete"
                  placeholder="Bilhete"
                  disabled
                  value={refundToSave.ticket}
                />
              </Col>

              <Col sm={12} md={6} lg={3}>
                <Input
                  name="request"
                  label="Pedido/OS"
                  placeholder="Pedido/OS"
                  disabled
                  value={refundToSave.request}
                />
              </Col>

              <Col sm={12} md={6} lg={3}>
                <Input
                  name="invoice"
                  label="Fatura"
                  placeholder="Fatura"
                  disabled
                  value={refundToSave.invoice}
                />
              </Col>

              <Col sm={12} md={6} lg={3}>
                <Input
                  name="traveler"
                  label="Passageiro"
                  placeholder="Passageiro"
                  disabled
                  value={refundToSave.traveler}
                />
              </Col>

              <Col sm={12} md={6} lg={3}>
                <Input
                  name="stretch"
                  label="Trecho"
                  placeholder="Trecho"
                  disabled
                  value={refundToSave.stretch}
                />
              </Col>

              <Col sm={12} md={6} lg={3}>
                <Input
                  name="provider"
                  label="Fornecedor"
                  placeholder="Fornecedor"
                  disabled
                  value={refundToSave.provider}
                />
              </Col>

              <Col sm={12} md={6} lg={3}>
                <Input
                  name="costCenter"
                  label="Centro de Custo"
                  placeholder="Centro de Custo"
                  disabled
                  value={refundToSave.costCenter}
                />
              </Col>
            </Row>
          )}

          <br />

          <h4>
            <span>
              Dados Bancários
            </span>

            <CaretButton type="button" onClick={() => setShowBankDetails(!showBankDetails)}>
              <i className={`fas fa-caret-${showBankDetails ? 'up' : 'down'} fa-lg`} />
            </CaretButton>
          </h4>

          {showBankDetails && (
            <Row>
              <Col sm={12} lg={6}>
                <Row>
                  <Col sm={12} style={{ padding: '0 15px' }}>
                    <Input
                      name="name"
                      value={refundToSave.name}
                      onChange={e => {
                        if (e.target.value.length > 100) {
                          return false;
                        }

                        return setRefundToSave({
                          ...refundToSave,
                          name: e.target.value,
                        });
                      }}
                      label="Nome do favorecido"
                      placeholder="Nome do favorecido"
                      disabled={disableInputs(refundToSave.status)}
                    />
                  </Col>

                  <Col sm={12} style={{ padding: '0 15px' }}>
                    <Input
                      name="email"
                      value={refundToSave.email}
                      type="email"
                      onChange={e => {
                        return setRefundToSave({
                          ...refundToSave,
                          email: e.target.value,
                        });
                      }}
                      label="E-mail *"
                      placeholder="E-mail"
                      disabled={disableInputs(refundToSave.status)}
                      required
                    />
                  </Col>
                </Row>
              </Col>

              <Col sm={12} lg={6}>
                <Row style={{ height: '100%'}}>
                  <Col>
                    <TextArea
                      style={{
                        height: 'calc(100% - 2.4rem)',
                      }}
                      name="reason"
                      value={refundToSave.reason}
                      onChange={e => {
                        if (e.target.value.length > 300) {
                          return false;
                        }

                        return setRefundToSave({
                          ...refundToSave,
                          reason: e.target.value,
                        });
                      }}
                      label={`Informe a razão da solicitação. *`}
                      required
                    />
                  </Col>
                </Row>
              </Col>

              <Col sm={6} lg={3}>
                <Input
                  name="bankName"
                  value={refundToSave.bankName}
                  onChange={e => {
                    if (e.target.value.length > 100) {
                      return false;
                    }

                    return setRefundToSave({
                      ...refundToSave,
                      bankName: e.target.value,
                    });
                  }}
                  label="Banco"
                  placeholder="Banco"
                  disabled={disableInputs(refundToSave.status)}
                />
              </Col>

              <Col sm={6} lg={3}>
                <Input
                  name="bankAgency"
                  value={refundToSave.bankAgency}
                  onChange={e => {
                    const newValue = mask(
                      e.target.value,
                      ['99999999'],
                    );

                    return setRefundToSave({
                      ...refundToSave,
                      bankAgency: newValue,
                    });
                  }}
                  label="Agência"
                  placeholder="Agência"
                  disabled={disableInputs(refundToSave.status)}
                />
              </Col>

              <Col sm={6} lg={3}>
                <Input
                  name="bankAccount"
                  value={refundToSave.bankAccount}
                  onChange={e => {
                    const newValue = mask(
                      e.target.value,
                      ['9999999999999999'],
                    );

                    return setRefundToSave({
                      ...refundToSave,
                      bankAccount: newValue,
                    });
                  }}
                  label="Conta"
                  placeholder="Conta"
                  disabled={disableInputs(refundToSave.status)}
                />
              </Col>

              <Col sm={6} lg={3}>
                <Input
                  name="cpfCnpj"
                  value={refundToSave.cpfCnpj}
                  onChange={e => {
                    var newValue = mask(
                      e.target.value,
                      ["999.999.999-99"],
                    );

                    if (e.target.value.length > 14) {
                      newValue = mask(
                        e.target.value,
                        ["99.999.999/9999-99"],
                      );
                    }

                    return setRefundToSave({
                      ...refundToSave,
                      cpfCnpj: newValue,
                    });
                  }}
                  label="CPF/CNPJ"
                  placeholder="CPF/CNPJ"
                  disabled={disableInputs(refundToSave.status)}
                />
              </Col>

              <Col sm={12}>
                <p>
                  &middot;&nbsp;Para serviços aéreos o reembolso seguirá conforme a Lei
                  Federal 14.034/2020.
                </p>

                <p>
                  &middot;&nbsp;Para serviços terrestes o reembolso seguirá conforme a
                  Lei Federal 14.046/2020.
                </p>

                <p>
                  &middot;&nbsp;Em caso de remarcação e crédito também verificar com seu Wee
                  Consultor.
                </p>

                {refundToSave.status === 'available' && (
                  <p onClick={() => setAcceptTerms(!acceptTerms)}>
                    <input
                      checked={acceptTerms}
                      onChange={() => setAcceptTerms(!acceptTerms)}
                      type="checkbox"
                    />

                    &nbsp;&nbsp;

                    <strong>
                      Eu concordo com as regras do processo de reembolso.
                    </strong>
                  </p>
                )}
              </Col>
            </Row>
          )}

          <Row>
            <Col sm={12} style={{
              textAlign: 'right',
            }}>
              <Button
                onClick={closeModal}
                style={{
                  marginLeft: '8px',
                }}>
                Fechar
              </Button>

              <Button
                type="submit"
                disabled={refundToSave.status === 'available' && !acceptTerms}
                style={{
                  marginLeft: '8px',
                }}>
                {loading
                  ? <Spinner size="sm" color="#ffff" />
                  : 'Enviar'
                }
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
