import styled from 'styled-components';

export const AgencyStyle = styled.div`
  .col, .row {
    margin: 0;
    padding: 0;
  }

  .row, .col {
    margin-bottom: 3px;
  }

  p {
    margin: 3px 3px 6px 3px;
  }
`;
