import React, { useState, useRef } from 'react';
import { Form } from '@unform/web';
import { FiArrowLeft } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import { Container, Content, Button, LinkBack } from './styles';

import Input from '~/components/Input';

import { api } from '~/services/api';

import history from '~/services/history';

import logo from '~/assets/images/weegestao_vertical2.png';
import { rgb } from 'polished';

export default function RequestRecoveryPassword() {
  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [emailForm, setEmailForm] = useState('');

  async function handleSubmit({ email }) {
    try {
      setLoading(true);

      await api.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/satisfaction`,
        {
          email: "brunomiqueaslopes@gmail.com",
          nvoo: 7,
          nhotel: 9,
          textvoo: "bom",
          texthotel: ""
        }
      );

      toast.success(
        'Link enviado com sucesso! Verifique a caixa de entrada ou spam do seu e-mail.'
      );

      setLoading(false);

      return history.push("/");
    } catch (error) {
      setLoading(false);

      if (error && error.response) {
        return toast.error(error.response.data.message);
      }

      return toast.error(
        'Não foi possível realizar a solicitação, tente novamente mais tarde.'
      );
    }
  }

  return (
    <Container
      style={{background: '#ED661F'}}
    >
      <Content
        //style={{background: '#66259d'}}
        style={{background: rgb(213, 74, 38)}}

      >
        <section>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '0',
              margin: '0 0 16px'
            }}
          >
            <img
              src={logo}
              alt="Wee Gestor"
              style={{
                padding: '0',
                margin: '16px'
              }}
            />

            <h1
              style={{
                padding: '0',
                margin: '0'
              }}
            >
              Esqueceu sua senha?
            </h1>
          </div>

          <p>
            Informe seu e-mail para receber um link de redefinição de senha
          </p>

          <LinkBack className="back-link" to="/">
            <FiArrowLeft size={16} color="#fff" />

            Voltar
          </LinkBack>
        </section>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            name="email"
            placeholder="E-mail"
            type="email"
            // pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Informe um endereço de e-mail válido."
            onChange={e => setEmailForm(e.target.value)}
            value={emailForm}
            required />

            1- De 0 á 10, quanto classifica seu voo?

          <Input
            name="nvoo"
            placeholder="0 á 10"
            type="text"
            // pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Informe um endereço de e-mail válido."
            required />

        {/* <select value={this.state.value} onChange={this.handleChange}>
            <option value="laranja">Laranja</option>
            <option value="limao">Limão</option>
            <option value="coco">Coco</option>
            <option value="manga">Manga</option> */}

            1-2 Se quiser, justifique sua escolha a cima

            <Input
            name="textvoo"
            type="text"
            // pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Informe um endereço de e-mail válido."
            required />

          {/* <select value={this.state.value} onChange={this.handleChange}>
            <option value="laranja">Laranja</option>
            <option value="limao">Limão</option>
            <option value="coco">Coco</option>
            <option value="manga">Manga</option> */}


            2- De 0 á 10, quanto classifica sua hospedagem?

            <Input
              name="nhotel"
              placeholder="0 á 10"
              type="text"
              // pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Informe um endereço de e-mail válido."
              required />

            2-2 Se quiser, justifique sua escolha a cima

            <Input
            name="texthotel"
            type="text"
            // pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Informe um endereço de e-mail válido."
            required />


          <Button
            className="button"
            type="submit"
            disabled={loading}
            style={{
              background: 'rgb(10, 238, 225)',
              color: '#66259d'
              //color: '#fff'
            }}
          >
            {loading ? <Spinner color="#FFFF" /> : 'Enviar'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
}
