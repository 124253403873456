import styled from "styled-components";
import ReactSelect from 'react-select';
import { FormGroup } from 'reactstrap';

export const Label = styled.label`
  margin: 2px 4px;
  font-weight: 600;
`;

export const SubLabel = styled.label`
  margin: 1px 4px;
  font-weight: 500;
  font-size: 10px;
`;

export const Group = styled(FormGroup)`
  padding: -5px;
  margin-bottom: 0px;
`;

export const SelectStyled = styled(ReactSelect)`
  width: 100%;
  height: 34px;
`;
