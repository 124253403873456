import styled from "styled-components";
import { Link } from "react-router-dom";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

export const NavUlItems = styled.ul`
  list-style-type: none;
  padding: ${props => props.minify ? '0 4px' : '0 16px'};

  a:hover {
    text-decoration: none;
  }

  li {
    border-radius: 6px;
  }

  li.active {
    background-color: #000;
  }

  li:not(.active):hover {
    background: #fff;
  }
`;

export const NavUlSubItems = styled.ul`
  list-style-type: none;
  padding: 0 12px 12px 36px;

  li:not(.active):hover {
    background: #ccc;
  }
`;

export const NavUlSubItemsPopover = styled.ul`
  list-style-type: none;
  padding: 0px;

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  li {
    border-radius: 6px;
  }

  li.active {
    background-color: #000;
  }

  li:not(.active):hover {
    background: #ccc;
  }
`;

export const NavLiItem = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.minify && '44px'};
  display: flex;
  justify-content: ${props => props.minify ? 'center' : 'space-between'};
  align-items: center;
  padding: ${props => props.minify ? '0' : '4px 12px'};
  font-size: 13px;
  color: #D54A26 !important;
  font-weight: 600;
  margin-top: 2px 0 2px 0;

  i {
    font-size: ${props => props.minify && '28px'};
    width: ${props => !props.minify && '24px'};
  }
`;

export const NavLiItemLink = styled(Link)`
  width: 100%;
  height: ${props => props.minify && '48px'};
  display: flex;
  justify-content: ${props => props.minify ? 'center' : 'flex-start'};
  align-items: center;
  padding: ${props => props.minify ? '0' : '6px 12px'};
  font-size: 13px;
  color: #D54A26 !important;
  font-weight: 600;
  margin-top: 2px 0 2px 0;

  i {
    font-size: ${props => props.minify && '28px'};
    width: ${props => !props.minify && '24px'};
  }
`;

export const NavLiSubItemLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 12px;
  font-size: 12px;
  color: #D54A26 !important;
  font-weight: 600;
  margin-top: 2px 0 2px 0;
`;

export const SubmenuPopover = styled(Popover)`
  border-radius: 6px !important;
  padding-bottom: 0px !important;
`;

export const SubmenuPopoverHeader = styled(PopoverHeader)`
  background-color: #D54A26;
  color: #fff;
`;

export const SubmenuPopoverBody = styled(PopoverBody)`
  ul {
    margin: 0;
  }
`;
