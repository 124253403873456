import React, { useState, useEffect } from "react";
import { IoIosAirplane } from "react-icons/io";
import GoogleMapReact from "google-map-react";
import Tooltip from "~/components/Tooltip";

import {
  IframeHolder,
  List,
  ListItem,
  InfosUser,
  Name,
  Infos,
  MapButton,
  Pagination,
  NoContent
} from "./styles";

export const getFirstTwoNames = (completeName) => {
  if (completeName) {
    const normalizedName = completeName.split("/").reverse().join(" ");
    const arrayNames = normalizedName.split(" ");
    return `${arrayNames[0]} ${arrayNames[1] ?? ""} ${arrayNames[2] ?? ""}`;
  } else return completeName;
};

export const parseDate = (date) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);

  return `${day}/${month}/${year}`;
};

function Card({
  travelers
}) {
  const [loading, setLoading] = useState(true);
  const [paginationProps, setPaginationProps] = useState({
    items: [],
    pageSize: 9,
    pageIndex: 0,
    pagesCount: 0,
    itemsCount: 0,
    skeletonItems: [1,2,3,4,5,6,7,8,9,0],
  });

  const getThePathCoordinates = (
    originLat,
    originLng,
    destinyLat,
    destinyLng
  ) => {
    return [
      { lat: parseFloat(originLat), lng: parseFloat(originLng) },
      { lat: parseFloat(destinyLat), lng: parseFloat(destinyLng) },
    ];
  };

  const handleGoogleMapApi = (google, path) => {
    var flightPath = new google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor: "#5d2291",
      strokeOpacity: 1,
      strokeWeight: 4,
    });

    flightPath.setMap(google.map);
  };

  useEffect(() => {
    async function loadPage () {
      setLoading(true);

      var items = travelers.slice(
        paginationProps.pageSize * paginationProps.pageIndex,
        paginationProps.pageSize * paginationProps.pageIndex + paginationProps.pageSize
      );

      setPaginationProps({
        ...paginationProps,
        items: items,
        itemsCount: travelers.length,
        pagesCount: Math.ceil(travelers.length / paginationProps.pageSize),
      });

      return setTimeout(() => setLoading(false), 1000);
    }

    loadPage();
  }, [travelers]);

  async function goToPreviousPage () {
    setLoading(true);

    const items = travelers.slice(
      paginationProps.pageSize * (paginationProps.pageIndex - 1),
      (paginationProps.pageSize * (paginationProps.pageIndex - 1)) + paginationProps.pageSize
    );

    setPaginationProps({
      ...paginationProps,
      items: items,
      pageIndex: paginationProps.pageIndex - 1,
    });

    return setTimeout(() => setLoading(false), 1000);
  }

  async function goToNextPage () {
    setLoading(true);

    const items = travelers.slice(
      paginationProps.pageSize * (paginationProps.pageIndex + 1),
      (paginationProps.pageSize * (paginationProps.pageIndex + 1)) + paginationProps.pageSize
    );

    setPaginationProps({
      ...paginationProps,
      items: items,
      pageIndex: paginationProps.pageIndex + 1,
    });

    return setTimeout(() => setLoading(false), 1000);
  }

  return (
    <>
      <List>
        {!loading && paginationProps.items.map((item, i) => {
          const path = getThePathCoordinates(
            item.origin.coordinates.latitude,
            item.origin.coordinates.longitude,
            item.destiny.coordinates.latitude,
            item.destiny.coordinates.longitude
          );

          return (
            <ListItem key={i}>
              <IframeHolder>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAzcOTd0EDXovHqGbG3eSYOIJypDCaNOwY",
                  }}
                  defaultCenter={{
                    lat: parseFloat(item.origin.coordinates.latitude),
                    lng: parseFloat(item.origin.coordinates.longitude),
                  }}
                  defaultZoom={7}
                  language="pt"
                  region="br"
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={(google) =>
                    handleGoogleMapApi(google, path)
                  }>
                  <IoIosAirplane
                    lat={parseFloat(item.origin.coordinates.latitude)}
                    lng={parseFloat(item.origin.coordinates.longitude)}
                    size={30}
                    color="#5d2291"
                  />
                </GoogleMapReact>
              </IframeHolder>

              <InfosUser>
                <Name>
                  {getFirstTwoNames(item.paxName)}
                </Name>

                <MapButton
                  to={`/traveler/person/${item.flightIDSale}/${item.flightNumber}/${item.origin.date}`}>
                  Ver detalhes
                </MapButton>
              </InfosUser>

              <Infos>
                <div>
                  De&nbsp;<strong>{`${item.origin.name} (${item.origin.airportCode.trim()})`}</strong>
                  &nbsp;até&nbsp;<strong>{`${item.destiny.name} (${item.destiny.airportCode.trim()})`}</strong>
                </div>

                {item.haveStopOrConnection && (
                  <div>
                    Trecho:&nbsp;

                    <strong>
                      <span id="info-travel">
                        {item.routes}
                      </span>
                    </strong>

                    <Tooltip
                      target="info-travel"
                      text={"Trecho da viagem"}
                    />
                  </div>
                )}

                <div>
                  Partida em{" "}
                  <strong>{parseDate(item.origin.date)}</strong>
                </div>

                <div>
                  Chegada em{" "}
                  <strong>{parseDate(item.destiny.date)}</strong>
                </div>

                {item.section && (
                  <div>
                    Empresa: <strong>{item.client}</strong>
                  </div>
                )}

                {item.section && (
                  <div>
                    Centro de Custo: <strong>{item.section}</strong>
                  </div>
                )}

                {item.approver && (
                  <div style={{}}>
                    Aprovador: <strong>{item.approver}</strong>
                  </div>
                )}
              </Infos>
            </ListItem>
          );
        })}
      </List>

      {paginationProps.items.length === 0 && !loading && (
        <NoContent>
          <h2>Sem resultados de viagens.</h2>
        </NoContent>
      )}

      {paginationProps.items.length > 0 && paginationProps.pagesCount > 1 && !loading &&  (
        <Pagination>
          {/* <pre>
            {JSON.stringify({
              itemsCount: paginationProps.itemsCount,
              pageIndex: paginationProps.pageIndex,
              pagesCount: paginationProps.pagesCount,
              pageSize: paginationProps.pageSize,
              startOfPage: paginationProps.pageSize * paginationProps.pageIndex,
              endOfPage: paginationProps.pageSize * paginationProps.pageIndex + paginationProps.pageSize
            })}
          </pre> */}

          <button
            disabled={paginationProps.pageIndex === 0 || loading}
            onClick={() => goToPreviousPage()}>
            {`Anterior`}
          </button>

          <div>
            <span>
              {`Página ${paginationProps.pageIndex + 1} de ${paginationProps.pagesCount}`}
            </span>
          </div>

          <button
            disabled={paginationProps.pageIndex === paginationProps.pagesCount - 1 || loading}
            onClick={() => goToNextPage()}>
            {`Próximo`}
          </button>
        </Pagination>
      )}
    </>
  );
}

export default Card;
