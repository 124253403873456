import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import LowestFare from '../../Widgets/LowestFare.js';
import WhiteCard from '../../WhiteCard';
import ShowSelects from '~/functions/ShowSelects';
import CompanyPermission from '~/functions/CompanyPermission';


import DashboardContext from '../../../context';

export default function LowestFare7 () {
  const chartId = 7;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);
  const userProfile = useSelector(state => state.user.userProfile);

  const {
    accessToken,
    companiesOptions,
    setCompaniesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCentersOptions,
    setCostCentersOptions,
    costCentersSelected,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [NationalAdoptionData, setNationalAdoptionData] = useState({
    jan: 0,
    fev: 0,
    mar: 0,
    abr: 0,
    mai: 0,
    jun: 0,
    jul: 0,
    ago: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dez: 0
  });

  useEffect(() => {
    async function loadData () {
      try {
        setNationalAdoptionData({
          jan: 0,
          fev: 0,
          mar: 0,
          abr: 0,
          mai: 0,
          jun: 0,
          jul: 0,
          ago: 0,
          set: 0,
          out: 0,
          nov: 0,
          dez: 0
        });

        setLoading(true);

        setLikes(user.preferences.dashboard.chart7);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        const Companies = companiesSelected.length === 0
        ? companiesOptions.map(x => (x.value))
        : companiesSelected.map(x => (x.value));

        const CostCenters = !CompanyPermission(userProfile.userProfileId).all
          ? (
            costCentersSelected.length === 0
              ? costCentersOptions.map(e => (e.value))
              : costCentersSelected.map(e => (e.value))
          )
          : costCentersSelected.map(e => (e.value));

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const mesI = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/shopping/air/agreementsi`,
          {
            handle: Companies,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: CostCenters
          },
          options
        );

        if(mesI.data){
          setNationalAdoptionData({
            jan: mesI.data[0].janInPercent,
            fev: mesI.data[0].fevInPercent,
            mar: mesI.data[0].marInPercent,
            abr: mesI.data[0].aprInPercent,
            mai: mesI.data[0].mayInPercent,
            jun: mesI.data[0].junInPercent,
            jul: mesI.data[0].julInPercent,
            ago: mesI.data[0].augInPercent,
            set: mesI.data[0].sepInPercent,
            out: mesI.data[0].octInPercent,
            nov: mesI.data[0].novInPercent,
            dez: mesI.data[0].decInPercent
          })
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected]);

  return (
    <WhiteCard
      title={'Compra antecipada aéreo internacional'}
      modalTitle='Observação'
      modalDesc='Informação referente a antecedência da solicitação em relação a data da viagem.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <LowestFare data={NationalAdoptionData}/>
    </WhiteCard>
  );
}
