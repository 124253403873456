import React, { useRef, useState, useEffect } from 'react';
import { ApiAxios } from '~/services/api.js';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from '@unform/web';
import { FilterButtons } from './styles';
import TextArea from '~/components/TextArea';
import { Spinner } from 'reactstrap';

function ModalInvoiceReport({
  showModal,
  handleCloseModal,
  invoiceToReport,
}) {
  const [invoice, setInvoice] = useState(null);
  const accessToken = useSelector(state => state.auth.accessToken);
  const axios = ApiAxios();
  const formRef = useRef(null);
  const companies = useSelector(state => state.user.companies);
  const user = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);
  const [bodyMessage, setBodyMessage] = useState('');
  const [messageOk, setMessageOk] = useState('Sua observação será analisada e logo retornaremos por email.');

  const [disableSend, setDisableSend] = useState(false);
  const [colorMessageOk, setColorMessageOk] = useState('#333');
  const [disableClose, setDisableClose] = useState(false);
  const [disableBodyMessage, setDisableBodyMessage] = useState(false);

  const [bodyMessageCount, setBodyMessageCount] = useState(false);
  const [bodyMessageCountColor, setBodyMessageCountColor] = useState('#b00');

  useEffect(() => {
    async function load () {
      return setInvoice(invoiceToReport.original);
    }

    load();
  }, [invoiceToReport]);

  useEffect(() => {
    if (bodyMessage.length < 30 || bodyMessage.length > 140) {
      setBodyMessageCountColor('#b00');

      setDisableSend(true);
    } else {
      setBodyMessageCountColor('#191');

      setDisableSend(false);
    }

    setBodyMessageCount(bodyMessage.length);
  }, [bodyMessage]);

  async function handleSubmit() {
    try {
      setLoading(true);

      setMessageOk('');

      setDisableSend(true);

      setDisableClose(true);

      const filter = {
        desc: bodyMessage,
        name: companies.length === 1 ? companies[0].CodCliente : "",
        email: user.email,
        fatura: invoice.handle,
      }

      const options = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_INVOICE}/invoice/invoiceError`,
        filter,
        options,
      );

      setTimeout(() => {
        if (response.data) {
          setMessageOk('Obrigado pelo feedback! Aguarde enquanto analisamos sua solicitação.');

          setLoading(false);

          setDisableSend(true);

          setDisableClose(false);

          setColorMessageOk('#191');

          setDisableBodyMessage(true);
        } else {
          setDisableSend(false);

          setDisableClose(false);
        }
      }, 1000);

      return setLoading(false);
    } catch (error) {
      setLoading(false);

      return toast.info('Nada foi encontrado, tente novamente');
    }
  }

  return (
    <>
      {invoice !== null && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                Olá! <b>Conte-nos com detalhes o que há de errado com a Fatura {invoice.handle}:</b>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <TextArea
                name="desc"
                //label={`Informe, com detalhes, o que há de errado com a Fatura ${invoice.handle}:`}
                value={bodyMessage}
                onChange={e => {
                  if (e.target.value.length <= 200) {
                    return setBodyMessage(e.target.value)
                  }

                  return false;
                }}
                disabled={disableBodyMessage} />

              <p style={{color: bodyMessageCountColor, width: '100%', textAlign: 'right', fontWeight: 'bold'}}>
                {bodyMessageCount}/140 caracteres
              </p>
            </Modal.Body>

            <Modal.Footer>
              <p style={{color: colorMessageOk}}>
                <b>
                  {messageOk}
                </b>
              </p>

              <FilterButtons>
                <Button
                  variant="btn btn-warning"
                  type="button"
                  onClick={handleCloseModal}
                  disabled={loading || messageOk === '' || disableClose}>
                  Fechar
                </Button>

                <button
                  className={`btn btn-primary ${loading && 'disabled'}`}
                  type="submit"
                  disabled={loading || disableSend}>
                  {loading && <Spinner color="#fff" size="sm" />}

                  {!loading && <>Enviar</>}
                </button>
              </FilterButtons>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
}

export default ModalInvoiceReport;
