import styled from 'styled-components'

export const TitleFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: 4px 8px;
  margin: 0;

  h1 {
    margin: 0;
  }

  i {
    font-size: 16px;
    margin: 0 6px;
  }

  span {
    border-radius: 8px;
    border: none;
    padding: 4px 8px;
  }
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  border: none;
  padding: 0;
  margin: 0 0 8px;

  i {
    color: #ccc;
    font-size: 13px;
    margin: 0 6px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;;
  padding: 0;
  margin: 0;

  @media(max-width: 800px) {
    flex-direction: column;
  }
`;

export const HeaderTitle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
  margin: 0;
`;

export const HeaderNav = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
  margin: 0;
`;

export const HeaderNavButton = styled.button`
  flex: 1 1;
  border: none;
  border-radius: 8px;
  background: ${props => props.selected ? '#7e2dc2' : 'transparent' };
  color: ${props => props.selected ? '#ffffff' : '#222222' };
  font-size: ${props => props.selected ? '14px' : '13px' };
  padding: ${props => props.selected ? '4px 8px' : '0px 0px' };
  font-weight: '700';
`;
