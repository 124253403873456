import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  changeLanguage: ['key', 'text', 'badge'],
});

export const LanguageTypes = Types;
export default Creators;

const INITIAL_STATE = {
  key: 'pt-BR',
  text: 'Português',
  badge: 'br',
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_LANGUAGE]: (_, action) => ({ ...action }),
});
