import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

export default function DaysOutlays({
  data
}) {
    const [chartData11, setChartData11] = useState({});

    useEffect(() => {
      async function loadData () {
        setChartData11({
          labels: [
            'Aéreo',
            'Hotel',
            'Carro',
            'Rodoviário',
            'Outros',
          ],
          datasets: [{
            data: [
              data.tk,
              data.h,
              data.cr,
              data.rd,
              data.ou,
            ],
            backgroundColor: [
              '#00EAE1',
              '#BDBFBF',
              '#95F496',
              '#f4dbab',
              '#7e2ec2',
            ],
            borderColor: 'transparent',
            label: 'Quantidade de Transações'
          }]
        });
      }

      loadData();
    }, [data])

    return (
      <Bar
        data={chartData11}
        options={{
          legend: {
            display: false,
          },
          responsive: true
        }}
      />
    );
}

