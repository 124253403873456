import React, { useState, useEffect, useContext } from 'react';
// import axios from 'axios';
import { ApiAxios } from '~/services/api.js';
import Modal from "react-bootstrap/Modal";
import { useSelector } from 'react-redux';
import { FormatValueBRL } from '~/functions/FormatValue';
import azul from '~/assets/images/azul.png';
import latam from '~/assets/images/latam.png';
import gol from '~/assets/images/gol.png';
import glob from '~/assets/images/glob.png';
import styled from 'styled-components';

import CompanyPermission from '~/functions/CompanyPermission';
import DashboardContext from '../../context';

const OthersCompaniesButton = styled.div`
  transition: opacity 0.4s;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export default function TopCompanies({ data }) {

  const [chartData11, setChartData11] = useState({});
  const [othersCompaniesModalIsVisible, setOthersCompaniesModalIsVisible] = useState(false);
  function handleOpenCompaniesModal() {
    setOthersCompaniesModalIsVisible(true);
  }

  function handleCloseCompaniessModal() {
    setOthersCompaniesModalIsVisible(false);
  }


  if (!data.firstThreeCompanies || !data.rest) {
    return <div></div>
  }
  return (
    <>
      <div style={{ width: '100%', margin: '7% auto', }}>

        {
          data.firstThreeCompanies.map(
            company => {
              return (
                <div className="d-flex align-items-center m-b-15">
                  <div className="w-50 text-truncate">
                    <div className="f-s-14"
                      style={{
                        wordBreak: "break-all",
                        width: 400,
                        maxWidth: "25ch",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >{company.nomeCia}</div>
                    <div className="text-black-lighter f-s-16">
                      {FormatValueBRL(company.total)}
                    </div>
                  </div>
                  <div className="ml-auto text-center">
                    <div className="f-s-16">
                      <span data-animation="number" data-value="0">
                        {company.quant_Tickets}
                      </span>
                    </div>
                    <div className="f-s-13">Tickets</div>
                  </div>
                  <div className="ml-auto text-center">
                    <div className="f-s-16">
                      <span data-animation="number" data-value="0">
                        {FormatValueBRL(company.media)}
                      </span>
                    </div>
                    <div className="f-s-13">Tarifa média</div>
                  </div>
                </div>
              );
            }
          )
        }

        {
        data.rest.restSumValues.total > 0 && (<OthersCompaniesButton className="d-flex align-items-center m-b-15" onClick={handleOpenCompaniesModal}>
          <div className="w-50 text-truncate">
            <div className="f-s-16">Outras</div>
            <div className="text-black-lighter f-s-16">
              {FormatValueBRL(data.rest.restSumValues.total)}
            </div>
          </div>
          <div className="ml-auto text-center">
            <div className="f-s-16">
              <span data-animation="number" data-value="0" >
                {data.rest.restSumValues.tickets !== null ? data.rest.restSumValues.tickets : 0}
              </span>
            </div>
            <div className="f-s-13">Tickets</div>
          </div>
          {
            data.rest.count > 0 && (<div className="ml-auto text-center">
              <div className="f-s-16">
                <span data-animation="number" data-value="0">
                  {FormatValueBRL(data.rest.restSumValues.media / data.rest.count)}
                </span>
              </div>

              <div className="f-s-13">Tarifa média</div>
            </div>)}
        </OthersCompaniesButton>)

        }


        {
          data.rest.restArray.length > 0 &&
          <Modal Modal show={othersCompaniesModalIsVisible} onHide={handleCloseCompaniessModal} size="lg" he>
            <Modal.Header closeButton>
              <Modal.Title>Outras Companhias</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                data.rest.restArray?.map(
                  company => {
                    return (
                      <div className="d-flex align-items-center m-b-15">
                        <div className="w-50 text-truncate">
                          <div className="f-s-16"
                            style={{
                              wordBreak: "break-all",
                              width: 400,
                              maxWidth: "40ch",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}>{company.nomeCia}</div>
                          <div className="text-black-lighter f-s-16">
                            {FormatValueBRL(company.total)}
                          </div>
                        </div>
                        <div className="ml-auto text-center">
                          <div className="f-s-16">
                            <span data-animation="number" data-value="0">
                              {company.quant_Tickets}
                            </span>
                          </div>
                          <div className="f-s-13">Tickets</div>
                        </div>
                        <div className="ml-auto text-center">
                          <div className="f-s-16">
                            <span data-animation="number" data-value="0">
                              {FormatValueBRL(company.media)}
                            </span>
                          </div>
                          <div className="f-s-13">Tarifa média</div>
                        </div>
                      </div>
                    );
                  }
                )
              }
            </Modal.Body>
          </Modal>
        }

      </div>
    </>
  );
}
