import styled from "styled-components";
import { FormGroup } from 'reactstrap';

export const Label = styled.label`
  margin: 2px 4px;
  font-weight: 600;
`;

export const Group = styled(FormGroup)`
  padding: -5px;
  margin-bottom: 10px;
  height: 100%;
`;

export const TextAreaV = styled.textarea`
  min-height: 80px !important;
  border: 1px solid #ccc;
`;
