import React from 'react';
import styled from "styled-components";
import { FormatValueBRL } from '~/functions/FormatValue';

export const CellLogo = styled.img`
  border-radius: 5px;
`;

export const columns = [
  {
    default: true,
    Header: 'Emissão',
    accessor: 'dataEmissao',
    width: 120,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Data da Viagem',
    accessor: 'dataViagem',
     width: 100,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Cia',
    accessor: 'cia',
     width: 100,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Localizador',
    accessor: 'locali',
     width: 100,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Empresa',
    accessor: 'cliente',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Viajante',
    accessor: 'passageiro',
     width: 120,
    show: true,
    Cell: ({row}) => {
      let nome = row.original.passageiro.toUpperCase().split('/').join(' / ');

      return (<span>{nome}</span>);
    },
    style: { textAlign: 'center',
    paddingLeft: '20px',
    whiteSpace: 'pre-wrap' },
  },
  {
    default: true,
    Header: 'OS',
    accessor: 'numOS',
     width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Venda',
    accessor: 'id',
    width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Centro de Custo',
    accessor: 'centro',
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Status',
    accessor: 'tipo',
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Total',
    accessor: 'valor',
     width: 80,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.valor)}
      </span>;
    }
  },
];
