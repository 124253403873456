import React from 'react';

const Row = ({ line }) => {
   const keys = Object.keys(line)
    return (
        <tr key={line.id}>
            {
                    keys.map(key => <td style={{paddingLeft: 10}} style={{textAlign: 'center'}} key={key}>{line[key]}</td>)
            }
        </tr>
    ) 
} 

const Table = ({data}) =>{
    //const keys = Object.keys(data[0])
    return (
        <table>
            <thead>
                <tr>
                    <th style={{textAlign: 'center'}}>Data</th>
                    <th style={{paddingLeft: 10}}>Qtd. de Acessos</th>
                    <th style={{paddingLeft: 10}}>Valor</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map(line => <Row line={line}/>) 
                } 
            </tbody> 
        </table> 
    )
}

export default Table