import {
  useDispatch,
  useSelector
} from 'react-redux';

import AuthActions from '~/store/modules/ducks/auth';

import axios from 'axios';

import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_AUTH,
  timeout: 40000,
});

const apiDefault = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_INVOICE,
  timeout: 40000,
});

const ApiAxios = () => {
  const accessToken = useSelector(state => state.auth.accessToken);

  const dispatch = useDispatch();

  axios.defaults.headers.Authorization = `Bearer ${accessToken}`;

  axios.defaults.headers.ContentType = `application/json`;

  axios.get(
    `${process.env.REACT_APP_ENDPOINT_AUTH}/auth`
  ).then(() => {
    return true;
  }).catch(() => {
    setTimeout(() => {
      toast.dismiss();

      return dispatch(AuthActions.signOut());
    }, 2000);
  });

  return axios;
};

export {
  api,
  apiDefault,
  ApiAxios,
};
