import React from 'react';
import { Link } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PageSettings from '~/config/PageSettings';
import SidebarProfile from './Profile';
import SidebarNav from './Nav';

export default function Sidebar() {
  return (
    <PageSettings.Consumer>
      {pageSettings => (
        <>
          <div
            id="sidebar"
            className={`
              sidebar
              ${
                pageSettings.pageSidebarTransparent
                  ? 'sidebar-transparent '
                  : ''
              }
            `}
            style={{
              margin: '20px 0px'
            }}>
            <PerfectScrollbar
              className="height-full"
              options={{
                suppressScrollX: true
              }}>
              <SidebarProfile />

              <br />

              <SidebarNav pageSettings={pageSettings} />

              <Link
                to="#"
                className="sidebar-minify-btn"
                onClick={pageSettings.toggleSidebarMinify}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '4px',
                  width: '100%',
                  textDecoration: 'none'
                }}>
                <i className="fa fa-angle-double-left" />
              </Link>
            </PerfectScrollbar>
          </div>

          <div className="sidebar-bg" />

          <div
            className="sidebar-mobile-dismiss"
            onClick={pageSettings.toggleMobileSidebar}
          />
        </>
      )}
    </PageSettings.Consumer>
  );
}
