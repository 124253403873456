import React, { useContext, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { Form } from "@unform/web";
import { Row, Col, Spinner } from "reactstrap";
import * as Yup from "yup";
import BudgetManagement from "../../context";
import { Panel, PanelHeader, PanelBody } from "~/components/Panel";
import { FilterButtons } from "./styles";
import Select from "~/components/Select";
import CompanySelect from "~/components/CompanySelect";
import ShowSelects from '~/functions/ShowSelects';
import Input from "~/components/Input";
import { FormatValueBRL, GetCurrencyValue, FormatInputValue } from '~/functions/FormatValue';
import Button from 'react-bootstrap/Button';

const validatorSchema = Yup.object().shape({
  request_id: Yup.string(),
  localizer: Yup.string(),
  ticket: Yup.string(),
  passenger: Yup.string(),
});

export default function Filters() {
  const userProfile = useSelector(state => state.user.userProfile);
  const {
    companiesSelected,
    getBudget,
    getBudgetAnalytics,
    companiesOptions,
    setCompaniesSelected,
    costCentersSelected,
    setCostCentersSelected,
    costCentersOptions,
    analytics,
    loading,
    formRef,
    handleResetForm,
  } = useContext(BudgetManagement);


  async function handleSubmit(data) {
    if (analytics)
      getBudgetAnalytics();
    else
      getBudget();
  }

  return (
    <Panel theme="inverse">
      <PanelHeader noButton title="Filtros"  />

      <PanelBody>
        <Form
          ref={formRef}
          validationSchema={validatorSchema}
          onSubmit={handleSubmit}>
          <Row>
            {/* {analytics && (
              <Col sm={12} md={6} lg={4} xl={3}>
                <Select
                  name="month"
                  label="Mês"
                  placeholder="Selecione"
                  defaultValue={{ value: 1, label: "Janeiro" }}
                  options={monthsArraySearch}
                  onChange={({ value }) => setActualMonth(value)}
                />
              </Col>
            )} */}

            {ShowSelects(userProfile.userProfileId).companies && (
              <Col sm={12} md={6} lg={4}>
                <CompanySelect
                  name="CompanyHandle"
                  label="Empresas"
                  placeholder="Empresas"
                  value={companiesSelected}
                  options={companiesOptions}
                  setCompaniesSelected={setCompaniesSelected}
                  isMulti={false}
                />
              </Col>
            )}

            {analytics && (
              <Col sm={12} md={6} lg={4}>
                <CompanySelect
                  name="costCenter"
                  label="Centros de Custo"
                  placeholder="Centros de Custo"
                  value={costCentersSelected}
                  options={costCentersOptions}
                  setCompaniesSelected={setCostCentersSelected}
                  isMulti={false}
                />
              </Col>
            )}

            {!analytics && (
              <Col sm={12} md={6} lg={4}>
                <CompanySelect
                  name="costCenter"
                  label="Centros de Custo"
                  placeholder="Centros de Custo"
                  options={costCentersOptions}
                  setCompaniesSelected={setCostCentersSelected}
                  isMulti={true}
                />
              </Col>
            )}

            <Col sm={12}>
              <FilterButtons>
                <Button
                  className="btn btn-warning"
                  type="button"
                  onClick={handleResetForm}
                  disabled={loading}>
                  Limpar
                </Button>

                <Button
                  className={`btn btn-primary`}
                  type="submit"
                  disabled={loading || costCentersOptions.length === 0}>
                  {loading && <Spinner color="#fff" size="sm" />}

                  {!loading && 'Aplicar'}
                </Button>
              </FilterButtons>
            </Col>
          </Row>
        </Form>
      </PanelBody>
    </Panel>
  );
}
