import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { ApiAxios } from '~/services/api.js';

import {
  Spinner
} from 'reactstrap';

import {
  Card,
  CardTitle,
  CardBody,
  CardBodyItem,
} from '~/styles/card';

import {
  CancelDeleteAction,
} from '~/components/ActionsOfTables';
import { Row, Col } from 'reactstrap';

function Delete({
  company,
  managerToDelete,
  setManagerPage
 }) {
  const axios = ApiAxios();
  const [loading, setLoading] = useState(false);

  async function handleDelete () {
    try {
      setLoading(true);

      const managerDeleteResponse = await axios.delete(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/deleteclientmanager`,
        {
          data: {
            Email: managerToDelete.manager.email
          }
        }
      );

      toast.success(
        "Gestor removido com sucesso!"
      );

      setLoading(false);

      return setManagerPage('list');
    } catch (error) {
      setLoading(false);

      toast.warning("Não foi possível remover o Gestor.");

      return false;
    }
  }

  return <Row>
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardTitle>
          <h5>
            {managerToDelete.type === 1 && ('Gestor de Centro de Custo')}
            {managerToDelete.type === 2 && ('Gestor de Viagens')}
            {managerToDelete.type === 3 && ('Gestor Geral da Empresa')}
          </h5>
        </CardTitle>

        <CardBody>
          <CardBodyItem>
            <div>
              <p><b>Empresa:</b> {company.name}</p>

              <p><b>Nome do Gestor:</b> {managerToDelete.manager.name}</p>

              <p><b>Email:</b> {managerToDelete.manager.email}</p>

              <p>
                <CancelDeleteAction
                  type='button'
                  onClick={() => handleDelete()}>
                  {loading && <Spinner color="#fff" size="sm" />}

                  {!loading && 'Remover Gestor'}
                </CancelDeleteAction>
              </p>
            </div>
          </CardBodyItem>
        </CardBody>
      </Card>
    </Col>
  </Row>;
}

export default Delete;
