import React, {useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';

export default function VolumePurchase({
  data
}) {
  const [chartData3, setChartData3] = useState({});

  function showValue(section){
    switch (section) {
      case "Nacional": return data.valueNacional
      case "Internacional": return data.valueInternational
      default: return data.valueNacional
    }
  }

  useEffect(() => {
    async function loadData () {
      setChartData3 (
        {
          labels: [
            'Internacional',
            'Nacional'
          ],
          datasets: [{
            data: [
              data.international,
              data.nacional
            ],
            backgroundColor: [
              '#BDBFBF',
              '#00EAE1'
            ],
            borderWidth: 2
          }]
        }
      );
    }
    loadData();
  }, [data])

  return (
    <>
      <Pie
        data={chartData3}
        options={
          {
            responsive: true,
            tooltips: {
              enabled: true,
              callbacks: {
                label: function(tooltipItem, data) {
                  const value = showValue(data.labels[tooltipItem.index]);
                  return ` ${data.labels[tooltipItem.index]}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}% / ${value}`;
                }
              }
            }
          }
        }
      />
    </>
  );
}
