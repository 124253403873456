import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  signInRequest: ['email', 'password', 'pageid', 'webview'],
  signInSuccess: ['accessToken', 'user', 'agencySetting'],
  signOut: null,
  signFailure: null,
});

export const AuthTypes = Types;
export default Creators;

const INITIAL_STATE = {
  accessToken: null,
  signed: false,
  loading: false,
  webview: false,
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: state => ({
    ...state,
    signed: false,
    loading: true,
  }),

  [Types.SIGN_IN_SUCCESS]: (state, { accessToken }) => ({
    ...state,
    accessToken,
    signed: true,
    loading: false,
  }),

  [Types.SIGN_FAILURE]: state => ({
    ...state,
    loading: false,
    accessToken: null,
    signed: false,
  }),

  [Types.SIGN_OUT]: () => ({
    accessToken: null,
    signed: false,
    loading: false,
  }),
});
