import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import Input from '~/components/Input';
import { Container, Content, Button, LinkBack } from './styles';

import { api } from '~/services/api';
import history from '~/services/history';

import logo from '~/assets/images/weegestao_vertical2.png';

export default function RequestRecoveryPassword() {
  const formRef = useRef();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [validToken, setValidToken] = useState(false);

  async function handleSubmit({ password, confirmPassword }) {
    try {
      if (!password || !confirmPassword)
        return toast.error('Você deve preencher todos os campos.');

      if (password !== confirmPassword)
        return toast.error('As senhas não correspondem.');

      if (
        password.length < 8 ||
        password.length > 12 ||
        !password.match(/\d/g) ||
        !password.match(/[a-z]/gi)
      )
        return toast.error(
          'A senha deve possuir de 8 a 12 caracteres, contendo obrigatoriamente letras e números.'
        );

      setLoading(true);

      const [, token] = location.pathname.split('/').filter(x => x !== '');

      await api.put(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/forgotPassword?token=${token}`,
        { password }

      );

      toast.success('Sua senha foi alterada com sucesso!');

      setLoading(false);

      return history.push('/');
    } catch (error) {
      setLoading(false);

      return toast.error(
        'Não foi possivel redefinir sua senha, tente novamente mais tarde.'
      );
    }
  }

  useEffect(() => {
    async function validateToken(token) {
      try {
        const response = await api.get('auth/user/forgotPassword/check', {
          params: { token },
        });

        setValidToken(response.data);
      } catch (error) {
        toast.info("Link expirado! Solicite novamente.");

        history.push('/forgot-password');
      }
    }

    if (location) {
      const [, token] = location.pathname.split('/').filter(x => x !== '');

      validateToken(token);
    }
  }, [location, validToken]);

  return (
    <Container>
      <Content>
        <section>
          <img src={logo} alt="iGestor" />

          <h1>Alteração de senha</h1>
          <p>
            Para sua segurança, a senha deve <br/>
            possuir de 8 a 12 caracteres, contendo <br/> obrigatoriamente letras e números.
          </p>

          <LinkBack className="back-link" to="/">
            <FiArrowLeft size={16} color="#fff" />
            Voltar
          </LinkBack>
        </section>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="password" placeholder="Nova senha" type="password" />
          <Input
            name="confirmPassword"
            placeholder="Confirme sua senha"
            type="password"
          />

          <Button
            className="button"
            type="submit"
            disabled={!loading && !validToken}
            validate={!validToken}
          >
            {loading ? <Spinner color="#FFFF" /> : 'Alterar senha'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
}
