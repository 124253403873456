import React, { useState, useContext, useEffect } from "react";
import { Row, Col } from 'reactstrap';
import ReactTable from '~/components/Table';
import { Panel, PanelHeader } from "~/components/Panel";

import RequestsContext from "../../context";
import { columns, parseFormatDate,parseExternalFields } from "../../constants";
import TableDetails from "./TableDetails";

import ReactExport from 'react-export-excel';
import { ColumnsTableMain, ColumnsTitleMain, ButtonCheckColumns } from './styles';


import { FormatValueBRL } from '~/functions/FormatValue.js';

import moment from "moment";

export default function Table() {

  const { data, loadingOrders } = useContext(RequestsContext);
  const [tableColumns, setTableColumns] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState(true);
  const [todos, setTodos] = useState(false);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [columnsToExcel, setColumnsToExcel] = useState([]);
  const [dataToExcel, setDataToExcel] = useState([]);

  useEffect(() => {
    async function loadDataToExcel() {
      var newData = [];

      data.map(e => {
        newData.push({
          ...e,
          created: moment(e.created).format("DD/MM/YYYY"),
          first_approver: `${e.first_approver_name} ${e.first_approver_middle_name} ${e.first_approver_last_name}`,
          first_approver_date: e.first_approve_date ? parseFormatDate(e.first_approve_date) : "",
          second_approver: `${e.second_approver_name} ${e.second_approver_middle_name} ${e.second_approver_last_name}`,
          second_approver_date: e.second_approve_date ? parseFormatDate(e.second_approve_date) : "",
          third_approver: `${e.third_approver_name} ${e.third_approver_middle_name} ${e.third_approver_last_name}`,
          third_approver_date: e.third_approve_date ? parseFormatDate(e.third_approve_date) : "",
          last_change_date: moment(e.last_change_date).format("DD/MM/YYYY"),
          valor_total: FormatValueBRL(Number(e.valor_total)),
          filial_intercia: parseExternalFields(e.externalfields).filial_intercia,
          projeto: parseExternalFields(e.externalfields).projeto,
          atividade: parseExternalFields(e.externalfields).atividades,
          regiao: parseExternalFields(e.externalfields).regiao,
          cargo: parseExternalFields(e.externalfields).cargo,
          diaria: parseExternalFields(e.externalfields).diaria,
        })
      });

      return setDataToExcel(newData);
    }

    loadDataToExcel();
  }, [data]);

  useEffect(() => {
    async function loadColumnsToExcel() {
      let newColumns = [];

       tableColumns.map(e => {
         if (e.show) {
           newColumns.push(e);
         }
       });

      return setColumnsToExcel(newColumns);
    }

    loadColumnsToExcel();
  }, [tableColumns]);

  useEffect(() => {
    function updateColumns() {
      return setTableColumns(columns.map(e => ({...e, show: true})));
    }

    updateColumns();
  }, [columns]);

  useEffect(() => {
    function updateTodos() {
      setTodos(true);

      tableColumns.map((e) => {
        if (e.show === false) {
          return setTodos(false);
        }

        return true;
      });
    }

    updateTodos();
  }, [tableColumns]);

  function changeColumn2(e) {
    if (e === 'all') {
      setTodos(!todos);

      let newColumns = tableColumns.map((c) => {
        c.show = !todos;
        return c;
      });

      return setTableColumns(newColumns);
    }

    setTodos(false);

    let newColumns = tableColumns.map((c) => {
      if (c.accessor === e) {
        c.show = !c.show;
      }
      return c;
    });

    return setTableColumns(newColumns);
  }


  return (
    <>
      <Panel theme="inverse">
        <PanelHeader noButton>
        <ColumnsTitleMain>
          <div>
            <span>
              {!hiddenColumns ? 'Selecione as colunas desejadas:' : 'Resultado da Pesquisa'}
            </span>
            {(!hiddenColumns) && (
              <>
                <input
                  type="checkbox"
                  defaultChecked={todos}
                  checked={todos}
                  value={'all'}
                  onChange={({ target }) => changeColumn2(target.value)}
                />
                <span>Todos</span>
              </>
            )}
          </div>

          <div>
            <ButtonCheckColumns
              type='button'
              onClick={() => {
                return setHiddenColumns(!hiddenColumns);
              }}>
              {hiddenColumns ? 'Escolher Colunas' : (<i className="fa fa-caret-up fa-1x"></i>)}
            </ButtonCheckColumns>

            <ExcelFile
              element={
                <ButtonCheckColumns
                  type="button"
                  onClick={() => { }}
                  disabled={data.length === 0 || tableColumns.length === 0}>
                  Exportar
                </ButtonCheckColumns>
              }
              filename={`Solicitações de Viagens`}>
              <ExcelSheet data={dataToExcel} name="Solicitações">
                {columnsToExcel.map((e, i) => (
                  <ExcelColumn label={e.Header} value={e.accessor} key={i} />
                ))}
              </ExcelSheet>
            </ExcelFile>
          </div>
        </ColumnsTitleMain>

        <ColumnsTableMain hidden={hiddenColumns}>
          <Row>{
            tableColumns.map((c, i) =>
            (<Col sm={3} md={3} lg={4} xl={3} key={i}>
              <input
                type="checkbox"
                defaultChecked={c.show}
                checked={c.show}
                value={c.accessor}
                onChange={({ target }) => changeColumn2(target.value)}
              />

              <span>{c.Header}</span>
            </Col>)
            )}</Row>
        </ColumnsTableMain>
      </PanelHeader>

        <ReactTable
          columns={tableColumns.filter(e => e.show === true)}
          data={data}
          tableProps={{
            defaultPageSize: 10,
            hasFooter: false,
            hasPagination: true,
            loading: loadingOrders,
            resizable: true
          }}
          tableStyle={{
            fontSize: '13px',
            cellPadding: '6px 4px'
          }}
          tableLabels={{
            loadingText: 'Carregando...',
            noDataText: 'Sem registros',
            nextButtonText: 'Próximo',
            previousButtonText: 'Anterior',
            pageOfText: 'de',
            pageSizeSelectText: 'registros',
            pageText: 'Página'
          }}
          subComponent={e => {

            return (
              <div style={{
                width: '200px'
              }}>
                <b>Passageiros:</b>
                <ul>
                  {e.row.original.travelers.map(x => (
                    <li>{x.fullName}</li>
                  ))}
                </ul>
              </div>
            );

            // console.log(e);

            // return (
            //   <TableDetails original = {e.row.original}/>
            // );
          }}
        />
      </Panel>
    </>
  );
}
