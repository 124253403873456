import React, { useState, useEffect } from 'react';
import { PanelHeader } from '~/components/Panel';
import { Row, Col } from 'reactstrap';

import {
  Submit
} from '~/styles/buttons/defaults';

import ExportSpreadSheet from '~/components/ExportSpreadSheet';
import { FormatValueBRL } from '~/functions/FormatValue';

function Header({
  data,
  original
}) {
  const [dataToExcel, setDataToExcel] = useState([]);

  useEffect(() => {
    async function loadDataToExcel () {
      let newData = [];

      newData.push({
        c1: original.status,
        c2: original.handle,
        c3: original.dataVencimentoFormatada,
        c4: FormatValueBRL(original.valorFatura),
        c5: FormatValueBRL(original.valorPago),
      });

      newData.push({c1: ''});

      if (data.length > 0) {
        newData.push({
          c1: 'Emissão',
          c2: 'Referência',
          c3: 'OS',
          c4: 'Serviço',
          c5: 'Fornecedor',
          c6: 'Viajante',
          c7: 'Centro de Custo',
          c8: 'Valor do Item',
          c9: '',
          c10: '',
          c11: '',
          c12: '',
          c13: '',
          c14: '',
        });

        newData.push({c1: ''});

        data.map(e => {
          newData.push({
            c1: e.dataFormatada,
            c2: e.handle,
            c3: e.numOS,
            c4: e.tipoFaturamento,
            c5: e.fornecedor,
            c6: e.pax,
            c7: e.centroCusto,
            c8: FormatValueBRL(e.valorTotalDoDocumento),
          });
        });
      }

      return setDataToExcel(newData);
    }

    loadDataToExcel();
  }, [data]);

  return (
    <PanelHeader
      noButton
      title=""
      children={
        <Row>
          <Col sm={12} md={12} lg={12} style={{textAlign: 'left'}}>
            Pedidos
          </Col>

          {/* <Col sm={12} md={5} style={{textAlign: 'right'}}>
            {dataToExcel.length > 0 && (
              <ExportSpreadSheet
                data={dataToExcel}
                columns={[
                  {label: 'Status', value: 'c1'},
                  {label: 'N° Fatura', value: 'c2'},
                  {label: 'Vencimento', value: 'c3'},
                  {label: 'Valor Fatura', value: 'c4'},
                  {label: 'Valor Pago', value: 'c5'},
                  {label: '', value: 'c6'},
                  {label: '', value: 'c7'},
                  {label: '', value: 'c8'},
                  {label: '', value: 'c9'},
                  {label: '', value: 'c10'},
                  {label: '', value: 'c11'},
                  {label: '', value: 'c12'},
                  {label: '', value: 'c13'},
                  {label: '', value: 'c14'},
                ]}
                element={
                  <Submit type='button'>
                    Exportar
                  </Submit>
                }
                filename={`FAT-${original.handle}-Analítico`}
                sheetName={'Detalhes da Fatura'} />
            )}
          </Col> */}
        </Row>
      }
    />
  );
}

export default Header;
