import React from 'react';
import PropTypes from 'prop-types';

import PageSettings from '~/config/PageSettings';

export default function Content({ children }) {
  return (
    <PageSettings.Consumer>
      {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
        <div
          className={`content ${
            pageContentFullWidth ? 'content-full-width ' : ''
          }${
            pageContentInverseMode ? 'content-inverse-mode ' : ''
          }${pageContentClass}`}
        >
          {children}
        </div>
      )}
    </PageSettings.Consumer>
  );
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
};
