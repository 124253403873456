import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Section,
  Header,
  Title,
  Iframe,
  IframeHolder,
  DetailsHolder,
  DetailsList,
  DetailsItem,
  Modal,
  ModalClose,
  ModalBox,
  ModalContent,
  ModalTextarea,
  ModalButton,
  ModalTitle,
  HeaderPaxList,
} from "./styles";
import GoogleMapReact from "google-map-react";
import { IoIosAirplane, IoMdArrowBack } from "react-icons/io";
import SkeletonCard from "~/components/Skeleton";
import Input from "~/components/Input";
import { ApiAxios } from "~/services/api.js";
import { Form } from "@unform/web";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGroup } from "reactstrap";
import { useSelector } from "react-redux";

import { api } from "~/services/api";

import { getFirstTwoNames } from "../TravelerList";

import moment from "moment";

export const parseDate = (dateTime) => {
  const date = moment(dateTime).format("DD/MM/YYYY");
  const time = moment(dateTime).format("HH:mm");

  return `${date} às ${time}`;
};

export default function TravelerUser({ match }) {
  const formRef = useRef(null);
  const history = useHistory();

  const { flightIDSale, flightNumber, flightDateOrigin, flightStatus } = match.params;

  const accessToken = useSelector((state) => state.auth.accessToken);
  const companies = useSelector((state) => state.user.companies);
  const user = useSelector((state) => state.user.profile);
  const userProfile = useSelector(state => state.user.userProfile);
  const [modalSendMessage, toggleModalSendMessage] = useState(false);
  const [modalSetContact, toggleModalSetContact] = useState(false);
  const [flightData, setFlightData] = useState(null);
  const [userSelectedForModal, setUserSelectedForModal] = useState({
    name: "",
    index: "",
  });
  const [paxsWhatsapp, setPaxsWhatsapp] = useState([]);
  const axios = ApiAxios();
  const [loading, setLoading] = useState(true);

  const [paxWhatsAppNumber, setPaxWhatsAppNumber] = useState([]);
  const [allPaxWhatsappNumbers, setAllPaxWhatsappNumbers] = useState([]);

  const [messageToSend, setMessageToSend] = useState("");
  const [
    isFetchingHandleRecordWhatsapp,
    setIsFetchingHandleRecordWhatsapp,
  ] = useState(false);
  const [wpp, setWpp] = useState("");

  const [auxPaxRecorded, setAuxPaxRecorded] = useState([]);

  // const sendWhatsApp = async (paxToSend) => {
  //   try {
  //     const filter = {
  //       phoneNumbers: paxToSend.map(({ wpp }) => wpp),
  //       message: messageToSend,
  //     };

  //     const options = {
  //       headers: { Authorization: `Bearer ${accessToken}` },
  //     };

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_ENDPOINT_INDICATORS}/flights/send/twilio-whatsapp/pax`,
  //       filter,
  //       options
  //     );

  //     setMessageToSend("");
  //     toast.success("Mensagem Enviada com sucesso.");
  //     return response;
  //   } catch (error) {
  //     return toast.error(
  //       "Não foi possível enviar a mensagem. Tente mais tarde."
  //     );
  //   }
  // };

  // useEffect(() => {
  //   const timing = setTimeout(() => {
  //     setLoading(false)
  //   }, 1500);
  //   return () => clearTimeout(timing)
  // }, []);

  const closeModal = (target, toogleModal) => {
    if (target.id == "modal-background") return toogleModal(false);
  };

  const handleGoogleMapApi = (google, path) => {
    var flightPath = new google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor: "#5d2291",
      strokeOpacity: 1,
      strokeWeight: 4,
    });

    flightPath.setMap(google.map);
  };

  const getThePathCoordinates = (
    originLat,
    originLng,
    destinyLat,
    destinyLng
  ) => {
    return [
      { lat: parseFloat(originLat), lng: parseFloat(originLng) },
      { lat: parseFloat(destinyLat), lng: parseFloat(destinyLng) },
    ];
  };

  const allPaxsHaveWhatsapp = () => {
    // this algorithm will return true with all pax have a
    // whatsapp number recorded on object

    return true;
  };

  const getFlightData = useCallback(async () => {
    try {
      setLoading(true);

      const options = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      const { data } = await api.post(
        `${process.env.REACT_APP_ENDPOINT_INDICATORS}/flights/flights-data`,
        { flightIDSale, flightNumber, flightDateOrigin},
        options
      );

      setFlightData(data[0]);

      setAllPaxWhatsappNumbers(
        data[0]?.paxs
          .map((pax) => {
            if (pax.whatsapp[0]?.phone) {
              return { name: pax.paxName, wpp: pax.whatsapp[0].phone };
            }
          })
          .filter(Boolean)
      );

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getFlightData();
  }, [auxPaxRecorded]);

  // fetch conditional
  if (!flightData) {
    return true;
  }

  const dateOrigin = parseDate(flightData.origin.date);
  const locationOrigin = `${
    flightData.origin.name
  } (${flightData.origin.airportCode.trim()})`;
  const dateDestiny = parseDate(flightData.destiny.date);
  const locationDestinty = `${
    flightData.destiny.name
  } (${flightData.destiny.airportCode.trim()})`;

  const PHONEMask = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/^0/, "")
      .replace(/^(\d\d)(\d{4})(\d{4}).*/, "($1) $2-$3");
  };

  const RenderPaxDetail = ({ pax, index }) => {
    // useEffect(() => {
    //   // const filter = {
    //   //   name: getFirstTwoNames(pax.paxName)
    //   // }
    //   // async function loadPaxWhatsApp () {
    //   //   try {
    //   //     const { data } = await axios.post(
    //   //       `${process.env.REACT_APP_ENDPOINT_INDICATORS}/flights/getwppnumber`,
    //   //       filter
    //   //     );

    //   //     return setPaxWhatsAppNumber(data);
    //   //   } catch (error) {
    //   //     return console.log('Não foi possível encontrar o WhatsApp do passageiro.')
    //   //   }
    //   // }

    //   if(teste){
    //     loadPaxWhatsApp();
    //     setTeste(false);
    //   }
    // }, [pax]);

    const [whatsapp, setWhatsapp] = useState("");
    async function handleSubmit(values) {
      if (values.phone.length < 14) return;
      try {
        setIsFetchingHandleRecordWhatsapp(true);
        const filter = {
          pax: getFirstTwoNames(userSelectedForModal.name),
          phone: values.phone,
          email: user.email,
          // Id: userProfile.userProfileId,
          companies: companies.map((x) => x.codCliente),
        };
        toggleModalSetContact(false);
        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/flights/contactnumber`,
          filter,
          options
        );

        setIsFetchingHandleRecordWhatsapp(false);

        setAuxPaxRecorded([
          ...auxPaxRecorded,
          getFirstTwoNames(userSelectedForModal.name),
        ]);
        // setPaxWhatsAppNumber([...paxWhatsAppNumber, { name: getFirstTwoNames(userSelectedForModal.name), wpp: values.phone }]);
        setAllPaxWhatsappNumbers([
          ...allPaxWhatsappNumbers,
          {
            name: getFirstTwoNames(userSelectedForModal.name),
            wpp: values.phone,
          },
        ]);

        toast.success("Passageiro cadastrado com sucesso");
        return response;
      } catch (error) {
        setIsFetchingHandleRecordWhatsapp(false);
        toast.error("Não foi possível aplicar os filtros");
      }
    }

    return (
      <>
        {loading && <SkeletonCard heigth={`100%`} width={`100%`} />}
        {!loading && (
          <Section key={index}>
            <div className="mb-4">
              <Title small>
                <strong>{getFirstTwoNames(pax.paxName)}</strong>
              </Title>
            </div>
            <DetailsHolder>
              <DetailsList>
                <DetailsItem>
                  <strong>Aprovador</strong>
                  <br />
                  {pax.approver}
                </DetailsItem>
                <DetailsItem>
                  <strong>Centro de Custo</strong>
                  <br />
                  {pax.coustCenter}
                </DetailsItem>
                <DetailsItem>
                  <strong>Projeto</strong>
                  <br />
                  {pax.project}
                </DetailsItem>
                <DetailsItem>
                  <strong>Localizador</strong>
                  <br />
                  {pax.locator}
                </DetailsItem>
                <DetailsItem>
                  <strong>Motivo</strong>
                  <br />
                  {pax.reason}
                </DetailsItem>
                {pax.whatsapp[0]?.phone ||
                auxPaxRecorded.some((actualPax) => actualPax === pax.paxName) ||
                allPaxWhatsappNumbers.some(
                  (actualPax) => actualPax.name === pax.paxName
                )// ? (
                  // <DetailsItem
                  //   whatsapp
                  //   onClick={() => {
                  //     setPaxWhatsAppNumber([
                  //       {
                  //         name: getFirstTwoNames(pax.paxName),
                  //         wpp: pax.whatsapp[0]?.phone,
                  //       },
                  //     ]);
                  //     toggleModalSendMessage(true);
                  //   }}
                  // >
                  //   <strong>Enviar mensagem</strong>
                  //   <br />
                  // </DetailsItem>
                // ) : (
                  // <DetailsItem
                  //   whatsapp
                  //   onClick={() => {
                  //     toggleModalSetContact(true);
                  //     setUserSelectedForModal({
                  //       name: getFirstTwoNames(pax.paxName),
                  //       index,
                  //     });
                  //   }}
                  // >
                  //   {/* <strong>Cadastrar Whatsapp</strong> */}
                  //   <br />
                  //   {isFetchingHandleRecordWhatsapp &&
                  //     userSelectedForModal.name ===
                  //       getFirstTwoNames(pax.paxName) && (
                  //       <i className="fas fa-circle-notch fa-spin" />
                  //     )}
                  // </DetailsItem>
               // )
              }
              </DetailsList>
            </DetailsHolder>

            {/* <Modal
              visible={modalSetContact}
              id="modal-background"
              onClick={({ target }) =>
                closeModal(target, toggleModalSetContact)
              }
            >
              <Form
                ref={formRef}
                onSubmit={(values) =>
                  handleSubmit({
                    ...values,
                    name: userSelectedForModal.name,
                    indexList: userSelectedForModal.index,
                  })
                }
              >
                <ModalBox insertWhatsapp>
                  <span
                    className="modal-close"
                    onClick={() => toggleModalSetContact(false)}
                  ></span>
                  <ModalContent>
                    <ModalTitle>
                      Digite o número do whatsapp para{" "}
                      {userSelectedForModal.name} sem o 9.
                    </ModalTitle>
                    <FormGroup>
                      <Input
                        placeholder="(00) 0000-0000"
                        name="phone"
                        insertWhatsapp
                        value={whatsapp}
                        onChange={({ target }) =>
                          setWhatsapp(PHONEMask(target.value))
                        }
                      />
                    </FormGroup>
                    <ModalButton type="submit">Cadastrar</ModalButton>
                  </ModalContent>
                </ModalBox>
              </Form>
            </Modal> */}
          </Section>
        )}
      </>
    );
  };

  const path = getThePathCoordinates(
    flightData.origin.coordinates.latitude,
    flightData.origin.coordinates.longitude,
    flightData.destiny.coordinates.latitude,
    flightData.destiny.coordinates.longitude
  );

  return (
    <>
      <Header>
        <Title new={true}>
          <IoMdArrowBack size={30} onClick={() => history.goBack()} />
          Controle do Viajante - Detalhes do Vôo
        </Title>
        <hr></hr>
      </Header>
      {loading && <SkeletonCard heigth={`100%`} width={`100%`} />}
      {!loading && (
        <Section general={true}>
          <Title>{`Informações sobre Vôo ${flightData.flightNumber}`}</Title>
          {/* <Title>{`✈️ Informações sobre Vôo ${flightData.flightNumber}`}</Title> */}
          <IframeHolder>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAzcOTd0EDXovHqGbG3eSYOIJypDCaNOwY",
              }}
              defaultCenter={{
                lat: parseFloat(flightData.origin.coordinates.latitude),
                lng: parseFloat(flightData.origin.coordinates.longitude),
              }}
              defaultZoom={6}
              language="pt"
              region="br"
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={(google) => handleGoogleMapApi(google, path)}
            >
              <IoIosAirplane
                lat={parseFloat(flightData.origin.coordinates.latitude)}
                lng={parseFloat(flightData.origin.coordinates.longitude)}
                size={30}
                color="#5d2291"
              />
            </GoogleMapReact>
            <DetailsHolder row={true}>
              <DetailsList row={true}>
                <DetailsItem general={true}>
                  <strong>Partida</strong>
                  <br />
                  {`${locationOrigin} em ${dateOrigin}`}
                </DetailsItem>
                <DetailsItem general={true}>
                  <strong>Chegada</strong>
                  <br />
                  {`${locationDestinty} em ${dateDestiny}`}
                </DetailsItem>
                <DetailsItem general={true}>
                  <strong>Companhia Aérea</strong>
                  <br />
                  {flightData.flightCompany}
                </DetailsItem>
                <DetailsItem general={true}>
                  <strong>Status</strong>
                  <br />
                  {flightData.status}
                </DetailsItem>
                {allPaxWhatsappNumbers.length === 0 ? (
                  <DetailsItem pendingWhatsapp>
                    <strong>Sem contatos cadastrados</strong>
                    <br />
                    {/* Cadastre nos detalhes abaixo */}
                  </DetailsItem>
                ) : (
                  // <DetailsItem whatsapp={true} onClick={() => console.log(allPaxWhatsappNumbers)}>
                  <DetailsItem
                    whatsapp={true}
                    onClick={() => {
                      setPaxWhatsAppNumber(allPaxWhatsappNumbers);
                      toggleModalSendMessage(true);
                    }}
                  >
                    <strong>Enviar mensagem para todos via WhatsApp</strong>
                    <br />
                  </DetailsItem>
                )}
              </DetailsList>
            </DetailsHolder>
          </IframeHolder>
        </Section>
      )}

      {/** PAX LIST */}
      <HeaderPaxList>
        {" "}
        <strong>Viajantes no Vôo</strong>
      </HeaderPaxList>
      {flightData.paxs.map((pax, index) => (
        <RenderPaxDetail pax={pax} index={index} />
      ))}

      <Modal
        visible={modalSendMessage}
        id="modal-background"
        onClick={({ target }) => closeModal(target, toggleModalSendMessage)}
      >
        <ModalBox>
          <ModalContent>
            <span
              className="modal-close"
              onClick={() => toggleModalSendMessage(false)}
            ></span>
            <ModalTitle>Envio de Mensagem</ModalTitle>
            {/* Você está enviando uma mensagem para */}
            Você está enviando uma mensagem para:{" "}
            <strong>
              {paxWhatsAppNumber.map(
                (pax, index) =>
                  `${pax.wpp} (${pax.name})${
                    paxWhatsAppNumber.length > 1 &&
                    index !== paxWhatsAppNumber.length - 1
                      ? ", "
                      : ""
                  }`
              )}
            </strong>
            <ModalTextarea
              value={messageToSend}
              onChange={(e) => setMessageToSend(e.target.value)}
              placeholder="Escreva sua mensagem aqui..."
            />
            <ModalButton
              className="btn"
              disabled={messageToSend.length <= 0}
              onClick={() => {
                toggleModalSendMessage(false);
                // sendWhatsApp(paxWhatsAppNumber);
              }}
            >
              Enviar
            </ModalButton>
          </ModalContent>
        </ModalBox>
      </Modal>
    </>
  );
}
