import React, { useRef, useState, useEffect, useContext } from 'react';
import Select from '~/components/Select';
import DatePicker from '~/components/DatePickerIndicators';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import { Col, Row } from 'reactstrap';

import ShowSelects from '~/functions/ShowSelects';

import CostCenterSelect from '~/components/CostCenterSelect'
import CompanySelect from '~/components/CompanySelect'

import DashboardContext from '../../context';
import { Form } from '@unform/web';
import {
  HeaderTitle,
  HeaderNav,
  HeaderNavButton,
  TitleFilters,
  FiltersContainer
} from './styles';

export default function DashboardHeader() {
  const axios = ApiAxios();
  const userProfile = useSelector(state => state.user.userProfile);

  const formRef = useRef(null);
  const [showFilter, setShowFilter] = useState(true);

  const {
    loading,
    loading3,
    user,
    setDateState,
    resetDateState,
    body,
    setBody,
    companiesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCentersOptions,
    setCostCentersOptions,
    setCostCentersSelected,
    dateState,
  } = useContext(DashboardContext);

  useEffect(() => {
    async function getCostCenters () {
      if (userProfile && companiesSelected.length === 1 && ShowSelects(userProfile.userProfileId).companies) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/${companiesSelected[0].value}/costCenters`,
          );

          return setCostCentersOptions(data.map(e => {
            return {
              label: e.name,
              value: e.name
            };
          }));
        } catch (error) {
          return setCostCentersOptions([]);
        }
      }

      return setCostCentersOptions([]);
    }

    getCostCenters();
  }, [companiesSelected, userProfile]);

  function handleBody (body) {
    setBody(body);
  }

  function handleShowFilter () {
    return setShowFilter(!showFilter);
  }

  return (
    <DashboardContext.Provider value={{
      companiesOptions,
      costCentersOptions
    }}>
      <Row>
        <Col sm={12} style={{
          padding:'0 15px'
        }}>
          <TitleFilters>
            <h1 className="page-header">
              Dashboard
            </h1>

            <span
              className={showFilter ? 'btn btn-primary' : 'btn'}
              style={{background: showFilter?'#000000':'transparent'}}
              onClick={() => handleShowFilter()}>
              <i className="fas fa-filter"/>
              Filtros
            </span>
          </TitleFilters>
        </Col>

        <Col sm={12} hidden={!showFilter} style={{padding: '0 20px',margin: 0}}>
          <FiltersContainer>
            <div style={{
              width: '100%',
              padding: '16px 17px 0'
            }}>
              <Row>
                <Col sm={12} md={6} lg={5} xl={4}>
                  {dateState !== null && (
                    <DatePicker
                      label=''
                      setState={setDateState}
                      state={dateState}
                      reset={resetDateState}
                      isDisabled={loading || loading3}
                      hasYearLimit={true}
                    />
                  )}
                </Col>

                {user && ShowSelects(userProfile.userProfileId).companies && companiesOptions.length > 1 && (
                  <Col sm={12} md={6} lg={5} xl={4}>
                    <Form ref={formRef} style={{width: '100%'}}>
                      <CompanySelect
                        name="CompanyHandle"
                        placeholder="Empresas"
                        options={companiesOptions}
                        setCompaniesSelected={setCompaniesSelected}
                        setCostCentersSelected={setCostCentersSelected}
                        isDisabled={loading || loading3}
                      />
                    </Form>
                  </Col>
                )}

                {user && ShowSelects(userProfile.userProfileId).costCenters && costCentersOptions.length > 0 && (
                  <Col sm={12} md={6} lg={5} xl={4}>
                    <Form ref={formRef} style={{width: '100%'}}>
                      <CostCenterSelect
                        name="costCenter"
                        placeholder="Centros de Custo"
                        options={costCentersOptions}
                        setCostCentersSelected={setCostCentersSelected}
                        isDisabled={loading || loading3}
                      />
                    </Form>
                  </Col>
                )}
              </Row>
            </div>
          </FiltersContainer>
        </Col>
      </Row>

      <Row style={{
        justifyContent: 'space-between'
      }}>
        <Col sm={0} md={0} lg={5} xl={7}></Col>

        <Col sm={12} md={12} lg={7} xl={5} style={{
          marginBottom: '8px',
          textAlign: 'right'
        }}>
          <HeaderNav>
            <HeaderNavButton
              selected={body === 1 ? true : false}
              type="button"
              onClick={() => handleBody(1)}>
              Meu Painel
            </HeaderNavButton>

            <HeaderNavButton
              selected={body === 2 ? true : false}
              type="button"
              onClick={() => handleBody(2)}>
              Gastos
            </HeaderNavButton>

            <HeaderNavButton
              selected={body === 3 ? true : false}
              type="button"
              onClick={() => handleBody(3)}>
              Políticas
            </HeaderNavButton>

            <HeaderNavButton
              selected={body === 4 ? true : false}
              type="button"
              onClick={() => handleBody(4)}>
              Bilhetes
            </HeaderNavButton>
          </HeaderNav>
        </Col>
      </Row>
    </DashboardContext.Provider>
  );
}
