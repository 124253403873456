import React, { useState, useEffect } from 'react';
import { PanelHeader } from '~/components/Panel';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import {
  Submit
} from '~/styles/buttons/defaults';

import ExportSpreadSheet from '~/components/ExportSpreadSheet';
import { splitPaxName } from '~/functions/String';
import { formatDate } from '~/functions/Date';

function Header({
  data,
  title = ''
}) {
  const [dataToExcel, setDataToExcel] = useState([]);

  useEffect(() => {
    async function loadDataToExcel () {
      let newData = [];

      newData.push({
        status: `Emissão:`,
        traveler: `${moment().format('DD/MM/YYYY HH:mm')}`,
      });

      newData.push({
        traveler: '',
      });

      newData.push({
        status: 'STATUS',
        traveler: 'VIAJANTE',
        costCenter: 'SETOR',
        departure: 'PARTIDA',
        departureDate: 'DATA DA PARTIDA',
        destiny: 'DESTINO',
        arrivalDate: 'DATA DA CHEGADA',
      });

      data.map(e => {
        newData.push({
          status: e.status,
          traveler: splitPaxName(e.paxName),
          costCenter: e.section ? e.section : "SEM SETOR",
          departure: `${e.origin.name} (${e.origin.airportCode.trim()})`,
          departureDate: formatDate(e.origin.date),
          destiny: `${e.destiny.name} (${e.destiny.airportCode.trim()})`,
          arrivalDate: formatDate(e.destiny.date),
        });
      });

      return setDataToExcel(newData);
    }

    loadDataToExcel();
  }, [data]);

  return (
    <PanelHeader
      noButton
      title=""
      children={
        <div style={{ width: '100%' }}>
          <Row>
            <Col sm={12} md={7}>
              Resultado da Pesquisa
            </Col>

            <Col sm={12} md={5} style={{textAlign: 'right'}}>
              {dataToExcel.length > 0 && (
                <ExportSpreadSheet
                  data={dataToExcel}
                  columns={[
                    {label: `${title}`, value: 'status'},
                    {label: 'Controle do Viajante', value: 'traveler'},
                    {label: '', value: 'costCenter'},
                    {label: '', value: 'departure'},
                    {label: '', value: 'departureDate'},
                    {label: '', value: 'destiny'},
                    {label: '', value: 'arrivalDate'},
                  ]}
                  element={
                    <Submit type='button'>
                      Exportar
                    </Submit>
                  }
                  filename={'Controle-de-Viajantes'}
                  sheetName={'Lista de Viagens'}
                />
              )}
            </Col>
          </Row>
        </div>
      }
    />
  );
}

export default Header;
