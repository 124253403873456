import styled from 'styled-components';
import { Row } from 'reactstrap';

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100px;

  .table-responsive {
    min-height: 100px;
    border: 0;
    border-bottom: 1px solid #eee;

    table,
    thead,
    tr,
    th,
    tbody,
    td,
    tfoot {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-size: ${props => props.tableStyle?.fontSize};
      word-wrap: break-word;
      text-align: center;
      border: none;
      border-spacing: 0;
    }

    table {
      width: 100%;
      height: 100%;
      background: #fff;

      thead {
        tr {
          min-width: 100%;
          background-color: #fff;

          th {
            border: 0;
            border-right: 1px solid #f2f3f4;
            white-space: nowrap;
            padding: ${props => props.tableStyle?.cellPadding};
            vertical-align: baseline;

            :last-child {
              border-right: 0;
            }

            .resizer {
              display: inline-block;
              width: 8px;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              transform: translateX(10%);
              z-index: 1;
              touch-action: none;
            }
          }
        }
      }

      tbody {
        tr {
          min-width: 100%;

          td {
            border: 0;
            border-right: 1px solid #f2f3f4;
            padding: ${props => props.tableStyle?.cellPadding};
            vertical-align: bottom;

            :last-child {
              border-right: 0;
            }

            i {
              font-size: 15px;
            }

            button {
              padding: 0px 8px;
            }
          }
        }

        tr.expanding {
          box-shadow: 0px 0px 4px #ccc;
          background-color: #f2f3f4;
        }

        tr.sub-component > td {
          border: 0;
          padding: 0 !important;
          margin: 0;
          background-color: #f2f3f4;
        }
      }

      tfoot {

        tr {
          min-width: 100%;
          box-shadow: 0px 0px 8px #ccc;

          td {
            font-weight: bold;
            border-right: 1px solid #eaeaea;
            padding: 4px;

            :last-child {
              border-right: 0;
            }
          }
        }
      }
    }
  }
`;

export const SubContainer = styled.div`
  position: relative;
  padding: 0 16px;
  box-sizing: border-box;
  height: 100%;
  min-height: 100px;
  width: 100%;
  min-width: 100%;
  box-shadow: 0px 4px 4px #ccc;
`;

export const Pagination = styled(Row)`
  width: 100%;
  padding: 8px !important;
  margin: 0 !important;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;
  }

  button {
    width: 100%;
    min-height: 35px;
    font-size: 13px;
    background: #ddd;
    color: #333;
    border: none;
    border-radius: 4px;
    padding: 8px 4px;
    margin: 4px 0;
    font-weight: 600;

    &:hover {
      cursor: pointer;
    }
  }

  /* * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  } */

  /* div {
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  /* .row {
    width: 100%;
  } */
`;

export const NavigationButton = styled.button`
  width: 100% !important;
  font-size: 13px !important;
  background: #ddd !important;
  color: #333 !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 8px 0px !important;
  margin: 4px 0 !important;

  &:hover {
    cursor: pointer;
  }
`;

export const PageSelector = styled.div`
  font-size: 11px;
  text-align: center;

  select {
    text-align: center;
    height: 28px;
    border-radius: 4px;
    padding: 4px 4px;
    border: 1px solid #ccc;
  }

  input {
    text-align: center;
    height: 28px;
    width: 50px;
    background: #fff;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    margin: 0px 2px 0px 4px !important;

    &:hover {
      cursor: text;
    }
  }
`;

export const InfoContent = styled.div`
  position: absolute;
  background-color: rgba(200,200,200,.3);
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoContentFlag = styled.div`
  padding: 4px 8px;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: #fafafa;
  color: #777;
  font-weight: bold;
  font-size: 13px;
`;
