import { plugins } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';

export default function RankingPass({
  data
}) {
  const [chartData19, setChartData19] = useState({});
  const [labels, setlabels] = useState([]);
  const [values, setvalues] = useState([]);

  const chart19 = () => {
    setChartData19({
      labels: labels,
      datasets: [{
        data: values,
        backgroundColor: ['#00EAE1', '#BDBFBF', '#95F496', '#f4dbab', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2',],
        borderWidth: 4,
        label: 'Quantidade'
      }],
    })
  };

  useEffect(() => {
    async function loadData() {
      setlabels(data.map(x => x.cidade));
      setvalues(data.map(x => x.soma));
    }

    loadData();
  }, [data])

  useEffect(() => {
    chart19()
  }, [labels, values])

  return (
    <>
      <div style={{ width: '50%', margin: '3% auto', }}></div>
      <HorizontalBar data={chartData19} options={{
        legend: {
          display: false,
        },
        responsive: true }} />
    </>
  );
}

// const styles = {
//     titleLegend: {
//         marginTop: "10px",
//         fontSize: "11px",
//         fontWeight: 700
//     },
//     redLegend: {
//         marginTop: "10px",
//         fontSize: "11px",
//         fontWeight: 700,
//         color: 'red'
//     }
// }
