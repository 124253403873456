import moment from 'moment';

export const DefaultDate = {
  currentWeek:
  `${moment().subtract(5, 'months').startOf('month').format('DD/MM/YYYY')} -
  ${moment().endOf('month').format('DD/MM/YYYY')}`,
  selectedDate: {
    startDate: `${moment().subtract(5, 'months').startOf('month').format('DD/MM/YYYY')}`,
    endDate: `${moment().endOf('month').format('DD/MM/YYYY')}`,
  },
  prevWeek:
    `${moment().subtract(5, 'months').startOf('month').format('DD/MM/YYYY')} -
    ${moment().endOf('month').format('DD/MM/YYYY')}`,
  startDate: moment().subtract(5, 'months').startOf('month'),
  endDate: moment().endOf('month'),
};
