import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ShowSelects from "~/functions/ShowSelects";

import {
  Avatar,
  TextAvatar,
  TextEmail,
  TextUser,
  AgencyAdmin
} from "./styles";

export default function SidebarProfile() {
  const { profile } = useSelector(({ user }) => user);
  const userProfile = useSelector((state) => state.user.userProfile);

  const [name, setName] = useState("");

  useEffect(() => {
    if (profile && profile.name) {
      var names = profile.name.split(" ");

      var res = "";
      if (names) {
        names.map((e, i) => {
          if (i === 0 || i === 1) res += e[0];

          return true;
        });
      }

      setName(res);
    }
  }, [profile]);

  return (
    <>
      <Avatar className="sidebar-nav__avatar">
        <TextAvatar>
          {name.toUpperCase()}
        </TextAvatar>
      </Avatar>

      <div className="sidebar-nav__infos">
        <TextUser>
          {profile.name}
        </TextUser>

        <TextEmail>
          {profile.email}
        </TextEmail>

        {ShowSelects(userProfile.userProfileId).isAgency && (
          <AgencyAdmin>
            <Link to={"/agency"}>
              Minha Agência
            </Link>
          </AgencyAdmin>
        )}
      </div>
    </>
  );
}
