import React, { useEffect, useState, useRef } from "react";

import { Row, Col } from "reactstrap";

import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import { Panel, PanelBody, PanelHeader } from "~/components/Panel";

import { ApiAxios } from "~/services/api.js";

import { Form } from "@unform/web";

import { Card, CardBody } from "~/styles/card";

import { ButtonCard } from "~/styles/buttons/card";

import { toast } from "react-toastify";

import Select from "~/components/Select";

function Dashboard({ company, setCompanyPage }) {
  const axios = ApiAxios();
  const formRef = useRef(null);
  const location = useLocation([]);

  const [alterPlan, setAlterPlan] = useState(false);
  const [categoryPlan, setCategoryPlan] = useState([]);
  const [loading, setLoading] = useState(false);

  const initialState = {
    category: null,
  };

  console.log(company);

  const [plan, setPlan] = useState(initialState);

  useEffect(() => {
    async function getPlans() {
      try {
        setLoading(true);

        setCategoryPlan([]);

        const res = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/plans`
        );

        setLoading(false);

        return setCategoryPlan(res.data);
      } catch (error) {
        setLoading(false);

        return toast.error(
          "Não foi possível obter os planos."
        );
      }
    }
    getPlans();
  }, []);
  async function handlePlanClient() {
    try {
      setAlterPlan(true);
      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/plan`,
        {
          id: company.id,
          PlanId: plan.category.value,
        }
      );

      toast.info(`Plano alterado com sucesso.`);

      return setAlterPlan(false);
    } catch (error) {
      setAlterPlan(false);

      return console.log("Não foi possível atualizar o plano.");
    }
  }

  useEffect(() => {
    async function loadSource() {
      if (categoryPlan.length > 0) {
        const res = categoryPlan.find(
          (item) =>
            item.id ===
            company.planId
        );

        if (res) {
          setPlan({
            ...plan,
            category: {
              value: res.id,
              label: res.name,
            },
          });
        }
      }
    }

    loadSource();
  }, [categoryPlan]);

  return (
    <Row>
      <Col xl={6}>
        <Card>
          <CardBody>
            <p>
              <b>Empresa:</b>&nbsp;
              {company.name}
            </p>

            <p>
              <b>Nome Fantasia:</b>&nbsp;
              {company.fantasy}
            </p>

            {company.group !== "" && (
              <p>
                <b>Grupo Empresarial:</b>&nbsp;
                {company.group}
              </p>
            )}

            <p>
              <b>Cód. BKO:</b>&nbsp;
              {company.code}
            </p>
          </CardBody>
        </Card>
      </Col>

      <Col md={12} lg={12} xl={2}>
        <Card>
          <ButtonCard
            bg={"#7e2ec2"}
            fg={"#eee"}
            type="button"
            onClick={() => setCompanyPage("managers")}
          >
            Gestores
          </ButtonCard>
        </Card>
      </Col>

      <Col md={12} lg={12} xl={2}>
        <Card>
          <ButtonCard
            rd
            bg={"#00EAE1"}
            fg={"#333"}
            type="button"
            onClick={() => setCompanyPage("travelers")}
          >
            Viajantes
          </ButtonCard>
        </Card>
      </Col>
      <Col md={12} lg={12} xl={2}>
        <Card>
          <ButtonCard
            bg={"#f4dbab;"}
            fg={"#000000"}
            type="button"
            onClick={() => setCompanyPage("costCenters")}
          >
            Centros de Custo
          </ButtonCard>
        </Card>
      </Col>

      <Col md={12} lg={12} xl={3}>
        <Panel>
          <Form ref={formRef} onSubmit={handlePlanClient}>
            <Select
              disabled={false}
              name={"category"}
              label={"Planos"}
              placeholder={"Selecione..."}
              options={categoryPlan.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              value={plan.category}
              onChange={(e) =>
                setPlan({
                  ...plan,
                  category: e,
                })
              }
              isClearable
            />
            <button className="btn btn-primary" type="submit">
              Salvar
            </button>
          </Form>
        </Panel>
      </Col>
    </Row>
  );
}

export default Dashboard;
