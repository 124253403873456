export const columnsOrders = [
  {
    Header: 'Emissão',
    accessor: 'dataFormatada',
    width: 100,
    className: 'd-flex justify-content-center',
  },
  {
    Header: 'Referência',
    accessor: 'handle',
    width: 100,
    style: {
      textAlign: 'center'
    }
  },
  {
    default: true,
    Header: 'OS',
    accessor: 'numOS',
    width: 90,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    Header: 'Serviço',
    accessor: 'tipoFaturamento',
    width: 140,
  },
  {
    Header: 'Fornecedor',
    accessor: 'fornecedor'
  },
  {
    Header: 'Viajante',
    accessor: 'pax',
    width: 140,
  },
  {
    Header: 'Centro de Custo',
    accessor: 'centroCusto',
    width: 140,
  },
  {
    Header: 'Valor do Item',
    accessor: 'valorTotalDoDocumento',
    className: 'd-flex justify-content-end',
    Cell: row => `${row.value.toLocaleString(
      'pt-BR',
      {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }
    )}`,
    width: 100,
  },
];
