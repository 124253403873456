import React, { useContext, useState, useEffect } from 'react';
import ReactExport from "react-export-excel";
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import ReactTable from '~/components/Table';
import { Panel, PanelHeader } from "~/components/Panel";

import InvoiceReportContext from "../../context";

import { FormatValueBRL } from '~/functions/FormatValue';

import { ColumnsTableMain, ColumnsTitleMain, ButtonCheckColumns } from './styles';

import { columns } from './constants';

function Table() {
  const [tableColumns, setTableColumns] = useState(columns);
  const [hiddenColumns, setHiddenColumns] = useState(true);
  const [todos, setTodos] = useState(true);
  const [columnsToExcel, setColumnsToExcel] = useState([]);
  const [dataToExcel, setDataToExcel] = useState([]);

  const { data, loadingTable, lastObtUpdate } = useContext(InvoiceReportContext);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  useEffect(() => {
    async function loadDataToExcel() {
      var newData = [];

      data.map(e => {
        newData.push({
          ...e,
          emission: moment(e.emission, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          refundable: e.refundable ? 'SIM' : 'NÃO',
          ticket_value: FormatValueBRL(e.ticket_value),
          departure_tax: FormatValueBRL(e.departure_tax),
          fee: FormatValueBRL(e.fee),
          cancellation_change_fee: FormatValueBRL(e.cancellation_change_fee),
          no_show: FormatValueBRL(e.no_show),
          refund_ammount: FormatValueBRL(e.refund_ammount),
        });
      });

      return setDataToExcel(newData);
    }

    loadDataToExcel();
  }, [data]);

  useEffect(() => {
    async function loadColumnsToExcel() {
      let newColumns = [];

       tableColumns.map(e => {
         if (e.show) {
           newColumns.push(e);
         }
       });

      return setColumnsToExcel(newColumns);
    }

    loadColumnsToExcel();
  }, [tableColumns]);

  function changeColumn2(e) {
    if (e === 'all') {
      const columnsMapped = tableColumns.map(c => {
        var column = c;

        column.show = !todos;

        return column;
      });

      setTodos(!todos);

      return setTableColumns(columnsMapped);
    }

    const columnsMapped = tableColumns.map(c => {
      var column = c;

      if (column.accessor === e) {
        column.show = !column.show;
      }

      return column;
    });

    setTodos(false);

    return setTableColumns(columnsMapped);
  }

  return (
    <Panel theme="inverse">
      <PanelHeader noButton>
        <ColumnsTitleMain>
          <div>
            <span>
              {!hiddenColumns ? 'Selecione as colunas desejadas:' : 'Detalhes da Pesquisa'}
            </span>
            {(!hiddenColumns) && (
              <>
                <input
                  type="checkbox"
                  defaultChecked={todos}
                  checked={todos}
                  value={'all'}
                  onChange={({ target }) => changeColumn2(target.value)}
                />
                <span>Todos</span>
              </>
            )}
          </div>

          <div>
            <ButtonCheckColumns
              type='button'
              onClick={() => {
                return setHiddenColumns(!hiddenColumns);
              }}>
              {hiddenColumns ? 'Escolher Colunas' : <i className="fa fa-caret-up fa-1x" />}
            </ButtonCheckColumns>

            <ExcelFile
              element={
                <ButtonCheckColumns
                  type="button"
                  onClick={() => {}}
                  disabled={data.length === 0 || tableColumns.length === 0}>
                  Exportar
                </ButtonCheckColumns>
              }
              filename={`Relatório Analítico de Perdas`}>
              <ExcelSheet data={dataToExcel} name="Perdas">
                {columnsToExcel.map((e, i) => (
                  <ExcelColumn label={e.Header} value={e.accessor} key={i} />
                ))}
              </ExcelSheet>
            </ExcelFile>
          </div>
        </ColumnsTitleMain>

        <ColumnsTableMain hidden={hiddenColumns}>
          <Row>
            {tableColumns.map((c, i) =>(
              <Col sm={3} md={3} lg={4} xl={3} key={i}>
                <input
                  type="checkbox"
                  defaultChecked={c.show}
                  checked={c.show}
                  value={c.accessor}
                  onChange={({ target }) => changeColumn2(target.value)}
                />

                <span>{i === 0 ? 'Emissão' : c.Header}</span>
              </Col>
            ))}
          </Row>
        </ColumnsTableMain>
      </PanelHeader>

      {data.length > 0 && lastObtUpdate !== null && (
        <div style={{
          width: '100%',
          padding: '4px 8px',
          textAlign: 'right',
        }}>
          <p style={{
            margin: 0
          }}>
            *Dados atualizados em: <b>{moment(lastObtUpdate.lastUpdate).format("DD/MM/YYYY HH:mm")}</b>
          </p>
        </div>
      )}

      <ReactTable
        columns={tableColumns.filter(e => e.show === true)}
        data={data}
        tableProps={{
          defaultPageSize: 10,
          hasFooter: false,
          hasPagination: true,
          loading: loadingTable,
          resizable: true
        }}
        tableStyle={{
          fontSize: '13px',
          cellPadding: '6px 4px'
        }}
        tableLabels={{
          loadingText: 'Carregando...',
          noDataText: 'Sem registros',
          nextButtonText: 'Próximo',
          previousButtonText: 'Anterior',
          pageOfText: 'de',
          pageSizeSelectText: 'registros',
          pageText: 'Página'
        }} />
    </Panel>
  );
}

export default Table;
