const refundStatus = [
  { label: "Todos", value: "all" },
  { label: "Solicitado", value: "requested" },
  { label: "Pendente", value: "pending" },
  { label: "Reembolsado", value: "refunded" },
  { label: "Rejeitado", value: "rejected" },
  { label: "Cancelado", value: "canceled" },
];

export {
  refundStatus,
};
