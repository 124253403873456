import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import { toast } from 'react-toastify';
import ReactTable from 'react-table';
import { Panel, PanelHeader, PanelBody } from '~/components/Panel';
import { columns } from './constants';
import { Link } from 'react-router-dom';

import { Row, Col, Spinner } from 'reactstrap';
import { Form } from '@unform/web';
import {
  FormatPhoneOnlyNumbersBR,
  FormatPhoneBR
} from '~/functions/FormatPhone';
import { useHistory } from 'react-router-dom';

import {
  EditAction,
  CancelDeleteAction,
  CreateAction
} from '~/components/ActionsOfTables';

import Input from '~/components/Input';
import Select from '~/components/Select';
import SliderToggle from '~/components/SliderToggle';

import {
  SubmitButton,
  PanelFilterCompanyGroup,
  PanelSearchResult,
  SpanPasswordAlert,
  SpanLi,
  LiSearch,
  Modal,
  ModalBox,
  ModalContent,
  ModalTitle,
  ModalTextarea,
  ModalButton,
} from './styles';

function List({
  setPage,
  setToEdit
}) {
  const axios = ApiAxios();
  const user = useSelector(state => state.user.profile);
  const accessToken = useSelector(state => state.auth.accessToken);
  const [loading, setLoading] = useState(false);
  const [userProfiles, setUserProfiles] = useState([]);

  useEffect(() => {
    async function loadUserProfiles () {
      try {
        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/profile`,
          options
        );

        setUserProfiles(response.data.payload);
      } catch (error) {
        setLoading(false);

        toast.error('Não foi possível carregar os dados.');
      }
    }

    loadUserProfiles ();
  }, [accessToken]);

  async function handleStatus (e) {
    try {
      setLoading(true);

      const options = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/profile/updateProfileStatus/${e.id}`,
        options
      );

      setUserProfiles(response.data.payload);

      return setLoading(false);
    } catch (error) {
      return setLoading(false);
    }
  }

  return (
    <>
      <h5>
        {`Tipos de Usuários`}
      </h5>

      <table className='table table-hover table-responsive'>
        <thead>
          <tr>
            <th>Título</th>
            <th>Descrição</th>
            <th>Status</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {userProfiles.map((e, i) => {
            return (
              <tr key={i}>
                <td>{e.text}</td>

                <td>{e.description}</td>

                <td>
                  <SliderToggle
                    checked={!e.deletado}
                    click={() => handleStatus(e)} />
                </td>

                <td>
                  {
                    <>
                      <EditAction
                        type='button'
                        style={{backgroundColor: '#000000', color: 'white'}}
                        onClick={() => {
                          setToEdit(e);

                          return setPage('edit');
                        }}>
                        Editar
                      </EditAction>
                    </>
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default List;
