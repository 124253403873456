import { createReducer, createActions } from 'reduxsauce';

import { AuthTypes } from './auth';

const { Types, Creators } = createActions({
  insertAgencySetting: ['agencySetting']
});

export const AgencyTypes = Types;
export default Creators;

const INITIAL_STATE = {
  agencySetting: null
};

export const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.SIGN_IN_SUCCESS]: (state, { agencySetting }) => ({
    ...state,
    agencySetting,
    loading: false,
  }),

  [Types.INSERT_AGENCY_SETTING]: (state, { agencySetting }) => ({
    ...state,
    agencySetting
  }),

  [AuthTypes.SIGN_FAILURE]: state => ({
    ...state,
    agencySetting: null,
    loading: false,
    accessToken: null,
    signed: false,
  }),

  [AuthTypes.SIGN_OUT]: () => ({
    agencySetting: null,
    loading: false,
    accessToken: null,
    signed: false,
  })
});
