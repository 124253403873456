import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import { toast } from 'react-toastify';
import AuthActions from '~/store/modules/ducks/auth';

import DashboardHeader from './components/DashboardHeader';
import DashboardIndicatiors from './components/DashboardIndicatiors';
import MyPanel from './components/DashboardBody/MyPanel';
import Outlays from './components/DashboardBody/Outlays';
import Policy from './components/DashboardBody/Policy';
import Tickets from './components/DashboardBody/Tickets';

import DashboardAgencyContext from './context';

import { DefaultDate } from './constants';
import { Row, Container } from './styles';

import ShowSelects from '~/functions/ShowSelects';

export default function DashboardAgency () {
  const axios = ApiAxios();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.profile);

  const accessToken = useSelector(state => state.auth.accessToken);
  const companies = useSelector(state => state.user.companies);
  const costCenters = useSelector(state => state.user.costCenters);

  const [dateState, setDateState] = useState(DefaultDate);
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState(1);

  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [costCentersOptions, setCostCentersOptions] = useState([]);
  const [costCentersSelected, setCostCentersSelected] = useState([]);
  const [executivesOptions, setExecutivesOptions] = useState([]);
  const [executivesSelected, setExecutivesSelected] = useState([]);
  const [emitterOptions, setEmitterOptions] = useState([]);
  const [emitterSelected, setEmitterSelected] = useState([]);

  useEffect(() => {
    async function setPage(){
      if(user.myCharts.length <= 0){
        handleBody(2);
      }
    }
    setPage();
  }, [user]);

  const handleBody = (body) => setBody(body);

  const resetDateState = () => setDateState(DefaultDate);

  async function PinChart (id, setpinned) {
    try {
      const options = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      if (user.myCharts.indexOf(id) !== -1) {
        return toast.info(
          'O Indicador selecionado já está adicionado ao Meu Painel!'
        );
      }

      let charts = user.myCharts;
      let handles = companiesSelected.map(x => x.codCliente);
      charts.push(id);

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/${user.email}/preferences/mypanel/charts`,
        charts,
        options
      );
      // let handle = companies[0].handleEmpresa.toString();

      // setCostCenterOptions(costCenterResponse.data);

      if (response.data && response.data.statusCode === 200) {
        dispatch(AuthActions.signInSuccess(
          accessToken,
          {
            ...user,
            myCharts: charts
          }
        ));
        setpinned(true);
      }

      return toast.success(
        'Indicador adicionado ao Meu Painel.'
      );
    } catch (error) {
      return toast.info(
        'Não foi possível adicionar esse indicador ao Meu Painel!'
      );
    }
  }

  async function UnpinChart (id, setpinned) {
    try {
      // let handle = companies[0].handleEmpresa.toString();

      const options = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      if (user.myCharts.indexOf(id) === -1) {
        return toast.warning(
          'O Indicador selecionado não está adicionado ao Meu Painel!'
        );
      }

      let charts = user.myCharts.filter(c => c !== id);

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/${user.email}/preferences/mypanel/charts`,
        charts,
        options
      );

      if (response.data && response.data.statusCode === 200) {
        dispatch(AuthActions.signInSuccess(
          accessToken,
          {
            ...user,
            myCharts: charts
          }
        ));
        setpinned(false);
      }

      return toast.warning(
        'Indicador removido do Meu Painel.'
      );
    } catch (error) {
      return toast.info(
        'Não foi possível remover esse indicador do Meu Painel!'
      );
    }
  }

  return (
    <DashboardAgencyContext.Provider value={{
      companies,
      costCenters,
      companiesOptions,
      setCompaniesOptions,
      companiesSelected,
      setCompaniesSelected,
      costCentersOptions,
      setCostCentersOptions,
      costCentersSelected,
      setCostCentersSelected,
      executivesOptions,
      setExecutivesOptions,
      executivesSelected,
      setExecutivesSelected,
      emitterOptions,
      setEmitterOptions,
      emitterSelected,
      setEmitterSelected,
      user,
      dateState,
      setDateState,
      resetDateState,
      accessToken,
      loading,
      body,
      setBody,
      setLoading,
      PinChart,
      UnpinChart
    }}>

      <Container>
        <DashboardHeader />
        <DashboardIndicatiors />
        <Row>
          { body === 1 && (
            <MyPanel />
          )}

          { body === 2 && (
            <Outlays />
          )}

          { body === 3 && (
            <Policy />
          )}

          { body === 4 && (
            <Tickets />
          )}
        </Row>
      </Container>

    </DashboardAgencyContext.Provider>
  );
}
