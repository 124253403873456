import React from "react";

import { useSelector } from "react-redux";

import {
  Container,
  Logo,
  Main,
  CardContainer,
  Card,
  CardBody,
  CardBodyHeader,
  CardTitle,
  CardBodyItem,
} from "./styles";

function Plan() {
  const user = useSelector((state) => state.user);

  var hasPlanFree =
    user &&
    user.userProfile &&
    user.companies.some((x) => x.planId === 1) &&
    user.userProfile.code === "company";
  var hasPlanEconomic =
    user &&
    user.userProfile &&
    user.companies.some((x) => x.planId === 2) &&
    user.userProfile.code === "company";
  var hasPlanMaster =
    user &&
    user.userProfile &&
    user.companies.some((x) => x.planId === 3) &&
    user.userProfile.code === "company";
  var hasPlanPremium =
    user &&
    user.userProfile &&
    user.companies.some((x) => x.planId === 4) &&
    user.userProfile.code === "company";

  return (
    <Container>
      <br></br>
      <br></br>
      <h2>
        Você está na versão {hasPlanFree ? "FREE" : ""}{" "}
        {hasPlanEconomic ? "ECONOMY" : ""} {hasPlanMaster ? "MASTER" : ""}
        {hasPlanPremium ? "PREMIUM" : ""}
      </h2>
      <br></br>
      <h4>Conheça nossos planos:</h4>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            width: 250,
            margin: 8,
            backgroundColor: "#D54C35",
            padding: "27px",
          }}
        >
          <CardTitle
            style={{
              color: "white",
            }}
          >
            FREE
          </CardTitle>

          <CardBody>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              1 Licença Gestor de Viagens
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              B.I - Gerenciador de dados Personalizados
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              Faturas e Boletos
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              Relatório Emissão e Reemissões
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              Relatório (BNV)
            </CardBodyItem>
          </CardBody>
        </Card>

        <Card
          style={{
            width: 250,
            margin: 8,
            backgroundColor: "#D54C35",
            padding: "27px",
          }}
        >
          <CardTitle
            style={{
              color: "white",
            }}
          >
            ECONOMY
          </CardTitle>

          <CardBody>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              <b>Tudo da versão Free +</b>
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              2 Licenças Gestor de área
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              Wee Gestão App
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              Controle do Viajante
            </CardBodyItem>
          </CardBody>
        </Card>

        <Card
          style={{
            margin: 8,
            width: 250,
            backgroundColor: "#D54C35",
            padding: "27px",
          }}
        >
          <CardTitle
            style={{
              color: "white",
            }}
          >
            MASTER
          </CardTitle>

          <CardBody>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              <b>Tudo da versão Economy +</b>
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              3 Licenças Gestor de Viagens
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              5 Licenças Gestor da Área
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              Gestão de Custos
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              Wee Gestão App
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              Alertas de Viagens
            </CardBodyItem>
          </CardBody>
        </Card>

        <Card
          style={{
            margin: 8,
            width: 250,
            backgroundColor: "#D54C35",
            padding: "27px",
          }}
        >
          <CardTitle
            style={{
              color: "white",
            }}
          >
            PREMIUM
          </CardTitle>

          <CardBody>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              <b>Tudo da Versão Master +</b>
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              15 Licenças Gestor de Viagens
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              Licença Gestor da Área ILIMITADA
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              ChatBot - IA
            </CardBodyItem>
            <CardBodyItem
              style={{
                borderRadius: "0px",
                padding: "8px",
              }}
            >
              40 horas de Customizações sem custo
            </CardBodyItem>
          </CardBody>
        </Card>
      </div>
      <Main>
        <h5>
          Para mais informações enviar e-mail para <b>suporte@weetech.io</b> ou
          fale agora mesmo no WhatsApp:{" "}
          <a
            style={{
              // color:"#097cff"
              color: "#d54c35",
            }}
            href="https://api.whatsapp.com/send?phone=+5585997932538&text=Olá,%20tenho%20interesse%20em%20conhecer%20os%20demais%20planos!%20=)"
            target="_blank"
          >
            Enviar mensagem
          </a>
        </h5>
      </Main>
    </Container>
  );
}

export default Plan;
