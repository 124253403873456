import React, { useEffect, useState } from 'react';
// import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';
import { HorizontalBar } from 'react-chartjs-2';

export default function Grafico3({
  data
}) {

  const [chartData22, setChartData22] = useState({});
  const [labels, setlabels] = useState([]);
  const [margem, setMargem] = useState([]);
  const [vendas, setVendas] = useState([]);

const chart22 = () => {
  setChartData22({
  labels: labels,
  datasets: [
    {
      data: vendas,
      backgroundColor: '#BDBFBF',
      borderWidth: 4,
      label: 'Vendas'
    },
    {
      data: margem,
      backgroundColor: '#00EAE1',
      borderWidth: 4,
      label: 'Margem'
    }],
  })
}

useEffect(() => {
  async function loadData(){
    setMargem(data.map(x => x.total_margem));
    setVendas(data.map(x => x.total_venda));
    setlabels(data.map(x => x.centro_custo));
  }

  loadData();
}, [data])

useEffect(() => {
  chart22()
}, [labels, margem, vendas])

// const options = {
//   scales: {
//     yAxes: [
//       {
//         ticks: {
//           beginAtZero: true,
//           callback: function(label, index, labels) {
//             return FormatValueBRL(label);
//         },
//         },
//       },
//     ],
//   },
//   tooltips: {
//     enabled: true,
//     callbacks: {
//       label: function (tooltipItem, data) {
//         return `${FormatValueBRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`;
//       }
//     },
//   },
// }

return(
  <>
    <HorizontalBar data={chartData22} options={{
         scales: {
          xAxes: [
              {
                  ticks: {
                      callback: function(label, index, labels) {
                          return FormatValueBRL(label);
                      },
                      beginAtZero: true,
                      maxTicksLimit: 6
                  },

              }
          ]
        },
        responsive: true,
        tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            return `Valor: ${FormatValueBRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`;
          }
        }
      } }} />
  </>
)
}
//export default GroupedBar

