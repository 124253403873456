import React from 'react';
import Tooltip from '~/components/Tooltip';
import moment from 'moment';

import GolLogo from '../../../assets/images/logos/providers/gol-min-ticket.jpg';
import AzulLogo from '../../../assets/images/logos/providers/azul-min-ticket.jpg';
import { CellLogo } from '../styles';

export const columns = [
  {
    Header: (<>
      <i id="infoInvoice" className="fas fa-file-alt" />
      <Tooltip target="infoInvoice" text={"Quantidade de Faturas vinculadas à Bilhetes"}/>
    </>),
    accessor: 'nInvoices',
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      fontSize: '14px',
      fontWeight: '500' },
    width: 30,
  },
  {
    Header: 'Emissão',
    accessor: 'dataEmissao',
    width: 80,
    style: {
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    Header: 'Partida',
    accessor: 'datapartida',
    width: 80,
    style: {
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    Header: 'Chegada',
    accessor: 'datachegada',
    width: 80,
    style: {
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    Header: 'Cia',
    accessor: 'ciaAerea',
    width: 60,
    Cell: props => (
      <CellLogo
        src={props.row.ciaAerea === 'G3' ? GolLogo : AzulLogo}
        alt="Companhia aérea"
      />
    ),
  },
  {
    Header: 'Trecho',
    accessor: 'origem',
    width: 70,
    style: {
      textAlign: 'center'
    },
    Cell: ({original}) => {
      return <span>{`${original.origem}/${original.destino}`}</span>
    }
  },
  {
    Header: 'Grupo',
    accessor: 'grupo',
    width: 80,
    style: {
      whiteSpace: 'pre-wrap' },
  },
  {
    Header: 'Empresa',
    accessor: 'cliente',
    width: 80,
    style: {
      whiteSpace: 'pre-wrap' },
  },
  {
    Header: 'Voo',
    accessor: 'numeroVoo',
    width: 70,
    style: {
      textAlign: 'center'
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 80,
    style: {
      textAlign: 'center'
    },
  },
  {
    Header: 'Localizador',
    accessor: 'localizador',
    width: 80,
    style: {
      textAlign: 'center'
    },
  },
  {
    Header: 'Pax',
    accessor: 'passageiro',
    width: 180,
    style: { textAlign: 'left',
    paddingLeft: '20px',
    whiteSpace: 'pre-wrap' },
  },
  {
    Header: 'Aprovador',
    accessor: 'aprovador',
    width: 120,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
      textTransform: 'capitalize',
    },
  },
  {
    Header: 'Solicitante',
    accessor: 'solicitante',
    width: 120,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
      textTransform: 'capitalize',
    },
  },
  {
    Header: 'Motivo',
    accessor: 'motivo',
    width: 120,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    Header: 'CentroCusto',
    accessor: 'centroCusto',
    width: 150,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    Header: 'Projeto',
    accessor: 'projeto',
    width: 100,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  // {
  //   Header: 'Finalidade',
  //   accessor: 'finalidade',
  //   width: 100,
  //   style: {
  //     textAlign: 'left',
  //     paddingLeft: '20px',
  //     whiteSpace: 'pre-wrap',
  //   },
  // },
  // {
  //   Header: 'Divisao',
  //   accessor: 'divisao',
  //   width: 100,
  //   style: {
  //     textAlign: 'left',
  //     paddingLeft: '20px',
  //     whiteSpace: 'pre-wrap',
  //   },
  // },
  {
    Header: 'Atividade',
    accessor: 'atividade',
    width: 100,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    Header: 'Valor (R$)',
    Cell: props =>
      new Intl.NumberFormat('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(props.row.valor),
    accessor: 'valor',
    width: 80,
  },
  {
    Header: 'Taxa (R$)',
    accessor: 'valorTaxa',
    Cell: props =>
      new Intl.NumberFormat('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(props.row.valorTaxa),
    width: 80,
  },
  {
    Header: 'Taxa DU (R$)',
    accessor: 'valorDU',
    Cell: props =>
      new Intl.NumberFormat('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(props.row.valorDU),
    width: 80,
  },
];

export const headersColumns = [
  {
    label: 'Produto',
    key: 'produto',
  },
  {
    label: 'Qtde',
    key: 'qtde',
  },
  {
    label: 'Valor',
    key: 'valor',
  },
  {
    label: 'Faturado',
    key: 'faturado',
  }
];

export const columnsClient = [
  {
    Header: (<>
      <i id="infoInvoice" className="fas fa-file-alt" />
      <Tooltip target="infoInvoice" text={"Quantidade de Faturas vinculadas à Bilhetes"}/>
    </>),
    accessor: 'nInvoices',
    style: { textAlign: 'center', whiteSpace: 'pre-wrap' },
    width: 40,
  },
  {
    Header: 'Emissão',
    accessor: 'dataEmissao',
    width: 80,
    style: {
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    Header: 'Partida',
    accessor: 'datapartida',
    width: 80,
    style: {
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    Header: 'Chegada',
    accessor: 'datachegada',
    width: 80,
    style: {
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    Header: 'Cia',
    accessor: 'ciaAerea',
    Cell: props => (
      <CellLogo
        src={props.row.ciaAerea === 'G3' ? GolLogo : AzulLogo}
        alt="Companhia aérea"
      />
    ),
    width: 70,
    style: { textAlign: 'center' },
  },
  {
    Header: 'Trecho',
    accessor: 'origem',
    width: 70,
    style: {
      textAlign: 'center'
    },
    Cell: ({original}) => {
      return <span>{`${original.origem}/${original.destino}`}</span>
    }
  },
  {
    Header: 'Grupo',
    accessor: 'grupo',
    width: 80,
    style: {
      whiteSpace: 'pre-wrap' },
  },
  {
    Header: 'Empresa',
    accessor: 'cliente',
    width: 80,
    style: {
      whiteSpace: 'pre-wrap' },
  },
  {
    Header: 'Voo',
    accessor: 'numeroVoo',
    width: 70,
    style: { textAlign: 'center' },
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 80,
    style: { textAlign: 'center' },
  },
  {
    Header: 'Localizador',
    accessor: 'localizador',
    width: 80,
    style: { textAlign: 'center' },
  },
  {
    Header: 'Pax',
    accessor: 'passageiro',
    width: 180,
    style: { textAlign: 'left', paddingLeft: '20px', whiteSpace: 'pre-wrap' },
  },
  {
    Header: 'Aprovador',
    accessor: 'aprovador',
    width: 120,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
      textTransform: 'capitalize',
    },
  },
  {
    Header: 'Solicitante',
    accessor: 'solicitante',
    width: 120,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
      textTransform: 'capitalize',
    },
  },
  {
    Header: 'Motivo',
    accessor: 'motivo',
    width: 120,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    Header: 'CentroCusto',
    accessor: 'centroCusto',
    width: 150,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    Header: 'Projeto',
    accessor: 'projeto',
    width: 100,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  // {
  //   Header: 'Finalidade',
  //   accessor: 'finalidade',
  //   width: 100,
  //   style: {
  //     textAlign: 'left',
  //     paddingLeft: '20px',
  //     whiteSpace: 'pre-wrap',
  //   },
  // },
  // {
  //   Header: 'Divisao',
  //   accessor: 'divisao',
  //   width: 100,
  //   style: {
  //     textAlign: 'left',
  //     paddingLeft: '20px',
  //     whiteSpace: 'pre-wrap',
  //   },
  // },
  {
    Header: 'Atividade',
    accessor: 'atividade',
    width: 100,
    style: {
      textAlign: 'left',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  // {
  //   Header: 'Valor (R$)',
  //   Cell: props =>
  //     new Intl.NumberFormat('pt-br', {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     }).format(props.row.valor),
  //   accessor: 'valor',
  //   width: 100,
  //   style: { textAlign: 'right', paddingRight: '20px' },
  // },
  // {
  //   Header: 'Taxa (R$)',
  //   accessor: 'valorTaxa',
  //   Cell: props =>
  //     new Intl.NumberFormat('pt-br', {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     }).format(props.row.valorTaxa),
  //   width: 100,
  //   style: { textAlign: 'right', paddingRight: '20px' },
  // },
  // {
  //   Header: 'Taxa DU (R$)',
  //   accessor: 'valorDU',
  //   Cell: props =>
  //     new Intl.NumberFormat('pt-br', {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     }).format(props.row.valorDU),
  //   width: 100,
  //   style: { textAlign: 'right', paddingRight: '20px' },
  // },
];

export const headersColumnsClient = [
  {
    label: 'Produto',
    key: 'produto',
  },
  {
    label: 'Qtde',
    key: 'qtde',
  },
  {
    label: 'Valor',
    key: 'valor',
  },
  {
    label: 'Faturado',
    key: 'faturado',
  }
];

export const groupQuery = [
  { label: 'Produto', value: 'Produto' },
  { label: 'Centro de Custo', value: 'CentroCusto' },
  { label: 'Fornecedor', value: 'Fornecedor' },
  { label: 'Passageiro', value: 'Pax' },
];

export const tipoData = [
  { label: 'Viagem', value: 1 },
  { label: 'Emissão', value: 2 },
  { label: 'Partida', value: 3 },
  { label: 'Chegada', value: 4 },
];

export const ciaAerea = [
  { label: 'Gol', value: 'G3' },
  { label: 'Latam', value: 'L3' },
  { label: 'Azul', value: 'AD' },
  { label: 'Passaredo', value: 'P3' },
];

export const status = [
  { label: 'Não Voado', value: 'NAO VOADO' },
  { label: 'Gerado', value: 'GERADO' },
  { label: 'Reemitido', value: 'REEMITIDO' },
  { label: 'Voado', value: 'VOADO' },
  { label: 'Reembolsado', value: 'REEMBOLSADO' },
  { label: 'Checkin', value: 'CHECKIN' },
];

export const tipo = [{ label: 'Todos', value: 1 }];

export const origem = [{ label: 'Todos', value: 1 }];

export const exibicao = [{ label: 'Todos', value: 1 }];

export const productsData = [
  { label: 'Aéreo', value: 1, all: false },
  { label: 'Hotel', value: 2, all: false },
  { label: 'Carro', value: 3, all: false },
  { label: 'Miscelâneos', value: 4, all: false },
  { label: 'E-Ticket', value: 7, all: false },
  { label: 'Rodoviário', value: 8, all: false },
];

export const all = [{ label: 'Todos', value: 'all', all: true }];

export const DefaultDate = {
  currentWeek:
    `${moment().subtract(30, 'days').format('DD/MM/YYYY')} -
    ${moment().format('DD/MM/YYYY')}`,
  selectedDate: {
    startDate: `${moment().subtract(30, 'days').format('DD/MM/YYYY')}`,
    endDate: `${moment().format('DD/MM/YYYY')}`,
  },
  prevWeek:
    `${moment().subtract(15, 'days').format('DD/MM/YYYY')} -
    ${moment().subtract(8, 'days').format('DD/MM/YYYY')}`,
  startDate: moment().subtract(30, 'days'),
  endDate: moment(),
};
