import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AuthActions from '~/store/modules/ducks/auth';

import { Row, Col } from 'reactstrap';
import { Form } from '@unform/web';
import { ApiAxios } from '~/services/api.js';
import { toast } from 'react-toastify';

import Input from '~/components/Input';
import { Panel, PanelHeader, PanelBody } from '~/components/Panel';
import UserData from './UserData';
import { Container } from './styles';

function Profile() {
  const userData = useSelector(state => state.user);

  
  const [pageProps, setPageProps] = useState({
    title: 'Meu Perfil',
    type: 'userData',
    userData: {
      name: '',
      surname: '',
      fullName: '',
      email: '',
      email2: '',
      phone: '',
      phone2: '',
      profile: {
        id: 0,
        title: '',
        desc: '',
        code: '',
      },
      costCenters: [],
      companies: [],
      menus: [],
      agency: {

      }
    }
  });

  useEffect(() => {
    async function loadUserData () {
      setPageProps({
        ...pageProps,
        userData: {
          name: userData.profile.name ?? '',
          surname: userData.profile.surname ?? '',
          fullName: userData.profile.fullName ?? '',
          email: userData.profile.email ?? '',
          email2: userData.profile.email2 ?? '',
          phone: userData.profile.phone ?? '',
          phone2: userData.profile.phone2 ?? '',
          profile: {
            id: userData.userProfile.userProfileId ?? 0,
            title: userData.userProfile.title ?? '',
            desc: userData.userProfile.desc ?? '',
            code: userData.userProfile.code ?? '',
          },
          companies: userData.companies,
          costCenters: userData.costCenters,
          menus: userData.profile.profile.profileMenu,
        }
      });
    }

    loadUserData();
  }, [userData]);

  return (
    <Container>
      <h1 className='page-header'>
        {pageProps.title}
      </h1>

      <Panel>
        <PanelHeader noButton />

        <PanelBody>
          {pageProps.type === 'userData' && (
            <UserData data={pageProps.userData}/>
          )}
        </PanelBody>
      </Panel>
    </Container>
  );
}

export default Profile;
