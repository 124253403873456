import React, { useContext, useState } from 'react';
import { Route } from 'react-router-dom';

import PageSettings from '~/config/PageSettings';
import FloatSubMenuList from './FloatSubMenuList';

function FloatSubMenu() {
  const [state, setState] = useState({ active: -1, clicked: -1 });
  const context = useContext(PageSettings);

  function handleExpand(e, i, match) {
    e.preventDefault();

    if (state.clicked === -1 && match) {
      setState({
        active: -1,
        clicked: 1,
      });
    } else {
      setState({
        active: state.active === i ? -1 : i,
        clicked: 1,
      });
    }
    setTimeout(() => {
      context.handleFloatSubMenuClick();
    }, 0);
  }

  return (
    <PageSettings.Consumer>
      {({
        pageFloatSubMenu,
        pageFloatSubMenuActive,
        pageFloatSubMenuTop,
        pageFloatSubMenuLeft,
        pageFloatSubMenuBottom,
        pageFloatSubMenuLineTop,
        pageFloatSubMenuLineBottom,
        pageFloatSubMenuArrowTop,
        pageFloatSubMenuArrowBottom,
        handleFloatSubMenuOnMouseOver,
        handleFloatSubMenuOnMouseOut,
      }) => (
        <div
          id="float-sub-menu"
          onFocus={handleFloatSubMenuOnMouseOver}
          onBlur={handleFloatSubMenuOnMouseOut}
          className={`float-sub-menu-container ${
            pageFloatSubMenuActive ? 'd-block' : 'd-none'
          }`}
          style={{
            left: pageFloatSubMenuLeft,
            top: pageFloatSubMenuTop,
            bottom: pageFloatSubMenuBottom,
          }}
        >
          <div
            className="float-sub-menu-arrow"
            style={{
              top: pageFloatSubMenuArrowTop,
              bottom: pageFloatSubMenuArrowBottom,
            }}
          />
          <div
            className="float-sub-menu-line"
            style={{
              top: pageFloatSubMenuLineTop,
              bottom: pageFloatSubMenuLineBottom,
            }}
          />
          <ul className="float-sub-menu">
            {pageFloatSubMenu.children &&
              pageFloatSubMenu.children.map((menu, i) => (
                <Route path={menu.path} exact={menu.exact} key={i}>
                  {({ match }) => (
                    <FloatSubMenuList
                      data={menu}
                      key={i}
                      expand={e => handleExpand(e, i, match)}
                      active={i === state.active}
                      clicked={state.clicked}
                    />
                  )}
                </Route>
              ))}
          </ul>
        </div>
      )}
    </PageSettings.Consumer>
  );
}

export default FloatSubMenu;
