import { combineReducers } from 'redux';

import { reducer as auth } from './ducks/auth';
import { reducer as user } from './ducks/user';
import { reducer as language } from './ducks/language';
import { reducer as agency } from './ducks/agency';

export default combineReducers({
  auth,
  user,
  language,
  agency,
});
