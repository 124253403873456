/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useContext,
} from "react";
import { useSelector } from "react-redux";

import { Row, Col } from "reactstrap";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import Input from "~/components/Input";
import { Panel, PanelHeader, PanelBody } from "~/components/Panel";
import TextArea from "~/components/TextArea";
import ShowSelects from "~/functions/ShowSelects";
import CompanySelect from "~/components/CompanySelect";

import { ApiAxios } from "~/services/api.js";

import { Body, NavMenu, LabelItemJustified } from "./styles";

function CreateNotify({ setPage }) {
  const user = useSelector((state) => state.user.profile);
  const userProfile = useSelector((state) => state.user.userProfile);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [descriptionLenght, setDescriptionLenght] = useState(0);
  const [titleLenght, setTitleLenght] = useState(0);

  const axios = ApiAxios();

  function handleDescriptionLength({ target }) {
    let descriptionLength = target.value.length;

    console.log(descriptionLenght);

    if (descriptionLength <= 200) {
      setDescriptionLenght(descriptionLength);
    }
  }

  function handleTitleLength({ target }) {
    let titleLength = target.value.length;

    console.log(titleLenght);

    if (titleLength <= 100) {
      setTitleLenght(titleLength);
    }
  }

  useEffect(() => {
    async function applyCompanyOptions() {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client`
      );

      setCompaniesOptions(
        response.data.map((e) => {
          return {
            label: e.name,
            value: e.id,
          };
        })
      );
    }

    applyCompanyOptions();
  }, []);

  async function handleSubmit(values) {
    if (values.cliente_id_fk.length === 0)
      return toast.info(
        "Informe pelo menos uma empresa para vincular a notificação."
      );

    try {
      const filter = {
        cliente_id_fk: values.cliente_id_fk,
        titulo: values.titulo,
        descricao: values.descricao,
      };

      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/notify`,
        filter
      );

      formRef.current.reset();

      setCompaniesSelected([]);

      setPage("list");

      return toast.success(`Notificação criada com sucesso.`);
    } catch (error) {
      return toast.error(
        "Não foi possível criar uma notificação, por favor tente mais tarde"
      );
    }
  }

  return (
    <>
      <Panel theme="inverse">
        <PanelHeader noButton>
          <NavMenu id="title">
            <h4>Notificações</h4>
          </NavMenu>
        </PanelHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <PanelBody>
            <Body>
              <Row>
                <Col sm={12} md={6}>
                {/* <LabelItemJustified> */}
                  <Input
                    label="Título"
                    name="titulo"
                    type="text"
                    placeholder="Resumo"
                    required
                    maxLength={70}
                    onChange={handleTitleLength}
                    required
                  />
                </Col>

                {ShowSelects(userProfile.userProfileId).companies && companiesOptions.length > 1 && (
                  <Col sm={12} md={6} lg={4} xl={3}>
                    <CompanySelect
                      name="cliente_id_fk"
                      label="Empresa"
                      placeholder="Selecione..."
                      options={companiesOptions}
                      setCompaniesSelected={setCompaniesSelected}
                      disable={loading}
                      required
                    />
                  </Col>
                )}

                <Col sm={12} md={6}>
                  <LabelItemJustified>
                    <span>Descrição</span>
                    <span>{descriptionLenght}/200</span>
                  </LabelItemJustified>
                  <TextArea
                    style={{
                      resize: "none",
                      maxHeight: 10,
                    }}
                    maxLength={200}
                    onChange={handleDescriptionLength}
                    required
                    name="descricao"
                  ></TextArea>
                </Col>
              </Row>

              <Row>
                <Col>
                  <button type="submit" className="btn btn-primary">
                    Salvar
                  </button>
                </Col>
              </Row>

              <br></br>

              <Row>
                <Col>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setPage("list")}
                  >
                    Cancelar
                  </button>
                </Col>
              </Row>
            </Body>
          </PanelBody>
        </Form>
      </Panel>
    </>
  );
}

export default CreateNotify;
