import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import Input from '~/components/Input';
import DatePicker from '~/components/DatePickerIndicators';
import { Panel, PanelHeader, PanelBody } from '~/components/Panel';
import { Row, Col, Spinner } from 'reactstrap';
import { Form } from '@unform/web';
import { FilterButtons } from '../../styles';
import OrderAndInvoiceContext from '../../context';
import CostCenterSelect from '~/components/CostCenterSelect';
import CompanySelect from '~/components/CompanySelect';
import ShowSelects from '~/functions/ShowSelects';
import ExecutiveSelect from '~/components/ExecutiveSelect';
import { DefaultDate } from "../../constants";

function Filters({
  pageFilters,
  setPageFilters,
}) {
  const userProfile = useSelector(state => state.user.userProfile);
  const {
    formRef,
    loading,
    dateState,
    setDateState,
    pax,
    setPax,
    costCentersOptions,
    costCentersSelected,
    setCostCentersSelected,
    companiesOptions,
    companiesSelected,
    setCompaniesSelected,
    executivesOptions,
    executivesSelected,
    setExecutivesSelected,
  } = useContext(OrderAndInvoiceContext);

  async function handleSubmit() {
    const Companies = companiesSelected.length === 0
      ? companiesOptions
      : companiesSelected;

    const CostCenters = costCentersSelected.length === 0
      ? costCentersOptions
      : costCentersSelected;

    return setPageFilters({
      ...pageFilters,
      dateState: dateState,
      companies: Companies,
      costs: CostCenters,
      traveler: pax,
    });
  }

  async function handleResetForm() {
    formRef.current.reset();

    setCostCentersSelected([]);

    setCompaniesSelected([]);

    setExecutivesSelected([]);

    setPax('');

    setDateState(DefaultDate);

    return await handleSubmit();
  }

  async function resetDate() {
    return setDateState(DefaultDate);
  }

  return (
    <Panel theme="inverse">
      <PanelHeader noButton title="Filtros" />

      <PanelBody>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row>
            <Col sm={12} md={6} lg={4} xl={3}>
              <DatePicker
                label="Período"
                setState={setDateState}
                state={dateState}
                reset={resetDate}
                disable={loading}
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                name="pax"
                label="Viajante"
                value={pax}
                onChange={(e) => setPax(e.target.value)}
                disable={loading}
              />
            </Col>

            {executivesOptions.length > 0 && (
              <Col sm={12} md={6} lg={4} xl={3}>
                <ExecutiveSelect
                  value={executivesSelected}
                  name="executives"
                  label="Executivo de Conta"
                  placeholder="Selecione..."
                  options={executivesOptions}
                  setExecutivesSelected={setExecutivesSelected}
                  setCompaniesSelected={setCompaniesSelected}
                  setCostCentersSelected={setCostCentersSelected}
                  disable={loading}
                  isMulti={false}
                  isClearable
                />
              </Col>
            )}

            {ShowSelects(userProfile.userProfileId).companies && companiesOptions.length > 0 && (
              <Col sm={12} md={6} lg={4} xl={3}>
                <CompanySelect
                  value={companiesSelected}
                  name="CompanyHandle"
                  label="Empresas"
                  placeholder="Empresas"
                  options={companiesOptions}
                  setCompaniesSelected={setCompaniesSelected}
                  setCostCentersSelected={setCostCentersSelected}
                  disable={loading}
                />
              </Col>
            )}

            {costCentersOptions.length > 0 && (
              <Col sm={12} md={6} lg={4} xl={3}>
                <CostCenterSelect
                  value={costCentersSelected}
                  name="costCenter"
                  label="Centros de Custo"
                  placeholder="Centros de Custo"
                  options={costCentersOptions}
                  setCostCentersSelected={setCostCentersSelected}
                  disable={loading}
                />
              </Col>
            )}

            <Col sm={12} md={12} lg={12} xl={12}>
              <FilterButtons>
                <button
                  className="btn btn-warning"
                  type="button"
                  onClick={() => handleResetForm()}>
                  {loading && <Spinner color="#fff" size="sm" />}

                  {!loading && <>Limpar</>}
                </button>

                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}>
                  {loading && <Spinner color="#fff" size="sm" />}

                  {!loading && <>Aplicar</>}
                </button>
              </FilterButtons>
            </Col>
          </Row>
        </Form>
      </PanelBody>
    </Panel>
  );
}

export default Filters;
