const refundInitialState = {
  id: 0,
  emission: '',
  request: '',
  invoice: '',
  traveler: '',
  provider: '',
  company: '',
  travelDate: '',
  costCenter: '',
  stretch: '',
  locator: '',
  ticket: '',
  status: 'available',
  refundId: 0,
  code: '',
  name: '',
  email: '',
  reason: '',
  bankName: '',
  bankAgency: '',
  bankAccount: '',
  cpfCnpj: '',
};

const refundStatus = [
  { label: "Solicitado", value: "requested" },
  { label: "Pendente", value: "pending" },
  { label: "Reembolsado", value: "refunded" },
  { label: "Rejeitado", value: "rejected" },
  { label: "Cancelado", value: "canceled" },
];

const disableInputs = (e) => {
  switch (e) {
    case 'requested':
      return false;
    case 'pending':
      return false;
    case 'refunded':
      return true;
    case 'rejected':
      return true;
    case 'canceled':
      return true;
    default:
      return false;
  }
};

export {
  refundInitialState,
  refundStatus,
  disableInputs,
};
