import styled from 'styled-components';

export const UlAgencys = styled.ul`
  padding: 4px 8px;
  margin: 0;
`;

export const LiAgencys = styled.ul`
  padding: 8px;
  margin: 0;
  background-color: #eee;
  border-radius: 8px;

  p {
    margin: 0 2px;
  }
`;

