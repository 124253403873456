import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background-color: #D54A26 !important;
  box-shadow: 0px 0px 16px #444;
`;

export const LinkHome = styled(Link)`
  padding: 0;
  margin: 0;
  &:link{text-decoration: none}
  &:hover{text-decoration: none}
`;

export const LinkHomeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    height: 44px;
    margin: 0 8px;
  }

  @media (max-width: 500px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    img {
      height: 36px;
    }
  }
`;

export const AgencyName = styled.span`
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  padding: 16px;
  text-decoration: none;
`;
