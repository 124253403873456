import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import { PanelHeader } from '~/components/Panel';
import OrderAndInvoiceContext from '../../../../context';

import {
  HeaderTitle
} from './styles';

import {
  Submit
} from '~/styles/buttons/defaults';

import ExportSpreadSheet from '~/components/ExportSpreadSheet';
import { FormatValueBRL } from '~/functions/FormatValue';

function Header() {
  const {
    data,
    dataToExcel,
    setDataToExcel
  } = useContext(OrderAndInvoiceContext);

  useEffect(() => {
    async function loadDataToExcel () {
      if (data.length === 0) {
        return setDataToExcel([]);
      }

      var footer = {
        grouping: 'Resumo',
        count: 0,
        sumNotBilled: 0,
        sumNotPaid: 0,
        sumPaid: 0,
        total: 0,
      };

      let empty = {
        grouping: '',
        count: '',
        sumNotBilled: '',
        sumNotPaid: '',
        sumPaid: '',
        total: '',
      };

      data.map(e => {
        footer.count += e.count;
        footer.sumNotBilled += e.sumNotBilled;
        footer.sumNotPaid += e.sumNotPaid;
        footer.sumPaid += e.sumPaid;
        footer.total += e.total;
      });

      data.map(e => {
        e.sumNotBilled = FormatValueBRL(e.sumNotBilled);
        e.sumNotPaid = FormatValueBRL(e.sumNotPaid);
        e.sumPaid = FormatValueBRL(e.sumPaid);
        return e.total = FormatValueBRL(e.total);
      });

      footer.sumNotBilled = FormatValueBRL(footer.sumNotBilled);
      footer.sumNotPaid = FormatValueBRL(footer.sumNotPaid);
      footer.sumPaid = FormatValueBRL(footer.sumPaid);
      footer.total = FormatValueBRL(footer.total);

      const newData = [empty].concat(data.concat([ empty, footer ]));

      return setDataToExcel(newData);
    }

    loadDataToExcel();
  }, [data]);

  return (
    <PanelHeader
      noButton
      title=""
      children={
        <HeaderTitle>
          <Row>
            <Col sm={12} md={7}>
              Resultado da Pesquisa
            </Col>

            <Col sm={12} md={5} style={{textAlign: 'right'}}>
              {dataToExcel.length > 0 && (
                <ExportSpreadSheet
                  data={dataToExcel}
                  columns={[
                    {label: 'Agrupamento', value: 'grouping'},
                    {label: 'Transações', value: 'count'},
                    {label: 'Não Faturado', value: 'sumNotBilled'},
                    {label: 'Faturado', value: 'sumNotPaid'},
                    {label: 'Liquidado', value: 'sumPaid'},
                    {label: 'Valor Total', value: 'total'},
                  ]}
                  element={
                    <Submit type='button'>
                      Exportar
                    </Submit>
                  }
                  filename={'GC-Sintético'}
                  sheetName={'Relatório de Gestão de Custos - Sintético'} />
              )}
            </Col>
          </Row>
        </HeaderTitle>
      }
    />
  );
}

export default Header;
