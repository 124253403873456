import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { FormGroup } from 'reactstrap';
import { Label, SubLabel } from "./styles";
import { useField } from '@unform/core';

export default function Select({ name, label, sublabel, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }

          return ref.state.value.map(option => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        style={{ zIndex: 123 }}
        classNamePrefix="react-select"
        {...rest}
        />
      {sublabel && <SubLabel>{sublabel}</SubLabel>}
    </FormGroup>
  );
}

Select.defaultProps = {
  label: null,
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  sublabel: PropTypes.string
};
