import styled from 'styled-components';

export const CreateAction = styled.button`
  font-size: ${props => props.fz ?? '11px'};
  border: none;
  background-color: #000000;
  border-radius: 4px;
  color: white;
  font-weight: 700;
  text-decoration: none;
  margin: 0 8px;
  border: 1px solid #fff;
`;

export const EditAction = styled.button`
  font-size: 11px;
  border: none;
  border-radius: 4px;
  background-color: white;
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  margin: 0 2px;
`;

export const DeleteAction = styled.button`
  font-size: 11px;
  background-color: #FBBA37;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  margin: 0 8px;
`;

export const CancelDeleteAction = styled.button`
  font-size: 11px;
  background-color: #DB575B;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  margin: 0 8px;
`;

export const CancelAction = styled.button`
  font-size: 11px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  margin: 0 8px;
`;

export const CloseAction = styled.button`
  font-size: 11px;
  background-color: transparent;
  border: none;
  color: #777;
  font-weight: 700;
  text-decoration: none;
  margin: 0 8px;
`;

export const SubModulesAction = styled.button`
  font-size: 11px;
  background-color: red;
`;
