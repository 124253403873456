import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import { FormatValueBRL } from '~/functions/FormatValue.js';
import { Bar } from 'react-chartjs-2';

import { GetMonthsNamesBR } from '~/functions/GetMonthsNames.js';
import { Get12Colors } from '~/functions/GetColors.js';

export default function AdvancedPurchase({
  data
}) {
  const axios = ApiAxios();
  const accessToken = useSelector(state => state.auth.accessToken);
  const companies = useSelector(state => state.user.companies);
  const [chartData, setChartData] = useState({});

  const loadChartData = () => {
    setChartData({
      labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      datasets: [{
        label: 'Valor',
        data: [data.jan, data.fev, data.mar, data.abr, data.mai, data.jun, data.jul, data.ago, data.set, data.out, data.nov, data.dez],
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        backgroundColor: ['#00EAE1', '#BDBFBF', '#95F496', '#f4dbab', '#7e2ec2', '#FBBA37', '#DB575B', '#A6D59A', '#D54A26','#333333', '#9e5ef2', '#2fa9a0'],
        borderWidth: 1
      }]
    });
  };

  // useEffect(() => {
  //   async function loadData () {
  //     try {
  //       var res = await axios.post(
  //         `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/air/agreements`,
  //         {
  //           Handle: 1212121
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`
  //           }
  //         }
  //       );

  //       return setEconomyAirAgreements(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }

  //   loadData ();
  // }, [accessToken, companies]);

  useEffect(() => {
    loadChartData ();
  }, [data]);
  return (
    <>
      <Bar data={chartData} options={{
          scales: {
            yAxes: [
                {
                    ticks: {
                        callback: function(label, index, labels) {
                            return FormatValueBRL(label);
                        }
                    },
                }
            ]
        },
        responsive: true,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return `Valor total: ${FormatValueBRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`;
            }
          },
        },
      }} />

      <div style={{ width: '100%',
        display:
          'flex', justifyContent: 'space-around' }}>
        <div className='description'>
          <p style={styles.titleLegend}>Economia Realizada</p>
          <p className='textBoldGreen'>{FormatValueBRL(data.soma)}</p>
        </div>
        <div></div>
        <div className='description'>
          <p style={styles.titleLegend}>Oportunidade De Economia</p>
          <p className='textBoldRed'>{FormatValueBRL(data.somae)}</p>
        </div>
      </div>
    </>
  );
}

const styles = {
  titleLegend: {
    marginTop: "10px",
    fontSize: "11px",
    fontWeight: 700
  },
  redLegend: {
    marginTop: "10px",
    fontSize: "11px",
    fontWeight: 700,
    color: 'red'
  }
}
