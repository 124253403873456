import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';

export default function NationalTicketsRemission({
  data
}) {
    const [chartData11, setChartData11] = useState({});
    const [total, setTotal] = useState(0.00);

    useEffect(() => {
      async function loadData () {
        setChartData11({
          labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
          datasets: [{
            data: [data.jan, data.fev, data.mar, data.abr, data.mai, data.jun, data.jul, data.ago, data.sep, data.oct, data.nov, data.dez],
            backgroundColor: ['rgba(0,0,0,0.0)'],
            borderColor: '#0aeee1',
            borderWidth: 2,
            label: 'Valor Reemissão'
          }]
        })
      }
      loadData();

      let values = Object.values(data);

      let totalTemp = values.reduce((totalTemp, x) => totalTemp + x, 0.00);

      setTotal(totalTemp);
    }, [data])

    return (
        <>
            <Line data={chartData11} options={{ responsive: true,
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            return `Valor Reemissão: ${FormatValueBRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`;
          }
        }
      } }} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='description'>
                    <p style={styles.titleLegend}>Gasto em Reemissão</p>
                    <p className='textBoldRed'>{FormatValueBRL(total)}</p>
                </div>
            </div>
        </>
    );
}
const styles = {
    titleLegend: {
        marginTop: "10px",
        fontSize: "14px",
        fontWeight: 700
    },
    greenLegend: {
        marginTop: "10px",
        fontSize: "14px",
        fontWeight: 700,
        color: '#27b261'
    }
}
