import React from 'react';
import { useSelector } from 'react-redux';
import DropdownLanguage from './DropdownLanguage';
import DropdownProfile from './DropdownProfile';

import {
  Container,
  AgencyName,
  LinkHome,
} from './styles';

import PageSettings from '~/config/PageSettings';

function Header() {
  const { agencySetting } = useSelector(state => state.agency);

  return (
    <PageSettings.Consumer>
      {({
        toggleMobileSidebar,
        toggleMobileTopMenu,
        pageTopMenu,
        pageSidebar,
      }) => (
        <Container
          id="header"
          className="header navbar-inverse">
          <div
            className="navbar-header"
            style={{
              flex: '1 1 auto'
            }}>
            <LinkHome to="/dashboard">
              <AgencyName>
              {`${agencySetting.displayName} ${agencySetting.suffix}`}
              </AgencyName>
            </LinkHome>

            {pageTopMenu && pageSidebar && (
              <button
                type="button"
                className="navbar-toggle pt-0 pb-0 mr-0 collapsed"
                onClick={toggleMobileTopMenu}>

                <span className="fa-stack fa-lg text-inverse">
                  <i className="far fa-square fa-stack-2x" />

                  <i className="fa fa-cog fa-stack-1x" />
                </span>
              </button>
            )}

            {!pageSidebar && pageTopMenu && (
              <button
                type="button"
                className="navbar-toggle"
                onClick={toggleMobileTopMenu}>
                <span className="icon-bar" />

                <span className="icon-bar" />

                <span className="icon-bar" />
              </button>
            )}

            {pageSidebar && (
              <button
                type="button"
                className="navbar-toggle"
                onClick={toggleMobileSidebar}>
                <span className="icon-bar" />

                <span className="icon-bar" />

                <span className="icon-bar" />
              </button>
            )}
          </div>

          <ul className="navbar-nav navbar-right">
            <DropdownLanguage />

            <DropdownProfile />
          </ul>
        </Container>
      )}
    </PageSettings.Consumer>
  );
}

export default Header;
