import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from '~/styles/theme';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ED661F;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1120px;
  padding: 96px;
  /* background: ${colors.PRUSSIAN}; */
  //background: #7e2dc2;
  background: rgb(213, 74, 38);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border: 0;
  margin: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 957px) {
    flex-direction: column;
    padding: 10px;

    section {
      width: 90%;
      position: relative;
      max-width: 90%;
      display: flex;
      flex-direction: column;
    }

    form {
      max-width: 90%;
    }
  }

  section {
    width: 100%;
    max-width: 380px;

    img {
      width: 290px;
      margin-left: 8px;
      height: auto;
      align-self: flex-start;
    }

    h1 {
      margin: 32px 0;
      padding: 5px 0 5px 0;
      font-size: 32px;
      color: #f3f3f3;
    }

    p {
      font-size: 18px;
      color: #c1c1c1;
      line-height: 32px;
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;

      > svg {
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      color: #f3f3f3;
      font-size: 18px;
      text-decoration: none;
      font-weight: 500;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.8;
      }

      svg {
        margin-right: 8px;
      }
    }
  }

  form {
    width: 100%;
    max-width: 450px;

    input {
      //width: 100%;
      width: 450px;
      height: 60px;
      margin-top: 8px;
      font: 400 18px Roboto, sans-serif;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      input + input {
        margin-left: 8px;
      }
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 60px;
  background: rgb(10, 238, 225);
  border: 0;
  border-radius: 8px;
  //color: #fff;
  color: #66259d;
  font-weight: 700;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 60px;
  transition: filter 0.2s;

  ${props =>
    props.validate &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const LinkBack = styled(Link)`
  width: 80px;

  @media (max-width: 957px) {
    position: absolute;
    right: 0px;
    top: 4px;
  }
`;
