import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import TopHotels from '../../Widgets/TopHotels';
import WhiteCard from '../../WhiteCard';
import ShowSelects from '~/functions/ShowSelects';
import CompanyPermission from '~/functions/CompanyPermission';


import DashboardContext from '../../../context';

export default function TopHotels41 () {
  const chartId = 14;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);
  const userProfile = useSelector(state => state.user.userProfile);

  const {
    accessToken,
    companiesOptions,
    setCompaniesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCentersOptions,
    setCostCentersOptions,
    costCentersSelected,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [TopHotelsData, setTopHotelsData] = useState({
    nomeHotel: "",
    quant: 99,
    nome: null,
    total: 189976.4
  });

  useEffect(() => {
    async function loadData () {
      try {
        setTopHotelsData({
          g3: 0,
          g3_tic: 0,
          lan: 0,
          lan_tic: 0,
          ad: 0,
          ad_tic: 0,
          outr: 0,
          outr_tic: 0,
          somaValor: 0,
          somaTick: 0
        });

        setLoading(true);

        setLikes(user.preferences.dashboard.chart11);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        const Companies = companiesSelected.length === 0
        ? companiesOptions.map(x => (x.value))
        : companiesSelected.map(x => (x.value));

        const CostCenters = !CompanyPermission(userProfile.userProfileId).all
          ? (
            costCentersSelected.length === 0
              ? costCentersOptions.map(e => (e.value))
              : costCentersSelected.map(e => (e.value))
          )
          : costCentersSelected.map(e => (e.value));

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const tick = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/tickets/ticketsHotel`,
          {
            handle: Companies,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: CostCenters
          },
          options
        );

        if(tick.data){

          const firstThreeHotels = tick.data.slice(0, 3).sort((a, b) => (a.total < b.total) ? 1 : -1);
          const rest = tick.data.slice(3).sort((a, b) => (a.total < b.total) ? 1 : -1);
          const total = rest.reduce((acc, hotel) =>
            acc + hotel.total
          , 0);
          const tickets = rest.reduce((acc, hotel) =>
            acc + hotel.quant
          , 0);
          const media = rest.reduce((acc, hotel) => acc + hotel.media, 0);


          setTopHotelsData({
            firstThreeHotels,
            rest: {
              restSumValues: {
                total, tickets, media
              },
              restArray: rest,
              count: tick.data.length -3
            }
          })
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected]);

  return (
    <WhiteCard
      title={'Ranking Hotelaria'}
      modalTitle='Observação'
      modalDesc='Valor total da venda x quantidade de diárias x média do valor das diárias.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <TopHotels data={TopHotelsData}/>
    </WhiteCard>
  );
}
