import React, { useContext } from 'react';

import OrderDetails from './OrderDetails';
import ReactTable from '~/components/Table';
import { Panel } from '~/components/Panel';

import {
  columns
} from './constants';

import OrderAndInvoiceContext from '../../../../context';

function Main({
  pageFilters
}) {
  const {
    data,
    loading,
  } = useContext(OrderAndInvoiceContext);

  return (
    <Panel theme="inverse">
      <ReactTable
        columns={columns}
        data={data}
        tableProps={{
          defaultPageSize: 5,
          hasFooter: true,
          hasPagination: true,
          loading: loading,
          resizable: true
        }}
        tableStyle={{
          fontSize: '13px',
          cellPadding: '6px 4px'
        }}
        tableLabels={{
            loadingText: 'Carregando...',
            noDataText: 'Sem registros',
            nextButtonText: 'Próximo',
            previousButtonText: 'Anterior',
            pageOfText: 'de',
            pageSizeSelectText: 'registros',
            pageText: 'Página'
        }}
        subComponent={({row}) => {
          console.log(row);

          return (
            <OrderDetails
              pageFilters={pageFilters}
              filter={row.original.product}
              name={row.original.grouping}
              row={row.original}
            />
          );
        }}
      />
    </Panel>
  );
}

export default Main;
