/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useContext, useRef,  } from "react";

import { Panel, PanelHeader } from "~/components/Panel";

import ChartAnalytics from './ChartAnalytics'

import RequestsContext from "../../context";
import Switch from 'react-bootstrap/Switch';


import { ColumnsTitleMain } from "./styles";


export default function SectionAlert() {
  const { analytics, setAnalytics, budgetAnalytics } = useContext(RequestsContext);

  return (
    <>
      <Panel theme="inverse">
        <PanelHeader noButton>

        <ColumnsTitleMain>
          <div>
            <span>
              Resultado da Pesquisa
            </span>
          </div>

          <div>
            <Switch
              type="switch"
              id="custom-switch"
              label="Analisar"
              checked={analytics}
              onChange={() => setAnalytics(prev => !prev)}
            />
          </div>
        </ColumnsTitleMain>

      </PanelHeader>
          <ChartAnalytics data={budgetAnalytics} />
      </Panel>
    </>
  );
}
