import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import AuthActions from '~/store/modules/ducks/auth';

import { Row, Col } from 'reactstrap';
import { Form } from '@unform/web';
import { ApiAxios } from '~/services/api.js';
import { toast } from 'react-toastify';

import Input from '~/components/Input';
import TextArea from '~/components/TextArea';

import CompanyData from './CompanyData';
import AgencyData from './AgencyData';
import RecoveryPassword from './RecoveryPassword';

import { Panel, PanelHeader, PanelBody } from '~/components/Panel';

import { FormatPhoneBR } from '~/functions/FormatPhone';

import {
  Container
} from './styles';

function UserData({
  data
}) {
  const formRef = useRef(null);

  const [userDataProps, setUserDataProps] = useState({
    typeUser: 0,
    recoveryPassword: false,
  });

  useEffect(() => {
    async function loadUserType () {
      if (
        data.profile.id === 1
        || data.profile.id === 2
        || data.profile.id === 3
        || data.profile.id === 4
        || data.profile.id === 8
        || data.profile.id === 9
      ) {
        return setUserDataProps({
          ...userDataProps,
          typeUser: 1,
        });
      }

      if (
        data.profile.id === 5
        || data.profile.id === 6
        || data.profile.id === 7
      ) {
        return setUserDataProps({
          ...userDataProps,
          typeUser: 2,
        });
      }

    }

    loadUserType();
  }, [data]);

  return (
    <Container>
      <Form ref={formRef}>
        <h4>
          Dados Pessoais
        </h4>

        <Row>
          <Col sm={12} md={6} lg={4} xl={3}>
            <Input
              disabled={true}
              name={'name'}
              label={'Nome'}
              value={data.name} />
          </Col>

          {data.surname !== '' && (
            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                disabled={true}
                name={'surname'}
                label={'Sobrenome'}
                value={data.surname} />
            </Col>
          )}

          <Col sm={12} md={6} lg={4} xl={3}>
            <Input
              disabled={true}
              name={'email'}
              label={'Email Principal'}
              value={data.email} />
          </Col>

          {data.email2 !== '' && (
            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                disabled={true}
                name={'email2'}
                label={'Email Secundário'}
                value={data.email2} />
            </Col>
          )}

          <Col sm={12} md={6} lg={4} xl={3}>
            <Input
              disabled={true}
              name={'phone'}
              label={'Telefone Principal'}
              value={FormatPhoneBR(data.phone)} />
          </Col>

          {data.phone2 !== '' && (
            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                disabled={true}
                name={'phone2'}
                label={'Telefone Secundário'}
                value={FormatPhoneBR(data.phone2)} />
            </Col>
          )}

          <Col sm={12} md={6} lg={4} xl={3}>
            <Input
              disabled={true}
              name={'password'}
              type={'password'}
              label={'Senha'}
              value={'**********'}
              searchButton={true}
              callbackSearch={() => {
                return setUserDataProps({
                  ...userDataProps,
                  recoveryPassword: true,
                });
              }}
              searchIcon={'fas fa-pencil-alt'} />
          </Col>
        </Row>

        {userDataProps.recoveryPassword === true && (
          <RecoveryPassword
            data={data}
            userDataProps={userDataProps}
            setUserDataProps={setUserDataProps} />
        )}

        <hr />

        <Row>
          <Col sm={12} md={6}>
            <Row>
              <Col sm={12}>
                <Input
                  disabled={true}
                  name={'profileTitle'}
                  label={'Tipo de Usuário'}
                  value={data.profile.title} />
              </Col>

              <Col sm={12}>
                <TextArea
                  disabled={true}
                  name={'profileDesc'}
                  label={'Detalhes'}
                  value={data.profile.desc} />
              </Col>
            </Row>
          </Col>

          <Col sm={12} md={6}>
            <p style={{ fontWeight: '600'}}>
              Meus Módulos
            </p>

            <ul type='none'>
              {data.menus.map(e => (
                <li>
                  <i className={e.menu.icon} />

                  {e.menu.title}
                </li>
              ))}
            </ul>
          </Col>
        </Row>

        {userDataProps.typeUser === 1 && (
          <>
            <hr />

            <AgencyData />
          </>
        )}

        {userDataProps.typeUser === 2 && data.companies.length === 1 && (
          <>
            <hr />

            <CompanyData data={data} />
          </>
        )}
      </Form>
    </Container>
  );
}

UserData.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    email2: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    phone2: PropTypes.string.isRequired,
    profile: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      desc: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
    costCenters: PropTypes.array,
    companies: PropTypes.array,
    menus: PropTypes.array,
  })
};

export default UserData;
