import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AuthActions from '~/store/modules/ducks/auth';

import { Row, Col } from 'reactstrap';
import { Form } from '@unform/web';
import { Spinner } from 'reactstrap';
import { ApiAxios } from '~/services/api.js';
import { toast } from 'react-toastify';

import Input from '~/components/Input';
import { Panel, PanelHeader, PanelBody } from '~/components/Panel';

import { Container } from './styles';

import { verifyDefaultPass } from '~/functions/String';

function RecoveryPassword({
  data,
  userDataProps,
  setUserDataProps,
}) {
  const formRef = useRef(null);

  const axios = ApiAxios();
  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.auth.accessToken);
  const user = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);
  const [lockBody, setLockBody] = useState(true);
  const [lockPass, setLockPass] = useState(true);
  const [messagePassword, setMessagePassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [userInfo, setUserInfo] = useState({
    name: user.name,
    nickname: user.nickname,
    login: user.login,
  });
  const [lockPassArea, setLockPassArea] = useState(true);

  const [passInfoNew, setPassInfoNew] = useState({
    oldPass: '',
    pass: '',
    newPass: '',
  });

  const [passValues, setPassValues] = useState({
    oldPass: '',
    pass: '',
    newPass: '',
  });

  const [passRules, setPassRules] = useState({
    oldPassMessage: '',
    passMessage: '',
    newPassMessage: '',
    oldPassLock: true,
    passLock: true,
    newPassLock: true,
    lockSend: true,
  });

  useEffect(() => {
    function unlockSend () {
      if (verifyDefaultPass(passValues.pass) && passValues.pass === passValues.newPass)
        return setPassRules({
          ...passRules,
          newPassMessage: '',
          lockSend: false,
        });

      return setPassRules({
        ...passRules,
        newPassMessage: 'As senhas não conferem.',
        lockSend: true,
      });
    }

    unlockSend();
  }, [passValues]);

  function handlePass (field, value) {
    if (value.length > 12) return false;

    setPassValues({
      ...passValues,
      [field]: value
    });

    if (!verifyDefaultPass(value))
      return setPassRules({
        ...passRules,
        [`${field}Message`]: 'A senha deve possuir de 8 a 12 caracteres, contendo obrigatoriamente letras e números.',
        [`${field}Lock`]: true,
      });

    if (verifyDefaultPass(value))
      return setPassRules({
        ...passRules,
        [`${field}Message`]: '',
        [`${field}Lock`]: false,
      });
  }

  async function handleSubmitNewPass() {
    try {
      setLoading(true);

      const filter = {
        Email: user.email,
        Password: passValues.newPass,
        OldPassword: passValues.oldPass,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/updateSelfPass`,
        filter
      );

      if (response.data.statusCode !== 200) {
        setLoading(false);

        return toast.info(response.data.message);
      }

      setLoading(false);

      toast.success(
        'Senha alterada com sucesso.'
      );

      setPassValues({
        oldPass: '',
        pass: '',
        newPass: '',
      });

      setPassRules({
        ...passRules,
        oldPassLock: true,
        passLock: true,
        newPassLock: true,
        lockSend: true,
      });

      return setTimeout(() => setUserDataProps({
        ...userDataProps,
        recoveryPassword: false,
      }), 1000);
    } catch (error) {
      console.log(error);

      toast.error(
        'Não foi possível redefinir sua senha, por favor tente mais tarde'
      );

      return setLoading(false);
    }
  }

  return (
    <Container>
      <div className={'contentRecoveryPassword'}>
        <div className={'headerRecoveryPassword'}>
          <h6>
            Redefinir Senha
          </h6>

          <button
            type='button'
            onClick={() =>  setUserDataProps({
              ...userDataProps,
              recoveryPassword: false,
            })}>
            <i className={'fas fa-times'} />
          </button>
        </div>

        <div className={'bodyRecoveryPassword'}>
          <Form ref={formRef} onSubmit={() => {}}>
            <Row>
              <Col sm={12} md={6} lg={4} xl={3}>
                <Input
                  id={'recoveryOldPass'}
                  type={'password'}
                  label={'Senha Antiga'}
                  name={'pass'}
                  value={passValues.oldPass}
                  onChange={e => handlePass('oldPass', e.target.value)}
                  style={{
                    border:
                      !passRules.oldPassLock
                        ? '2px solid green'
                        : '2px solid red'
                  }} />

                  <span>{passRules.oldPassMessage}</span>
              </Col>

              <Col sm={12} md={6} lg={4} xl={3}>
                <Input
                  id={'recoveryPass'}
                  type={'password'}
                  label={'Nova Senha'}
                  name={'pass'}
                  value={passValues.pass}
                  onChange={e => handlePass('pass', e.target.value)}
                  style={{
                    border:
                      !passRules.passLock
                        ? '2px solid green'
                        : '2px solid red'
                  }} />

                <span>{passRules.passMessage}</span>
              </Col>

              <Col sm={12} md={6} lg={4} xl={3}>
                <Input
                  id={'recoveryNewPass'}
                  type={'password'}
                  label={'Confirme a Senha'}
                  name={'pass'}
                  value={passValues.newPass}
                  onChange={e => handlePass('newPass', e.target.value)}
                  style={{
                    border:
                      !passRules.newPassLock
                        ? '2px solid green'
                        : '2px solid red'
                  }} />

                <span>{passRules.newPassMessage}</span>
              </Col>

              <Col sm={12} style={{ textAlign: 'right' }}>
                <button
                  type="button"
                  className="btn btn-success"
                  disabled={passRules.lockSend}
                  style={{
                    backgroundColor: 'black',
                    color: '#fff',
                    textDecoration: 'none',
                    border: 'none',
                  }}
                  onClick={() => handleSubmitNewPass()}>
                  {!loading && ('Salvar')}

                  {loading && (
                    <Spinner size='sm' />
                  )}
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Container>
  );
}

export default RecoveryPassword;
