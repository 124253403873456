export const columns = ({
  view,
}) => {
  switch (view) {
    case 1:
      return [
        {
          Header: 'Data Emissão',
          accessor: 'emission',
        },
        {
          Header: 'Localizador',
          accessor: 'locator',
        },
        {
          Header: 'Bilhete',
          accessor: 'ticket',
        },
        {
          Header: 'Empresa',
          accessor: 'company',
        },
        {
          Header: 'Data da Viagem',
          accessor: 'travelDate',
        },
        {
          Header: 'Fornecedor',
          accessor: 'provider',
        },
        {
          Header: 'Trecho',
          accessor: 'stretch',
        },
      ];
    case 2:
      return [
        {
          Header: 'Data Emissão',
          accessor: 'emission',
        },
        {
          Header: 'Empresa',
          accessor: 'company',
        },
        {
          Header: 'Data da Viagem',
          accessor: 'travelDate',
        },
        {
          Header: 'Fornecedor',
          accessor: 'provider',
        },
        {
          Header: 'Trecho',
          accessor: 'stretch',
        },
      ];
    default:
      return [];
  }
};
