import React, { useState, useEffect, useRef } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { Form } from "@unform/web";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DefaultDate } from "~/pages/Dashboard/components/DashboardUser/constants";
import DatePicker from "~/components/DatePickerIndicators";
import { api } from "~/services/api";
import Input from "~/components/Input";
import Select from "~/components/Select";
import { Row, Col, Spinner } from 'reactstrap';
import Card from './Card';
import Table from './Table';

import {
  Header,
  Title,
  ButtonMenu,
  DatePickerContainer,
  ContainerDestinyFilter,
  FilterButtons,
} from "./styles";

export const getFirstTwoNames = (completeName) => {
  if (completeName) {
    const normalizedName = completeName.split("/").reverse().join(" ");
    const arrayNames = normalizedName.split(" ");
    return `${arrayNames[0]} ${arrayNames[1] ?? ""} ${arrayNames[2] ?? ""}`;
  } else return completeName;
};

export const parseDate = (date) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);

  return `${day}/${month}/${year}`;
};

export const getTheNameOfFile = (filter) => {
  switch (filter) {
    case "next-flights":
      return "PróximasViagens";
    case "last-month":
      return "ÚltimoMês";
    case "last-semester":
      return "ÚltimoSemestre";
    case "custom-period":
      return "Personalizado";
    default:
      return "Sem infomação.";
  }
};

export const getTheNameOfFileComplete = (filter) => {
  switch (filter) {
    case "next-flights":
      return "Próximas Viagens";
    case "last-month":
      return "Último Mês";
    case "last-semester":
      return "Último Semestre";
    case "custom-period":
      return "Personalizado";
    default:
      return "Sem infomação.";
  }
};

function TravelerList({ match }) {
  const history = useHistory();
  const companies = useSelector((state) => state.user.companies);
  const costCenters = useSelector(state => state.user.costCenters);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [costCentersOptions, setCostCentersOptions] = useState([]);
  const [matchFilter, setMatchFilter] = useState('');

  const [pageProps, setPageProps] = useState({
    title: '',
    type: 'card',
    // inputFilterType: ''
  });

  const [travelers, setTravelers] = useState([]);
  const [originalTravelers, setOriginalTravelers] = useState([]);

  const formRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [originsValues, setOriginsValues] = useState([]);
  const [destinationsValues, setDestinationsValues] = useState([]);
  const [locator, setLocator] = useState('');
  const [locatorFilter, setLocatorFilter] = useState('');
  const [pax, setPax] = useState('');
  const [paxFilter, setPaxFilter] = useState('');

  const [dateState, setDateState] = useState(DefaultDate);

  const resetDateState = () => setDateState(DefaultDate);

  const parseDateComming = (date) => {
    const parsedDateDay = date.slice(0, 2);
    const parsedDateMonth = date.slice(3, 5);
    const parsedDateYear = date.slice(6, 10);

    return Number(`${parsedDateYear}${parsedDateMonth}${parsedDateDay}`);
  };

  useEffect(() => {
    async function loadCompanies() {
      const list = companies.map(company => company.handleEmpresa);

      return setCompaniesOptions(list);
    }

    loadCompanies();
  }, [companies]);

  useEffect(() => {
    async function loadCostCenter() {
      const list = costCenters.map(({ costCenter }) => costCenter);

      return setCostCentersOptions(list);
    }

    loadCostCenter();
  }, [costCenters]);

  useEffect(() => {
    async function loadMatch() {
      setMatchFilter(match.params.id);

      let newTitle = '';

      switch (match.params.id) {
        case "next-flights":
          newTitle = "Próximas Viagens";
          break;
        case "last-month":
          newTitle = "Último mês";
          break;
        case "last-semester":
          newTitle = "Último semestre";
          break;
        case "custom-period":
          newTitle = "Período personalizado";
          break;
        default:
          break;
      }

      return setPageProps({
        ...pageProps,
        title: newTitle,
      });
    }

    loadMatch();
  }, [match]);

  useEffect(() => {
    async function loadTravelers() {
      if (matchFilter === '') {
        return false;
      }

      try {
        setLoading(true);

        setTravelers([]);

        setOriginalTravelers([]);

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const { data } = await api.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/flights/${matchFilter}`,
          {
            initialDate: parseDateComming(dateState.selectedDate.startDate),
            finalDate: parseDateComming(dateState.selectedDate.endDate),
            CompanyHandle: companiesOptions,
            CoustCenterNames: costCentersOptions,
            Locator: locatorFilter,
            passenger: paxFilter,
          },
          options
        );

        const [origins, destinations] = await travelsCrawler(data);

        setOrigins(origins);

        setDestinations(destinations);

        setLoading(false);

        setOriginalTravelers(data);

        return setTravelers(data);
      } catch (error) {
        setLoading(false);

        return console.log(error);
      }
    }

    loadTravelers();
  }, [dateState, companiesOptions, costCentersOptions, locatorFilter, paxFilter]);

  function filteredTravelers() {
    if (originsValues.length === 0 && destinationsValues.length === 0) {
      return travelers;
    }

    const filterTravelers = travelers.filter(({ origin, destiny }) => {
      let filteredByOrigin = originsValues.some(
        (values) =>
          values.label === `${origin.name} (${origin.airportCode.trim()})`
      );
      let filteredByDestiny = destinationsValues.some(
        (values) =>
          values.label === `${destiny.name} (${destiny.airportCode.trim()})`
      );

      return filteredByOrigin || filteredByDestiny;
    });

    return filterTravelers;
  }

  async function travelsCrawler(travels) {
    const originsCrawled = travels.map(({ origin }) => ({
      label: `${origin.name} (${origin.airportCode.trim()})`,
      value: origin.name,
    }));

    const destinationsCrawled = travels.map(({ destiny }) => ({
      label: `${destiny.name} (${destiny.airportCode.trim()})`,
      value: destiny.name,
    }));

    const origins = originsCrawled.reduce((acc, current) => {
      const x = acc.find((item) => item.label === current.label);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const destinations = destinationsCrawled.reduce((acc, current) => {
      const x = acc.find((item) => item.label === current.label);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    return [origins, destinations];
  }

  function handleSetOriginsFilter(origin) {
    if (origin !== null) {
      return setOriginsValues(origin);
    }

    return setOriginsValues([]);
  }

  function handleSetDestinationsFilter(destination) {
    if (destination !== null) {
      return setDestinationsValues(destination);
    }

    return setDestinationsValues([]);
  }

  function searchTravelers() {
    setPaxFilter(pax);
    setLocatorFilter(locator);
  }


  return (
    <>
      <Header>
        <Title new={true}>
          <IoMdArrowBack size={30} onClick={() => history.push("/traveler")} />
          Controle do Viajante
        </Title>
        <hr></hr>
      </Header>

      <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
        <Title>
          {pageProps.title}
        </Title>

        <ButtonMenu
          type={'button'}
          onClick={() => setPageProps({
            ...pageProps,
            type: pageProps.type === 'card'
              ? 'table'
              : 'card'
          })}>
          Mudar visualização
        </ButtonMenu>
      </div>

      <Form ref={formRef} onSubmit={() => { }}>
        <ContainerDestinyFilter>
          <Row>
            {matchFilter === "custom-period" && (
              <Col sm={12} md={12} lg={4} xl={3}>
                <DatePicker
                  label={'Data da Viagem'}
                  setState={setDateState}
                  state={dateState}
                  reset={resetDateState}
                />
              </Col>
            )}

            <Col sm={12} md={6} lg={4} xl={3}>
              <Select
                value={originsValues}
                label="Origem"
                name="origins"
                placeholder=""
                options={origins}
                isClearable
                isMulti={true}
                onChange={handleSetOriginsFilter}
                disable={loading}
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Select
                value={destinationsValues}
                label="Destino"
                placeholder=""
                name="destinations"
                isClearable
                options={destinations}
                isMulti={true}
                onChange={handleSetDestinationsFilter}
                disable={loading}
              />
            </Col>

            <Col sm={12} md={5} lg={matchFilter === "custom-period" ? 3 : 4} xl={matchFilter === "custom-period" ? 3 : 2}>
              <Input
                label="Localizador/Bilhete"
                name="locator"
                placeholder=""
                onChange={e => setLocator(e.target.value)}
                value={locator}
                //searchButtonLabel={true}
                //searchButtonText={'Buscar'}
                // callbackSearch={() => setLocatorFilter(locator)}
                disable={loading}
                loading={loading}
              />
            </Col>

            <Col sm={12} md={5} lg={4} xl={3}>
              <Input
                label="Viajante"
                name="pax"
                placeholder=""
                onChange={e => setPax(e.target.value)}
                value={pax}
                //searchButtonLabel={true}
                //searchButtonText={'Buscar'}
                // callbackSearch={() => setPaxFilter(pax)}
                disable={loading}
                loading={loading}
              />
            </Col>

            <Col sm={12} md={2} lg={matchFilter === "custom-period" ? 5 : 8} xl={matchFilter === "custom-period" ? 9 : 1}>
              <FilterButtons>
                <div>
                  <div></div>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => searchTravelers()}
                    disabled={loading}
                  >
                    {loading && <Spinner color="#fff" size="sm" />}
                    {!loading && <>Aplicar</>}
                  </button>
                </div>

              </FilterButtons>
            </Col>
          </Row>
        </ContainerDestinyFilter>
      </Form>
      {/* <br /> */}
      {pageProps.type === 'card' && !loading && (
        <Card travelers={filteredTravelers()} />
      )}

      {pageProps.type === 'table' && !loading && (
        <Table
          travelers={filteredTravelers()}
          loading={loading}
          title={pageProps.title}
          date={dateState}
          showDate={match.params.id === 'custom-period'} />
      )}
    </>
  );
};

export default TravelerList;
