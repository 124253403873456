const ShowSelects = (userProfileId) => {
  switch (userProfileId) {
    case 1:
      return ({
        companies: true,
        costCenters: true,
        projects: true,
        executives: true,
        showNotify: true,
        isAgency: true,
        emitter: true,
      });
    case 2:
      return ({
        companies: true,
        costCenters: true,
        projects: true,
        executives: true,
        showNotify: true,
        isAgency: false,
        emitter: true,
      });
    case 3:
      return ({
        companies: true,
        costCenters: true,
        projects: true,
        executives: true,
        showNotify: true,
        isAgency: false,
        emitter: true,
      });
    case 4:
      return ({
        companies: true,
        costCenters: true,
        projects: true,
        executives: true,
        showNotify: true,
        isAgency: true,
        emitter: true,
      });
    case 5:
      return ({
        companies: false,
        costCenters: true,
        projects: true,
        executives: false,
        isAgency: false,
        showNotify: false,
        emitter: false,
      });
    case 6:
      return ({
        companies: true,
        costCenters: true,
        projects: true,
        executives: false,
        isAgency: false,
        showNotify: false,
        emitter: false,
      });
    case 7:
      return ({
        companies: false,
        costCenters: true,
        projects: true,
        executives: false,
        isAgency: false,
        showNotify: false,
        emitter: false,
      });
    case 8:
      return ({
        companies: true,
        costCenters: true,
        projects: true,
        executives: true,
        showNotify: true,
        isAgency: true,
        emitter: true,
      });
      case 9:
        return ({
        companies: true,
        costCenters: true,
        projects: true,
        executives: true,
        showNotify: true,
        isAgency: false,
        emitter: true,
      });
    default:
      return ({
        companies: true,
        costCenters: true,
        projects: true,
        executives: false,
        showNotify: false,
        isAgency: false,
        emitter: false,
      });
  }
}

export default ShowSelects;
