import React, { useState, useEffect } from 'react';
import { PanelHeader } from '~/components/Panel';
import { Row, Col } from 'reactstrap';
import {
  Submit
} from '~/styles/buttons/defaults';
import ExportSpreadSheet from '~/components/ExportSpreadSheet';
import { FormatValueBRL } from '~/functions/FormatValue';
import { ApiAxios } from '~/services/api.js';
import { toast } from 'react-toastify';
import {
  Spinner
} from 'reactstrap';
import moment from 'moment';

function Header({
  data
}) {
  const axios = ApiAxios();
  const [dataToExcel, setDataToExcel] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadDataToExcel () {

      var newData = [];

      newData = newData.concat([{a: ''}]);

      data.map(e => {
        if (e.checked) {
          setLoading(true);

          axios.post(
            `${process.env.REACT_APP_ENDPOINT_INVOICE}/invoice/ordered`,
            { FaturaHandle: e.handle },
          ).then(res => {
            newData = newData.concat([{
              a: `Fatura ${e.handle}`
            }]);

            newData = newData.concat([{
              a: `Status`,
              b: `N° Fatura`,
              c: `Vencimento`,
              d: `Valor Fatura`,
              e: `Valor Pago`,
            }]);

            newData = newData.concat([{
              a: e.status,
              b: e.handle,
              c: e.dataVencimentoFormatada,
              d: FormatValueBRL(e.valorFatura),
              e: FormatValueBRL(e.valorPago),
            }]);

            newData = newData.concat([{
              a: `Emissão`,
              b: `Referência`,
              c: `OS`,
              d: `Serviço`,
              e: `Fornecedor`,
              f: `Viajante`,
              g: `Centro de Custo`,
              h: `Valor do Item`,
            }]);

            res.data.map(x => {
              newData = newData.concat([{
                a: x.dataFormatada,
                b: x.handle,
                c: x.numOS,
                d: x.tipoFaturamento,
                e: x.fornecedor,
                f: x.pax,
                g: x.centroCusto,
                h: FormatValueBRL(x.valorTotalDoDocumento),
              }]);
            });

            newData = newData.concat([{a: ''}, {a: ''}]);

            setDataToExcel(newData);

            setLoading(false);
          }).catch(() => setLoading(false));
        }
      });

      return true;
    }

    loadDataToExcel();
  }, [data]);

  return (
    <PanelHeader
      noButton
      title=""
      children={
        <div style={{ width: '100%' }}>
          <Row>
            <Col sm={12} md={7}>
              Resultado da Pesquisa
            </Col>

            <Col sm={12} md={5} style={{textAlign: 'right'}}>
              {dataToExcel.length > 0 && (
                <ExportSpreadSheet
                  data={dataToExcel}
                  columns={[
                    {label: 'Emissão', value: 'a'},
                    {label: moment().format('DD/MM/YYYY HH:mm:ss'), value: 'b'},
                    {label: '', value: 'c'},
                    {label: '', value: 'd'},
                    {label: '', value: 'e'},
                    {label: '', value: 'f'},
                    {label: '', value: 'g'},
                    {label: '', value: 'h'},
                  ]}
                  element={
                    <Submit
                      disabled={loading}
                      type='button'>
                      {loading && <Spinner color="#fff" size="sm" />}

                      {!loading && 'Exportar'}
                    </Submit>
                  }
                  filename={'FAT-Relatório'}
                  sheetName={'Lista de Faturas'} />
              )}

              {dataToExcel.length === 0 && (
                <Submit
                  type='button'
                  onClick={() => toast.info('Selecione as faturas que deseja exportar.')}>
                  Exportar
                </Submit>
              )}
            </Col>
          </Row>
        </div>
      }
    />
  );
}

export default Header;
