import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Spinner } from 'reactstrap';
import { Form } from '@unform/web';
import {
  FormatPhoneOnlyNumbersBR,
  FormatPhoneBR
} from '~/functions/FormatPhone';
import { ApiAxios } from '~/services/api.js';

import {
  EditAction,
  DeleteAction
} from '~/components/ActionsOfTables';

import { Panel } from '~/components/Panel';
import Input from '~/components/Input';
import Select from '~/components/Select';

import {
  SubmitButton,
  PanelFilterCompanyGroup,
  PanelSearchResult,
  SpanPasswordAlert,
  SpanLi,
  LiSearch,
} from './styles';


function NewUser({
  setPage
}) {
  const axios = ApiAxios();
  const accessToken = useSelector(state => state.auth.accessToken);
  const formRef = useRef(null);
  const user = useSelector(state => state.user.profile);
  const userProfile = useSelector(state => state.user.userProfile);

  const [nameOfUser, setNameOfUser] = useState('');
  const [emailOfUser, setEmailOfUser] = useState('');
  const [phoneOfUser, setPhoneOfUser] = useState('');
  const [phoneOfUserFormated, setPhoneOfUserFormated] = useState('');
  const [phoneOfUserChecked, setPhoneOfUserChecked] = useState(false);
  const [messagePhone, setMessagePhone] = useState('');
  const [passOfUser, setPassOfUser] = useState('');
  const [passCheckOfUser, setPassCheckOfUser] = useState('');
  const [messagePassword, setMessagePassword] = useState('');
  const [messageConfirmPassword, setMessageConfirmPassword] = useState([]);
  const [typeOfNewUser, setTypeOfNewUser] = useState('0');
  const [agencyUserProfiles, setAgencyUserProfiles] = useState([]);
  const [agencyUserProfileSelected, setAgencyUserProfileSelected] = useState(null);

  const [loading, setLoading] = useState(false);

  const [originalListItems, setOriginalListItems] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [listItemsVinculados, setListItemsVinculados] = useState([]);

  useEffect(() => {
    async function loadItems () {
      setListItems([]);

      setListItemsVinculados([]);

      let response = await axios.options(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client`
      );

      setOriginalListItems(response.data);

      return setListItems(response.data);
    }

    loadItems();
  }, [accessToken]);

  useEffect(() => {
    async function loadAgencyUserProfiles () {
      setAgencyUserProfiles([]);

      let response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/user-type/ofagency`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        },
      );

      if (userProfile.userProfileId !== 4) {
        return setAgencyUserProfiles(
          response.data.filter(x => x.value !== '4')
        );
      }

      return setAgencyUserProfiles(response.data);
    }

    loadAgencyUserProfiles();
  }, [accessToken]);

  useEffect(() => {
    if (phoneOfUser.length > 0 && phoneOfUser.length < 11) {
      setMessagePhone('Telefone inválido.');
    }

    if (phoneOfUser.length === 11) {
      setMessagePhone('');
    }
  }, [phoneOfUser]);

  function handlePhone (value) {
    let phone = FormatPhoneOnlyNumbersBR(value);
    let phoneFormated = FormatPhoneBR(phone);

    setPhoneOfUser(phone);
    setPhoneOfUserFormated(phoneFormated);

    setPhoneOfUserChecked(phone.length === 11 || phone.length === 10);

    return true;
  }

  async function handleSubmit(body) {
    try {
      setLoading(true);

      if (body.password !== body.confirmPassword) {
        setLoading(false);
        return toast.error('As senhas não correspondem.');
      }

      if (
        body.password.length < 8 ||
        body.password.length > 12 ||
        !body.password.match(/\d/g) ||
        !body.password.match(/[a-z]/gi)
      ) {
        setLoading(false);

        return toast.error(
          'A senha deve possuir de 8 a 12 caracteres, contendo obrigatoriamente letras e números.'
        );
      }

      axios.defaults.headers.Authorization = `Bearer ${accessToken}`;

      const filter = {
        email: emailOfUser,
        phone: phoneOfUser,
        name: nameOfUser,
        password: passOfUser,
        userProfileId: Number(body.userType),
        companies: listItemsVinculados.map(e => e.value)
      };

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/agency-user`,
        filter
      );

      if (response.data.created === 2) {
        setLoading(false);

        return toast.success(response.data.message);
      }

      toast.success('Usuário Interno cadastrado com sucesso!');

      setTimeout(() => setPage('list'), 1000);

      return setLoading(false);
    } catch (error) {
      setLoading(false);

      return toast.error('Não foi possível cadastrar o usuário interno.');
    }
  }

  function checkPassword(value) {
    setPassOfUser(value);

    setMessagePassword('');

    if (!value) {
      return setMessagePassword('Este campo é obrigatório');
    }

    if (
      value.length < 8 ||
      value.length > 12 ||
      !value.match(/\d/g) ||
      !value.match(/[a-z]/gi)
    ) {
      return setMessagePassword(
        'A senha deve possuir de 8 a 12 caracteres, contendo obrigatoriamente letras e números.'
      );
    }

    return true;
  }

  function checkConfirmPassword(value) {
    setPassCheckOfUser(value);

    setMessageConfirmPassword('');

    if (!value) {
      return setMessageConfirmPassword('Este campo é obrigatório');
    }

    if (passOfUser !== value) {
      return setMessageConfirmPassword('As senhas não correspondem');
    }

    return true;
  }

  function targetItem(item) {
    const newListItems = [];

    listItems.map(list => {
      if (list.value !== item.value) {
        newListItems.push(list);
      }
      return false;
    });

    if (listItemsVinculados.length > 0) {
      const res = listItemsVinculados.filter(
        lv => lv.value === item.value
      );

      if (res.length <= 0) {
        setListItems(newListItems);

        return setListItemsVinculados(listItemsVinculados.concat([item]));
      }

      return toast.info(
        `A Empresa: ${item.empresa}, já está vinculada.`
      );
    }

    setListItems(newListItems);

    return setListItemsVinculados(listItemsVinculados.concat([item]));
  }

  function untargetItem(item) {
    const newListItemsVinculados = [];

    listItemsVinculados.map(list => {
      if (list.value !== item.value) {
        newListItemsVinculados.push(list);
      }
      return false;
    });

    setListItemsVinculados(newListItemsVinculados);

    if (listItems.length > 0) {
      const res = listItems.filter(
        lc => lc.value === item.value
      );

      if (res.length <= 0)
        return setListItems(listItems.concat([item]));

      return false;
    }

    return setListItems(listItems.concat([item]));
  }

  function targetAllItems () {
    setListItemsVinculados(originalListItems);

    setListItems([]);

    return true;
  }

  function untargetAllItems () {
    setListItems(originalListItems);

    setListItemsVinculados([]);

    return true;
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Row>
        <Col lg="6">
          <Input
            name="name"
            placeholder="Nome Completo"
            label="Nome"
            value={nameOfUser}
            onChange={e => setNameOfUser(e.target.value)}
            required
            />
        </Col>

        <Col lg="6">
          <Input
            name="email"
            type="email"
            placeholder="E-mail"
            label="E-mail"
            value={emailOfUser}
            onChange={e => setEmailOfUser(e.target.value)}
            required
          />
        </Col>

        <Col lg='6'>
          <Input
            name="telefone"
            type="text"
            placeholder="Telefone"
            label="Telefone"
            value={phoneOfUserFormated}
            onChange={e => handlePhone(e.target.value)}
            required
          />

          <SpanPasswordAlert>
            {messagePhone}
          </SpanPasswordAlert>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <Input
            name="password"
            type="password"
            placeholder="Senha"
            label="Senha"
            value={passOfUser}
            onChange={e => {
              setPassCheckOfUser('');

              return checkPassword(e.target.value)
            }}
            required
          />

          <SpanPasswordAlert>
            {messagePassword}
          </SpanPasswordAlert>
        </Col>

        <Col lg="6">
          <Input
            name="confirmPassword"
            type="password"
            placeholder="Confirme a Senha"
            label="Confirme a Senha"
            value={passCheckOfUser}
            onChange={e => checkConfirmPassword(e.target.value)}
            required
          />

          <SpanPasswordAlert>
            {messageConfirmPassword}
          </SpanPasswordAlert>
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <Select
            name="userType"
            label="Tipo de Usuário"
            placeholder="Selecione..."
            onChange={(e) => {
              // setListItems(originalListItems);

              // setListItemsVinculados([]);

              setAgencyUserProfileSelected(e.value);

              setTypeOfNewUser(e.value);

              return true;
            }}
            options={agencyUserProfiles}
          />
        </Col>
      </Row>

      {agencyUserProfileSelected === '9' && (
        <Row>
          <Col lg="6">
            <Panel>
              <PanelFilterCompanyGroup>
                <b>Escolha as Empresas</b>

                <EditAction
                  className='btn'
                  disabled={listItems.length === 0}
                  type='button'
                  onClick={() => targetAllItems()}>
                  Adicionar Todos
                </EditAction>
              </PanelFilterCompanyGroup>

              <PanelSearchResult>
                <ul type="none">
                  {
                    listItems.map((row, i) => (
                      <LiSearch key={i.toString()}>
                        <SpanLi>{row.label}</SpanLi>

                        <button
                          className="btn"
                          type="button"
                          onClick={() => targetItem(row)}>
                          <i className="fa fa-plus" aria-hidden="true" />
                        </button>
                      </LiSearch>
                    ))
                  }
                </ul>
              </PanelSearchResult>
            </Panel>
          </Col>

          <Col lg="6">
            <Panel>
              <PanelFilterCompanyGroup>
                <b>Empresas Vinculadas</b>

                <DeleteAction
                  disabled={listItemsVinculados.length === 0}
                  className='btn'
                  type='button'
                  onClick={() => untargetAllItems()}>
                  Remover Todos
                </DeleteAction>
              </PanelFilterCompanyGroup>

              <PanelSearchResult>
                <ul type="none">
                  {
                    listItemsVinculados.map((row, i) => (
                      <LiSearch key={i.toString()}>
                        <SpanLi>{row.label}</SpanLi>

                        <button
                          className="btn"
                          type="button"
                          onClick={() => untargetItem(row)}
                        >
                          <i className="fa fa-minus" aria-hidden="true" />
                        </button>
                      </LiSearch>
                    ))
                  }
                </ul>
              </PanelSearchResult>
            </Panel>
          </Col>
        </Row>
      )}

      <Row>
        <SubmitButton>
          <button
            className="btn btn-success"
            type="submit"
            disabled={
              loading
              || typeOfNewUser === 0
              || (typeOfNewUser === '7' && listItemsVinculados.length === 0)
              || nameOfUser === ''
              || emailOfUser === ''
              || passOfUser === ''
              || passCheckOfUser === ''
              || agencyUserProfileSelected === ''
              || agencyUserProfileSelected === null
              || !phoneOfUserChecked
            }
            style={{
              background: '#000000',
              color: '#fff',
              fontWeight: 700,
              marginLeft: 10
            }}>
            {loading && <Spinner color="#fff" size="sm" />}

            {!loading && <>Salvar</>}
          </button>
        </SubmitButton>
      </Row>
    </Form>
  );
}

export default NewUser;
