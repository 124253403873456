import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalIntegration from '~/components/ModalIntegration/index.js';
import {
  Dropdown,
  DropdownToggle,
   DropdownMenu,
   DropdownItem,
} from 'reactstrap';

import { toast } from "react-toastify";

import { ApiAxios } from "~/services/api.js";

import { SpanLink, DropItem, DropMenu } from './styles';

import UserProfileModal from './components/Modal';

import AuthActions from '~/store/modules/ducks/auth';

import ShowSelects from '~/functions/ShowSelects';

import ShowWS from '~/functions/ShowWS';

function DropdownProfile() {
  const [state, setState] = useState({ dropdownOpen: false });

  const user = useSelector(state => state.user);
  const userProfile = useSelector(state => state.user.userProfile);

  const [name, setName] = useState('');

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const axios = ApiAxios();

  useEffect(() => {
    if(user && user.profile.name) {
      var names = user.profile.name.split(' ');

      var res = '';

      if(names) {
        names.map((e, i) => {
          if (i === 0 || i === 1)
            res += e + ' ';

          return true;
        });
      }

      setName(res);
    }
  }, [user]);

  function toggle() {
    setState({
      dropdownOpen: !state.dropdownOpen,
    });
  }

  function salvarProto(user) {
    
    setShowModal(true);

    return true;
  }

  async function handleLogout() {
    //DESLOGAR USUARIO

     try {

      var res = await axios.post(
         `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/signout`,
         {
          email: user.profile.email
         }
       );

      if (res.data === true)
      {
        dispatch(AuthActions.signOut());
      }
    } catch (error) {
      //erro ao deslogar
      return toast.info(
        "Faça login novamente para continuar."
      );
    }
  }

  async function showModalIntegration(){
    setShowModal(true);
  }
  function closeModalIntegration(){
    setShowModal(false); 
  }

  return (
    <>
      <UserProfileModal show={show} handleClose={handleClose} />

        <ModalIntegration
          showModal={showModal}
          closeModal={closeModalIntegration}
        />

      <Dropdown
        isOpen={state.dropdownOpen}
        toggle={toggle}
        className="dropdown navbar-user"
        tag="li">
        <DropdownToggle tag="a">
          <span className="d-md-inline">
            {name}
          </span>

          {' '}

          <b className="caret" />
        </DropdownToggle>

        <DropMenu className="dropdown-menu dropdown-menu-right" tag="ul">
          <SpanLink to="/profile">
            <DropItem>
              Meu Perfil
            </DropItem>
          </SpanLink>

          {ShowSelects(userProfile.userProfileId).showNotify && (
            <SpanLink to="/notification">
              <DropItem>
                Notificações
              </DropItem>
            </SpanLink>
          )}

          {user.profile.temws && (

                <DropItem onClick={showModalIntegration}>
                  Integrações
                </DropItem>
          )}

          <SpanLink to="/about">
            <DropItem>
              Sobre
            </DropItem>
          </SpanLink>

          <DropItem onClick={handleLogout}>
            Sair
          </DropItem>
        </DropMenu>
      </Dropdown>
    </>
  );
}

export default DropdownProfile;
