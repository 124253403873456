import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.div`
  padding: 16px;
  margin: 16px 0;
  border-radius: 16px;

  img {
    width: 320px;
  }
`;

export const Main = styled.div`
  width: 100%;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

export const Footer = styled.div`
  width: 100%;
  padding: 16px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;
