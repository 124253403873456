import React from 'react';

import { Col, Row } from 'reactstrap';

import VolumePurchase1 from './Charts/1VolumePurchase';
import FeePerProduct2 from './Charts/2FeePerProduct';
import RankingCC3 from './Charts/3RankingCC';
import RankingPass4 from './Charts/4RankingPass';
import RankingPassHotel17 from './Charts/17RankingPassHotel';
import VolumeFee5 from './Charts/5VolumeFee';
import DaysOutlays10 from './Charts/10DaysOutlays';
import TopHotels14 from './Charts/14TopHotels';
import RankingCities from './Charts/16RankingCities';

export default function Outlays() {
  return (
    <>
      <Row
        style={{
          width: '100%',
          padding: '0',
          margin: '0'
        }}>

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <VolumeFee5 />
        </Col>

        <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <VolumePurchase1 />
        </Col>

        {/* <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <VolumePurchase1 />
        </Col> */}

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <DaysOutlays10 />
        </Col>

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <FeePerProduct2 />
        </Col>

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <RankingCities />
        </Col>

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <TopHotels14 />
        </Col>

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <RankingPassHotel17 />
        </Col>

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <RankingCC3 />
        </Col>

      </Row>
    </>
  );
}
