import React from 'react';

import {
  SolicitationButton,
  RequestedButton,
  PendingButton,
  RefundedButton,
  RejectedButton,
  CancelededButton,
} from './styles';

export const columns = ({
  userProfile,
  openModal = () => {},
  view = 0,
}) => {
  switch (view) {
    case 1:
      return [
        {
          Header: () => null,
          id: 'expander',
          Cell: ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
              <i className={`
                fas fa-caret-${row.isExpanded ? 'up' : 'down'}
              `} />
            </span>
          ),
          width: 40,
        },
        {
          Header: 'Pedido/OS',
          accessor: 'request',
          width: 100,
        },
        {
          Header: 'Fatura',
          accessor: 'invoice',
          width: 100,
        },
        {
          Header: 'Viajante',
          accessor: 'traveler',
          width: 200,
        },
        {
          Header: 'Centro de Custo',
          accessor: 'costCenter',
        },
        {
          Header: 'Solicitado em',
          accessor: 'createdAt',
          width: 120,
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ row }) => {
            switch (row.original.status) {
              case 'available':
                return (
                  <SolicitationButton onClick={() => openModal(row.original)}>
                    Solicitar
                  </SolicitationButton>
                );
              case 'requested':
                return (
                  <RequestedButton onClick={() => openModal(row.original)}>
                    Solicitado<br/>
                    {`(${row.original.code})`} <br/>
                  </RequestedButton>
                );
              case 'pending':
                return (
                  <PendingButton onClick={() => openModal(row.original)}>
                    Pendente<br/>
                  {`(${row.original.code})`}
                  </PendingButton>
                );
              case 'refunded':
                return (
                  <RefundedButton onClick={() => openModal(row.original)}>
                    Reembolsado<br/>
                  {`(${row.original.code})`}
                  </RefundedButton>
                );
              case 'rejected':
                return (
                  <RejectedButton onClick={() => openModal(row.original)}>
                    Rejeitado<br/>
                  {`(${row.original.code})`}
                  </RejectedButton>
                );
              case 'canceled':
                return (
                  <CancelededButton onClick={() => openModal(row.original)}>
                    Cancelado<br/>
                  {`(${row.original.code})`}
                  </CancelededButton>
                );
              default:
                return (
                  <span></span>
                );
            }
          },
        },
      ];
      case 2:
        return [
          {
            Header: () => null,
            id: 'expander',
            Cell: ({ row }) => (
              <span {...row.getToggleRowExpandedProps()}>
                <i className={`
                  fas fa-caret-${row.isExpanded ? 'up' : 'down'}
                `} />
              </span>
            ),
            width: 40,
          },
          {
            Header: 'Pedido/OS',
            accessor: 'request',
          },
          {
            Header: 'Fatura',
            accessor: 'invoice',
          },
          {
            Header: 'Viajante',
            accessor: 'traveler',
          },
          {
            Header: 'Centro de Custo',
            accessor: 'costCenter',
          },
          {
            Header: 'Localizador',
            accessor: 'locator',
          },
          {
            Header: 'Bilhete',
            accessor: 'ticket',
          },
        ];
    default:
      return [];
  }
};
