import React, { useRef, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import EmptyList from '~/components/EmptyList';
import {removeSpace} from '~/functions/String';

import{shuffelWord} from '~/functions/EmbaralhaString';

import TextArea from '~/components/TextArea';

import { Panel, PanelHeader, PanelBody } from '~/components/Panel';

import { getFirstTwoNames } from '~/pages/TravelerList/index';

import history from '~/services/history';


import { Form } from '@unform/web';

import Input from '~/components/Input';

import { FormGroup } from 'reactstrap';

import {PaginationFooter, Button, Tabela, Pesquisa, Modal, ModalClose, ModalBox, ModalContent, ModalTextarea, ModalButton, ModalTitle, DetailsItem, Body, EditAction } from './styles'

import { ApiAxios } from '~/services/api.js';
import SliderToggle from '~/components/SliderToggle';

import {
  Card,
  CardTitle,
  CardBody,
  CardBodyItem,
} from '~/styles/card';

import {
  SpaceBetweenDiv
} from '~/styles/divs';

import {
  CancelDeleteAction,
  DeleteAction,
  CreateAction
} from '~/components/ActionsOfTables';
import { Row, Col, Spinner } from 'reactstrap';

import CompanyRegistrationContext from '../../../context';

function List({
  company,
  setCompanyPage,
  setManagerToEditViajantes,
  setManagerToDelete,
  setManagerToDetails,
  setManagerToEdit,
  setManagerPage
}) {
  const axios = ApiAxios();

  const formRef = useRef(null);

  const {
    companyToDetail
  } = useContext(CompanyRegistrationContext);

  const [loading, setLoading] = useState(false);
  const [paxFilter, setPaxFilter] = useState('');

  const [modalSendMessage, toggleModalSendMessage] = useState(false);
  const [modalSetContact, toggleModalSetContact] = useState(false);

  const [userSelectedForModal, setUserSelectedForModal] = useState({name: "", index: "",id: ""})

  const closeModal = (target, toogleModal) => { if(target.id == 'modal-background') return toogleModal(false) }

  const [whatsapp, setWhatsapp] = useState("");

  const [travelers, setTravelers] = useState([]);
  const [alterStatus, setAlterStatus] = useState(false);
  const [viajantes, setViajantes] = useState([]);
  const [originalViajantes, setOriginalViajantes] = useState([]);
  const [contactNumbers, setContactNumbers] = useState([]);

  const [travelerList, setTravelerList] = useState([]);

  const [actualPage, setActualPage] = useState(0);

  const [travelerListPaginated, setTravelerListPaginated] = useState([]);


  function sliceTravelerToPagination(travelerArray, pageSize) {
    let index = 0;
    let arrayLength = travelerArray.length;
    let auxArray = [];

    for (index = 0; index < arrayLength; index += pageSize) {
      let chunk = travelerArray.slice(index, index + pageSize);
      auxArray.push(chunk);
    }

    return auxArray;
  }


  useEffect(() => {
    async function getTravelers() {
      try
      {
        const response = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/obt/${company.code}`
        );


        setTravelerList(response.data);

        setViajantes(response.data);

        return setOriginalViajantes(response.data);
      } catch (error) {
        return toast.error('Não foi possível obter o usuário.');
      }
    }

    getTravelers();
  }, []);

  useEffect(() => {
    setTravelerListPaginated(sliceTravelerToPagination(travelerList, 10));
  }, [travelerList]);

  const handleSearch = (value) => {
    setLoading(true);

    if(paxFilter === "" ){
      setLoading(false);
      return setTravelerList(originalViajantes);
    }

    var newListViajantes = [];

    viajantes.map(e => {

      var a = removeSpace(e.name) +' '+ removeSpace(e.middleName) +' '+ removeSpace(e.lastName);

      const pos = a.toLowerCase().indexOf(paxFilter.toLowerCase());


      if (pos !== -1) {
        newListViajantes.push(e);
      }
    });

    if (newListViajantes.length === 0) {
      toast.dismiss();

      toast.info("Nenhum viajante encontrado!");

      setLoading(false);

      return setTimeout(() => {
        setTravelerList(originalViajantes);
      }, 1000);
    }

    setTravelerList(newListViajantes);

    return setLoading(false);
  }

  return (

    <Row>
      <Col sm={12} lg={9}>
        <div style={{maxHeight: '500px', overflow: 'auto', }}>
          <table className="table table-responsive">
            <thead>
              <tr>
                <th>Viajante</th>
                <th>Telefone</th>
                <th>E-mail</th>
                <th></th>
              </tr>
            </thead>

            {viajantes.length === 0 && (<EmptyList />)}

            {viajantes.length > 0 && (
              <tbody style={{width: '100%'}}>
                {travelerListPaginated[actualPage]?.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td width="250">
                        {e.name +" "+ e.middleName+" "+ e.lastName}


                      </td>

                      <td width='120'>
                        {e.primaryPhoneNumber}
                      </td>

                      <td width='200'>
                        {e.email}
                      </td>
                      <td>
                        <DeleteAction
                        style={{backgroundColor: '#000000', color: 'white'}}
                        type='button'
                        align = 'center'
                        onClick={() => setManagerToEdit({
                          manager: e,
                          type: 2
                        })}>
                        Editar
                        </DeleteAction>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </Col>

      <Col sm={12} lg={3}>
        <Form ref={formRef} onSubmit={handleSearch}>
          <Row>
            <Col sm={12}>
              <FormGroup>
                <Input
                  name="viajante"
                  type="search"
                  value={paxFilter}
                  onChange={e => {
                    if (e.target.value === '') {
                      setViajantes(originalViajantes);
                    }

                    setPaxFilter(e.target.value);

                  }}
                  placeholder="Pesquisar viajante"
                  searchButton={true}
                  loading={loading}
                  callbackSearch={handleSearch} />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Col>

      <PaginationFooter>
        <Button
          type='button'
          className={actualPage === 0 ? "on-limit" : ""}
          onClick={() => {
            if (actualPage !== 0) {
              setActualPage(actualPage - 1);
            }
          }}
        >
          {"<"}
        </Button>
        {`${actualPage === 0 ? actualPage + 1 : actualPage * 10 + 1} -  ${
          (actualPage === 0 ? actualPage : actualPage * 10) +
          travelerListPaginated[actualPage]?.length
        }
        de  ${travelerList.length}`}
        <Button
          type='button'
          className={
            actualPage + 1 === travelerListPaginated.length
              ? "on-limit"
              : ""
          }
          onClick={() => {
            if (actualPage + 1 !== travelerListPaginated.length) {
              setActualPage(actualPage + 1);
            }
          }}
        >
          {">"}
        </Button>
      </PaginationFooter>
    </Row>





  );
}

export default List;
