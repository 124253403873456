import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import EmptyList from '~/components/EmptyList';

import { ApiAxios } from '~/services/api.js';

import {
  Card,
  CardTitle,
  CardBody,
  CardBodyItem,
} from '~/styles/card';

import {
  CancelDeleteAction,
  DeleteAction,
  EditAction
} from '~/components/ActionsOfTables';
import { Row, Col } from 'reactstrap';

import CompanyRegistrationContext from '../../../context';

function List({
  setManagerToDelete,
  setManagerToDetails,
  setManagerToEdit
}) {
  const axios = ApiAxios();

  const {
    companyToDetail
  } = useContext(CompanyRegistrationContext);

  const [gccs, setGccs] = useState([]);
  const [gvs, setGvs] = useState([]);

  useEffect(() => {
    async function getGccs() {
      try
      {
        const gccResponse = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/getcostmanagers`,
          {
            ClientCode: companyToDetail.code
          }
        );

        return setGccs(gccResponse.data);
      } catch (error) {
        return toast.error('Não foi possível cadastrar o usuário.');
      }
    }

    getGccs();
  }, []);

  useEffect(() => {
    async function getGvs() {
      try
      {
        const gccResponse = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/gettravelmanagers`,
          {
            ClientCode: companyToDetail.code
          }
        );

        return setGvs(gccResponse.data);
      } catch (error) {
        return toast.error('Não foi possível cadastrar o usuário.');
      }
    }

    getGvs();
  }, []);

  return <Row>
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardTitle>
          <h5>Licensas</h5>
        </CardTitle>

        <CardBody>
          {/* {gccs.length === 0 && (<EmptyList />)} */}

          {gccs.map((e, i) => {
            return (
              <CardBodyItem key={i}>
                <div>
                  <p><b>Nome:</b> {e.name}</p>

                  <p><b>Email:</b> {e.email}</p>

                  <p>
                    <b>Ações:</b>

                    <EditAction
                      type='button'
                      onClick={() => setManagerToDetails({
                        manager: e,
                        type: 1
                      })}>
                      Detalhes
                    </EditAction>

                    <DeleteAction
                      type='button'
                      onClick={() => setManagerToEdit({
                        manager: e,
                        type: 1
                      })}>
                      Editar
                    </DeleteAction>

                    <CancelDeleteAction
                      type='button'
                      onClick={() => setManagerToDelete({
                        manager: e,
                        type: 1
                      })}>
                      Remover
                    </CancelDeleteAction>
                  </p>
                </div>
              </CardBodyItem>
            );
          })}
        </CardBody>
      </Card>
    </Col>

    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardTitle>
          <h5>Acessos e Restrições</h5>
        </CardTitle>

        <CardBody>
          {/* {gvs.length === 0 && (<EmptyList />)} */}

          {gvs.map((e, i) => {
            return (
              <CardBodyItem key={i}>
                <div>
                  <p><b>Nome:</b> {e.name}</p>

                  <p><b>Email:</b> {e.email}</p>

                  <p>
                    <b>Ações:</b>

                    <EditAction
                      type='button'
                      onClick={() => setManagerToDetails({
                        manager: e,
                        type: 2
                      })}>
                      Detalhes
                    </EditAction>

                    <DeleteAction
                      type='button'
                      onClick={() => setManagerToEdit({
                        manager: e,
                        type: 2
                      })}>
                      Editar
                    </DeleteAction>

                    <CancelDeleteAction
                      type='button'
                      onClick={() => setManagerToDelete({
                        manager: e,
                        type: 2
                      })}>
                      Remover
                    </CancelDeleteAction>
                  </p>
                </div>
              </CardBodyItem>
            );
          })}
        </CardBody>
      </Card>
    </Col>
  </Row>;
}

export default List;
