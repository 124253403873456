import React, { useState } from 'react';
import moment from 'moment';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useTranslation } from 'react-i18next';

import {
  Group,
  Group2,
  Input,
  Span,
  SpanDiv,
} from './styles';

export default function DatePicker({
  state,
  setState,
  label,
  reset,
  ...rest
}) {
  const { t } = useTranslation('Components');

  function handleDateApplyEvent(_, picker) {
    const gap = state.endDate.diff(picker.startDate, 'days');

    const currentWeek = `${picker.startDate.format(
      'DD/MM/YYYY'
    )} - ${picker.endDate.format('DD/MM/YYYY')}`;

    const prevWeek = `${moment(picker.startDate)
      .subtract(gap, 'days')
      .format('DD/MM/YYYY')} - ${moment(picker.startDate)
      .subtract(1, 'days')
      .format('DD/MM/YYYY')}`;

    setState({
      currentWeek,
      prevWeek,
      startDate: picker.startDate,
      endDate: picker.endDate,
      selectedDate: {
        startDate: picker.startDate.format('DD/MM/YYYY'),
        endDate: picker.endDate.format('DD/MM/YYYY'),
      },
    });
  }

  return (
    <Group>
      {label && (
        <label htmlFor="dateRangePicker">
          {label}
        </label>
      )}

      <Group2>
        <DateRangePicker
          id="dateRangePicker"
          onApply={handleDateApplyEvent}
          initialSettings={{
            startDate: state.startDate,
            endDate: state.endDate,
            alwaysShowCalendars:true,
            autoApply:true,
            locale:t('DatePicker.Options'),
            ranges:{
              [t('DatePicker.Ranges.Today')]: [moment(), moment()],
              [t('DatePicker.Ranges.Yesterday')]: [
                moment().subtract(1, 'days'),
                moment().subtract(1, 'days'),
              ],
              [t('DatePicker.Ranges.LastSevenDays')]: [
                moment().subtract(6, 'days'),
                moment(),
              ],
              [t('DatePicker.Ranges.LastThirtyDays')]: [
                moment().subtract(29, 'days'),
                moment(),
              ],
              [t('DatePicker.Ranges.ThisMonth')]: [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
              [t('DatePicker.Ranges.LastMonth')]: [
                moment()
                  .subtract(1, 'months')
                  .startOf('month'),
                moment()
                  .subtract(1, 'months')
                  .endOf('month'),
              ],
              [t('DatePicker.Ranges.LastSixMonth')]: [
                moment()
                  .subtract(5, 'months')
                  .startOf('month'),
                moment()
                  .endOf('month'),
              ],
              [t('DatePicker.Ranges.LastYear')]: [
                moment().subtract(1, 'year').startOf('year'),
                moment().subtract(1, 'year').endOf('year'),
              ],
            }
          }}
          {...rest} >
          <Input>
            <Span>
              {state.currentWeek}
            </Span>
          </Input>
        </DateRangePicker>

        <SpanDiv onClick={reset}>
          <i className="fa fa-times" />
        </SpanDiv>
      </Group2>
    </Group>
  );
}
