import React, { useContext, useState } from 'react';

import {
  CloseAction
} from '~/components/ActionsOfTables';

import {
  Container,
  ContainerPd8
} from '~/styles/container';

import {
  SpaceBetweenDiv
} from '~/styles/divs';

import Auth from './components/Auth';
import Managers from './components/Managers';
import Travelers from './components/Travelers';
import CostCenters from './components/CostCenters';
import Dashboard from './components/Dashboard';

import CompanyRegistrationContext from '../context';

function Companies({
  setType
}) {

  const {
    companyToDetail
  } = useContext(CompanyRegistrationContext);

  const [companyPage, setCompanyPage] = useState('dashboard');

  return (
    <Container>
      <ContainerPd8>
        <SpaceBetweenDiv>
          <h4>
            {companyToDetail.fantasy}
          </h4>

          <CloseAction
            type='button'
            onClick={() => setType('list')}>
            <i className='fas fa-times' />
          </CloseAction>
        </SpaceBetweenDiv>
      </ContainerPd8>

      <hr />

      {companyPage === 'dashboard' && (
        <Dashboard
          company={companyToDetail}
          setCompanyPage={setCompanyPage} />
      )}

      {/* {companyPage === 'auth' && (
        <Auth
          company={companyToDetail}
          companyPage={companyPage}
          setCompanyPage={setCompanyPage} />
      )} */}

      {companyPage === 'managers' && (
        <Managers
          company={companyToDetail}
          companyPage={companyPage}
          setCompanyPage={setCompanyPage}/>
      )}

      {companyPage === 'travelers' && (
        <Travelers
          company={companyToDetail}
          companyPage={companyPage}
          setCompanyPage={setCompanyPage}/>
      )}

      {companyPage === 'costCenters' && (
        <CostCenters
          company={companyToDetail}
          companyPage={companyPage}
          setCompanyPage={setCompanyPage}/>
      )}
    </Container>
  );
}

export default Companies;
