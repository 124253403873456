import styled from "styled-components";

export const PaginationFooter = styled.div`
  display: flex;
  justify-content: last baseline;
  margin-left: 320px;

  width: 100%;

  button {
    border: 0;
    background-color: transparent;
    margin: 0 10px;
  }
`;

export const Button = styled.div`
  margin: 0px 4px;
`;

export const LabelItemJustified = styled.div`
  display: flex;
  justify-content: space-between;
`;
