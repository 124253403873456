export default (profile, companies) => {
  const logo = {
    grupoEmpresarial: 'Casablanca Turismo',
    logotipo: 'null',
  };
  if (profile.id === 99) return logo;

  if (companies) {
    if (companies.length === 1) {
      logo.grupoEmpresarial = companies[0].empresa;
      if (companies[0].logotipo) {
        logo.logotipo = companies[0].logotipo;
      }
      else {
        logo.logotipo = 'noFoto';
      }
    }

    if (companies.length > 1) {
      let groups = 0;
      companies.map(c => {
        return groups += (c.handleEmpresa === c.handleGrupo) ? 1 : 0;
      });

      if (groups === 1) {
        companies.map(c => {
          if (c.handleEmpresa === c.handleGrupo) {
            logo.logotipo = c.logotipo;
            logo.grupoEmpresarial = c.grupoEmpresarial;
            return true;
          }
          return true;
        })
      }

    }




  }

  // if (companies) {
  //   if (companies.length > 1) {
  //     companies.map(c => {
  //       if (c.handleEmpresa === c.handleGrupo) {
  //         logo.grupoEmpresarial = c.grupoEmpresarial;
  //       }
  //       if (c.handleEmpresa === c.handleGrupo && c.logotipo) {
  //         logo.grupoEmpresarial = c.grupoEmpresarial;
  //         logo.logotipo = c.logotipo;
  //       }
  //       return true;
  //     });
  //   }
  // }

  return logo;

  // if (profile.description.toLowerCase() === 'cliente') {
  //   const { grupoEmpresarial, logotipo } = companies.find(x => {
  //     if (x.handleGrupo === x.handleEmpresa) {
  //       return {
  //         grupoEmpresarial: x.grupoEmpresarial,
  //         logotipo: x.logotipo,
  //       };
  //     }

  //     return {
  //       grupoEmpresarial: x.empresa,
  //       logotipo: x.logotipo,
  //     };
  //   });

  //   return {
  //     grupoEmpresarial,
  //     logotipo,
  //   };
  // }
};
