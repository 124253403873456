import React from "react";
import ReactTable from '~/components/Table';
import { Panel } from "~/components/Panel";
import Header from './Header';
import { columns } from "./constants";

function Table({
  travelers,
  loading,
  title
}) {
  return (
    <Panel theme="inverse">
      <Header data={travelers} title={title} />

      <ReactTable
        columns={columns()}
        data={travelers}
        tableProps={{
          defaultPageSize: 10,
          hasFooter: false,
          hasPagination: true,
          loading: loading,
          resizable: true
        }}
        tableStyle={{
          fontSize: '13px',
          cellPadding: '6px 4px'
        }}
        tableLabels={{
          loadingText: 'Carregando...',
          noDataText: 'Sem registros',
          nextButtonText: 'Próximo',
          previousButtonText: 'Anterior',
          pageOfText: 'de',
          pageSizeSelectText: 'registros',
          pageText: 'Página'
        }}
      />
    </Panel>
  );
}

export default Table;
