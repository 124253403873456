import React from 'react';
import './styles.css';

function SliderToggle({
  defaultChecked = false,
  checked = false,
  label = '',
  info = '',
  fontSize = '10px',
  click = () => {},
  textAlign = 'center'
}) {
  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'start'
    }}>
        <span style={{
          fontSize: fontSize,
          fontWeight: '600',
          width: '100%',
          textAlign: textAlign
        }}>
          {label}
        </span>

        <div style={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: textAlign,
          width: '100%',
          margin: '4px 0'
        }}>
          <div></div>
          <div>
            <span style={{
              fontSize: fontSize,
              fontWeight: '600',
            }}>
              {info}
            </span>

            <label className="switch">
              <input
                type="checkbox"
                checked={checked}
                onChange={click}
                />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
    </div>
  );
}

export default SliderToggle;
