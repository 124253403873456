import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

export default function DaysOutlays({
  data
}) {
    const [chartData11, setChartData11] = useState({});

  useEffect(() => {
    async function loadData () {
      setChartData11({
        labels: [
          'Aéreo',
          'Hotel',
          'Seguro Viagem',
          'Visto',
          'Outros',
        ],
        datasets: [{
          data: [
            data.tk,
            data.h,
            data.sv,
            data.vi,
            data.ou,
          ],
          backgroundColor: [
            '#00EAE1',
            '#BDBFBF',
            '#95F496',
            '#f4dbab',
            '#7e2ec2',
          ],
          borderColor: '#0aeee1',
          borderWidth: 2,
          label: 'Quantidade de Transações'
        }]
      })
    }

    loadData();
  }, [data])

  return (
    <Line data={chartData11} options={{ responsive: true }} />
  );
}
const styles = {
    titleLegend: {
        marginTop: "10px",
        fontSize: "14px",
        fontWeight: 700
    },
    greenLegend: {
        marginTop: "10px",
        fontSize: "14px",
        fontWeight: 700,
        color: '#27b261'
    }
}
