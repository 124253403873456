import React from 'react';

import { Col, Row } from 'reactstrap';

import VolumePurchase1 from './Charts/1VolumePurchase';
import FeePerProduct2 from './Charts/2FeePerProduct';
import RankingCC3 from './Charts/3RankingCC';
import RankingPass4 from './Charts/4RankingPass';
import VolumeFee5 from './Charts/5VolumeFee';
import Grafico1 from './Charts/Grafico1';
import Grafico2 from './Charts/Grafico2';


export default function Outlays () {
  return (
    <>
      <Row
        style={{
          width: '100%',
          padding: '0',
          margin: '0'
        }}>

        <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <FeePerProduct2 />
        </Col>

        <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <RankingCC3 />
        </Col>

        <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <Grafico1 />
        </Col>

        {/* <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <RankingPass4 />
        </Col> */}



        <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <Grafico2 />
        </Col>

        <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <VolumeFee5 />
        </Col>

      </Row>
    </>
  );
}
