import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Spinner } from 'reactstrap';
import { Form } from '@unform/web';
import {
  FormatPhoneOnlyNumbersBR,
  FormatPhoneBR
} from '~/functions/FormatPhone';
import { ApiAxios } from '~/services/api.js';

import {
  EditAction,
  DeleteAction,
  CreateAction
} from '~/components/ActionsOfTables';

import { Panel } from '~/components/Panel';
import Input from '~/components/Input';
import Select from '~/components/Select';

import {
  SubmitButton,
  PanelFilterCompanyGroup,
  PanelSearchResult,
  SpanPasswordAlert,
  SpanLi,
  LiSearch,
} from './styles';

function Edit({
  company,
  setManagerPage,
  managerToEdit
}) {
  const axios = ApiAxios();
  const accessToken = useSelector(state => state.auth.accessToken);
  const formRef = useRef(null);

  const [idOfUser, setIdOfUser] = useState('');
  const [nameOfUser, setNameOfUser] = useState('');
  const [middleNameOfUser, setMiddleNameOfUser] = useState('');
  const [lastNameOfUser, setLastNameOfUser] = useState('');
  const [numberOfUser, setNumberOfUser] = useState('');
  const [oldEmailOfUser, setOldEmailOfUser] = useState('');
  const [emailOfUser, setEmailOfUser] = useState('');
  const [phoneOfUser, setPhoneOfUser] = useState('');
  const [phoneOfUserFormated, setPhoneOfUserFormated] = useState('');
  const [cpfOfUser, setCpfOfUser] = useState('');
  const [cpfOfUserFormated, setCpfOfUserFormated] = useState('');
  const [phoneOfUserChecked, setPhoneOfUserChecked] = useState(false);
  const [cpfOfUserChecked, setCpfOfUserChecked] = useState(false);

  const [messagePhone, setMessagePhone] = useState('');
  const [messageEmailUsed, setMessageEmailUsed] = useState('');
  const [typeOfNewUser, setTypeOfNewUser] = useState('0');
  const [clientsUserProfiles, setClientsUserProfiles] = useState([]);
  const [clientsUserProfileSelected, setClientsUserProfileSelected] = useState(null);

  const [alterPhone, setAlterPhone] = useState(false);
  const [alterName, setAlterName] = useState(false);

  const [loading, setLoading] = useState(false);
  const [editChange, setEditChange] = useState(true);

  const [originalListCC, setOriginalListCC] = useState([]);
  const [listCC, setListCC] = useState([]);
  const [listCCVinculadas, setListCCVinculadas] = useState([]);

  useEffect(() => {
    function loadManagerToEdit() {
      setNameOfUser(managerToEdit.manager.name);
      setMiddleNameOfUser(managerToEdit.manager.middleName);
      setLastNameOfUser(managerToEdit.manager.lastName);
      setIdOfUser(managerToEdit.manager.id);
      handlePhone(managerToEdit.manager.primaryPhoneNumber);
      handleCpf(managerToEdit.manager.cpf);
      setEmailOfUser(managerToEdit.manager.email);
      setOldEmailOfUser(managerToEdit.manager.email);
    }

    loadManagerToEdit();
  }, [managerToEdit]);

  useEffect(() => {
    async function loadClientCostCenter () {
      try {
        const { data: ccsOfCostManager } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/manager/getcostcenters/${managerToEdit.manager.email}`
        );

        const { data: ccsOfClient } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/manager/getcostcenters/notof/${managerToEdit.manager.email}`
        );

        setListCC(ccsOfClient);

        setListCCVinculadas(ccsOfCostManager);

        setOriginalListCC(ccsOfClient.concat(ccsOfCostManager));

        return true;
      } catch (error) {
        return console.log(error);
      }
    }

    loadClientCostCenter();
  }, []);

  useEffect(() => {
    async function loadClientsUserProfile () {
      setClientsUserProfiles([]);

      let response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/user-type/ofclients`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        },
      );

      return setClientsUserProfiles(response.data);
    }

    loadClientsUserProfile();
  }, [accessToken]);

  useEffect(() => {
    if (phoneOfUser.length > 0 && phoneOfUser.length < 11) {
      setMessagePhone('Telefone inválido.');
    }

    if (phoneOfUser.length === 11 || phoneOfUser.length === 0) {
      setMessagePhone('');
    }
  }, [phoneOfUser]);

  const PHONEMask = (value) =>{
    if(value === null){
      return '';
    }

    return value
      .replace(/\D/g, '')
      .replace(/^0/, '')
      .replace(/^(\d\d)(\d{4})(\d{5}).*/, '($1) $2-$3');
  }

  const PHONEMaskOnlyNumbersBR = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/^0/, '')
      .replace(/^(\d\d)(\d{4})(\d{4}).*/, '$1$2$3');
  }

  const CPFMask = (value) =>{
    if(value === null){
      return '';
    }

    return value
      .replace(/\D/g, '')
      .replace(/^0/, '')
      .replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
  }

  function handlePhone (value) {
    //let phone = FormatPhoneOnlyNumbersBR(value);
    let phone = PHONEMask(value);

    //let phoneFormated = FormatPhoneBR(value);
    let phoneFormated = PHONEMask(value);

    setPhoneOfUser(phone);
    setPhoneOfUserFormated(phoneFormated);

    setPhoneOfUserChecked(phone.length === 1);

    return true;
  }

  function handleCpf (value) {
    //let phone = FormatPhoneOnlyNumbersBR(value);
    let cpf = CPFMask(value);

    //let phoneFormated = FormatPhoneBR(value);
    let cpfFormated = CPFMask(value);

    setCpfOfUser(cpf);
    setCpfOfUserFormated(cpfFormated);

    setCpfOfUserChecked(cpf.length === 1);

    return true;
  }

  async function handleSubmit(values) {
    try {
      setLoading(true);

      if (emailOfUser !== managerToEdit.manager.email) {
        const res = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/checaremailobt/${emailOfUser}`
        );

        if (res.data === true) {
          setLoading(false);

          return setMessageEmailUsed('O email já está sendo utilizado.')
        }
      }

      setMessageEmailUsed('')


      /*const filter = {
        id: idOfUser,
        oldEmail: oldEmailOfUser,
        email: emailOfUser,
        phone: phoneOfUser,
        name: nameOfUser,
        clientCode: company.code,
        costCenters: listCCVinculadas.map(e => (e.nameCC)),
      };*/

      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/getallnumberpassengerobt/${idOfUser}/${phoneOfUser}`
      );

      const response2 = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/getallemailpassengerobt/${idOfUser}/${emailOfUser}`
      );

      /*await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/manager/updateclientmanager`,
        filter
      );*/

      toast.success('Usuário atualizado com sucesso!');

      setTimeout(() => setManagerPage('list'), 1000);

      return setLoading(false);
    } catch (error) {
      setLoading(false);

      return toast.error('Não foi possível atualizar o usuário.');
    }
  }

  function targetCostCenter(cc) {
    setEditChange(false);

    const newListCC = [];

    listCC.map(list => {
      if (list.nameCC !== cc.nameCC) {
        newListCC.push(list);
      }
      return false;
    });

    if (listCCVinculadas.length > 0) {
      const res = listCCVinculadas.filter(
        lv => lv.nameCC === cc.nameCC
      );

      if (res.length <= 0) {
        setListCC(newListCC);

        return setListCCVinculadas(listCCVinculadas.concat([cc]));
      }

      return toast.info(
        `A Empresa: ${cc.empresa}, já está vinculada ao usuário`
      );
    }

    setListCC(newListCC);

    return setListCCVinculadas(listCCVinculadas.concat([cc]));
  }

  function untargetCostCenter(cc) {
    setEditChange(false);

    const newListCCVinculadas = [];

    listCCVinculadas.map(list => {
      if (list.nameCC !== cc.nameCC) {
        newListCCVinculadas.push(list);
      }
      return false;
    });

    setListCCVinculadas(newListCCVinculadas);

    if (listCC.length > 0) {
      const res = listCC.filter(
        lc => lc.nameCC === cc.nameCC
      );

      if (res.length <= 0)
        return setListCC(listCC.concat([cc]));

      return false;
    }

    return setListCC(listCC.concat([cc]));
  }

  function targetAllCostCenters () {
    setEditChange(false);

    setListCCVinculadas(originalListCC);

    setListCC([]);

    return true;
  }

  function untargetAllCostCenters () {
    setEditChange(false);

    setListCC(originalListCC);

    setListCCVinculadas([]);

    return true;
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Row>
        <Col lg="6">
          <Input
            name="name"
            placeholder="Nome Completo"
            label="Nome"
            value={nameOfUser+" "+middleNameOfUser +" "+ lastNameOfUser}
            onChange={e => {
              setNameOfUser(e.target.value)
              setMiddleNameOfUser(e.target.value)
              setLastNameOfUser(e.target.value)

              return setEditChange(false);
            }}
            required
            disabled={true}
            />
        </Col>

        <Col lg="6">
          <Input
            name="email"
            type="email"
            placeholder="E-mail"
            label="E-mail"
            value={emailOfUser}
            onChange={e => {
              setEmailOfUser(e.target.value);

              return setEditChange(false);
            }}
            required
            disabledButton={true}
            errorMessage={messageEmailUsed}
          />
        </Col>

        <Col lg='6'>
          <Input
            name="telefone"
            type="text"
            placeholder="Telefone"
            label="Telefone"
            value={phoneOfUserFormated}
            onChange={e => {
              handlePhone(e.target.value)

              return setEditChange(false);
            }}
            disabledButton={true}
            errorMessage={messagePhone}
            required
          />
        </Col>

        <Col lg='6'>
          <Input
            name="cpf"
            type="text"
            placeholder="Cpf"
            label="Cpf"
            value={cpfOfUserFormated}
            onChange={e => {
              handleCpf(e.target.value)

              return setEditChange(false);
            }}
            disabled={true}
            required
          />
        </Col>

      </Row>

      <Row>
        <SubmitButton>
          <button
            className="btn btn-success"
            type="submit"
            disabled={
              editChange
              || (phoneOfUser.length > 0 && phoneOfUser.length < 11
            )}
            style={{
              background: '#000000',
              color: 'white',
              fontWeight: 700,
              marginLeft: 10
            }}
          >
            {loading && <Spinner color="#fff" size="sm" />}
            {!loading && <>Salvar</>}
          </button>
        </SubmitButton>
      </Row>
    </Form>
  );
}

export default Edit;

