import React, { useState, useContext } from 'react';
import { ApiAxios } from '~/services/api.js';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import ReactTable from '~/components/Table';

import { Panel } from '~/components/Panel';
import TableOrdered from './TableOrdered';
import ModalDocs from '../ModalDocs';
import Modal from '../Modal';

import InvoiceContext from '../../context';
import { columns } from '../../constants';

import DownloadFile from '~/functions/DownloadFile';

import Header from './Header';

export default function Table() {
  const axios = ApiAxios();

  const {
    data,
    setData,
    loading,
    salvarProto
  } = useContext(InvoiceContext);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalInvoice, setIsOpenModalInvoice] = useState(false);
  const [isOpenModalBillet, setIsOpenModalBillet] = useState(false);
  const [isOpenModalNF, setIsOpenModalNF] = useState(false);
  const [isOpenModalDocs, setIsOpenModalDocs] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectAllInvoices, setSelectAllInvoices] = useState(false);
  const [downloadingAllFiles, setDownloadingAllFiles] = useState(false);

  const accessToken = useSelector(state => state.auth.accessToken);

  function handleSelectInvoice(handle) {
    setIsOpenModal(!isOpenModal);
    setSelectedInvoice(handle);
  }

  async function handleDownload(id, nf, docType) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_INVOICE}/invoice/download/invoice/${id}/${nf}/${docType}`,
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );


      if (response.data.length > 0) {
        toast.info(`Baixando documento, aguarde!`);

        DownloadFile(`data:application/octet-stream;base64,${response.data}`, docType === 'Faturas' ? `FT_${id}.pdf` : docType === 'NFs' ? `NF_${nf}_FT_${id}.pdf` : `BT_${id}.pdf`);
      } else {
        toast.info(`Documento não encontrado, tente novamente mais tarde!`);
      }

    } catch (error) {
      toast.error(`Não foi possível baixar o documento solicitado: ${id}`);
    }
  }

  async function handleDownloadRow() {
    try {
      setDownloadingAllFiles(true);

      toast.info(`Baixando arquivos, aguarde!`);

      const body = data
        .filter(f => f.checked)
        .map(({ handle, nf, arquivoFatura, arquivoBoleto, arquivoNF, qtdeDeDocumentos }) => ({
          handle,
          nf,
          arquivoFatura,
          arquivoBoleto,
          arquivoNF,
          qtdeDeDocumentos,
        }));

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_INVOICE}/invoice/download/all`,
        body,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      DownloadFile(
        `data:application/octet-stream;base64,${response.data}`,
        'Arquivos.zip'
      );

      setDownloadingAllFiles(false);
    } catch (error) {
      toast.error(`Não foi possível baixar os arquivos solicitados.`);
      setDownloadingAllFiles(false);
    }
  }

  function handleSelectAllRows({ target }) {
    const values = data.map(x => ({ ...x, checked: target.checked }));

    setSelectAllInvoices(target.checked);

    setData(values);
  }

  function handleSelectRow(id) {
    const values = data.map(x => x.handle === id ? { ...x, checked: !x.checked } : x);

    setData(values);
  }

  return (
    <>
      {isOpenModal && (
        <ModalDocs
          setIsOpenModal={setIsOpenModal}
          isOpenModal={isOpenModal}
          FaturaHandle={selectedInvoice}
          accessToken={accessToken}
        />
      )}

      {isOpenModalInvoice && (
        <Modal
          setIsOpenModal={setIsOpenModalInvoice}
          isOpenModal={isOpenModalInvoice}
          accessToken={accessToken}
          data={data.map(x => ({
            name: x.arquivoFatura,
            code: x.handle,
            invoice: x.handle,
            selected: false,
            wasDownload: x.wasDownloaded
          }))}
          title="Faturas"
          fileName={`Faturas-${Date.now()}.zip`}
        />
      )}

      {isOpenModalBillet && (
        <Modal
          setIsOpenModal={setIsOpenModalBillet}
          isOpenModal={isOpenModalBillet}
          accessToken={accessToken}
          data={data.map(x => ({
            name: x.arquivoBoleto,
            code: x.handle,
            invoice: x.handle,
            selected: false,
            wasDownload: x.wasDownloadedBoleto
          }))}
          title="Boletos"
          fileName={`Boletos-${Date.now()}.zip`}
        />
      )}
      {isOpenModalNF && (
        <Modal
          setIsOpenModal={setIsOpenModalNF}
          isOpenModal={isOpenModalNF}
          accessToken={accessToken}
          data={data.map(x => ({
            name: x.arquivoNF,
            code: x.nf,
            invoice: x.handle,
            selected: false,
            wasDownload: x.wasDownloadedNF
          }))}
          title="NFs"
          fileName={`NFs-${Date.now()}.zip`}
        />
      )}

      {isOpenModalDocs && (
        <Modal
          setIsOpenModal={setIsOpenModalDocs}
          isOpenModal={isOpenModalDocs}
          accessToken={accessToken}
          data={data.filter(x => x.qtdeDeDocumentos > 0)
            .map(x => ({
              name: x.handle,
              selected: false,
            }))}
          title="Documentos por fatura"
          url={`${process.env.REACT_APP_ENDPOINT_INVOICE}/invoice/download/documents`}
          fileName={`Documentos-${Date.now()}.zip`}
        />
      )}

      <Panel theme="inverse">
        <Header data={data} />

        <ReactTable
          columns={columns({
            handleSelectInvoice,
            handleDownload,
            setIsOpenModalInvoice,
            setIsOpenModalBillet,
            setIsOpenModalNF,
            setIsOpenModalDocs,
            invoice: data,
            handleSelectRow,
            handleSelectAllRows,
            selectAllInvoices,
            handleDownloadRow,
            downloadingAllFiles,
            salvarProto
          })}
          data={data}
          tableProps={{
            defaultPageSize: 10,
            hasFooter: true,
            hasPagination: true,
            loading: loading,
            resizable: true
          }}
          tableStyle={{
            fontSize: '13px',
            cellPadding: '6px 4px'
          }}
          tableLabels={{
            loadingText: 'Carregando...',
            noDataText: 'Sem registros',
            nextButtonText: 'Próximo',
            previousButtonText: 'Anterior',
            pageOfText: 'de',
            pageSizeSelectText: 'registros',
            pageText: 'Página'
          }}
          subComponent={({row}) => (
            <TableOrdered
              original={row.original}
              accessToken={accessToken} />
          )} />
      </Panel>
    </>
  );
}
