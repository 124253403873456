import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';

export default function RankingCC({
  data
}) {
  const [chartData19, setChartData19] = useState({});
  const [labels, setlabels] = useState([]);
  const [values, setvalues] = useState([]);
  const [total, setTotal] = useState(0);

  const chart19 = () => {
    setChartData19({
      labels: labels,
      datasets: [{
        data: values,
        backgroundColor: ['#00EAE1', '#BDBFBF', '#95F496', '#f4dbab', '#9e5ef2', '#FBBA37'],
        borderWidth: 4,
        label: 'Valor'
      }]
    })
  };


  useEffect(() => {
    async function loadData() {
      const total = data.reduce((acc, element) => acc + element.soma,0);
      setTotal(total);
      const topFive = data.slice(0, 5)
      const rest = data.slice(5)
      const restValue = rest.reduce((acc, element) => acc + element.soma,0)

      const labels = topFive.map(x => x.ccustos_cliente)
      const values = topFive.map(x => x.soma)

      setlabels([...labels, "Outros"]);
      setvalues([...values, restValue]);
    }

    loadData();
  }, [data])

  useEffect(() => {
    chart19()
  }, [labels, values])

  return (
    <>
      <div style={{ width: '50%', margin: '2% auto', }}></div>
      <Bar data={chartData19} options={{
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (label, index, labels) {
                  return FormatValueBRL(label);
                },
                beginAtZero: true,
                maxTicksLimit: 6
              },

            }
          ]
        },
        legend: {
          display: false,
        },
        responsive: true,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              const percent = (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / total) * 100;
              const formatPercent = Math.round(percent * 100) / 100
              return ` ${formatPercent}% / ${FormatValueBRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`;
            }
          }
        }
      }} />

    </>
  );
}

// const styles = {
//     titleLegend: {
//         marginTop: "10px",
//         fontSize: "11px",
//         fontWeight: 700
//     },
//     redLegend: {
//         marginTop: "10px",
//         fontSize: "11px",
//         fontWeight: 700,
//         color: 'red'
//     }
// }
