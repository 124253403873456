import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';

export default function InternationalTicketsRemission({
  data, datai
}) {
  const [chartData11, setChartData11] = useState({});
  const [total, setTotal] = useState(0.00);

  useEffect(() => {
    async function loadData() {
      setChartData11({
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        datasets: [{
          data: [data.jan, data.fev, data.mar, data.abr, data.mai, data.jun, data.jul, data.ago, data.sep, data.oct, data.nov, data.dez],
          count: [data.jancount, data.fevcount, data.marcount, data.abrcount, data.maicount, data.juncount, data.julcount, data.agocount, data.sepcount, data.octcount, data.novcount, data.dezcount],
          backgroundColor: ['rgba(0,0,0,0.0)'],
          borderColor: '#D54A26',
          borderWidth: 2,
          label: 'Valor Reemissão'
        }]
      });

      let values = Object.values(data);

      let totalTemp = values.reduce((totalTemp, x) => totalTemp + x, 0.00);

      setTotal(totalTemp);
    }
    loadData();
  }, [data])

  return (
    <>
      <div style={{ width: '50%', margin: '2% auto', }}></div>
      <Line data={chartData11} options={{
        legend: {
          display: false,
        },
        responsive: true,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return `Valor Reemissão: ${FormatValueBRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])} - Bilhetes: ${data.datasets[tooltipItem.datasetIndex].count[tooltipItem.index]}`;
            }
          }
        }
      }} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='description'>
          <p style={styles.titleLegend}>Gasto em Reemissão</p>
          <p className='textBoldRed'>{FormatValueBRL(total)}</p>
          <p style={styles.titleLegend}>Quantidade de Bilhetes</p>
          <p className='textBoldRed'>{(datai.quant_Tickets)}</p>
        </div>
      </div>
    </>
  );
}
const styles = {
  titleLegend: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: 700
  },
  greenLegend: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: 700,
    color: '#27b261'
  }
}
