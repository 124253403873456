import React, { useEffect, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';

export default function RankingEmissor({
  data
}) {
  const [chartData19, setChartData19] = useState({});
  const [labels, setlabels] = useState([]);
  const [values, setvalues] = useState([]);

  const chart19 = () => {
    setChartData19({
      labels: labels,
      datasets: [{
        data: values,
        backgroundColor: ['#00EAE1', '#BDBFBF', '#95F496', '#f4dbab', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2',],
        borderWidth: 4,
        label: 'Valor'
      }]
    })
  };


  useEffect(() => {
    async function loadData() {
      setlabels(data.map(x => x.ccustos_cliente));
      setvalues(data.map(x => x.soma));
    }

    loadData();
  }, [data])

  useEffect(() => {
    chart19()
  }, [labels, values])

  return (
    <>
      <div style={{ width: '50%', margin: '3% auto', }}></div>
      <HorizontalBar data={chartData19} options={{
        scales: {
          xAxes: [
            {
              ticks: {
                callback: function (label, index, labels) {
                  return FormatValueBRL(label);
                },
                beginAtZero: true,
                maxTicksLimit: 6
              },

            }
          ]
        },
        legend: {
          display: false,
        },
        responsive: true,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return `Valor: ${FormatValueBRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`;
            }
          }
        }
      }} />

    </>
  );
}

// const styles = {
//     titleLegend: {
//         marginTop: "10px",
//         fontSize: "11px",
//         fontWeight: 700
//     },
//     redLegend: {
//         marginTop: "10px",
//         fontSize: "11px",
//         fontWeight: 700,
//         color: 'red'
//     }
// }
