import React from 'react';
import styled from "styled-components";
import { FormatValueBRL } from '~/functions/FormatValue';
import moment from 'moment';

export const CellLogo = styled.img`
  border-radius: 5px;
`;

export const columns = [
  {
    default: true,
    Header: 'Pedido',
    accessor: 'order',
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',

    },
    width:65
  },
  {
    default: true,
    Header: 'Localizador',
    accessor: 'locator',
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    width:85
  },
  {
    default: true,
    Header: 'Valor do Bilhete',
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.ticket_value)}
      </span>;
    },
    accessor: 'ticket_value',
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
    width:110
  },
  {
    default: true,
    Header: 'Taxa de Embarque',
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.departure_tax)}
      </span>;
    },
    accessor: 'departure_tax',
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
    width:125
  },
  {
    default: true,
    Header: 'Reembolsável',
    accessor: 'refundable',
    Cell: ({ value }) => <span>{ value ? 'SIM' : 'NÃO' }</span>,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    width:100
  },
  {
    default: true,
    Header: 'Cancel/Alter',
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.cancellation_change_fee)}
      </span>;
    },
    accessor: 'cancellation_change_fee',
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
    width:85
  },
  {
    default: true,
    Header: 'No-show',
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.no_show)}
      </span>;
    },
    accessor: 'no_show',
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
    width:80
  },
  {
    default: true,
    Header: 'Vlr Reembolso',
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.refund_ammount)}
      </span>;
    },
    accessor: 'refund_ammount',
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
    width:100
  },
  // {
  //   default: true,
  //   Header: 'Centro de Custo',
  //   accessor: 'cost_center',
  //   show: true,
  //   style: {
  //     textAlign: 'center',
  //     whiteSpace: 'pre-wrap',
  //     padding: '8px 4px',
  //   },
  //   width:110
  // },
  {
    default: true,
    Header: 'Passageiro',
    accessor: 'pax',
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    width:110
  },
  {
    default: true,
    Header: 'Cia Aerea',
    accessor: 'provider',
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    width:70
  },
  {
    default: true,
    Header: 'Emissão',
    accessor: 'emission',
    Cell: ({ value }) => <span>{ moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') }</span>,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    },
    width:90
  },
  // {
  //   default: true,
  //   Header: 'Taxa Fee',
  //   Cell: ({ row }) => {
  //     return <span>
  //       {FormatValueBRL(row.original.fee)}
  //     </span>;
  //   },
  //   accessor: 'fee',
  //   show: true,
  //   style: {
  //     textAlign: 'center',
  //     paddingLeft: '20px',
  //     whiteSpace: 'pre-wrap',
  //   },
  // },
];
