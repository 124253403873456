import React, { useContext } from 'react';

import DashboardAgencyContext from '../../context';
import { Col, Row } from 'reactstrap';

import VolumePurchase1 from './Charts/1VolumePurchase';
import FeePerProduct2 from './Charts/2FeePerProduct';
import RankingCC3 from './Charts/3RankingCC';
import RankingPass4 from './Charts/4RankingPass';
import VolumeFee5 from './Charts/5VolumeFee';
import AdvancedPurchase6 from './Charts/6AdvancedPurchase';
import LowestFare7 from './Charts/7LowestFare';
import NationalTicketsRemission8 from './Charts/8NationalTicketsRemission';
import InternationalTicketsRemission9 from './Charts/9InternationalTicketsRemission';
import DaysOutlays10 from './Charts/10DaysOutlays';
import TopCompanies11 from './Charts/11TopCompanies';

export default function MyPanel () {
  const { user } = useContext(DashboardAgencyContext);

  return (
    <>
      <Row
        style={{
          width: '100%',
          margin: '0'
        }}>
        { user.myCharts !== null &&
          user.myCharts.map((e, i) => {
            if (e === 1)
              return <Col key={i} sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
                {React.createElement(VolumePurchase1)}
              </Col>

            if (e === 2)
              return <Col key={i} sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
                {React.createElement(FeePerProduct2)}
              </Col>

            if (e === 3)
              return <Col key={i} sm={12} lg={12} style={{padding: '0 8px', margin: '8px 0'}}>
                {React.createElement(RankingCC3)}
              </Col>

            if (e === 4)
              return <Col key={i} sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
                {React.createElement(RankingPass4)}
              </Col>

            if (e === 5)
              return <Col key={i} sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
                {React.createElement(VolumeFee5)}
              </Col>

            if (e === 6)
              return <Col key={i} sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
                {React.createElement(AdvancedPurchase6)}
              </Col>

            if (e === 7)
              return <Col key={i} sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
                {React.createElement(LowestFare7)}
              </Col>

            if (e === 8)
              return <Col key={i} sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
                {React.createElement(NationalTicketsRemission8)}
              </Col>

            if (e === 9)
              return <Col key={i} sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
                {React.createElement(InternationalTicketsRemission9)}
              </Col>

            if (e === 10)
              return <Col key={i} sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
                {React.createElement(DaysOutlays10)}
              </Col>

            if (e === 11)
              return <Col key={i} sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
                {React.createElement(TopCompanies11)}
              </Col>
          })
        }
      </Row>
    </>
  );
}
