import React, { useState } from 'react';
import { Spinner } from "reactstrap";

import {
  Container,
  Item,
  ButtonAction,
  LoadingDiv,
  EmptyDiv,
} from './styles';

function FileList({
  files = [],
  accept,
  downloadCallback = () => {},
  removeCallback = () => {},
  addCallback = () => {},
  loading,
  stopAdd = false,
  allowRemove = false,
  allowAdd = false,
}) {
  const [addingFile, setAddingFile] = useState(false);

  return (
    <Container>
      {loading && (
        <LoadingDiv>
          <Spinner size="md" color="#333" />
        </LoadingDiv>
      )}

      {!loading && (
        <>
          {!stopAdd && allowAdd && (
            <Item style={{ backgroundColor: "#bfb"}}>
              {addingFile && (
                <>
                  <input
                    type="file"
                    accept={accept}
                    onChange={(e) => {
                      setAddingFile(false);

                      addCallback(e);
                    }}
                  />

                  <ButtonAction type="button" onClick={() => setAddingFile(false)}>
                    <i className="fas fa-times" />
                  </ButtonAction>
                </>
              )}

              {!addingFile && (
                <>
                  Adicionar Arquivo

                  <ButtonAction type="button" onClick={() => setAddingFile(true)}>
                    <i className="fas fa-plus" />
                  </ButtonAction>
                </>
              )}
            </Item>
          )}

          {files.map((e, i) => {
            return (
              <Item key={i}>
                {e.filename}

                <ButtonAction type="button" onClick={() => downloadCallback(e)}>
                  <i className="fas fa-file-download" />
                </ButtonAction>

                {allowRemove && (
                  <ButtonAction type="button" onClick={() => removeCallback(e)}>
                    <i className="fas fa-times" />
                  </ButtonAction>
                )}
              </Item>
            );
          })}

          {files.length === 0 && (
            <EmptyDiv>
              Nenhum arquivo anexado
            </EmptyDiv>
          )}
        </>
      )}
    </Container>
  );
}

export default FileList;
