import React, { useEffect, useState } from 'react';

import {
  CreateAction,
  CancelDeleteAction,
  CloseAction
} from '~/components/ActionsOfTables';

import {
  Row,
  Col,
} from 'reactstrap';

import {
  Card,
  CardTitle,
  CardBody,
  CardBodyItem,
  CardContainer
} from '~/styles/card';

import {
  Container
} from '~/styles/container';

import {
  SpaceBetweenDiv
} from '~/styles/divs';

import List from './List';
import Create from './Create';
import Delete from './Delete';
import Details from './Details';
import Edit from './Edit';
import Permission from './Permission';

function Managers({
  company,
  setCompanyPage
}) {
  const [managerPage, setManagerPage] = useState('list');
  const [managerToDelete, setManagerToDelete] = useState(null);
  const [managerToDetails, setManagerToDetails] = useState(null);
  const [managerToEdit, setManagerToEdit] = useState(null);
  const [managerToPermission, setManagerToPermission] = useState(null);

  const handleDelete = () => setManagerPage('delete');
  const handleDetails = () => setManagerPage('details');
  const handleEdit = () => setManagerPage('edit');
  const handlePermission = () => setManagerPage('permission');

  useEffect(() => {
    if (managerToDelete !== null) handleDelete();
  }, [managerToDelete]);

  useEffect(() => {
    if (managerToDetails !== null) handleDetails();
  }, [managerToDetails]);

  useEffect(() => {
    if (managerToEdit !== null) handleEdit();
  }, [managerToEdit]);

  useEffect(() => {
    if (managerToPermission !== null) handlePermission();
  }, [managerToPermission]);

  return (
    <CardContainer>
      <Row>
        <Col sm={12}>
          <Card>
            <CardBody>
              <SpaceBetweenDiv>
                <h4>
                  {`Gestores`}
                  {managerPage === 'create' && (' > Novo Gestor')}
                  {managerPage === 'delete' && (' > Remover')}
                  {managerPage === 'details' && (' > Detalhes')}
                  {managerPage === 'edit' && (' > Editar')}
                  {managerPage === 'permission' && (' > Permissões Especiais')}
                </h4>

                <div>
                  {managerPage === 'create' &&
                    <CancelDeleteAction
                      type='button'
                      onClick={() => setManagerPage('list')}>
                      Cancelar
                    </CancelDeleteAction>
                  }

                  {managerPage === 'details' &&
                    <CreateAction
                      type='button'
                      onClick={() => setManagerPage('list')}>
                      Voltar
                    </CreateAction>
                  }

                  {managerPage === 'delete' &&
                    <CancelDeleteAction
                      type='button'
                      onClick={() => setManagerPage('list')}>
                      Cancelar
                    </CancelDeleteAction>
                  }

                  {managerPage === 'edit' &&
                    <CancelDeleteAction
                      type='button'
                      onClick={() => setManagerPage('list')}>
                      Cancelar
                    </CancelDeleteAction>
                  }

                  {managerPage === 'permission' &&
                    <CancelDeleteAction
                      type='button'
                      onClick={() => setManagerPage('list')}>
                      Cancelar
                    </CancelDeleteAction>
                  }

                  {managerPage === 'list' &&
                    <CloseAction
                      type='button'
                      onClick={() => setCompanyPage('dashboard')}>
                      <i className='fas fa-times' />
                    </CloseAction>
                  }
                </div>
              </SpaceBetweenDiv>

              <hr />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {managerPage === 'list' && (
        <List
          setManagerToDelete={setManagerToDelete}
          setManagerToDetails={setManagerToDetails}
          setManagerToEdit={setManagerToEdit}
          setManagerToPermission={setManagerToPermission}
          setManagerPage={setManagerPage} />
      )}

      {managerPage === 'create' && (
        <Create company={company} setManagerPage={setManagerPage} />
      )}

      {managerPage === 'delete' && (
        <Delete
          company={company}
          managerToDelete={managerToDelete}
          setManagerPage={setManagerPage} />
      )}

      {managerPage === 'details' && (
        <Details company={company} managerToDetails={managerToDetails} />
      )}

      {managerPage === 'edit' && (
        <Edit
          company={company}
          managerToEdit={managerToEdit}
          setManagerPage={setManagerPage}/>
      )}
      {managerPage === 'permission' && (
        <Permission
           managerToPermission={managerToPermission}
        />
      )}
    </CardContainer>
  );
}

export default Managers;
