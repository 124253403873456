import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import RankingCC from '../../Widgets/RankingCC.js';
import WhiteCard from '../../WhiteCard';
import ShowSelects from '~/functions/ShowSelects';
import CompanyPermission from '~/functions/CompanyPermission';


import DashboardContext from '../../../context';

export default function RankingCC3 () {
  const chartId = 3;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);
  const userProfile = useSelector(state => state.user.userProfile);

  const {
    accessToken,
    companiesOptions,
    setCompaniesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCentersOptions,
    setCostCentersOptions,
    costCentersSelected,
    setCostCentersSelected,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [RankingCCData, setRankingCCData] = useState([]);

  useEffect(() => {
    async function loadData () {
      try {
        setRankingCCData([]);

        setLoading(true);

        setLikes(user.preferences.dashboard.chart3);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        const Companies = companiesSelected.length === 0
        ? companiesOptions.map(x => (x.value))
        : companiesSelected.map(x => (x.value));

        const CostCenters = !CompanyPermission(userProfile.userProfileId).all
          ? (
            costCentersSelected.length === 0
              ? costCentersOptions.map(e => (e.value))
              : costCentersSelected.map(e => (e.value))
          )
          : costCentersSelected.map(e => (e.value));

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const cc = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/shopping/air/RankingCC`,
          {
            handle: Companies,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: CostCenters
          },
          options
        );

        if (cc.data) {
          setRankingCCData(cc.data);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected]);

  return (
    <WhiteCard title={'Setor com maior custo em viagens'}
      modalTitle='Observação'
      modalDesc='Gastos por centro de custo.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <RankingCC data={RankingCCData} />
    </WhiteCard>
  );
}
