import React from 'react';

import { Col, Row } from 'reactstrap';

import NationalTicketsRemission8 from './Charts/8NationalTicketsRemission';
import InternationalTicketsRemission9 from './Charts/9InternationalTicketsRemission';
// import DaysOutlays10 from './Charts/10DaysOutlays';
import RankingRoutes15 from './Charts/15RankingRoutes';
import RankingCities from './Charts/16RankingCities';
import TopCompanies11 from './Charts/11TopCompanies';
import TopHotels14 from './Charts/14TopHotels';
import RankingPass4 from './Charts/4RankingPass';

export default function Tickets() {
  return (
    <>
      <Row
        style={{
          width: '100%',
          padding: '0',
          margin: '0'
        }}>

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <TopCompanies11 />
        </Col>

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <RankingRoutes15 />
        </Col>

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <NationalTicketsRemission8 />
        </Col>

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <InternationalTicketsRemission9 />
        </Col>

        {/* <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <RankingCities />
        </Col> */}

        {/* <Col sm={12} lg={6} style={{padding: '0 8px', margin: '8px 0'}}>
          <TopHotels14 />
        </Col> */}

        <Col sm={12} lg={6} style={{ padding: '0 8px', margin: '8px 0' }}>
          <RankingPass4 />
        </Col>

      </Row>
    </>
  );
}
