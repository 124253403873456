/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AuthActions from '~/store/modules/ducks/auth';

import { Row, Col } from 'reactstrap';
import { Form } from '@unform/web';
import { ApiAxios } from '~/services/api.js';
import { toast } from 'react-toastify';

import Input from '~/components/Input';
import { Panel, PanelHeader, PanelBody } from '~/components/Panel';

import {
  Body,
  NavMenu,
  SpanAlert
} from './styles';

function UserProfile() {
  const axios = ApiAxios();
  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.auth.accessToken);
  const user = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const formRefNewPass = useRef(null);
  const [lockBody, setLockBody] = useState(true);
  const [lockPass, setLockPass] = useState(true);
  const [messagePassword, setMessagePassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [userInfo, setUserInfo] = useState({
    name: user.name,
    nickname: user.nickname,
    login: user.login,
  });
  const [lockPassArea, setLockPassArea] = useState(true);

  const [passInfoNew, setPassInfoNew] = useState({
    oldPass: '',
    pass: '',
    newPass: '',
  });

  function verifyPass(pass, newPass) {
    setMessagePassword('');
    setConfirmPassword('');
    setLockPass(true);

    if (!pass) {
      setMessagePassword('Este Campo é obrigatório!');
      return false;
    }
    if (!newPass) {
      setConfirmPassword('Este Campo é obrigatório!');
      return false;
    }
    if (
      pass.length < 8 ||
      pass.length > 12 ||
      !pass.match(/\d/g) ||
      !pass.match(/[a-z]/gi)
    ) {
      setMessagePassword(
        'A senha deve possuir de 8 a 12 caracteres, contendo obrigatoriamente letras e números.'
      );
      return false;
    }
    if (pass !== newPass) {
      setConfirmPassword('As Senhas não correspondem!');
      return false;
    }
    setConfirmPassword('');
    setMessagePassword('');
    setLockPass(false);
    return true;
  }

  async function handleSubmit(userForm) {
    try {
      setLoading(true);

      const filter = {
        email: user.email,
        name: userForm.name,
        status: user.status,
        profileId: user.profileId
      };

      axios.defaults.headers.Authorization = `Bearer ${accessToken}`;

      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/updateSelf`,
        filter
      );

      dispatch(AuthActions.signInSuccess(accessToken, response.data.payload));

      setPassInfoNew({
        oldPass: '',
        pass: '',
        newPass: '',
      });
      setLockPass(true);
      setLockBody(true);
      setLoading(false);

      if (!response.data.payload) return toast.error(response.data.message);

      return toast.success(
        `Dado alterado com sucesso.`
      );
    } catch (error) {
      if (error && error.response) {
        return toast.error(error.response.data);
      }

      // setPassInfo({ pass: '', newPass: '' });
      setPassInfoNew({
        oldPass: '',
        pass: '',
        newPass: '',
      });
      setLockPass(true);
      setLockBody(true);

      toast.error(
        'Não foi possível atualizar seu usuário, por favor tente mais tarde'
      );

      return setLoading(false);
    }
  }

  async function handleSubmitNewPass(userFormNewPass) {
    if (!verifyPass(userFormNewPass.password, userFormNewPass.newPassword))
      return toast.info('Algo parece estar errado, tente novamente!');

    try {
      setLoading(true);

      axios.defaults.headers.Authorization = `Bearer ${accessToken}`;

      const filterCheck = {
        Email: user.email,
        OldPassword: userFormNewPass.oldPassword,
        Password: userFormNewPass.password
      };

      const responseCheck = await axios.put(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/checkPass`,
        filterCheck
      );

      setLockPass(true);
      setLockBody(true);
      setLoading(false);

      if (!responseCheck.data.payload) {
        setPassInfoNew({ pass: '', newPass: '' });
        setLockPassArea(true);
        setLoading(false);
        return toast.error(responseCheck.data.message);
      }

      const filter = {
        Email: user.email,
        Password: userFormNewPass.password,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/updateSelfPass`,
        filter
      );

      if (!response.data) {
        setLoading(false);
        setLockBody(false);
        return toast.error(response.data.message);
      }

      setLockPassArea(true);

      setLockBody(true);

      setLoading(false);

      return toast.success(
        `Dado alterado com sucesso.`
      );
    } catch (error) {
      if (error && error.response) {
        return toast.error(error.response.data);
      }

      setPassInfoNew({ pass: '', newPass: '' });
      setLockPassArea(true);
      setLockPass(true);
      setLockBody(true);

      toast.error(
        'Não foi possível atualizar seu usuário, por favor tente mais tarde'
      );

      return setLoading(false);
    }
  }

  return (
    <>
      <Panel theme="inverse">
        <PanelHeader noButton>
          <NavMenu id="title">
            <h4>Dados Cadastrais</h4>
            <div>
              <button
                type="button"
                className="btn btn-default"
                style={{
                  backgroundColor: 'black',
                  color: '#fff',
                  textDecoration: 'none',
                }}
                disabled={lockBody}
                onClick={() => {
                  setLockPassArea(!lockPassArea);
                  // setPassInfo({ pass: '', newPass: '' });
                  // setMessagePassword('');
                  // setConfirmPassword('');
                  // setUserInfo({
                  //   name: user.name,
                  //   nickname: user.nickname,
                  //   login: user.login,
                  // });
                  // setLockPass(false);
                  // setLockBody(!lockBody);
                }}
              >
                Alterar Senha <i className="fas fa-key fa-1x"
                />
              </button>

              <button
                type="button"
                className="btn btn-default"
                style={{
                  backgroundColor: 'black',
                  color: '#fff',
                  textDecoration: 'none',
                }}
                onClick={() => {
                  setLockPassArea(true);
                  // setPassInfo({ pass: '', newPass: '' });
                  setPassInfoNew({
                    oldPass: '',
                    pass: '',
                    newPass: '',
                  });
                  setMessagePassword('');
                  setConfirmPassword('');
                  setUserInfo({
                    name: user.name,
                    nickname: user.nickname,
                    login: user.login,
                  });
                  setLockPass(false);
                  setLockBody(!lockBody);
                }}
              >
                {lockBody ? (
                  <i className="fas fa-lock fa-1x" />
                ) : (
                  <i className="fas fa-lock-open fa-1x" />
                )}
              </button>
            </div>
          </NavMenu>
        </PanelHeader>
        {lockPassArea && (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <PanelBody>
              <Body>
                {/* {!lockBody && (<h3>Alterar Informações Pessoais</h3>)} */}
                <Row>
                  <Col sm={12} md={6}>
                    <Input
                      label="Nome Completo"
                      //disabled={lockBody}
                      disabled
                      value={userInfo.name}
                      onChange={e => {
                        setUserInfo({
                          nickname: userInfo.nickname,
                          name: e.target.value,
                          login: userInfo.login,
                        });
                      }}
                      name="name"
                      type="text"
                      placeholder="Nome Completo"
                    />
                  </Col>

                  <Col sm={12} md={6}>
                    <Input
                      label="Email"
                      disabled
                      value={user.email}
                      type="email"
                      name="email"
                      placeholder="Email"
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={lockPass || lockBody || loading}
                      style={{
                        backgroundColor: 'black',
                        color: '#fff',
                        textDecoration: 'none',
                        cursor: lockBody ? 'not-allowed' : 'pointer'
                      }}
                    >
                      <i className="fas fa-1x" /> Salvar
                    </button>
                  </Col>
                </Row> */}
              </Body>
            </PanelBody>
          </Form>
        )}

        {!lockPassArea && (
          <Form ref={formRefNewPass} onSubmit={handleSubmitNewPass}>
            <PanelBody>
              <Body>
                {/* {!lockBody && (<h3>Alterar Senha de Acesso</h3>)} */}
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <Input
                      label="Senha Atual"
                      required
                      disabled={lockBody}
                      value={passInfoNew.oldPass}
                      onChange={e => {
                        if (e.target.value.length > 12) {
                          return false;
                        }
                        setPassInfoNew({
                          oldPass: e.target.value,
                          pass: passInfoNew.pass,
                          newPass: passInfoNew.newPass,
                        });
                      }}
                      name="oldPassword"
                      type="password"
                      placeholder="Senha Atual"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6}>
                    <Input
                      label="Nova Senha"
                      required
                      disabled={lockBody}
                      value={passInfoNew.pass}
                      onChange={e => {
                        setPassInfoNew({
                          oldPass: passInfoNew.oldPass,
                          pass: e.target.value,
                          newPass: passInfoNew.newPass,
                        });
                        verifyPass(e.target.value, passInfoNew.newPass);
                      }}
                      name="password"
                      type="password"
                      placeholder="Nova Senha"
                    />
                    {!lockBody && lockPass && (
                      <SpanAlert>{messagePassword}</SpanAlert>
                    )}
                  </Col>

                  <Col sm={12} md={6}>
                    <Input
                      label="Confirme Nova Senha"
                      required
                      disabled={lockBody}
                      value={passInfoNew.newPass}
                      onChange={e => {
                        setPassInfoNew({
                          oldPass: passInfoNew.oldPass,
                          pass: passInfoNew.pass,
                          newPass: e.target.value,
                        });
                        verifyPass(passInfoNew.pass, e.target.value);
                      }}
                      name="newPassword"
                      type="password"
                      placeholder="Confirme Nova senha"
                    />
                    {!lockBody && lockPass && (
                      <SpanAlert>{confirmPassword}</SpanAlert>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={lockPass || lockBody || loading}
                      style={{
                        backgroundColor: 'black',
                        color: '#fff',
                        textDecoration: 'none',
                      }}
                    >
                      <i className="fas fa-1x" /> Salvar
                    </button>
                  </Col>
                </Row>
              </Body>
            </PanelBody>
          </Form>
        )}
      </Panel>
    </>
  );
}

export default UserProfile;
