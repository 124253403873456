import React, { useEffect, useState } from 'react';
// import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';

export default function DistribuicaoMargem({
  data
}) {

  const [chartData23, setChartData23] = useState({});
  const [labels, setlabels] = useState([]);
  const [margemE, setMargemE] = useState([]);
  const [margemP, setMargemP] = useState([]);
  const [margemG, setMargemG] = useState([]);

const chart23 = () => {
  setChartData23({
  labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  datasets: [
    {
      data: margemE,
      backgroundColor: '#7e2ec2',
      borderWidth: 4,
      label: 'Emissor'
    },
    {
      data: margemP,
      backgroundColor: '#00ede0',
      borderWidth: 4,
      label: 'Promotor'
    },
    {
      data: margemG,
      backgroundColor: '#7e2ec2',
      borderWidth: 4,
      label: 'Gerente'
    }
  ],
  })
}

useEffect(() => {
  async function loadData(){
    setMargemE(data.map(x => x.margemE));
    setMargemP(data.map(x => x.margemP));
    setMargemG(data.map(x => x.margemG));
  }

  loadData();
}, [data])

useEffect(() => {
  chart23()
}, [labels, margemE, margemP, margemG])


const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function(label, index, labels) {
            return FormatValueBRL(label);
        },
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
    callbacks: {
      label: function (tooltipItem, data) {
        return `${FormatValueBRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`;
      }
    },
  },
}

return(
  <>
    <Bar data={chartData23} options={options} />
  </>
)
}
//export default GroupedBar

