import React, { useEffect, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';

export default function RankingEmissor({
  data
}) {
    const [chartData19, setChartData19] = useState({});
    const [labels, setlabels] = useState([]);
    const [values, setvalues] = useState([]);

    const chart19 = () => {
      setChartData19({
        labels: labels,
        datasets:[{
          data: values,
          backgroundColor: ['#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1','#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1', '#0aeee1',],
          borderWidth: 4,
          label: 'Valor'
        }]
      })
    };

    useEffect(() => {
      async function loadData(){
        setlabels(data.map(x => x.passageiro));
        setvalues(data.map(x => x.soma));
      }

      loadData();
    }, [data])

    useEffect(() => {
      chart19()
    }, [labels, values])

    return (
      <>
       <HorizontalBar data={chartData19} options={{
         scales: {
          xAxes: [
              {
                  ticks: {
                      callback: function(label, index, labels) {
                          return FormatValueBRL(label);
                      },
                      beginAtZero: true,
                      maxTicksLimit: 6
                  },

              }
          ]
        },
        responsive: true,
        tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            return `Valor: ${FormatValueBRL(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`;
          }
        }
      } }} />

        </>
    );
}

// const styles = {
//     titleLegend: {
//         marginTop: "10px",
//         fontSize: "11px",
//         fontWeight: 700
//     },
//     redLegend: {
//         marginTop: "10px",
//         fontSize: "11px",
//         fontWeight: 700,
//         color: 'red'
//     }
// }
