import React, { useContext, useState, useEffect } from 'react';
import { Panel, PanelHeader } from "~/components/Panel";
import { toast } from 'react-toastify';
import { Row, Col } from "reactstrap";
import ReactTable from '~/components/Table';
import OrderAndInvoiceContext from '../../../../../context';
import ExportSpreadSheet from '~/components/ExportSpreadSheet';

import {
  columnsAir,
  columnsHotel,
  columnsCar,
  columnsRoad,
  columnsOther
} from './constants';

import {
  HeaderTitle
} from './styles';

import {
  Submit
} from '~/styles/buttons/defaults';

import { FormatValueBRL } from '~/functions/FormatValue';

import {
  getrderManagementTableAnalytical
} from '~/services/orderManagement';

import { getPeriod } from '~/functions/Date';

export default function OrderDetails({
  pageFilters,
  filter,
  name,
  row
}) {
  const [columns, setColumns] = useState([]);
  const [dataDetails, setDataDetails] = useState([]);
  const [dataExcelDetails, setDataExcelDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    accessToken,
    dateState,
  } = useContext(OrderAndInvoiceContext);

  useEffect(() => {
    async function loadColumns () {
      switch (filter) {
        case 'TKT':
          setColumns(columnsAir);
          break;
        case 'HTL':
          setColumns(columnsHotel);
          break;
        case 'CAR':
          setColumns(columnsCar);
          break;
        case 'ROD':
          setColumns(columnsRoad);
          break;
        default:
          setColumns(columnsOther);
          break;
      }
    }

    loadColumns();
  }, [filter]);

  useEffect(() => {
    async function loadTable () {
      const {
        dateI,
        dateF
      } = getPeriod(dateState);

      const res = await getrderManagementTableAnalytical(
        accessToken,
        {
          Companies: pageFilters.companies.map(e => e.value),
          CostCenter: pageFilters.costs.map(e => e.value),
          InitialDate: dateI,
          FinalDate: dateF,
          Pax: pageFilters.traveler,
          Product: filter
        },
        setLoading,
      );

      if (res !== null) {
        return setDataDetails(res.data);
      }

      return toast.info(
        'Não foi possível carregar a tabela de seus últimos custos em viagens.'
      );
    }

    loadTable();
  }, [pageFilters]);

  useEffect(() => {
    async function loadDataToExcel () {
      try {
        setDataExcelDetails([]);

        let newData = [];

        const empty = {
          c1: ''
        };

        var parentRow = {
          c1: row.grouping,
          c2: row.count,
          c3: FormatValueBRL(row.sumNotBilled),
          c4: FormatValueBRL(row.sumNotPaid),
          c5: FormatValueBRL(row.sumPaid),
          c6: FormatValueBRL(row.total),
        };

        newData = newData.concat([
          empty,
          parentRow,
          empty,
        ]);

        switch (filter) {
          case 'TKT':
            newData.push({
              c1: 'Fatura',
              c2: 'Referência',
              c3: 'Emissão',
              c4: 'Fornecedor',
              c5: 'Localizador',
              c6: 'Viajante',
              c7: 'Centro de Custo',
              c8: 'Valor Total',
            });

            newData.push(empty);

            dataDetails.map(e => {
              return newData.push({
                c1: e.invoice > 0 ? `FAT-${e.invoice}` : '',
                c2: e.sell,
                c3: e.emission,
                c4: e.cia,
                c5: e.locator,
                c6: e.passenger,
                c7: e.costCenter,
                c8: FormatValueBRL(e.total),
              });
            });
            break;
          case 'HTL':
            newData.push({
              c1: 'Fatura',
              c2: 'Referência',
              c3: 'Emissão',
              c4: 'Fornecedor',
              c5: 'Localizador',
              c6: 'Cidade',
              c7: 'Viajante',
              c8: 'Centro de Custo',
              c9: 'Valor Total',
            });

            newData.push(empty);

            dataDetails.map(e => {
              return newData.push({
                c1: e.invoice > 0 ? `FAT-${e.invoice}` : '',
                c2: e.sell,
                c3: e.emission,
                c4: e.cia,
                c5: e.locator,
                c6: e.city,
                c7: e.passenger,
                c8: e.costCenter,
                c9: FormatValueBRL(e.total),
              });
            });
            break;
          case 'CAR':
            newData.push({
              c1: 'Fatura',
              c2: 'Referência',
              c3: 'Emissão',
              c4: 'Fornecedor',
              c5: 'Localizador',
              c6: 'Cidade',
              c7: 'Viajante',
              c8: 'Centro de Custo',
              c9: 'Valor',
            });

            newData.push(empty);

            dataDetails.map(e => {
              return newData.push({
                c1: e.invoice > 0 ? `FAT-${e.invoice}` : '',
                c2: e.sell,
                c3: e.emission,
                c4: e.cia,
                c5: e.locator,
                c6: e.city,
                c7: e.passenger,
                c8: e.costCenter,
                c9: FormatValueBRL(e.total),
              });
            });
            break;
          case 'ROD':
            newData.push({
              c1: 'Fatura',
              c2: 'Referência',
              c3: 'Emissão',
              c4: 'Fornecedor',
              c5: 'Cidade',
              c6: 'Viajante',
              c7: 'Centro de Custo',
              c8: 'Valor',
            });

            newData.push(empty);

            dataDetails.map(e => {
              return newData.push({
                c1: e.invoice > 0 ? `FAT-${e.invoice}` : '',
                c2: e.sell,
                c3: e.emission,
                c4: e.cia,
                c5: e.city,
                c6: e.passenger,
                c7: e.costCenter,
                c8: FormatValueBRL(e.total),
              });
            });
            break;
          case 'OUT':
            newData.push({
              c1: 'Fatura',
              c2: 'Referência',
              c3: 'Emissão',
              c4: 'Fornecedor',
              c5: 'Localizador',
              c6: 'Serviços',
              c7: 'Cidade',
              c8: 'Viajante',
              c9: 'Centro de Custo',
              c10: 'Valor',
            });

            newData.push(empty);

            dataDetails.map(e => {
              return newData.push({
                c1: e.invoice > 0 ? `FAT-${e.invoice}` : '',
                c2: e.sell,
                c3: e.emission,
                c4: e.cia,
                c5: e.locator,
                c6: e.productDesc,
                c7: e.city,
                c8: e.passenger,
                c9: e.costCenter,
                c10: FormatValueBRL(e.total),
              });
            });
            break;
        }

        setDataExcelDetails(newData);

        return setLoading(false);
      } catch (error) {
        return setLoading(false);
      }
    }

    loadDataToExcel();
  }, [dataDetails]);

  return (
    <>
      <Panel theme="inverse">
        <PanelHeader
          noButton
          title=''
          children={
            <HeaderTitle>
              <Row>
                <Col sm={12} md={7} style={{textAlign: 'left'}}>
                  {`Detalhes por Tipo de Serviço - ${name}`}
                </Col>

                <Col sm={12} md={5} style={{textAlign: 'right'}}>
                  {dataDetails.length > 0 && (
                    <ExportSpreadSheet
                      data={dataExcelDetails}
                      columns={[
                        {label: 'Agrupamento', value: 'c1'},
                        {label: 'Transações', value: 'c2'},
                        {label: 'Não Faturado', value: 'c3'},
                        {label: 'Faturado', value: 'c4'},
                        {label: 'Liquidado', value: 'c5'},
                        {label: 'Valor Total', value: 'c6'},
                        {label: '', value: 'c7'},
                        {label: '', value: 'c8'},
                        {label: '', value: 'c9'},
                        {label: '', value: 'c10'},
                        {label: '', value: 'c11'},
                        {label: '', value: 'c12'},
                        {label: '', value: 'c13'},
                        {label: '', value: 'c14'},
                      ]}
                      element={
                        <Submit type='button'>
                          Exportar
                        </Submit>
                      }
                      filename={`GC-Analítico-${name}`}
                      sheetName={`Relatório de Gestão de Custos - Analítico - ${name}`} />
                  )}
                </Col>
              </Row>
            </HeaderTitle>
          }
        />

        <Panel theme="inverse">
          <ReactTable
            columns={columns}
            data={dataDetails}
            tableProps={{
              defaultPageSize: 5,
              hasFooter: false,
              hasPagination: true,
              loading: loading,
              resizable: true
            }}
            tableStyle={{
              fontSize: '13px',
              cellPadding: '6px 4px',
              th: {
                backgroundColor: '#000000',
                color: '#fafafa',
              }
            }}
            tableLabels={{
              loadingText: 'Carregando...',
              noDataText: 'Sem registros',
              nextButtonText: 'Próximo',
              previousButtonText: 'Anterior',
              pageOfText: 'de',
              pageSizeSelectText: 'registros',
              pageText: 'Página'
            }}

          />
        </Panel>
      </Panel>
    </>
  );
}
