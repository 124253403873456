import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ApiAxios } from '~/services/api.js';

import {
  Card,
  CardTitle,
  CardBody,
  CardBodyItem,
} from '~/styles/card';

import {
  SpaceBetweenDiv,
  DivFlexCentered
} from '~/styles/divs';

import {
  CloseAction,
  EditAction,
  CreateAction
} from '~/components/ActionsOfTables';
import { Row, Col, Spinner } from 'reactstrap';

import CompanyRegistrationContext from '../../../context';

function List({
  setToDelete,
  setToDetails,
  setToEdit
}) {
  const axios = ApiAxios();

  const {
    companyToDetail
  } = useContext(CompanyRegistrationContext);

  const [loading, setLoading] = useState(false);
  const [list1, setList1] = useState([]);

  useEffect(() => {
    async function loadList1() {
      try
      {
        const res = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/getcostcenters`,
          {
            ClientCode: companyToDetail.code
          }
        );

        return setList1(res.data);
      } catch (error) {
        return toast.error('Não foi possível listar Centros de Custo.');
      }
    }

    loadList1();
  }, []);

  async function handleUpdateCostCenters () {
    try {
      setLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/updatecostcenters/${companyToDetail.code}`
      );

      setLoading(false);

      return setList1(res.data);
    } catch (error) {
      setLoading(false);

      return toast.error('Não foi possível listar Centros de Custo.');
    }
  }

  return (
    <Row>
      <Col md={12} lg={12} xl={6}>
        <Card>
          <CardTitle>
            <SpaceBetweenDiv>
              <h5>Centros de Custo</h5>

              <CreateAction
                type='button'
                disabled={loading}
                onClick={() => handleUpdateCostCenters()}>
                Atualizar
              </CreateAction>
            </SpaceBetweenDiv>
          </CardTitle>

          {loading && (
            <DivFlexCentered minHeight='200px'>
              <Spinner color='#eee' size='lg' />
            </DivFlexCentered>
          )}

          {!loading && (
            <CardBody>
              {list1.map((e, i) => {
                return (
                  <CardBodyItem key={i}>
                    <div>
                      <p><b>Nome:</b> {e.nameCC}</p>

                      <p><b>Descrição:</b> {e.descCC}</p>
                    </div>
                  </CardBodyItem>
                );
              })}
            </CardBody>
          )}
        </Card>
      </Col>

      <Col md={12} lg={12} xl={6}>
        <Card>
          <CardTitle>
            <SpaceBetweenDiv>
              <h5>{companyToDetail.fantasy}</h5>
            </SpaceBetweenDiv>
          </CardTitle>

          <CardBody>
            <CardBodyItem>
              <div>
                <p><b>Nome da Empresa: </b> {companyToDetail.name}</p>

                <p><b>Nome Fantasia: </b> {companyToDetail.fantasy}</p>

                <p><b>Cnpj: </b> {companyToDetail.cpfCnpj}</p>

                <p><b>Cod. BKO: </b> {companyToDetail.code}</p>
              </div>
            </CardBodyItem>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardBodyItem>
              {companyToDetail.useObt && (
                <div>
                  <p><b>Usa OBT: </b>
                    <EditAction disabled={true}>SIM</EditAction>
                  </p>

                  <p><b>Nome do OBT: </b>
                    {companyToDetail.obt.title}
                  </p>

                  <p><b>Código do OBT: </b>
                    {companyToDetail.obt.code}
                  </p>

                  <p><b>Descrição: </b>
                    {companyToDetail.obt.desc}
                  </p>
                </div>
              )}

              {!companyToDetail.useObt && (
                <div>
                  <p><b>Usa OBT: </b>
                    <CloseAction disabled={true}>NÃO</CloseAction>
                  </p>
                </div>
              )}
            </CardBodyItem>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default List;
