import React from 'react';
import { useSelector } from 'react-redux';

import DashboardUser from './components/DashboardUser';
import DashboardAgency from './components/DashboardAgency';

function Dashboard() {
  const userProfile = useSelector(state => state.user.userProfile);

  return (
    <>
      {(userProfile.userProfileId !== 6
        && userProfile.userProfileId !== 7
        && userProfile.userProfileId !== 5 ) && (
        <DashboardAgency />
      )}

      {(
        userProfile.userProfileId === 6
        || userProfile.userProfileId === 7
        || userProfile.userProfileId === 5) && (
        <DashboardUser />
      )}
    </>
  );
}

export default Dashboard;
