import styled from "styled-components";
import { Row } from "reactstrap";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.div`
  padding: 16px;
  margin: 16px 0;
  border-radius: 16px;

  img {
    width: 100%;
  }
`;

export const Main = styled.div`
  width: 100%;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

export const Footer = styled.div`
  width: 100%;
  padding: 16px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

export const CardContainer = styled.div`
  background: #fafafa;
  border-radius: 4px;
  padding: 4px;
`;

export const Card = styled.div`
  background: #fafafa;
  border-radius: 4px;
  padding: 8px;
  overflow: auto;
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 4px;
  padding: 0;
  width: 100%;
`;

export const CardBody = styled.div`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
`;

export const CardBodyItem = styled(Row)`
  background: #ededed;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

export const CardBodyHeader = styled(Row)`
  border-radius: 4px;
  padding: 4px 16px;
  margin: 0px 0px 4px;
  display: flex;
  align-items: center;
  font-weight: bold;
`;
