import React, { useState, useEffect, useContext } from 'react';
import { ApiAxios } from '~/services/api.js';
import Grafico2 from '../../Widgets/Grafico1.js';
import WhiteCard from '../../WhiteCard';

import DashboardContext from '../../../context';

export default function Grafico1 () {
  const chartId = 2;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);

  const {
    companiesSelected,
    accessToken,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
    costCentersSelected,
    emitterSelected
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [feePerProductData, setFeePerProductData] = useState([]);

  useEffect(() => {
    async function loadData () {
      try {
        setLoading(true);

        setLikes(user.preferences.dashboard.chart2);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        let handles = companiesSelected.map(x => x.value);
        let emitters = emitterSelected.map(x => x.value);

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const cc = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/shopping/air/CliTM`,
          {
            handle: handles,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: costCentersSelected.map(e => (e.value)),
            emitter: emitterSelected.map(e => (e.label))
          },
          options
        );


        if (cc.data) {
          setFeePerProductData(cc.data);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected, emitterSelected]);

  return (
    <WhiteCard title={'Vendas / Margem Clientes PJ'}
      modalTitle='Observação'
      modalDesc='Vendas e margem de clientes Corp'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <Grafico2 data={feePerProductData}/>
    </WhiteCard>
  );
}
