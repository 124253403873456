import React from 'react';

import { Container } from './styles';

function EmptyList() {
  return (
    <Container>
      <span>
        Lista vazia.
      </span>
    </Container>
  );
}

export default EmptyList;
