import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function Shimmer({ color, highlightColor, ...rest }) {
  return (
    <SkeletonTheme color={color} highlightColor={highlightColor}>
      <Skeleton {...rest} />
    </SkeletonTheme>
  );
}
