import React from 'react';
import { useSelector } from 'react-redux';
import Input from '~/components/Input';
import DatePicker from '~/components/DatePickerIndicators';
import { Panel, PanelHeader, PanelBody } from '~/components/Panel';
import { Row, Col, Spinner } from 'reactstrap';
import { Form } from '@unform/web';
import Select from "~/components/Select";
import { FilterButtons } from '../../styles';
import CompanySelect from '~/components/CompanySelect';
import ShowSelects from '~/functions/ShowSelects';
import { DefaultDate } from "../../constants";
import { useRefund } from 'context';
import {
  refundStatus,
} from './constants';

function Filters() {
  const userProfile = useSelector(state => state.user.userProfile);
  const {
    formRef,
    loading,
    handleSumbit,
    pageFilters,
    setPageFilters,
    resetPageFilters,
  } = useRefund();

  return (
    <Panel theme="inverse">
      <PanelHeader noButton title="Filtros" />

      <PanelBody>
        <Form ref={formRef} onSubmit={handleSumbit}>
          <Row>
            <Col sm={12} md={6} lg={4} xl={3}>
              <DatePicker
                label="Período"
                setState={state => setPageFilters({
                  ...pageFilters,
                  date: state,
                })}
                state={pageFilters.date}
                reset={() => setPageFilters({
                  ...pageFilters,
                  date: DefaultDate,
                })}
                disable={loading}
              />
            </Col>

            {ShowSelects(userProfile.userProfileId).companies && pageFilters.companies.length > 1 && (
              <Col sm={12} md={6} lg={4} xl={3}>
                <CompanySelect
                  value={pageFilters.companiesSelected}
                  name="companiesSelected"
                  label="Empresa"
                  placeholder="Selecione..."
                  options={pageFilters.companies}
                  setCompaniesSelected={c => {
                    if (c.length < 2) {
                      return setPageFilters({
                        ...pageFilters,
                        companiesSelected: c,
                      })
                    }

                    return false;
                  }}
                  disable={loading}
                />
              </Col>
            )}

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                name="locator"
                label="Localizador"
                value={pageFilters.locator}
                onChange={e => setPageFilters({
                  ...pageFilters,
                  locator: e.target.value,
                })}
                disable={loading}
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                name="ticket"
                label="Bilhete"
                value={pageFilters.ticket}
                onChange={e => setPageFilters({
                  ...pageFilters,
                  ticket: e.target.value,
                })}
                disable={loading}
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                name="request"
                label="Pedido/OS"
                value={pageFilters.request}
                onChange={e => setPageFilters({
                  ...pageFilters,
                  request: e.target.value,
                })}
                disable={loading}
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input
                name="pax"
                label="Viajante"
                value={pageFilters.pax}
                onChange={e => setPageFilters({
                  ...pageFilters,
                  pax: e.target.value,
                })}
                disable={loading}
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Select
                name="status"
                label="Status do Reembolso"
                placeholder="Selecione..."
                onChange={e => {
                  setPageFilters({
                    ...pageFilters,
                    status: e.value,
                  });

                  return true;
                }}
                options={refundStatus}
              />
            </Col>

            <Col sm={12} md={12} lg={12} xl={12}>
              <FilterButtons>
                <button
                  className="btn btn-warning"
                  type="button"
                  onClick={resetPageFilters}>
                  {loading && <Spinner color="#fff" size="sm" />}

                  {!loading && <>Limpar</>}
                </button>

                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}>
                  {loading && <Spinner color="#fff" size="sm" />}

                  {!loading && <>Aplicar</>}
                </button>
              </FilterButtons>
            </Col>
          </Row>
        </Form>
      </PanelBody>
    </Panel>
  );
}

export default Filters;
