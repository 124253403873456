import React from 'react';

import {
  Row,
  Col
} from 'reactstrap';

import {
  UlAgencys,
  LiAgencys
} from '~/styles/ul';

import {
  DivFlex,
  DivFlexColumn,
  DivFlexCentered,
  ColorLegend
} from '~/styles/divs';

import {
  Avatar,
  TextAvatar,
  TextEmail,
  TextUser,
  AgencyAdmin
} from './styles';

import {
  FormatPhoneOnlyNumbersBR,
  FormatPhoneBR
} from '~/functions/FormatPhone';

import {
  CreateAction
} from '~/components/ActionsOfTables';

function HasNoAgency({
  setPage = () => {}
}) {
  return (
    <DivFlexCentered minHeight={'200px'}>
      <CreateAction
        fz='16px'
        type='button'
        onClick={() => setPage('create')}>
        Cadastre uma Agência!
      </CreateAction>
    </DivFlexCentered>
  );
}

export default HasNoAgency;
