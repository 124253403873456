import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { api } from '~/services/api';
import history from '~/services/history';
import AuthActions, { AuthTypes } from '../ducks/auth';
import UserActions from '../ducks/user';
import AgencyActions from '../ducks/agency';

export function* signIn({ email, password }) {
  try {
    const response = yield call(api.post, 'auth', { email, password, web: true} )

    if (response.data.payload === null) {
      yield put(AuthActions.signFailure());

      return toast.info(
        response.data.message
      );
    }

    const { accessToken, user } = response.data.payload;

    api.defaults.headers.Authorization = `Bearer ${accessToken}`;

    const userProfileResponse = yield call(
      api.post,
      `auth/getprofile`,
      {
        email: user.email
      }
    );

    const companiesResponse = yield call(
      api.get,
      `auth/${user.email}/companies`,
      {
        params: {
          limit: 999,
        },
      }
    );

    const costCentersResponse = yield call(
      api.get,
      `auth/${user.email}/companies/costCenters`,
    );

    let routes = [];

    user.profile.profileMenu.map(x => {
      if (x.menu.menuSubMenu.length > 0 && !x.deletado) {
        x.menu.menuSubMenu.map(y => {
          return routes.push(y.subMenu.path);
        });
      }

      return routes.push(x.menu.path);
    });

    const agencySetting = user.agency;

    // document.title = `${agencySetting.displayName} ${agencySetting.suffix} - Plataforma de Gestão Corporativa de Viagens`;

    const companies = companiesResponse.data.payload;

    yield put(UserActions.insertCompanies(companies));

    const costCenters = costCentersResponse.data.payload;

    if (costCenters)
      yield put(UserActions.insertCostCenters(
        costCenters.map((cc) => ({
          costCenter: cc.nameCC,
          costId: cc.costId,
          name: cc.nameCC,
          code: cc.codeCC,
          desc: cc.descCC,
          type: cc.typeCC,
        }))
      ))
    else
      yield put(UserActions.insertCostCenters([]));

    yield put(UserActions.insertUserProfile(userProfileResponse.data));

    yield put(UserActions.insertRoutes(routes));

    yield put(AuthActions.signInSuccess(accessToken, user, agencySetting));

    return history.push("/dashboard");
  } catch (error) {
    yield put(AuthActions.signFailure());

    console.log(error);

    return toast.error(
      "Não foi possível realizar o Login, tente novamente mais tarde."
    );
  }
}

export function setToken({ payload }) {
  if (!payload)
    return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(AuthTypes.SIGN_OUT, signOut),
]);
