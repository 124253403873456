import React, { useState, useEffect, useContext } from 'react';
import { ApiAxios } from '~/services/api.js';
import TopCompanies from '../../Widgets/TopCompanies.js';
import WhiteCard from '../../WhiteCard'

import DashboardContext from '../../../context';

export default function TopCompanies11 () {
  const chartId = 11;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);

  const {
    companiesSelected,
    accessToken,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
    costCentersSelected,
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [TopCompaniesData, setTopCompaniesData] = useState({
    g3: 0,
    g3_tic: 0,
    lan: 0,
    lan_tic: 0,
    ad: 0,
    ad_tic: 0,
    outr: 0,
    outr_tick: 0,
    somaTick: 0,
    somaValor: 0
  });

  useEffect(() => {
    async function loadData () {
      try {
        setLoading(true);

        setLikes(user.preferences.dashboard.chart11);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        let handles = companiesSelected.map(x => x.value);

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const tick = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/tickets/ticketsCompanies`,
          {
            handle: handles,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: costCentersSelected.map(e => (e.value))
          },
          options
        );

        if(tick.data){
          setTopCompaniesData({
            g3: tick.data[1].g3,
            g3_tic: tick.data[1].g3_tic,
            lan: tick.data[1].lan,
            lan_tic: tick.data[1].lan_tic,
            ad: tick.data[1].ad,
            ad_tic: tick.data[1].ad_tic,
            outr: tick.data[1].outr,
            outr_tic: tick.data[1].outr_tic,
            somaValor: tick.data[1].somaValor,
            somaTick: tick.data[1].somaTick
          })
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected]);

  return (
    <WhiteCard
      title={'Companhias'}
      modalTitle='Observação'
      modalDesc='Valor total e quantidade de bilhetes adquiridos por cia aérea.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <TopCompanies data={TopCompaniesData}/>
    </WhiteCard>
  );
}
