import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

export default function AdvancedPurchase({
  data
}) {

  const [chartData4, setChartData4] = useState({});


  useEffect(() => {
    async function loadData () {
    setChartData4({
      labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      datasets: [{
        data: [data.jan, data.fev, data.mar, data.abr, data.mai, data.jun, data.jul, data.ago, data.set, data.out, data.nov, data.dez],
        backgroundColor: ['#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2', '#7e2ec2'],
        borderWidth: 4,
        label: 'Compras Nacionais',
      }]
    })
  }

  loadData();
    }, [data])
  return (
    <>
      <Bar data={chartData4} options={{responsive: true,
            tooltips: {
              enabled: true,
              callbacks: {
                label: function(tooltipItem, data) {
                  return ` ${data.labels[tooltipItem.index]}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}%`;
                }
              }
            }}} />
    </>
  );
}
const styles = {
  titleLegend:{
    marginTop: "10px",
    fontSize: "11px",
    fontWeight: 700
  },
  redLegend: {
    marginTop: "10px",
    fontSize: "11px",
    fontWeight: 700,
    color: 'red'
  }
}
