import React from 'react';
import PropTypes from "prop-types";

import ReactExport from 'react-export-excel';

function ExportSpreadSheet({
  data,
  columns,
  element,
  filename,
  sheetName,
}) {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  return (
    <ExcelFile
      element={element}
      filename={filename}>
      <ExcelSheet
        data={data}
        name={sheetName}>
        {columns.map((e, i) => (
          <ExcelColumn
            label={e.label}
            value={e.value}
            key={i} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
}

ExportSpreadSheet.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  element: PropTypes.element.isRequired,
  filename: PropTypes.string.isRequired,
  sheetName: PropTypes.string.isRequired,
};

export default ExportSpreadSheet;
