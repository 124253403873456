import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import RankingRoutes from '../../Widgets/RankingRoutes';
import WhiteCard from '../../WhiteCard';
import CompanyPermission from '~/functions/CompanyPermission';


import DashboardContext from '../../../context';

export default function RankingRoutes15 () {
  const chartId = 15;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);
  const userProfile = useSelector(state => state.user.userProfile);

  const {
    accessToken,
    companiesOptions,
    setCompaniesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCentersOptions,
    setCostCentersOptions,
    costCentersSelected,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [DaysOutlaysData, setDaysOutlaysData] = useState({
    tk: 0,
    h: 0,
    sv: 0,
    ou: 0,
    vi: 0
  });

  const [rankingRoutes, setRankingRoutes] = useState([]);

  useEffect(() => {
    async function loadData () {
      try {
        setDaysOutlaysData({
          tk: 0,
          h: 0,
          sv: 0,
          ou: 0,
          vi: 0
        });

        setLoading(true);

        setLikes(user.preferences.dashboard.chart10);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        const Companies = companiesSelected.length === 0
        ? companiesOptions.map(x => (x.value))
        : companiesSelected.map(x => (x.value));

        const CostCenters = !CompanyPermission(userProfile.userProfileId).all
          ? (
            costCentersSelected.length === 0
              ? costCentersOptions.map(e => (e.value))
              : costCentersSelected.map(e => (e.value))
          )
          : costCentersSelected.map(e => (e.value));

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/shopping/air/Trechos`,
          {
            handle: Companies,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: CostCenters
          },
          options
        );

        if(data){
          const firstFiveRouters = data.slice(0, 6);

          setRankingRoutes(firstFiveRouters)
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected]);

  return (
    <WhiteCard
      title={'Ranking Trechos'}
      modalTitle='Observação'
      modalDesc='Trechos mais voados.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <p>{' '}</p>
      <p>{' '}</p>
      <RankingRoutes data={rankingRoutes}/>
      <p>{' '}</p>
      <p>{' '}</p>
    </WhiteCard>
  );
}
