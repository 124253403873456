import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Spinner } from 'reactstrap';
import { Form } from '@unform/web';
import {
  FormatPhoneOnlyNumbersBR,
  FormatPhoneBR
} from '~/functions/FormatPhone';
import { ApiAxios } from '~/services/api.js';
import { useHistory } from 'react-router-dom';

import {
  EditAction,
  CancelDeleteAction,
  CreateAction
} from '~/components/ActionsOfTables';

import { Panel } from '~/components/Panel';
import Input from '~/components/Input';
import Select from '~/components/Select';
import SliderToggle from '~/components/SliderToggle';

import {
  SubmitButton,
  PanelFilterCompanyGroup,
  PanelSearchResult,
  SpanPasswordAlert,
  SpanLi,
  LiSearch,
  Modal,
  ModalBox,
  ModalContent,
  ModalTitle,
  ModalTextarea,
  ModalButton,
} from './styles';

function List({
  setPage,
  typeOfList,
  setUserToEdit,
  setUserToDelete
}) {
  const axios = ApiAxios();
  const accessToken = useSelector(state => state.auth.accessToken);
  const [loading, setLoading] = useState(false);
  const closeModal = (target, toogleModal) => { if(target.id == 'modal-background') return toogleModal(false) }
  // const [modalSendMessage, toggleModalSendMessage] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [zapList, setZapList] = useState(false);
  // const formRef = useRef(null);

  // const closeModal = (target, toogleModal) => { if(target.id == 'modal-background') return toogleModal(false) }
  // const [messageToSend, setMessageToSend] = useState('');
  // const [modalSendMessage, toggleModalSendMessage] = useState(false);

  const [allUsers, setAllUsers] = useState({
    accountExecutive: [],
    admin: [],
    corpManager: [],
    developer: [],
    financial: [],
    support: [],
  });

  const [usersToList, setUsersToList] = useState([]);
  const [changingStatus, setChangingStatus] = useState(false);

  const formRef = useRef(null);
  const history = useHistory()

  const companies = useSelector(state => state.user.companies);
  const user = useSelector(state => state.user.profile);
  const [modalSendMessage, toggleModalSendMessage] = useState(false);
  const [modalSetContact, toggleModalSetContact] = useState(false);
  const [flightData, setFlightData] = useState(null);
  const [userSelectedForModal, setUserSelectedForModal] = useState({name: "", index: ""})
  const [paxsWhatsapp, setPaxsWhatsapp] = useState([]);

  const [paxWhatsAppNumber, setPaxWhatsAppNumber] = useState([]);
  const [allPaxWhatsappNumbers, setAllPaxWhatsappNumbers] = useState([]);

  const [messageToSend, setMessageToSend] = useState('');
  const [emissor, setEmissor] = useState([]);
  const [isFetchingHandleRecordWhatsapp, setIsFetchingHandleRecordWhatsapp] = useState(false);
  const [wpp, setWpp] = useState('');

  // const sendWhatsApp = async (paxToSend) => {
  //   try {

  //     const filter = {
  //       phoneNumbers: emissor.map(({phone}) => phone),
  //       message: messageToSend
  //     };

  //     if(0.1 + 0.1 == 0.2){

  //     }

  //     const options = {
  //       headers: { Authorization: `Bearer ${accessToken}` },
  //     };

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_ENDPOINT_INDICATORS}/flights/send/twilio-whatsapp/pax`,
  //       filter,
  //       options
  //     );

  //     setMessageToSend('');
  //     toast.success('Mensagem Enviada com sucesso.');
  //     return response;
  //   } catch (error) {
  //     return toast.error('Não foi possível enviar a mensagem. Tente mais tarde.');
  //   }
  // }

  useEffect(() => {
    async function loadUsers () {
      setAllUsers({
        accountExecutive: [],
        admin: [],
        corpManager: [],
        developer: [],
        financial: [],
        support: [],
        emitters: [],
      });

      try {
        setLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/agency-user/ofagency`
        );

        const emittersResponse = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/gettravelemitters`
        );

        const emittersData = emittersResponse.data.map((e) => ({
          id: e.id,
          name: e.entireName,
          phone: e.phone,
          status: e.active,
          code: e.codName
        }))

        setAllUsers({
          accountExecutive: response.data.accountExecutive,
          admin: response.data.admin,
          corpManager: response.data.corpManager,
          developer: response.data.developer,
          financial: response.data.financial,
          support: response.data.support,
          emitters: emittersData
        })

        return setLoading(false);
      } catch (error) {
        return setLoading(false);
      }
    }

    loadUsers();
  }, [accessToken]);

  useEffect(() => {
    async function loadUsersToList () {
      setUsersToList([]);

      switch (typeOfList) {
        case 1:
          setUsersToList(allUsers.admin);
          break;
        case 2:
          setUsersToList(allUsers.support);
          break;
        case 3:
          setUsersToList(allUsers.financial);
          break;
        case 4:
          setUsersToList(allUsers.developer);
          break;
        case 8:
          setUsersToList(allUsers.corpManager);
          break;
        case 9:
          setUsersToList(allUsers.accountExecutive);
          break;
        case 10:
          setUsersToList(allUsers.emitters);
          break;
        default:
          break;
      }

      return true;
    }

    loadUsersToList();
  }, [allUsers, typeOfList]);

  async function handleActivateUser(email, status) {
    try {
      setLoading(true);

      setChangingStatus(true);

      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/agency-user/alterstatus/${email}`
      );

      setAllUsers(response.data)

      setChangingStatus(false);

      toast.info(
        `Usuário ${status ? 'desativado' : 'ativado'} com sucesso.`
      );

      return setLoading(false);
    } catch (error) {
      setChangingStatus(false);

      return setLoading(false);
    }
  }

  async function resetPassword(email) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/forgotPassword/${email}`,
      );

      return toast.success(
        `Link de redefinição de senha enviado para o usuário. Email: ${email}`
      );
    } catch (error) {
      return console.log("Não foi possível enviar a redefinição de senha.");
    }
  }

  // async function enviarZap(e) {
  //   try {

  //     const Filter ={
  //       phone: e.phone,
  //       texto: messageToSend
  //     }

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_ENDPOINT_INDICATORS}/flights/send/twilio-whatsapp/relatory`,
  //       Filter
  //     );

  //     return setLoading(false);

  //   } catch (error) {
  //     toast.error('Não foi possível enviar WhatsApp');
  //     setLoading(false);
  //   }
  // }

  return (
    <>
      <h5>
        {`Lista de Usuários`}
        {typeOfList === 1 && ` (Administrador)`}
        {/* {typeOfList === 2 && ` (Suporte)`}
        {typeOfList === 3 && ` (Agente Financeiro)`} */}
        {typeOfList === 8 && ` (Gerente Corporativo)`}
        {typeOfList === 9 && ` (Executivo de Contas)`}
        {typeOfList === 10 && ` (Emissor)`}
      </h5>

      <table className='table table-hover table-responsive'>
        <thead>
          <tr>
            <th>Nome</th>
            {/* <th>Email</th> */}
            <th>Status</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {usersToList.map((e, i) => {
            return (
              <tr key={i}>
                <td>{e.name}</td>

                {/* <td>{e.email}</td> */}

                {/* <td>{e.contacts.length > 0 ? e.contacts[0].phone : ''}</td> */}

                <td>
                    <SliderToggle
                      checked={e.status}
                      click={() => handleActivateUser(e.email, e.status)} />
                </td>

                <td>
                  {
                    <>
                      <EditAction
                        type='button'
                        style={{backgroundColor: '#000000', color: 'white'}}
                        onClick={() => {
                          setUserToEdit(e);

                          return setPage('edit');
                        }}>
                        Editar
                      </EditAction>

                      <CancelDeleteAction
                        style={{
                          opacity: e.userProfileId === 1 && usersToList.length === 1
                          ? '0.4'
                          : '1'
                        }}
                        disabled={
                          e.userProfileId === 1 && usersToList.length === 1
                        }
                        type='button'

                        onClick={() => {
                          setUserToDelete(e);

                          return setPage('delete');
                        }}>
                        Remover
                      </CancelDeleteAction>

                      <CreateAction
                        type='button'
                        style={{backgroundColor: '#fbba37'}}
                        hidden={
                          typeOfList === 10
                        }
                        onClick={() => resetPassword(e.email)}>
                        Resetar senha
                      </CreateAction>

                      <EditAction
                        style={{backgroundColor: '#A6D59A'}}
                        type="button"
                        hidden={
                          typeOfList !== 10
                        }
                        onClick={() => { toggleModalSendMessage(e); setEmissor([e]); } }

                        // onClick={() => toggleModalSendMessage(true)}
                        >

                        <strong>
                          Mensagem
                        </strong>

                        <br/>
                      </EditAction>

                      <Modal visible={modalSendMessage} id="modal-background" onClick={({ target }) => closeModal( target, toggleModalSendMessage )}>
                        <ModalBox>
                          <ModalContent>
                            <span className="modal-close" onClick={() => toggleModalSendMessage(false)}></span>
                            <ModalTitle>Envio de Mensagem</ModalTitle>
                            {/* Você está enviando uma mensagem para */}

                            Você está enviando uma mensagem para: <strong>{paxWhatsAppNumber.map((pax, index) => `${pax.wpp} (${pax.name})${paxWhatsAppNumber.length > 1 && index !== paxWhatsAppNumber.length - 1 ? ", " : ""}`)}</strong>
                            <ModalTextarea
                              value={messageToSend}
                              onChange={e => setMessageToSend(e.target.value)}
                              placeholder="Escreva sua mensagem aqui..." />
                            <ModalButton
                            className='btn'
                            disabled={messageToSend.length <= 0}
                            onClick={() => {
                              toggleModalSendMessage(false);

                              // sendWhatsApp(e);
                            }}>
                              Enviar
                            </ModalButton>
                          </ModalContent>
                        </ModalBox>
                      </Modal>
                    </>
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default List;
