import React, { useState, useContext, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { ApiAxios } from "~/services/api.js";
import { Form } from "@unform/web";
import { Row, Col, Spinner } from "reactstrap";
import { FaSistrix } from "react-icons/fa";
import ShowSelects from "~/functions/ShowSelects";
import CompanyPermission from "~/functions/CompanyPermission";

import RequestManagementContext from "../../context";
import { Panel, PanelHeader, PanelBody } from "~/components/Panel";
import Input from "~/components/Input";
import Select from "~/components/Select";
import DatePicker from "~/components/DatePickerIndicators";

import { FilterButtons } from "./styles";
import CostCenterSelect from "~/components/CostCenterSelect";
import CompanySelect from "~/components/CompanySelect";
import ExecutiveSelect from "~/components/ExecutiveSelect";

import { DefaultDate } from "../../constants";

export default function Filters() {
  const {
    formRef,
    loading,
    dateState,
    setDateState,
    filterStates,
    pagination,
    getOrders,
  } = useContext(RequestManagementContext);

  const [, setPassenger] = filterStates.passenger;
  const [, setIdNumber] = filterStates.idNumber;
  const [status, setStatus] = filterStates.status;

  async function handleSubmit(values) {
    setPassenger(values.passenger);
    setIdNumber(values.idNumber);
    // setStatus(values.status);

    getOrders(pagination.page, pagination.offset, {
      openingDate: dateState.selectedDate.startDate
        .split("/")
        .reverse()
        .join("-"),
      closingDate: dateState.selectedDate.endDate
        .split("/")
        .reverse()
        .join("-"),
      status: values.status,
      idNumber: values.idNumber,
      approver: values.approver,
      passenger: values.passenger,
    });
  }

  function handleResetForm() {
    formRef.current.reset();
    setStatus("")
    setDateState(DefaultDate);
  }

  function resetDate() {
    return setDateState(DefaultDate);
  }

  return (
    <Panel theme="inverse">
      <PanelHeader noButton title="Filtros" />
      <PanelBody>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row>
            <Col sm={12} md={6} lg={4} xl={3}>
              <DatePicker
                label="Data de criação"
                setState={setDateState}
                state={dateState}
                reset={resetDate}
                disable={loading}
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Select
                name="status"
                label="Status"
                placeholder="Selecione"
                isClearable
                value={status}
                onChange={(e) => {
                  setStatus("");
                  if (e !== null)
                    return setStatus([e]);
                }}
                options={[
                  { label: "Em Aberto", value: "Em Aberto" },
                  { label: "Em Aberto (Erro)", value: "Em Aberto (Erro)" },
                  { label: "Fechada", value: "Fechada" },
                  {
                    label: "Pagamentos Pendentes",
                    value: "Pagamentos Pendentes",
                  },
                  { label: "Pagamentos Ok", value: "Pagamentos Ok" },
                  { label: "Produtos Pendentes", value: "Produtos Pendentes" },
                  { label: "Cancelada", value: "Cancelada" },
                ]}
              />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input name="idNumber" label="Pedido/OS" type="number" />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input name="passenger" label="Viajante" />
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <Input name="approver" label="Aprovador" />
            </Col>
          </Row>

          <FilterButtons>
            <button
              className="btn btn-warning"
              type="button"
              onClick={handleResetForm}
            >
              {loading && <Spinner color="#fff" size="sm" />}

              {!loading && <>Limpar</>}
            </button>

            <button
              className={`btn btn-primary ${loading && "disabled"}`}
              type="submit"
              disabled={loading}
            >
              {loading && <Spinner color="#fff" size="sm" />}

              {!loading && <>Aplicar</>}
            </button>
          </FilterButtons>
        </Form>
      </PanelBody>
    </Panel>
  );
}
