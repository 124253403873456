import React, { useRef, useState, useEffect, useContext } from 'react';
import Select from '~/components/Select';
import DatePicker from '~/components/DatePickerIndicators';
import { useSelector } from 'react-redux';
import { ApiAxios } from '~/services/api.js';
import { Row, Col } from 'reactstrap';

import ShowSelects from '~/functions/ShowSelects';
import { FormatValueBRL } from '~/functions/FormatValue.js';

import CostCenterSelect from '~/components/CostCenterSelect'
import CompanySelect from '~/components/CompanySelect'
import { ButtonCardColumn } from '~/styles/buttons/card';
import { ContainerTransparent } from '~/styles/container';
import AuthActions from '~/store/modules/ducks/auth';
import UserActions from '~/store/modules/ducks/user';
import DashboardAgencyContext from '../../context';
import { DefaultDate } from './constants';
import { Row as Row2, Container } from './styles';
import { Form } from '@unform/web';
import {
  IndicatorsTitle,
  IndicatorsNav,
  IndicatorsNavButton,
  TitleFilters,
  FiltersContainer
} from './styles';

export default function DashboardAgencyIndicators() {
  const axios = ApiAxios();
  const userProfile = useSelector(state => state.user.userProfile);

  const {
    companiesOptions,
    setCompaniesOptions,
    companiesSelected,
    setCompaniesSelected,
    costCenterSelected,
    setCostCenterSelected,
    costCenterOptions,
    setCostCenterOptions,
    user,
    dateState,
    setDateState,
    resetDateState,
    body,
    setBody,
    loading,
    setLoading,
    PinChart,
    UnpinChart
  } = useContext(DashboardAgencyContext);

  const userCompanies = useSelector(state => state.user.companies);

  const [showFilter, setShowFilter] = useState(true);
  const [costCentersFetched, setCostCentersFetched] = useState([]);
  const [emissor, setEmissor] = useState([]);
  const [total, setTotal] = useState([]);
  const [transacao, setTransacao] = useState([]);
  const [whatsAppLazer, setWhatsAppLazer] = useState({
    type: 'LAZER',
    quantity: 0,
    margins: 0.00,
    sells: 0.00
  });
  const [whatsAppCorp, setWhatsAppCorp] = useState({
    type: 'CORP',
    quantity: 0,
    margins: 0.00,
    sells: 0.00
  }
  );
  const [whatsAppRep, setWhatsAppRep] = useState({
    type: 'RECEPTIVO',
    quantity: 0,
    margins: 0.00,
    sells: 0.00
  }
  );
  const [whatsAppTech, setwhatsAppTech] = useState({
    type: 'WEETECH',
    quantity: 0,
    margins: 0.00,
    sells: 0.00
  }
  );
  const [ano, setAno] = useState([]);

  const formRef = useRef(null);


  useEffect(() => {
    async function loadData() {
      try {

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        // const options = {
        //   headers: { Authorization: `Bearer ${accessToken}` },
        // };

        const margem = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/shopping/air/TotalMargem`,
          {
            datai: VencimentoInicial,
            dataf: VencimentoFinal
          }
        );

        if (margem.data) {
          setEmissor(margem.data);
        }

        const total = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/shopping/air/Total`,
          {
            datai: VencimentoInicial,
            dataf: VencimentoFinal
          }
        );

        if (total.data) {
          setTotal(total.data);
        }

        const transacao = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/shopping/air/Transacao`,
          {
            datai: VencimentoInicial,
            dataf: VencimentoFinal
          }
        );

        if (transacao.data) {
          setTransacao(transacao.data);
        }

        const ano = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/shopping/air/Ano`,
          {
            datai: VencimentoInicial,
            dataf: VencimentoFinal
          }
        );

        if (ano.data) {
          setAno(ano.data);
        }

        const whatsApp = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/shopping/relatory/whatsapp`,
          {
            datai: VencimentoInicial,
            dataf: VencimentoFinal
          }
        );


        if (whatsApp.data.length > 0) {
          setWhatsAppCorp(whatsApp.data[0]);
        }
        else if (whatsApp.data.length == 0){
          setWhatsAppCorp(0);
        }

        if (whatsApp.data.length > 1) {
          setWhatsAppLazer(whatsApp.data[1]);
        }
        else if (whatsApp.data.length <= 1){
          setWhatsAppLazer(0);
        }

        if (whatsApp.data.length > 2) {
          setWhatsAppRep(whatsApp.data[2]);
        }
        else if (whatsApp.data.length <= 2){
          setWhatsAppRep(0);
        }

        if (whatsApp.data.length > 3) {
          setwhatsAppTech(whatsApp.data[3]);
        }
        else if (whatsApp.data.length <= 3){
          setwhatsAppTech(0);
        }


        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCenterSelected]);

  return (
    <DashboardAgencyContext.Provider
      value={{
        companiesOptions,
        costCenterOptions
      }}>
      <ContainerTransparent>
        <Row2>
          <Col md={6} lg={3}>
            GERAL
            <ButtonCardColumn bg='transparent' fg='#333'>
              <h5 style={{ borderRadius: '2px' }}>VENDAS</h5>

              <h3>{FormatValueBRL(total.map(x => x.total_venda))}</h3>
            </ButtonCardColumn>
          </Col>
          <Col md={6} lg={3}>
          &nbsp;
            <ButtonCardColumn bg='transparent' fg='#333'>
              <h5 style={{ borderRadius: '2px' }}>MARGEM</h5>

              <h3>{FormatValueBRL(emissor.map(x => x.total_margem))}</h3>
            </ButtonCardColumn>
          </Col>
          <Col md={6} lg={3}>
          &nbsp;
            <ButtonCardColumn bg='transparent' fg='#333'>
              <h5 style={{ borderRadius: '2px' }}>TRANSAÇÕES</h5>

              <h3>{transacao.map(x => x.transacao)}</h3>
            </ButtonCardColumn>
          </Col>
          <Col md={6} lg={3}>
          &nbsp;
            <ButtonCardColumn bg='transparent' fg='#333'>
              <h5 style={{ borderRadius: '2px' }}>VENDAS/ANO</h5>

              <h3>{FormatValueBRL(ano.map(x => x.total_venda))}</h3>
            </ButtonCardColumn>
          </Col>
        </Row2>

        <Row2>
          <Col md={12} lg={6}>
            LAZER
            <ButtonCardColumn bg='transparent' fg='#333'>
              <h5 style={{ borderRadius: '2px' }}>VENDAS LAZER</h5>

              <h3>{FormatValueBRL(whatsAppLazer.sells)}</h3>
            </ButtonCardColumn>
          </Col>
          <Col md={12} lg={6}>
          &nbsp;
            <ButtonCardColumn bg='transparent' fg='#333'>
              <h5 style={{ borderRadius: '2px' }}>MARGENS LAZER</h5>

              <h3>{FormatValueBRL(whatsAppLazer.margins)}</h3>
            </ButtonCardColumn>
          </Col>
        </Row2>
        <Row2>
          <Col md={12} lg={6}>
            CORPORATIVO
            <ButtonCardColumn bg='transparent' fg='#333'>
              <h5 style={{ borderRadius: '2px' }}>VENDAS CORPORATIVO</h5>

              <h3>{FormatValueBRL(whatsAppCorp.sells)}</h3>
            </ButtonCardColumn>
          </Col>
          <Col md={12} lg={6}>
          &nbsp;
            <ButtonCardColumn bg='transparent' fg='#333'>
            <h5 style={{ borderRadius: '2px' }}>MARGENS CORPORATIVO</h5>

            <h3>{FormatValueBRL(whatsAppCorp.margins)}</h3>
            </ButtonCardColumn>
          </Col>
        </Row2>
        <Row2>
          <Col md={12} lg={6}>
            WEE RECEPTIVO
            <ButtonCardColumn bg='transparent' fg='#333'>
              <h5 style={{ borderRadius: '2px' }}>VENDAS WEE RECEPTIVO</h5>

              <h3>{FormatValueBRL(whatsAppRep.sells)}</h3>
            </ButtonCardColumn>
          </Col>
          <Col md={12} lg={6}>
          &nbsp;
            <ButtonCardColumn bg='transparent' fg='#333'>
            <h5 style={{ borderRadius: '2px' }}>MARGENS WEE RECEPTIVO</h5>

            <h3>{FormatValueBRL(whatsAppRep.margins)}</h3>
            </ButtonCardColumn>
          </Col>
        </Row2>
        <Row2>
          <Col md={12} lg={6}>
            WEETECH
            <ButtonCardColumn bg='transparent' fg='#333'>
              <h5 style={{ borderRadius: '2px' }}>VENDAS WEETECH</h5>

              <h3>{FormatValueBRL(whatsAppTech.sells)}</h3>
            </ButtonCardColumn>
          </Col>
          <Col md={12} lg={6}>
          &nbsp;
            <ButtonCardColumn bg='transparent' fg='#333'>
            <h5 style={{ borderRadius: '2px' }}>MARGENS WEETECH</h5>

            <h3>{FormatValueBRL(whatsAppTech.margins)}</h3>
            </ButtonCardColumn>
          </Col>
        </Row2>

      </ContainerTransparent>

      <Row style={{
        justifyContent: 'space-between'
      }}>

      </Row>
    </DashboardAgencyContext.Provider>
  );
}
