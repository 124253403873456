/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react';

import { Panel, PanelHeader } from '~/components/Panel';
import {
  CreateAction,
  CloseAction,
  CancelDeleteAction
} from '~/components/ActionsOfTables';

import {
  Container,
} from '~/styles/container';

import {
  SpaceBetweenDiv
} from '~/styles/divs';

import Dashboard from './components/Dashboard';
import Companies from './components/Companies';
import Obt from './components/Obt';

function CompanyManager() {
  const [companyManagerPage, setCompanyManagerPage] = useState('dashboard');

  const [breadcrumb, setBreadcrumb] = useState([
    'Dashboard'
  ]);

  return (
    <Panel theme='inverse'>
      <PanelHeader noButton>
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <div>
            {'Gestão de Empresas'}
          </div>
        </div>
      </PanelHeader>

      {/* <Container>
        <SpaceBetweenDiv>
          <b>
            <CloseAction
              type='button'
              onClick={() => {
                setBreadcrumb(['Dashboard']);

                return setCompanyManagerPage('dashboard');
              }}>
              {companyManagerPage === 'dashboard' && <i className="fas fa-bars" />}

              {companyManagerPage !== 'dashboard' && <i className="fas fa-arrow-left" />}
            </CloseAction>

            {breadcrumb.map((e, i) => {
              if (i > 0) {
                return ` / ${e}`;
              }

              return `${e}`;
            })}
          </b>
        </SpaceBetweenDiv>
      </Container> */}

      {/* {companyManagerPage === 'dashboard' && (
        <Dashboard
          setCompanyManagerPage={setCompanyManagerPage}
          setBreadcrumb={setBreadcrumb}
          breadcrumb={breadcrumb} />
      )} */}

      {/* {companyManagerPage === 'companies' && ( */}
        <Companies
          setCompanyManagerPage={setCompanyManagerPage}
          setBreadcrumb={setBreadcrumb}
          breadcrumb={breadcrumb} />
      {/* )} */}

      {/* {companyManagerPage === 'obt' && (
        <Obt />
      )} */}
    </Panel>
  );
}

export default CompanyManager;
