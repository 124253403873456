import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'corpsys',
      storage,
      whitelist: ['auth', 'user', 'language', 'agency'],
    },
    reducers
  );

  return persistedReducer;
};
