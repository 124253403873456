import React, { useState, useEffect } from 'react';
import {
  useTable,
  useExpanded,
  usePagination,
  useBlockLayout,
  useResizeColumns,
} from 'react-table';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import {
  Container,
  SubContainer,
  Pagination,
  PageSelector,
  InfoContent,
  InfoContentFlag
} from './styles';

function Table({
  columns,
  data,
  tableLabels = {
    noDataText: 'No rows',
    loadingText: 'Loading',
    nextButtonText: 'Next',
    previousButtonText: 'Previous',
    pageText: 'Page',
    pageOfText: 'of',
    pageSizeSelectText: 'rows',
  },
  tableProps = {
    resizable: true,
    hasPagination: true,
    hasFooter: false,
    defaultPageSize: 10,
    loading: false,
  },
  tableStyle = {
    fontSize: '13px',
    cellPadding: '4px',
  },
  subComponent,
}) {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 36,
      // width: 100,
      maxWidth: 400,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    // rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    resetResizing,
    // visibleColumns,
    // isAllRowsExpanded,
    toggleAllRowsExpanded,
    // getToggleAllRowsExpandedProps,
    // toggleRowExpanded,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: tableProps.defaultPageSize,
        autoResetExpanded: true
      },
    },
    useExpanded,
    usePagination,
    useBlockLayout,
    useResizeColumns,
  );

  const [pageChanged, setPageChanged] = useState(0);

  useEffect(() => {
    function setPageOfTable() {
      const page = pageChanged ? Number(pageChanged - 1) : 0;

      return gotoPage(page);
    }

    setPageOfTable();
  }, [pageChanged]);

  useEffect(() => {
    resetResizing();
  }, [data]);

  function handleOthersRows () {
    let otherRows = [];

    for (var i = 0; i < (pageSize - page.length); i++) {
      otherRows.push((
        <tr key={i}>
          <td>&nbsp;</td>
        </tr>
      ));
    }

    return otherRows;
  }

  return (
    <>
      <Container tableStyle={tableStyle}>
        {data.length === 0 && (
          <InfoContent>
            <InfoContentFlag>
              {tableLabels.noDataText}
            </InfoContentFlag>
          </InfoContent>
        )}

        {tableProps.loading && (
          <InfoContent>
            <InfoContentFlag>
              {tableLabels.loadingText}
            </InfoContentFlag>
          </InfoContent>
        )}

        <div
          className={`
            table-responsive
          `}>
          <table
            className={`
              table
              table-sm
              table-striped
              table-hover
            `}
            {...getTableProps()}>
            <thead>
              {headerGroups.map(row => (
                <tr {...row.getHeaderGroupProps()}>
                  {row.headers.map(column => (
                    <th style={{width: column.width }} {...column.getHeaderProps()}>
                      {column.render('Header')}

                      {tableProps.resizable && (
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? 'isResizing' : ''
                          }`}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);

                return (
                  <React.Fragment key={i}>
                    <tr
                      className={`
                        ${row.isExpanded && 'expanding '}
                      `}
                      {...row.getRowProps()}
                      key={i}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>

                    {subComponent && row.isExpanded
                      ? (
                        <tr
                          className={`
                            sub-component
                          `}>
                          <td>
                            <SubContainer {...row.getRowProps()}>
                              <div
                                className={`
                                  table-responsive
                                `}>
                                {subComponent({ row }, toggleAllRowsExpanded)}
                              </div>
                            </SubContainer>
                          </td>
                        </tr>
                      )
                      : null
                    }
                  </React.Fragment>
                );
              })}

              {tableProps.hasPagination && data.length > tableProps.defaultPageSize && handleOthersRows()}
            </tbody>

            {tableProps.hasFooter && page.length > 0 && (
              <tfoot>
                {footerGroups.map(group => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map(column => (
                      <td {...column.getFooterProps()}>{column.render("Footer")}</td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </table>
        </div>
      </Container>

      {tableProps.hasPagination && data.length > tableProps.defaultPageSize && (
        <Pagination>
          <Col sm={3} md={4}>
            <button
              type='button'
              onClick={() => previousPage()}
              disabled={!canPreviousPage || tableProps.loading || data.length === 0}>
              {tableLabels.previousButtonText}
            </button>
          </Col>

          <Col sm={6} md={4}>
            <Row>
              <Col sm={12} xl={6}>
                <PageSelector>
                  <span>{tableLabels.pageText}</span>

                  <input
                    disabled={tableProps.loading || data.length === 0}
                    type="number"
                    min={1}
                    max={pageOptions.length}
                    value={pageIndex + 1}
                    onClick={e => {
                      return e.target.select()
                    }}
                    onChange={e => {
                      return setPageChanged(e.target.value)
                    }} />

                  <span>{`${tableLabels.pageOfText} ${pageOptions.length}`}</span>
                </PageSelector>
              </Col>

              <Col sm={12} xl={6}>
                <PageSelector>
                  <select
                    disabled={tableProps.loading || data.length === 0}
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value))
                    }}>
                    {[5, 10, 15, 25, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {`${pageSize} ${tableLabels.pageSizeSelectText}`}
                      </option>
                    ))}
                  </select>
                </PageSelector>
              </Col>
            </Row>
          </Col>

          <Col sm={3} md={4}>
            <button
              type='button'
              onClick={() => nextPage()}
              disabled={!canNextPage || tableProps.loading || data.length === 0}>
              {tableLabels.nextButtonText}
            </button>
          </Col>
        </Pagination>
      )}
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  subComponent: PropTypes.func,
  tableLabels: PropTypes.shape({
    loadingText: PropTypes.string,
    noDataText: PropTypes.string,
    nextButtonText: PropTypes.string,
    previousButtonText: PropTypes.string,
    pageText: PropTypes.string,
    pageOfText: PropTypes.string,
    pageSizeSelectText: PropTypes.string,
  }),
  tableStyle: PropTypes.shape({
    fontSize: PropTypes.string,
    cellPadding: PropTypes.string,
  }),
  tableProps: PropTypes.shape({
    resizable: PropTypes.bool,
    hasPagination: PropTypes.bool,
    hasFooter: PropTypes.bool,
    defaultPageSize: PropTypes.number,
    loading: PropTypes.bool,
  }),
};

export default Table;
