import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import {
  NavLiSubItemLink
} from '../styles';

function SidebarNavSubItem({
  data,
  location,
  setExpanded,
}) {
  const [itemState, setItemState] = useState({
    hasSub: false,
    active: false,
    clicked: false,
  });

  useEffect(() => {
    async function loadState () {
      return setItemState({
        ...itemState,
        hasSub: data.menuSubMenu && data.menuSubMenu.length > 0,
        active: location && location.pathname === data.path,
      });
    }

    loadState();
  }, [data, location]);

  useEffect(() => {
    async function handleExpand () {
      if (itemState.active) {
        setExpanded(true);
      }
    }

    handleExpand();
  }, [itemState]);

  return (
    <li
      className={`
        ${itemState.active && 'active '}
      `}>
      <NavLiSubItemLink to={data.path}>
        {itemState.hasSub && <b className="caret" />}

        <i style={{ fontSize: 18 }} className={data.icon} />

        <span>{data.title}</span>
      </NavLiSubItemLink>
    </li>
  );
}

SidebarNavSubItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    childrens: PropTypes.array
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

export default SidebarNavSubItem;
