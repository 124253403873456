
import React, { useState, useContext, useRef } from "react";
import ReactTable from "~/components/Table";


import Switch from 'react-bootstrap/Switch';
import {  ColumnsTitleMain } from './styles';


import BudgetManagement from "../../context";

import { Panel, PanelHeader } from "~/components/Panel";

import { columns } from "../../constants";
import { ModalAlerts } from "./ModalAlert";
import { ModalEditBudget } from "./ModalEditBudget";



export default function Table() {
  const formAlerts = useRef(null);
  const formBudgets = useRef(null);
  const { budgets, loadingBudgets, analytics, setAnalytics } = useContext(BudgetManagement);

  const [modalAlerts, setModalAlerts] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState(null);

  function handleOpenModalEdit(){
    setModalEdit(true);
  }

  function handleCloseModalEdit(){
    formBudgets?.current?.reset();
    setSelectedBudget(null)
    setModalEdit(false);
  }


  function handleOpenModalAlerts(){
    setModalAlerts(true);
  }

  function handleCloseModalAlerts(){
    formAlerts?.current?.reset();
    setSelectedBudget(null)
    setModalAlerts(false);
  }


  function openAlerts(budget){
    setSelectedBudget(budget)

    handleOpenModalAlerts()
  }

  function openEdit(budget){
    setSelectedBudget(budget)

    handleOpenModalEdit()
  }

  // useEffect(() => {
  //   formAlerts?.current?.reset();
  // }, [selectedBudget, modalAlerts])


  return (
    <>
      <Panel theme="inverse">
      <PanelHeader noButton>
        <ColumnsTitleMain>
          <div>
            <span>
              Resultado da Pesquisa
            </span>
          </div>

          <div>
            <Switch
              type="switch"
              id="custom-switch"
              label="Analisar"
              checked={analytics}
              onChange={() => setAnalytics(prev => !prev)}
            />
          </div>
        </ColumnsTitleMain>

      </PanelHeader>

        {/* <PanelHeader noButton title={"Resultado da Pesquisa"}>
        </PanelHeader> */}
        <ReactTable
          columns={columns({ openAlerts, openEdit })}
          data={budgets}
          tableProps={{
            defaultPageSize: 10,
            hasFooter: true,
            hasPagination: true,
            loading: loadingBudgets,
            resizable: true
          }}
          tableStyle={{
            fontSize: '13px',
            cellPadding: '6px 4px'
          }}
          tableLabels={{
            loadingText: 'Carregando...',
            noDataText: 'Sem registros',
            nextButtonText: 'Próximo',
            previousButtonText: 'Anterior',
            pageOfText: 'de',
            pageSizeSelectText: 'registros',
            pageText: 'Página'
          }}
        />

        <ModalAlerts
          isOpen={modalAlerts}
          budget={selectedBudget}
          formRef={formAlerts}
          handleClose={handleCloseModalAlerts}
          />

        <ModalEditBudget
          isOpen={modalEdit}
          budget={selectedBudget}
          formRef={formBudgets}
          handleClose={handleCloseModalEdit}
          />
      </Panel>
    </>
  );
}
