import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #aaa;
  height: 100%;
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
