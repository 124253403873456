import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ApiAxios } from "~/services/api.js";

import Table from "./components/Table";
import { DefaultDate } from "~/functions/DefaultDate";

import ShowSelects from "~/functions/ShowSelects";
import CompanyPermission from "~/functions/CompanyPermission";

import ReemissionContext from "./context";

import Filters from "./components/Filters";

import { getDefaultProducts } from "~/services/filters";

export default function Substitution() {
  const axios = ApiAxios();
  const user = useSelector((state) => state.user.profile);
  const userProfile = useSelector((state) => state.user.userProfile);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const companies = useSelector((state) => state.user.companies);
  const costCenters = useSelector((state) => state.user.costCenters);

  const formRef = useRef(null);
  const [data, setData] = useState([]);
  const [dateState, setDateState] = useState(DefaultDate);
  const [loading, setLoading] = useState(false);

  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [costCentersOptions, setCostCentersOptions] = useState([]);
  const [costCentersSelected, setCostCentersSelected] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    async function loadTable() {
      try {
        setData([]);

        setLoading(true);

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const StartDate = Number(startDate.split("/").reverse().join(""));

        const EndDate = Number(endDate.split("/").reverse().join(""));

        const Companies = companies.map((x) => x.codCliente);

        const CostCenters = costCenters.map((e) => e.costCenter);

        const filter = {
          StartDate,
          EndDate,
          Companies,
          CC: CostCenters,
          Rloc: "",
          Pax: "",
          Product: "",
          Fornecedor: "",
        };

        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/substitution/filtered`,
          filter
        );

        setLoading(false);

        return setData(response.data);
      } catch (error) {
        return setLoading(false);
      }
    }

    loadTable();
  }, []);

  useEffect(() => {
    async function getCostCenters() {
      if (
        companiesSelected.length === 1 &&
        ShowSelects(userProfile.userProfileId).companies
      ) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/${companiesSelected[0].value}/costCenters`
          );

          return setCostCentersOptions(
            data.map((e) => {
              return {
                label: e.name,
                value: e.name,
              };
            })
          );
        } catch (error) {
          return setCostCentersOptions([]);
        }
      }

      return setCostCentersOptions(
        costCenters.map((e) => {
          return {
            label: e.costCenter,
            value: e.costCenter,
          };
        })
      );
    }

    getCostCenters();
  }, [companiesSelected]);

  useEffect(() => {
    function applyCompanyOptions() {
      setCompaniesOptions(
        companies.map((e) => {
          return {
            label: e.nomeFantasia,
            value: e.codCliente,
          };
        })
      );
    }

    applyCompanyOptions();
  }, [companies]);

  useEffect(() => {
    function applycostCentersOptions() {
      return setCostCentersOptions(
        costCenters.map((e) => {
          return {
            label: e.costCenter,
            value: e.costCenter,
          };
        })
      );
    }

    applycostCentersOptions();
  }, [costCenters]);

  useEffect(() => {
    async function loadProviders() {
      try {
        setLoading(false);

        setProviderOptions([]);

        const body = {
          Companies: companiesOptions.map((x) => x.value),
        };

        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/filters/providersT`,
          body,
          config
        );

        setProviderOptions(
          response.data.map(({ label, value }) => ({
            label: label,
            value: value,
          }))
        );

        return setLoading(false);
      } catch {
        setLoading(false);

        return setProviderOptions([]);
      }
    }
    if (companiesOptions.length > 0) {
      loadProviders();
    }
  }, [companiesOptions]);

  useEffect(() => {
    async function loadingProducts() {
      try {
        setLoading(false);

        setProductOptions([]);

        const body = {
          Companies: companiesOptions.map((x) => x.value),
        };

        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const res = await getDefaultProducts(accessToken, setLoading);

        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/filters/productsT`,
          body,
          config
        );

        setProductOptions(
          response.data.map(({ label, value }) => ({
            label: label,
            value: value,
          }))
        );

        if (res !== null) {
          return setProductOptions(res.data);
        }

        return setLoading(false);
      } catch (error) {
        setLoading(false);

        return setProductOptions([]);
      }
    }

    loadingProducts();
  }, [companiesOptions]);

  function handleResetForm(e) {
    formRef.current.reset();

    setCostCentersSelected([]);

    setCompaniesSelected([]);

    return setDateState(DefaultDate);
  }

  function resetDateState() {
    return setDateState(DefaultDate);
  }

  async function handleSubmit(values) {
    try {
      setData([]);

      setLoading(true);

      const {
        selectedDate: { startDate, endDate },
      } = dateState;

      const StartDate = Number(startDate.split("/").reverse().join(""));

      const EndDate = Number(endDate.split("/").reverse().join(""));

      const Companies =
        companiesSelected.length === 0
          ? companiesOptions.map((x) => x.value)
          : companiesSelected.map((x) => x.value);

      const CostCenters = !CompanyPermission(userProfile.userProfileId).all
        ? costCentersSelected.length === 0
          ? costCentersOptions.map((e) => e.value)
          : costCentersSelected.map((e) => e.value)
        : costCentersSelected.map((e) => e.value);

      const filter = {
        StartDate,
        EndDate,
        Companies,
        Rloc: values.locator,
        Pax: values.passenger,
        Product: values.product,
        Fornecedor: values.provider,
        CC: CostCenters,
      };

      const options = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/substitution/filtered`,
        filter,
        options
      );

      setLoading(false);

      return setData(response.data);
    } catch (error) {
      setLoading(false);

      return toast.info("Nada foi encontrado, tente novamente");
    }
  }

  return (
    <ReemissionContext.Provider
      value={{
        axios,
        user,
        accessToken,
        companies,
        costCenters,
        formRef,
        handleResetForm,
        handleSubmit,
        resetDateState,
        data,
        setData,
        dateState,
        setDateState,
        loading,
        setLoading,
        companiesOptions,
        setCompaniesOptions,
        costCentersOptions,
        setCostCentersOptions,
        costCentersSelected,
        setCostCentersSelected,
        companiesSelected,
        setCompaniesSelected,
        providerOptions,
        setProviderOptions,
        productOptions,
        setProductOptions,
      }}
    >
      <h1 className="page-header mb-3">Relatório de Substituição</h1>

      <Filters />

      <Table />
    </ReemissionContext.Provider>
  );
}
