import React, { useState, useEffect, useContext } from 'react';
import { ApiAxios } from '~/services/api.js';
import RankingCli from '../../Widgets/RankingCli.js';
import WhiteCard from '../../WhiteCard'

import DashboardAgencyContext from '../../../context';

export default function RankingPass4 () {
  const chartId = 4;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);

  const {
    companiesSelected,
    accessToken,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
    costCentersSelected,
  } = useContext(DashboardAgencyContext);

  const axios = ApiAxios();

  const [RankingCData, setRankingCData] = useState([]);

  useEffect(() => {
    async function loadData () {
      try {
        setLoading(true);

        setLikes(user.preferences.dashboard.chart4);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        let handles = companiesSelected.map(x => x.value);

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const c = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/shopping/air/rankingE`,
          {
            handle: handles,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: costCentersSelected.map(e => (e.value))
          },
          options
        );

        if (c.data) {
          setRankingCData(c.data);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected]);

  return (
    <WhiteCard title={'Clientes que mais compraram'}
      modalTitle='Observação'
      modalDesc='Valor de compras por cliente.'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <RankingCli data={RankingCData}/>
    </WhiteCard>
  );
}
