import React from "react";

import Select from "~/components/Select";

export default function CompanySelectSingle({
  name,
  label,
  options,
  placeholder,
  setCostCentersSelected,
  setCompanySelected,
  ...rest
}) {
  return (
    <Select
      {...rest}
      name={name}
      label={label}
      options={options}
      placeholder={placeholder}
      onChange={e => setCompanySelected(e)}
    />
  );
};
