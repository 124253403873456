import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApiAxios } from '~/services/api.js';
import { MdInsertDriveFile } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import DownloadFile from '~/functions/DownloadFile';

import { Container, ContainerStatus } from './styles';

export default function TModal({
  isOpenModal,
  setIsOpenModal,
  accessToken,
  title,
  data,
  url,
  fileName,
}) {
  const axios = ApiAxios();
  const [downloading, setDownloading] = useState(false);
  const [files, setFiles] = useState(data.filter(x => x.wasDownload));
  const [disabled, setDisabled] = useState(true);
  const [showFilter, setShowFilter] = useState(true);

  function handleShowFilter () {
    return setShowFilter(true);
  }

  function handleHiddenFilter () {
    return setShowFilter(false);
  }

  function handleToggleModal() {
    return setIsOpenModal(!isOpenModal);
  }

  useMemo(() => {
    if (files.filter(file => file.selected).length > 0) {
      return setDisabled(false);
    }

    return setDisabled(true);
  }, [files]);

  function selectFile(name) {
    setFiles(
      files.map(file =>
        file.name === name ? { ...file, selected: !file.selected } : file
      )
    );
  }

  function selectAllFiles() {
    setFiles(files.map(file => ({ ...file, selected: true })));
  }

  function removeAllFiles() {
    setFiles(files.map(file => ({ ...file, selected: false })));
  }

  async function handleDownload() {
    try {
      setDownloading(true);
      setDisabled(true);

      const response = await axios.post(
        `${url}/${title}`,
        files.filter(file => file.selected).map(f => ({nf:f.code.toString(), invoice:f.invoice.toString()})),
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );


      if (response.data.length > 0) {
        toast.info(`Baixando documento, aguarde!`);

        DownloadFile(
          `data:application/octet-stream;base64,${response.data}`,
          fileName
        );
      } else {
        toast.info(`Documentos não foram encontrados, tente novamente mais tarde!`);
      }

      setDownloading(false);
      setDisabled(false);
    } catch (error) {
      toast.error(
        'Não foi possível baixar as faturas, tente novamente mais tarde.'
      );

      setDownloading(false);
      setDisabled(false);
    }
  }

  return (
    <Modal isOpen={isOpenModal} toggle={handleToggleModal}>
      <ModalHeader toggle={handleToggleModal}>{title}</ModalHeader>
      <ModalBody>
        <Container>
          <ul>
            {files.map(file => (
              <li key={file.name}>
                <button
                  type="button"
                  className="fileInfo"
                  onClick={() => selectFile(file.name)}
                >
                  <ContainerStatus selected={file.selected}>
                    <MdInsertDriveFile size={24} color="#A5Cfff" />
                    <div selected />
                  </ContainerStatus>
                  <strong>{file.name}</strong>
                </button>
              </li>
            ))}
          </ul>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" outline onClick={removeAllFiles}>
          Desmarcar todos
        </Button>
        <Button color="info" outline onClick={selectAllFiles}>
          Selecionar todos
        </Button>
        <Button color="primary" onClick={handleDownload} disabled={disabled}>
          {!downloading ? (
            'Baixar selecionados'
          ) : (
            <Spinner size="sm" color="#ffff" />
          )}
        </Button>
        <Button color="secondary" onClick={handleToggleModal}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

TModal.defaultProps = {
  url: `${process.env.REACT_APP_ENDPOINT_INVOICE}/invoice/download/invoices`,
};

TModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  setIsOpenModal: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  url: PropTypes.string,
  fileName: PropTypes.string.isRequired,
};
