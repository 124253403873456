import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { Label, Group, TextAreaV } from './styles';

export default function TextAreaWrapper({ name, label, icon, ...rest }) {
  const textAreaRef = useRef(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Group>
      {label && <Label htmlFor={name}>{label}  {icon && <i className={icon} />}</Label>}
      <TextAreaV
        className="form-control"
        name={name}
        id={name}
        ref={textAreaRef}
        {...rest}
      />
    </Group>
  );
}

TextAreaWrapper.defaultProps = {
  label: null,
};

TextAreaWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};
