import React from 'react';
import { Panel } from "~/components/Panel";
import ReactTable from '~/components/Table';
import {
  columns,
} from './constants';
import { useRefund } from 'context';

export default function Details({
  data,view,
}) {

  return (
    <Panel theme="inverse">
      <ReactTable
        columns={columns({
          view: view,
        })}
        data={[ data ]}
        tableProps={{
          defaultPageSize: 5,
          hasFooter: false,
          hasPagination: true,
          loading: false,
          resizable: true
        }}
        tableStyle={{
          fontSize: '13px',
          cellPadding: '6px 4px',
        }}
        tableLabels={{
          loadingText: 'Carregando...',
          noDataText: 'Sem registros',
          nextButtonText: 'Próximo',
          previousButtonText: 'Anterior',
          pageOfText: 'de',
          pageSizeSelectText: 'registros',
          pageText: 'Página'
        }}
      />
    </Panel>
  );
}
