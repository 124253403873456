import React from 'react';
import { Panel, PanelHeader } from '~/components/Panel';

import Agencys from './components/Agencys';

import {
  ContainerPd8
} from '~/styles/container';

function Settings() {
  return (
    <Panel theme='inverse'>
      <PanelHeader title='Resumo da Agência' noButton />

      <ContainerPd8>
        <Agencys />
      </ContainerPd8>
    </Panel>
  );
}

export default Settings;
