import React from 'react';

import {
  Row,
  Col
} from 'reactstrap';

import {
  UlAgencys,
  LiAgencys
} from '~/styles/ul';

import {
  ButtonCardSmall
} from '~/styles/buttons/card';

import {
  DivFlex,
  DivFlexColumn,
  ColorLegend
} from '~/styles/divs';

import {
  Avatar,
  TextAvatar,
  TextEmail,
  TextUser,
  AgencyAdmin
} from './styles';

import {
  FormatPhoneOnlyNumbersBR,
  FormatPhoneBR
} from '~/functions/FormatPhone';

import {
  CreateAction,
  EditAction,
} from '~/components/ActionsOfTables';

function HasAgency({
  agencys,
  setPage,
  setEditing
}) {
  return (
    <>
      {/* <CreateAction type='button' onClick={() => setPage('create')}>
        Cadastrar Agência
      </CreateAction>

      <hr /> */}

      {agencys.map((e) => {
        const names = e.displayName.split(' ');

        var name = '';

        names.map((e, i) => {
          if (i === 0 || i === 1)
            name += e[0];

          return true;
        });

        return (
          <>
            <LiAgencys>
              <Row>
                <Col>
                  <Avatar className="sidebar-nav__avatar">
                    <TextAvatar>
                      {name.toUpperCase()}
                    </TextAvatar>
                  </Avatar>

                  <h3 style={{textAlign: 'center'}}>
                    {e.displayName}
                  </h3>
                </Col>

                <Col>
                  <Col>
                    <div>
                      <p>
                        Nome da Agência: <b>
                          {e.fullName}
                        </b>
                      </p>

                      <p>
                        Email: <b>
                          {e.email1}
                        </b>
                      </p>

                      <p>
                        Telefone: <b>
                          {FormatPhoneBR(e.phone1)}
                        </b>
                      </p>

                    </div>

                    <DivFlex>
                      {/* <div>
                        <b>
                          Cor Principal
                        </b>

                        <ColorLegend bg={e.color1} />
                      </div>

                      <div>
                        <b>
                          Cor Secundária
                        </b>

                        <ColorLegend bg={e.color2} />
                      </div> */}
                    </DivFlex>
                    </Col>


                    <div>
                    <ButtonCardSmall
                      bg={'#000000'}
                      fg={'#eee'}
                      type='button'
                      onClick={() => {
                        setEditing(true);

                        return setPage('create');
                      }}>
                      Gerenciar
                    </ButtonCardSmall>
                    </div>
                </Col>
              </Row>
            </LiAgencys>
          </>
        );
      })}
    </>
  );
}

export default HasAgency;
