import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border: none;
  box-shadow: none;
  border-radius: 4px;
  padding: 10px 15px 10px 15px;

  /* :nth-child(1) {
    margin-right: 20px
  } */
`;

export const CardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const IconsCard = styled.div`
  display: flex;
`;

export const TitleHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h3`
  color: #1f2225;
  font-weight: 700;
  font-size: 18px;

  @media(max-width: 768px) {
    font-size: 14px;
  }

  @media(max-width: 375px) {
    font-size: 12px;
  }
`;

export const Row = styled.div`
  display: flex;
  height: "100%";
  width: "100%";
  justify-content: space-between;
`;
