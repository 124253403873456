import React from 'react';
import { Panel } from "~/components/Panel";

import Header from './components/Header';
import Main from './components/Main';

function Table({
  pageFilters
}) {
  return (
    <Panel theme="inverse">
      <Header />

      <Main pageFilters={pageFilters} />
    </Panel>
  );
}

export default Table;
