import React from 'react';
import styled from "styled-components";
import { FormatValueBRL } from '~/functions/FormatValue';

export const CellLogo = styled.img`
  border-radius: 5px;
`;

export const columns = [
  {
    default: true,
    Header: 'Emissão',
    accessor: 'dataEmissao',
    width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Empresa',
    accessor: 'cliente',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Dias Antecedência',
    accessor: 'datant',
     width: 100,
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    default: true,
    Header: 'Tipo de viagem',
    accessor: 'tipViagem',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Fornecedor',
    accessor: 'fornecedor',
     width: 100,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Produto',
    accessor: 'produto',
    width: 100,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Centro de Custo',
    accessor: 'centro',
     width: 140,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {

    default: true,
    Header: 'Viajante',
    accessor: 'passageiro',
     width: 160,
    show: true,
    Cell: ({row}) => {
      let nome = row.original.passageiro.toUpperCase().split('/').join(' / ');

      return (<span>{nome}</span>);
    },
    style: { textAlign: 'center',
    paddingLeft: '20px',
    whiteSpace: 'pre-wrap' },
  },
  {
    default: true,
    Header: 'Trechos',
    accessor: 'trecho',
     width: 100,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Solicitante',
    accessor: 'solicitante',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Cidade Origem',
    accessor: 'cidadeOri',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Cidade Destino',
    accessor: 'cidadeDest',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Data Viagem',
    accessor: 'dataViagem',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Data Entrada',
    accessor: 'entrada',
     width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Data Saída',
    accessor: 'saida',
     width: 100,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: '8px 4px',
    }
  },
  {
    default: true,
    Header: 'Motivo',
    accessor: 'motivo',
     width: 160,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Departamento',
    accessor: 'departamento',
     width: 160,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Forma Pagamento',
    accessor: 'forma',
     width: 130,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Nº Fatura',
    accessor: 'fatura',
    Cell: ({row}) => (
      <span>
        {row.original.fatura > 0 ? row.original.fatura.toString() : ""}
      </span>
    ),
     width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Data Emissão Fatura',
    accessor: 'dataEmissaoFat',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'N° NF',
    accessor: 'nf',
    Cell: ({row}) => (
      <span>
        {row.original.nf > 0 ? row.original.nf.toString() : ""}
      </span>
    ),
     width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Data Emissão NF',
    accessor: 'dataEmissaoNF',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'OS',
    accessor: 'numOS',
     width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Data OS',
    accessor: 'dataOS',
     width: 120,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Venda',
    accessor: 'id',
     width: 70,
    show: true,
    style: {
      textAlign: 'center'
    },
  },
  {
    default: true,
    Header: 'Aprovador',
    accessor: 'aprovador',
     width: 160,
    show: true,
    style: { textAlign: 'center',
    paddingLeft: '20px',
    whiteSpace: 'pre-wrap' },
  },
  {
    default: true,
    Header: 'Localizador',
    accessor: 'localizador',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap'
    },
  },
  {
    default: true,
    Header: 'Projeto',
    accessor: 'projeto',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap'
    },
  },
  {
    default: true,
    Header: 'Qtd. Diária',
    accessor: 'quantidade',
     width: 80,
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    default: true,
    Header: 'Tarifa',
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.totalTarifa)}
      </span>;
    },
    accessor: 'totalTarifa',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    default: true,
    Header: 'Taxas',
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.taxas)}
      </span>;
    },
    accessor: 'taxas',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    default: true,
    Header: 'Fee',
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.totalFee)}
      </span>;
    },
    accessor: 'totalFee',
     width: 80,
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    default: true,
    Header: 'Menor Tarifa',
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.mef)}
      </span>;
    },
    accessor: 'mef',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    default: true,
    Header: 'Maior Tarifa',
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.maf)}
      </span>;
    },
    accessor: 'maf',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
  {
    default: true,
    Header: 'Total',
    Cell: ({ row }) => {
      return <span>
        {FormatValueBRL(row.original.total)}
      </span>;
    },
    accessor: 'total',
     width: 120,
    show: true,
    style: {
      textAlign: 'center',
      paddingLeft: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
];
