import React, { useState, useEffect, useContext } from "react";
import { MdInfo } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { ApiAxios } from "~/services/api.js";

import RequestManagementContext from "../../context";

import Shimmer from "~/components/Shimmer";
import Tooltip from "~/components/Tooltip";
import { Widget } from "./styles";

export default function Indicators() {
  const axios = ApiAxios();
  const { t } = useTranslation("RequestManagement");

  const { indicatorsCount, loadingOrders, getOrders, pagination, filterStates, dateState, data } = useContext(
    RequestManagementContext
  );

  const [passenger] = filterStates.passenger;
  const [idNumber] = filterStates.idNumber;
  const [, setStatus] = filterStates.status;


  const approvedCount = data.reduce((total, elemento) => {
    if (elemento.status === 'Aprovada') return total += 1;
    else return total}, 0);
  const canceledCount = data.reduce((total, elemento) => {
    if (elemento.status === 'Cancelada') return total += 1;
    else return total}, 0);
  const finishedCount = data.reduce((total, elemento) => {
    if (elemento.status === 'Fechada') return total += 1;
    else return total}, 0);

  const totalOfRequests = approvedCount + canceledCount + finishedCount;

  useEffect(() => {


    // const total = Object.values(indicatorsCount).reduce((a, b) => a + b);

    // setTotalOfRequests(total);
  }, [indicatorsCount]);

  async function handleSubmit(status) {
    setStatus(status);
    getOrders(pagination.page, pagination.offset, {
      openingDate: dateState.selectedDate.startDate
        .split("/")
        .reverse()
        .join("-"),
      closingDate: dateState.selectedDate.endDate
        .split("/")
        .reverse()
        .join("-"),
      status,
      idNumber: idNumber,
      passenger: passenger,
    });
  }

  function returnPercentage(count){
    if(totalOfRequests === 0) return 0;

    return ((count * 100) / totalOfRequests).toFixed(
      2
    )
  }

  return (
    <div className="row">
      {/** Approved */}
      <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <Widget
          style={{ backgroundColor: "#8BD7D2" }}
          className="widget widget-stats"
          onClick={() => handleSubmit("Aprovada")}
        >
          <div className="stats-icon stats-icon-lg">
            <i className="fas fa-dollar-sign" />
          </div>
          <div className="stats-content " style={{ color: "#333" }}>
            <div className="stats-title " style={{ color: "#333" }}>
              {t("Indicators.Approved.Title")}&nbsp;&nbsp;&nbsp;
              <MdInfo id="approvedRequests" />
              <Tooltip
                target="approvedRequests"
                text="Quantidade total de pedidos aprovados"
              />
            </div>
            {loadingOrders  ? (
              <>
                <div className="stats-number " style={{ color: "#333" }}>
                  <Shimmer
                    color="rgba(255,255,255,0.1)"
                    highlightColor="rgba(255,255,255,0.3)"
                    width={100}
                  />
                </div>
                <div className="stats-desc " style={{ color: "#333" }}>
                  <Shimmer
                    color="rgba(255,255,255,0.1)"
                    highlightColor="rgba(255,255,255,0.3)"
                    width={50}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="stats-number " style={{ color: "#333" }}>
                  {approvedCount}
                </div>
                <div className="stats-desc " style={{ color: "#333" }}>
                  {returnPercentage(approvedCount)}
                  %
                </div>
              </>
            )}
          </div>
        </Widget>
      </div>
      {/** Canceled */}
      <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <Widget
          style={{ backgroundColor: "#E06E52" }}
          className="widget widget-stats"
          onClick={() => handleSubmit("Cancelada")}
        >
          <div className="stats-icon stats-icon-lg">
            <i className="fas fa-exclamation-triangle" />
          </div>
          <div className="stats-content">
            <div className="stats-title " style={{ color: "#333" }}>
              {t("Indicators.Canceled.Title")}
              &nbsp;&nbsp;&nbsp;
              <MdInfo id="canceledRequests" />
              <Tooltip
                target="canceledRequests"
                text="Quantidade total de pedidos cancelados"
              />
            </div>
            {loadingOrders  ? (
              <>
                <div className="stats-number " style={{ color: "#333" }}>
                  <Shimmer
                    color="rgba(255,255,255,0.1)"
                    highlightColor="rgba(255,255,255,0.3)"
                    width={100}
                  />
                </div>
                <div className="stats-desc " style={{ color: "#333" }}>
                  <Shimmer
                    color="rgba(255,255,255,0.1)"
                    highlightColor="rgba(255,255,255,0.3)"
                    width={50}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="stats-number " style={{ color: "#333" }}>
                  {canceledCount}
                </div>
                <div className="stats-desc " style={{ color: "#333" }}>
                    {returnPercentage(canceledCount)}
                  %
                </div>
              </>
            )}

          </div>
        </Widget>
      </div>
      {/** Finished */}
      <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <Widget
          style={{ backgroundColor: "#83ED6E" }}
          className="widget widget-stats"
          onClick={() => handleSubmit("Fechada")}
        >
          <div className="stats-icon stats-icon-lg">
            <i className="fas fa-thumbtack" />
          </div>
          <div className="stats-content">
            <div className="stats-title " style={{ color: "#333" }}>
              {t("Indicators.Finished.Title")}
              &nbsp;&nbsp;&nbsp;
              <MdInfo id="finishedRequests" />
              <Tooltip
                target="finishedRequests"
                text="Quantidade total de pedidos fechados"
              />
            </div>
            {loadingOrders ? (
              <>
                <div className="stats-number " style={{ color: "#333" }}>
                  <Shimmer
                    color="rgba(255,255,255,0.1)"
                    highlightColor="rgba(255,255,255,0.3)"
                    width={100}
                  />
                </div>
                <div className="stats-desc " style={{ color: "#333" }}>
                  <Shimmer
                    color="rgba(255,255,255,0.1)"
                    highlightColor="rgba(255,255,255,0.3)"
                    width={50}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="stats-number " style={{ color: "#333" }}>
                  {finishedCount}
                </div>
                <div className="stats-desc " style={{ color: "#333" }}>
                   {returnPercentage(finishedCount)}
                  %
                </div>
              </>
            )}
          </div>
        </Widget>
      </div>
    </div>
  );
}
