import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Filters from './components/Filters';
import Table from "./components/Table";
import ShowSelects from '~/functions/ShowSelects';
import { toast } from 'react-toastify';
import OrderAndInvoiceContext from './context';

import {
  DefaultDate
} from './constants';

import {
  getrderManagementTableSynthetical
} from '~/services/orderManagement';

import {
  getAccountExecutives,
  getAccountExecutivesCompanies
} from '~/services/agency';

import {
  getCompanyCostCenters
} from '~/services/auth';

import { getPeriod } from '~/functions/Date';

export default function OrderAndInvoice() {
  const accessToken = useSelector(state => state.auth.accessToken);
  const user = useSelector(state => state.user.profile);
  const userProfile = useSelector(state => state.user.userProfile);
  const companies = useSelector(state => state.user.companies);
  const costCenters = useSelector(state => state.user.costCenters);

  const [pageFilters, setPageFilters] = useState({
    dateState: DefaultDate,
    companies: companies.map(x => ({
      label: x.nomeFantasia,
      value: x.codCliente,
    })),
    costs: costCenters.map(x => ({
      label: x.costCenter,
      value: x.costCenter,
    })),
    status: '',
    product: '',
    invoiceNumber: 0,
    reference: 0,
    traveler: '',
  });

  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [costCentersOptions, setCostCentersOptions] = useState([]);
  const [costCentersSelected, setCostCentersSelected] = useState([]);
  const [executivesOptions, setExecutivesOptions] = useState([]);
  const [executivesSelected, setExecutivesSelected] = useState([]);

  const [data, setData] = useState([]);
  const [dataToExcel, setDataToExcel] = useState([]);
  const [dataToExcelDetailed, setDataToExcelDetailed] = useState([]);
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dateState, setDateState] = useState(DefaultDate);
  const [pax, setPax] = useState('');

  useEffect(() => {
    async function loadTable () {
      const {
        dateI,
        dateF
      } = getPeriod(dateState);

      const res = await getrderManagementTableSynthetical(
        accessToken,
        {
          Companies: pageFilters.companies.map(e => e.value),
          CostCenter: pageFilters.costs.map(e => e.value),
          InitialDate: dateI,
          FinalDate: dateF,
          Pax: pageFilters.traveler,
        },
        setLoading,
      );

      if (res !== null) {
        return setData(res.data);
      }

      return toast.info(
        'Não foi possível carregar a tabela de seus últimos custos em viagens.'
      );
    }

    loadTable();
  }, [pageFilters]);

  useEffect(() => {
    async function loadAccountExecutives() {
      if (!ShowSelects(userProfile.userProfileId).executives)
        return false;

      const res = await getAccountExecutives(
        accessToken,
        setLoading,
      );

      if (res !== null)
        return setExecutivesOptions(res.data);

      return toast.info(
        'Não foi possível carregar as opções de produtos.'
      );
    }

    loadAccountExecutives();
  }, []);

  useEffect(() => {
    function applyCompanyOptions() {
      setCompaniesOptions(
        companies.map((e) => {
          return {
            label: e.nomeFantasia,
            value: e.codCliente,
          };
        })
      );
    }

    applyCompanyOptions();
  }, []);

  useEffect(() => {
    function applyCostCentersOptions() {
      setCostCentersOptions(
        costCenters.map((e) => {
          return {
            label: e.costCenter,
            value: e.costCenter,
          };
        })
      );
    }

    applyCostCentersOptions();
  }, []);

  useEffect(() => {
    async function loadExecutivesCompanies() {
      if (executivesSelected.length === 1) {
        const res = await getAccountExecutivesCompanies(
          accessToken,
          () => {},
          executivesSelected[0].value
        );

        if (res === null) {
          setExecutivesSelected([]);

          return toast.info('Não foi possível obter a carteira de clientes.');
        }

        if (res.data.length > 0)
          return setCompaniesOptions(res.data);

        setExecutivesSelected([]);

        return toast.info(
          `${executivesSelected[0].label} - carteira de clientes vazia.`
        );
      }

      return setCompaniesOptions(companies.map(x => ({
        label: x.nomeFantasia,
        value: x.codCliente,
      })))
    }

    loadExecutivesCompanies();
  }, [executivesSelected]);

  useEffect(() => {
    async function loadCompanyCostCenters() {
      if (companiesSelected.length === 1 && ShowSelects(userProfile.userProfileId).costCenters) {
        const res = await getCompanyCostCenters(
          accessToken,
          () => {},
          companiesSelected[0].value
        );

        if (res === null) {
          return toast.info(
            `Não foi possível obter os centros de custo de clientes.`
          );
        }

        if (res.data.length > 0)
          return setCostCentersOptions(res.data.map(e => {
            return {
              label: e.name,
              value: e.name
            };
          }));

        toast.info(
          `${companiesSelected[0].label} - sem centros de custo.`
        );
      }

      return setCostCentersOptions(costCenters.map(x => ({
        label: x.costCenter,
        value: x.costCenter,
      })));
    }

    loadCompanyCostCenters();
  }, [companiesSelected]);

  return (
    <OrderAndInvoiceContext.Provider
      value={{
        companies,
        accessToken,
        user,
        data,
        setData,
        dataToExcel,
        dataToExcelDetailed,
        setDataToExcel,
        setDataToExcelDetailed,
        formRef,
        loading,
        setLoading,
        dateState,
        setDateState,
        pax,
        setPax,
        costCentersOptions,
        setCostCentersOptions,
        costCentersSelected,
        setCostCentersSelected,
        companiesOptions,
        setCompaniesOptions,
        companiesSelected,
        setCompaniesSelected,
        executivesOptions,
        setExecutivesOptions,
        executivesSelected,
        setExecutivesSelected,
      }}>
      <>
        <h1 className="page-header mb-3">
          Gestão de Custos de Viagens
        </h1>

        <Filters
          pageFilters={pageFilters}
          setPageFilters={setPageFilters} />

        <Table
          pageFilters={pageFilters} />
      </>
    </OrderAndInvoiceContext.Provider>
  );

}
