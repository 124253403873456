import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SliderToggle from '~/components/SliderToggle';
import { ApiAxios } from '~/services/api.js';
import {SubmitButton} from './styles';
import { Row, Col, Spinner} from 'reactstrap';

function Permission({
  managerToPermission
}) {
  const axios = ApiAxios();
  const [loading, setLoading] = useState(false);
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    async function handleIsToggled (email) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_ACCOUNT}/accounts/permission`,
          {
            email: email
          }
        );

        return setIsToggled(response.data.solicitacaovisualreemb);
      } catch (error) {
        return console.log("Não foi possível atualizar o usuário.");
      }
    }

    handleIsToggled(managerToPermission.manager.email)
  },[managerToPermission])

  async function handleSave(){
    try{
      setLoading(true);

      await axios.post(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/${managerToPermission.manager.email}/visualreemb`,
        {
          visualreemb: isToggled,
        }
      );

      toast.info(
        `Permissões ${isToggled ? 'ativadas' : 'desativadas'} com sucesso.`
      );

      setLoading(false);
    } catch(e) {
      setLoading(false);
    }
  }

  return (
    <Row>
      {!loading && (
        <Col lg={6}>
          <SliderToggle
            label={'Solicitação e Visualização Reembolsos'}
            fontSize={'13px'}
            checked={isToggled}
            click={() => setIsToggled(!isToggled)}
            textAlign={'left'}
          />
        </Col>
      )}

      <SubmitButton>
        <button
          className="btn btn-success"
          type="submit"
          onClick={handleSave}
          disabled={loading}
          style={{
            background: '#000000',
            color: '#fff',
            fontWeight: 700,
            border: 'none',
            alignItems: 'left'
          }}>
          {loading && <Spinner color="#777" size="sm" />}

          {!loading && <>Salvar</>}
        </button>
      </SubmitButton>
    </Row>
  );
}

export default Permission;
