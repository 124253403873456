import moment from 'moment';

const pageFiltersInitialState = ({
  date,
  companies,
  costs,
}) => {
  return {
    date,
    companies: companies.map(x => ({
      label: x.empresa,
      value: x.codCliente,
    })),
    companiesSelected: [],
    costs: costs.map(x => ({
      label: x.costCenter,
      value: x.costCenter,
    })),
    costsSelected: [],
    locator: '',
    ticket: '',
    request: '',
    pax: '',
    status: 'all',
  };
};

const DefaultDate = {
  currentWeek:
    `${moment().subtract(30, 'days').format('DD/MM/YYYY')} -
    ${moment().format('DD/MM/YYYY')}`,
  selectedDate: {
    startDate: `${moment().subtract(30, 'days').format('DD/MM/YYYY')}`,
    endDate: `${moment().format('DD/MM/YYYY')}`,
  },
  prevWeek:
    `${moment().subtract(15, 'days').format('DD/MM/YYYY')} -
    ${moment().subtract(8, 'days').format('DD/MM/YYYY')}`,
  startDate: moment().subtract(30, 'days'),
  endDate: moment(),
};

export {
  pageFiltersInitialState,
  DefaultDate,
};
