import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormatValueBRL } from '~/functions/FormatValue.js';

export default function FeePerProduct({
  data
}) {
  const [chartData2, setChartData2] = useState({});

  const total = data.aereo + data.hotel + data.seguro + data.outros + data.visto

  function returnPercent(label){
    switch (label) {
      case 'Aéreo': return data.aereo
      case 'Hotel': return data.hotel
      case 'Carro': return data.carro
      case 'Outros': return data.outros
      case 'Rodoviário': return data.rodoviario
      default: return 0;
    }
  }

  useEffect(() => {
    async function loaddata(){
      setChartData2({
        labels:[
          'Aéreo',
          'Hotel',
          'Carro',
          'Rodoviário',
          'Outros'
        ],
        datasets: [{
          data: [
            data.aereoPercent,
            data.hotelPercent,
            data.carroPercent,
            data.rodoviarioPercent,
            data.outrosPercent,
          ],
          backgroundColor:['#00EAE1', '#BDBFBF','#95F496', '#f4dbab', '#7e2ec2'],
          borderWidth:2
        }]
      })
    }

  loaddata();
  }, [data]

  )


  return (
    <>
      <Doughnut data={chartData2}   options={{
            responsive: true,
            tooltips: {
              enabled: true,
              callbacks: {
                label: function(tooltipItem, data) {
                  return `${data.labels[tooltipItem.index]}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}% / ${FormatValueBRL(returnPercent(data.labels[tooltipItem.index]))}`;
                }
              }
            }
          }} />
    </>
  );
}
