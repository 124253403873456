import React, { useState, useEffect, useContext } from 'react';
import { ApiAxios } from '~/services/api.js';
import RankingCC from '../../Widgets/RankingCC.js';
import WhiteCard from '../../WhiteCard'

import DashboardContext from '../../../context';
import EmitterSelect from '~/components/EmitterSelect/index.js';

export default function RankingCC3 () {
  const chartId = 3;
  const [pinned, setPinned] = useState(false);
  const [likes, setLikes] = useState(0);

  const {
    companiesSelected,
    accessToken,
    user,
    dateState,
    setLoading,
    PinChart,
    UnpinChart,
    costCentersSelected,
    emitterSelected
  } = useContext(DashboardContext);

  const axios = ApiAxios();

  const [RankingCCData, setRankingCCData] = useState([]);

  useEffect(() => {
    async function loadData () {
      try {
        setLoading(true);

        setLikes(user.preferences.dashboard.chart3);

        user.myCharts.map(e => {
          if (e === chartId) {
            return setPinned(true)
          }

          return false;
        });

        const {
          selectedDate: { startDate, endDate },
        } = dateState;

        const VencimentoInicial = Number(
          startDate
            .split('/')
            .reverse()
            .join('')
        );

        const VencimentoFinal = Number(
          endDate
            .split('/')
            .reverse()
            .join('')
        );

        let handles = companiesSelected.map(x => x.value);

        const options = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        const cc = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_INDICATORS}/indicators/dashboard/shopping/air/RankingEmissor`,
          {
            handle: handles,
            datai: VencimentoInicial,
            dataf: VencimentoFinal,
            custos: costCentersSelected.map(e => (e.value)),
            emitter: emitterSelected.map(e => (e.label))
          },
          options
        );

        if (cc.data) {
          setRankingCCData(cc.data);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    loadData();
  }, [dateState, companiesSelected, costCentersSelected, emitterSelected]);

  return (
    <WhiteCard title={'Ranking Emissores'}
      modalTitle='Observação'
      modalDesc='Emissores que mais venderam(Venda bruta).'
      chartId={chartId}
      PinChart={PinChart}
      UnpinChart={UnpinChart}
      pinned={pinned}
      setpinned={setPinned}
      likes={likes}
      setLikes={setLikes}
    >
      <RankingCC data={RankingCCData} />
    </WhiteCard>
  );
}
