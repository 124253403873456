import React, { useState, useEffect, useRef } from 'react';
import { Link, Route } from 'react-router-dom';
import TopMenuNavList from '../NavList';

import menus from './menu';

function TopMenuNav() {
  const [state, setState] = useState({
    active: -1,
    controlLeft: false,
    controlRight: false,
    marginLeft: 0,
    marginRight: 0,
    navWidth: 0,
  });

  const topMenu = useRef();

  useEffect(() => {
    const windowWidth = topMenu.current.offsetWidth - 128;
    let listFullWidth = 0;
    let listPrevWidth = 0;
    let listActive = false;

    document.querySelectorAll('.top-menu .nav > li').forEach(function(elm) {
      listFullWidth += elm.offsetWidth;
      listPrevWidth += !listActive ? elm.offsetWidth : 0;
      listActive = elm.classList.contains('active') ? true : listActive;
    });

    listPrevWidth = !listActive ? 0 : listPrevWidth;

    if (listPrevWidth >= windowWidth) {
      const finalScrollWidth = listPrevWidth - windowWidth + 128;
      let finalLeft;
      let finalRight;
      if (!document.body.classList.contains('rtl-mode')) {
        finalLeft = finalScrollWidth;
        finalRight = 0;
      } else {
        finalRight = finalScrollWidth;
        finalLeft = 0;
      }
      setState({
        marginLeft: finalLeft,
        marginRight: finalRight,
      });
    }

    setState({
      navWidth: listFullWidth,
      controlLeft: !!(
        listPrevWidth >= windowWidth && listFullWidth >= windowWidth
      ),
      controlRight: !!(
        listPrevWidth !== listFullWidth && listFullWidth >= windowWidth
      ),
    });
  }, []);

  function handleExpand(e, i) {
    e.preventDefault();

    setState({
      active: state.active === i ? -1 : i,
    });
  }

  function controlRight(e) {
    e.preventDefault();
    const containerWidth = document.querySelector('.top-menu').offsetWidth - 88;
    const widthLeft = state.navWidth + -state.marginLeft - containerWidth;
    let finalScrollWidth = 0;

    if (widthLeft <= containerWidth) {
      finalScrollWidth = widthLeft - -state.marginLeft + 128;
      setState(state => ({
        controlRight: false,
      }));
    } else {
      finalScrollWidth = containerWidth - -state.marginLeft - 128;
    }

    if (finalScrollWidth !== 0) {
      if (!document.body.classList.contains('rtl-mode')) {
        setState(state => ({
          marginRight: 0,
          marginLeft: finalScrollWidth,
        }));
      } else {
        setState(state => ({
          marginLeft: 0,
          marginRight: finalScrollWidth,
        }));
      }
      setState(state => ({
        controlLeft: true,
      }));
    }
  }

  function controlLeft(e) {
    e.preventDefault();
    const widthLeft = state.marginLeft;
    const containerWidth = document.querySelector('.top-menu').offsetWidth;
    let finalScrollWidth = 0;

    if (widthLeft <= containerWidth) {
      finalScrollWidth = 0;
      setState(state => ({
        controlLeft: false,
      }));
    } else {
      finalScrollWidth = widthLeft - containerWidth - 88;
      setState(state => ({
        controlLeft: true,
      }));
    }
    if (!document.body.classList.contains('rtl-mode')) {
      setState(state => ({
        marginLeft: finalScrollWidth,
        marginRight: 0,
        controlRight: true,
      }));
    } else {
      setState(state => ({
        marginLeft: 0,
        marginRight: finalScrollWidth,
        controlRight: true,
      }));
    }
  }

  return (
    <ul
      ref={topMenu}
      className="nav"
      style={{
        marginLeft: `-${state.marginLeft}px`,
        marginRight: `-${state.marginRight}px`,
      }}
    >
      {menus.map((menu, i) => (
        <Route
          path={menu.path}
          exact={menu.exact}
          key={i}
          children={({ match }) => (
            <TopMenuNavList
              data={menu}
              key={i}
              expand={e => handleExpand(e, i, match)}
              active={i === state.active}
              clicked={state.clicked}
            />
          )}
        />
      ))}
      <li
        className={`menu-control menu-control-left ${state.controlLeft &&
          'show'}`}
      >
        <Link to="/" onClick={controlLeft}>
          <i className="fa fa-angle-left" />
        </Link>
      </li>
      <li
        className={`menu-control menu-control-right ${state.controlRight &&
          'show'}`}
      >
        <Link to="/" onClick={controlRight}>
          <i className="fa fa-angle-right" />
        </Link>
      </li>
    </ul>
  );
}

export default TopMenuNav;
