import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import EmptyList from '~/components/EmptyList';

import { ApiAxios } from '~/services/api.js';
import SliderToggle from '~/components/SliderToggle';

import {
  Card,
  CardTitle,
  CardBody,
  CardBodyItem,
} from '~/styles/card';

import {
  SpaceBetweenDiv
} from '~/styles/divs';

import {
  CancelDeleteAction,
  DeleteAction,
  EditAction,
  CreateAction
} from '~/components/ActionsOfTables';
import { Row, Col, Spinner } from 'reactstrap';

import CompanyRegistrationContext from '../../../context';

function List({
  setManagerToDelete,
  setManagerToDetails,
  setManagerToEdit,
  setManagerPage,
  setManagerToPermission
}) {
  const axios = ApiAxios();

  const {
    companyToDetail
  } = useContext(CompanyRegistrationContext);

  const [gccs, setGccs] = useState([]);
  const [gvs, setGvs] = useState([]);
  const [ges, setGes] = useState([]);
  const [evs, setEvs] = useState([]);
  const [alterStatus, setAlterStatus] = useState(false);

  useEffect(() => {
    async function getGccs() {
      try
      {
        const gccResponse = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/getcostmanagers`,
          {
            ClientCode: companyToDetail.code
          }
        );

        return setGccs(gccResponse.data);
      } catch (error) {
        return toast.error('Não foi possível obter o usuário.');
      }
    }

    getGccs();
  }, []);

  useEffect(() => {
    async function getGvs() {
      try
      {
        const gccResponse = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/gettravelmanagers`,
          {
            ClientCode: companyToDetail.code
          }
        );

        return setGvs(gccResponse.data);
      } catch (error) {
        return toast.error('Não foi possível obter o usuário.');
      }
    }

    getGvs();
  }, []);

  useEffect(() => {
    async function getGes() {
      try
      {
        const geResponse = await axios.post(
          `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/client/getclientusers`,
          {
            ClientCode: companyToDetail.code
          }
        );

        return setGes(geResponse.data);
      } catch (error) {
        return toast.error('Não foi possível obter o usuário.');
      }
    }

    getGes();
  }, []);

  async function handleStatusUser(email, status) {
    try {
      setAlterStatus(true);

      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/agency/manager/status/${email}/${companyToDetail.code}`
      );

      setGccs(response.data.costManagers);

      setGvs(response.data.travelManagers);

      setGes(response.data.clientUsers);

      if (response.data.status) {
        toast.info(
          `Usuário ${status ? 'desativado' : 'ativado'} com sucesso.`
        );
      } else {
        toast.info(
          response.data.message
        );
      }

      return setAlterStatus(false);
    } catch (error) {
      setAlterStatus(false);

      return console.log("Não foi possível atualizar o usuário.");
    }
  }

  async function resetPassword({manager}) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_AUTH}/auth/user/forgotPassword/${manager.email}`,
      );

      return toast.success(
        `Link de redefinição de senha enviado para o usuário. Email: ${manager.email}`
      );
    } catch (error) {
      return console.log("Não foi possível enviar a redefinição de senha.");
    }
  }

  return <Row>
    <Col md={12} lg={12} xl={12} style={{textAlign: 'right'}}>
      <CreateAction
        type='button'
        onClick={() => setManagerPage('create')}>
        Cadastrar Novo Gestor
      </CreateAction>
    </Col>

    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <h5>Gestores de Viagens</h5>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          {gvs.length === 0 && (<EmptyList />)}

          {gvs.map((e, i) => {
            return (
              <CardBodyItem key={i}>
                <div style={{width: '100%', padding: '0 4px'}}>
                  <SpaceBetweenDiv>
                    <div>
                      <p><b>Nome:</b> {e.name}</p>

                      <p><b>Email:</b> {e.email}</p>
                    </div>

                    <SliderToggle
                      label={'Status'}
                      checked={e.status}
                      click={() => handleStatusUser(e.email, e.status)}
                    />
                  </SpaceBetweenDiv>

                  <p>
                    <b>Ações:</b>
                  </p>

                  <p>
                    <EditAction
                      type='button'
                      onClick={() => setManagerToDetails({
                        manager: e,
                        type: 2
                      })}>
                      Detalhes
                    </EditAction>

                    <DeleteAction
                      style={{backgroundColor: '#000000'}}
                      type='button'
                      onClick={() => setManagerToEdit({
                        manager: e,
                        type: 2
                      })}>
                      Editar
                    </DeleteAction>

                    <CreateAction
                      type='button'
                      style={{backgroundColor: '#fbba37'}}
                      onClick={() => resetPassword({
                        manager: e,
                        type: 1
                      })}>
                      Resetar senha
                    </CreateAction>

                    <CancelDeleteAction
                      type='button'
                      onClick={() => setManagerToDelete({
                        manager: e,
                        type: 2
                      })}>
                      Remover
                    </CancelDeleteAction>
                    <EditAction
                      type='button'
                      style={{backgroundColor: '#0000ee', color: '#fff'}}
                      onClick={() => setManagerToPermission({
                        manager: e,
                        type: 2
                      })}>
                      Permissões Especiais
                    </EditAction>
                  </p>
                </div>
              </CardBodyItem>
            );
          })}
        </CardBody>
      </Card>
    </Col>

    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <h5>Gestores de Centros de Custo</h5>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          {gccs.length === 0 && (<EmptyList />)}

          {gccs.map((e, i) => {
            return (
              <CardBodyItem key={i}>
                <div style={{width: '100%', padding: '0 4px'}}>
                  <SpaceBetweenDiv>
                    <div>
                      <p><b>Nome:</b> {e.name}</p>

                      <p><b>Email:</b> {e.email}</p>
                    </div>

                    <SliderToggle
                      label={'Status'}
                      checked={e.status}
                      click={() => handleStatusUser(e.email, e.status)}
                    />
                  </SpaceBetweenDiv>

                  <p>
                    <b>Ações:</b>
                  </p>

                  <p>
                    <EditAction
                      type='button'
                      onClick={() => setManagerToDetails({
                        manager: e,
                        type: 1
                      })}>
                      Detalhes
                    </EditAction>

                    <DeleteAction
                      style={{backgroundColor: '#000000'}}
                      type='button'
                      onClick={() => setManagerToEdit({
                        manager: e,
                        type: 1
                      })}>
                      Editar
                    </DeleteAction>

                    <CreateAction
                      type='button'
                      style={{backgroundColor: '#fbba37'}}
                      onClick={() => resetPassword({
                        manager: e,
                        type: 1
                      })}>
                      Resetar senha
                    </CreateAction>

                    <CancelDeleteAction
                      type='button'
                      onClick={() => setManagerToDelete({
                        manager: e,
                        type: 1
                      })}>
                      Remover
                    </CancelDeleteAction>
                  </p>
                </div>
              </CardBodyItem>
            );
          })}
        </CardBody>
      </Card>
    </Col>

    {/* <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <h5>Gestores Geral da Empresa</h5>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          {ges.length === 0 && (<EmptyList />)}

          {ges.map((e, i) => {
            return (
              <CardBodyItem key={i}>
                <div style={{width: '100%', padding: '0 4px'}}>
                  <SpaceBetweenDiv>
                    <div>
                      <p><b>Nome:</b> {e.name}</p>

                      <p><b>Email:</b> {e.email}</p>
                    </div>

                    <SliderToggle
                      label={'Status'}
                      checked={e.status}
                      click={() => handleStatusUser(e.email, e.status)}
                    />
                  </SpaceBetweenDiv>

                  <p>
                    <b>Ações:</b>
                  </p>

                  <p>
                    <EditAction
                      type='button'
                      onClick={() => setManagerToDetails({
                        manager: e,
                        type: 3
                      })}>
                      Detalhes
                    </EditAction>

                    <DeleteAction
                      type='button'
                      style={{backgroundColor: '#000000'}}
                      onClick={() => setManagerToEdit({
                        manager: e,
                        type: 3
                      })}>
                      Editar
                    </DeleteAction>

                    <CreateAction
                      type='button'
                      style={{backgroundColor: '#fbba37'}}
                      onClick={() => resetPassword({
                        manager: e,
                        type: 3
                      })}>
                      Resetar senha
                    </CreateAction>

                    <CancelDeleteAction
                      type='button'
                      onClick={() => setManagerToDelete({
                        manager: e,
                        type: 3
                      })}>
                      Remover
                    </CancelDeleteAction>
                  </p>
                </div>
              </CardBodyItem>
            );
          })}
        </CardBody>
      </Card>
    </Col> */}
  </Row>;
}

export default List;
