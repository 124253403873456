import React from 'react';

function Header() {
  return (
    <>
      <h1 className="page-header mb-3">
        Previsibilidade de Perdas
      </h1>
    </>
  );
}

export default Header;
